export const customStylesPageOnly = {
    rows: {
        style: {
            display: 'none',
            fontSize: '12px', // override the row height
        },
    },
    headCells: {
        style: {
            paddingLeft: '3px', // override the cell padding for head cells
            paddingRight: '3px',
            fontWeight: '800',
            border: '1px solid #AAAAAA',
            fontSize: '13px',
            color: '#000000',
            // backgroundColor : 'rgb(224, 224, 224)'
        },
    },
    cells: {
        style: {
            display: 'none',
            paddingLeft: '3px', // override the cell padding for data cells
            paddingRight: '3px',
            border: '1px solid #AAAAAA',
            color: '#000000',
            // background: "#FFF",
            fontWeight: '400',
            hover: 'rgba(0,0,0,.08)',
            // backgroundColor : 'rgb(224, 224, 224)'
        },
    },
  };