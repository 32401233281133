import React, { lazy, Suspense } from "react";
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from "react-router-dom";
import PrivateRoute from "./components/Layout/PrivateRoutes";
import Loading from "./components/Loading/Loading";
import FleggingVoucher from "pages/Tools/FleggingVoucher/FleggingVoucher";

const ResetPassword = lazy(() => import('pages/Login/ResetPassword'))
const APTransfer = lazy(() => import('pages_syariah/Fa/Finance/APTransfer/APTransfer'))
const DroppingBufferLibur = lazy(() => import('pages_syariah/Fa/Finance/DroppingBufferLibur/DroppingBufferLibur'))
const PenarikanBufferLibur = lazy(() => import('pages_syariah/Fa/Finance/PenarikanBufferLibur/PenarikanBufferLibur'))
const FullfillBufferLibur = lazy(() => import('pages_syariah/Fa/Finance/FullfillBufferLibur/FullfillBufferLibur'))
const PengajuanBufferTakeover = lazy(() => import('pages_syariah/Fa/Finance/PengajuanBufferTakeOver/PengajuanBufferTakeover'))
const MutasiBufferLiburv2 = lazy(() => import('pages_syariah/Fa/Finance/MutasiBufferLiburv2/MutasiBufferLiburv2'))
const PengajuanBufferLibur = lazy(() => import('pages_syariah/Fa/Finance/PengajuanBufferLibur/PengajuanBufferLibur'))
const MutasiBufferTakeOver = lazy(() => import('pages_syariah/Fa/Finance/BufferTakeoverNew/MutasiBufferTakeOverNew'))
const PelunasanAudit = lazy(() => import('pages_syariah/Audit/AuditVirtual/Customer/Pelunasan/PelunasanAudit'))
const PencairanCustomer = lazy(() => import('pages_syariah/Audit/AuditVirtual/Customer/PencairanCustomer/PencairanCustomer'))
const BapAudit = lazy(() => import('pages_syariah/Audit/AuditVirtual/Finance/Bap/BapAudit'))
const PettyCashAudit = lazy(() => import('pages_syariah/Audit/AuditVirtual/Finance/PettyCash/PettyCashAudit'))
const CloseCashierAudit = lazy(() => import('pages_syariah/Audit/AuditVirtual/Finance/CloseCashier/CloseCashierAudit'))
const OpenCashierAudit = lazy(() => import('pages_syariah/Audit/AuditVirtual/Finance/OpenCashier/OpenCashierAudit'))
const GeneralAudit = lazy(() => import('pages_syariah/Audit/GeneralAudit/GeneralAudit'))
const DaftarAudit = lazy(() => import('pages_syariah/Audit/GeneralAudit/DaftarAudit'))
const DetailAudit = lazy(() => import('pages_syariah/Audit/GeneralAudit/DetailAudit/DetailAudit'))

const ReportAuditVirtual = lazy(() => import('pages_syariah/ReportAudit/AuditVirtual/AuditVirtual'))
const ReportAuditGeneral = lazy(() => import('pages_syariah/Audit/GeneralAudit/ReportAudit/ReportAuditGeneral'))

const LaporanPenjualanGadai = lazy(() => import('pages_syariah/Report/Cabang/LaporanPenjualanGadai/LaporanPenjualanGadai'))
const LaporanPenjualanGadaiHO = lazy(() => import('pages_syariah/Report/Ho/LaporanPenjualanGadai/LaporanPenjualanGadai'))

const HasilPenanganan = lazy(() => import("pages_syariah/Utility/Master/Collection/HasilPenanganan/HasilPenanganan"))
const JenisPenanganan = lazy(() => import("pages_syariah/Utility/Master/Collection/JenisPenanganan/JenisPenanganan"))

const PenangananNasabah = lazy(() => import('pages_syariah/Aplikasi/Collection/PenanganNasabah/PenangananNasabah'))
const PenangananOvd = lazy(() => import('pages_syariah/Aplikasi/Collection/PenangananOVd/PenangananOvd'))
const JenisBlanko = lazy(() => import('pages_syariah/Utility/Master/Blanko/JenisBlanko/JenisBlanko'))
const UpdateButtonStoring = lazy(() => import('pages_syariah/Tools/ButtonStoringUlang/UpdateStatusNasabah'))

const SmsJatuhTempo = lazy(() => import('pages_syariah/Tools/SmsJatuhTempo/SmsJatuhTempo'))
const UpdateStatusNasabah = lazy(() => import('pages_syariah/Tools/UpdateStatusNasabah/UpdateStatusNasabah'))
const CombineExcel = lazy(() => import('pages_syariah/Tools/CombineExcel/CombineExcel'))
const PrintStoring = lazy(() => import('components/PdfPage/PrintStoring'))


const LaporanKasBankWilayah = lazy(() => import('pages_syariah/Report/Cabang/LaporanKasBankWilayah/LaporanKasBankWilayah'))
const LaporanPerfomanceCabang = lazy(() => import('pages_syariah/Report/Cabang/LaporanPerfomanceCabang/LaporanPerfomanceCabang'))
const QueryOvdCustomer = lazy(() => import('pages_syariah/Aplikasi/Collection/QueryOvdCustomer/QueryOvdCustomer'))
const LaporanBookingSummary = lazy(() => import('pages_syariah/Report/Ho/LaporanBookingSummary/LaporanBookingSummary'))
const LaporanBookingDetail = lazy(() => import('pages_syariah/Report/Ho/LaporanBookingDetail/LaporanBookingDetail'))
const LaporanOsVsTargetOs = lazy(() => import('pages_syariah/Report/Ho/LaaporanOsVsTargetOs/LaporanOsVsTargetOs'))
const LaporanSummmaryBap = lazy(() => import('pages_syariah/Report/Ho/summaryBap/LaporanSummmaryBap'))
const LaporanDireksi = lazy(() => import('pages_syariah/Report/Ho/LaporanDireksi/LaporanDireksi'))
const LaporanAverageOS = lazy(() => import('pages_syariah/Report/Ho/LaporanAverageOS/LaporanAverageOS'))
const LaporanDetailBAP = lazy(() => import('pages_syariah/Report/Ho/LaporanDetailBAP/laporanDetailBAP'))
const UpdateProsesGadai = lazy(() => import('pages_syariah/Aplikasi/Produk/UpdateProsesGadai/UpdateProsesGadai'))
const PencairanPrint = lazy(() => import('components/PdfPage/PencairanPrint'))
const LTVPrint = lazy(() => import('components/PdfPage/LTVPrint'))
const PelunasanPrint = lazy(() => import('components/PdfPage/PelunasanPrint'))
const BapPrint = lazy(() => import('components/PdfPage/BapPrint'))
const SbgPrint = lazy(() => import('components/PdfPage/Sbgprint'))
const FinPrint = lazy(() => import('components/PdfPage/FinPrint'))
const GeneralPrint = lazy(() => import('components/PdfPage/generalPrint'))
const LaporanPelunasanHO = lazy(() => import('pages_syariah/Report/Ho/LaporanPelunasan/LaporanPelunasan'))
const LaporanOutsandingHO = lazy(() => import('pages_syariah/Report/Ho/LaporanOutstanding/LaporanOutsanding'))
const LaporanBookingHO = lazy(() => import('pages_syariah/Report/Ho/LaporanBooking/LaporanBooking'))
const LaporanPenjualan = lazy(() => import('pages_syariah/Report/Cabang/LaporanPenjualan/LaporanPenjualan'))
const LaporanBarangJaminan = lazy(() => import('pages_syariah/Report/Cabang/LaporanBarangJaminan/LaporanBarangJaminan'))
const LaporanBarangJaminanHO = lazy(() => import('pages_syariah/Report/Ho/LaporanBarangJaminan/LaporanBarangJaminan'))
const MutasiBankAntarUnit = lazy(() => import('pages_syariah/Fa/Finance/MutasiBankAntarUnit/MutasiBankAntarUnit'))
const SetoranHO = lazy(() => import('pages_syariah/Fa/Finance/SetoranHO/SetoranHO'))
const LaporanSaldoKasBank = lazy(() => import('pages_syariah/Report/Cabang/LaporanSaldoKasBank/LaporanSaldoKasBank'))
const LaporanPelunasan = lazy(() => import('pages_syariah/Report/Cabang/LaporanPelunasan/LaporanPelunasan'))
const AdvanceEksternal = lazy(() => import('pages_syariah/Fa/Finance/AdvanceEksternal/AdvanceEksternal'))
const Suspend = lazy(() => import('pages_syariah/Fa/Finance/Suspend/Suspend'))
const RekonBank = lazy(() => import('pages_syariah/Fa/Finance/RekonBank/RekonBank'))
const LaporanAZ = lazy(() => import('pages_syariah/Report/Cabang/LaporanAZ/LaporanAZ'))
const AlasanPengeluaran = lazy(() => import('pages_syariah/Utility/Master/AlasanPengeluaran/AlasanPengeluaran'))
const QueryGadai = lazy(() => import('pages_syariah/Fa/Pembayaran/QuerySbgCair/QueryGadai/QueryGadai'))
const BAP = lazy(() => import('pages_syariah/Fa/Finance/BAP/BAP'))
const AdvanceInternal = lazy(() => import('pages_syariah/Fa/Finance/AdvanceInternal/AdvanceInternal'))
const HutangFintech = lazy(() => import('pages_syariah/Fa/Treasury/HutangFintech/HutangFintech'))
const PiutangFintech = lazy(() => import('pages_syariah/Fa/Treasury/PiutangFintech/PiutangFintech'))
const UbahPassword = lazy(() => import('pages/Login/UbahPassword'))
const MutasiFlag = lazy(() => import('pages_syariah/Fa/Treasury/MutasiFlag/MutasiFlag'))
const MutasiTitipan = lazy(() => import('pages_syariah/Fa/Pembayaran/Pelunasan/MutasiTitipan/MutasiTitipan'))
const MutasiBlanko = lazy(() => import('pages_syariah/Aplikasi/Blanko/MutasiBlanko/MutasiBlanko'))
const MutasiSbg = lazy(() => import('pages_syariah/Aplikasi/Inventory/MutasiSbg/MutasiSbg'))
const MutasiBarang = lazy(() => import('pages_syariah/Aplikasi/Inventory/MutasiBarang/MutasiBarang'))
const PenjualanBarangJaminan = lazy(() => import('pages_syariah/Aplikasi/Penjualan/PenjualanBarangJaminan/PenjualanBarangJaminan'))
const PengajuanBarangJaminan = lazy(() => import('pages_syariah/Aplikasi/Penjualan/PengajuanBarangJaminan/PengajuanBarangJaminan'))
const ApprovalPenjualanBarangJaminan = lazy(() => import('pages_syariah/Aplikasi/Penjualan/PengajuanBarangJaminan/PengajuanBarangJaminan'))
const DiskonJual = lazy(() => import('pages_syariah/Utility/MasterSetting/Penjualan/DiskonJual/DiskonJual'))
const HargaJual = lazy(() => import('pages_syariah/Utility/MasterSetting/Penjualan/HargaJual/HargaJual'))
const Funding = lazy(() => import('pages_syariah/Fa/Treasury/Funding/Funding'))
const SettingJF = lazy(() => import('pages_syariah/Utility/MasterSetting/SettingJf/SettingJF'))
const TipePartner = lazy(() => import('pages_syariah/Utility/Master/InformasiPartner/TipePartner/TipePartner'))
const Partner = lazy(() => import('pages_syariah/Utility/Master/InformasiPartner/Partner/Partner'))
const UnitBankFullFill = lazy(() => import('pages_syariah/Fa/Finance/UnitBankFullFill/UnitBankFullFill'))
const LaporanOutsanding = lazy(() => import('pages_syariah/Report/Cabang/LaporanOutstanding/LaporanOutsanding'))
const PencairanPerpanjangan = lazy(() => import('pages_syariah/Fa/Pembayaran/CairPerpanjang/PencairanPerpanjangan/PencairanPerpanjangan'))
const GenerateCek = lazy(() => import('pages_syariah/Utility/MasterF&A/GenerateCek/GenerateCek'))
const Lajur = lazy(() => import('pages_syariah/Utility/Master/Inventory/Lajur/Lajur'))
const Laci = lazy(() => import('pages_syariah/Utility/Master/Inventory/Laci/Laci'))
const OperasionalHarian = lazy(() => import('pages_syariah/Closing/Closing/OperasionalHarian/OperasionalHarian'))
const Brankas = lazy(() => import('pages_syariah/Utility/Master/Inventory/Brankas/Brankas'))
const Penerimaan = lazy(() => import('pages_syariah/Aplikasi/Inventory/Penerimaan/Penerimaan'))
const Login = lazy(() => import("./pages/Login/Login"));
const Kelurahan = lazy(() => import('./pages_syariah/Utility/Master/InformasiGeografis/Kelurahan/Kelurahan'))
const Wilayah = lazy(() => import('./pages_syariah/Utility/Master/InformasiGeografis/Wilayah/Wilayah'))
const Area = lazy(() => import('./pages_syariah/Utility/Master/InformasiGeografis/Area/Area'))
const JenisCabang = lazy(() => import('./pages_syariah/Utility/Master/InformasiCabang/JenisCabang/JenisCabang'))
const GradeCabang = lazy(() => import('./pages_syariah/Utility/Master/InformasiCabang/GradeCabang/GradeCabang'))
const LimitApproval = lazy(() => import('./pages_syariah/Utility/Master/InformasiCabang/LimitApproval/LimitApproval'))
const Bank = lazy(() => import('./pages_syariah/Utility/MasterF&A/Bank/Bank'))
const Cabang = lazy(() => import('./pages_syariah/Utility/Master/InformasiCabang/Cabang/Cabang'))
const Divisi = lazy(() => import("./pages_syariah/Utility/Master/InformasiKaryawan/Divisi/Divisi"));
const Jabatan = lazy(() => import("./pages_syariah/Utility/Master/InformasiKaryawan/Jabatan/Jabatan"));
const GradeKaryawan = lazy(() => import("./pages_syariah/Utility/Master/InformasiKaryawan/Grade/Grade"));
const KaryawanInternal = lazy(() => import('./pages_syariah/Utility/Master/InformasiKaryawan/KaryawanInternal/KaryawanInternal'))
const KaryawanEksternal = lazy(() => import('./pages_syariah/Utility/Master/InformasiKaryawan/KaryawanEksternal/KaryawanEksternal'))
const InfoCustPekerjaan = lazy(() => import("./pages_syariah/Utility/Master/InformasiCustomer/Pekerjaan/Pekerjaan"));
const ReferensiCustomer = lazy(() => import("./pages_syariah/Utility/Master/InformasiCustomer/ReferensiCustomer/ReferensiCustomer"));
const ReferensiTransaksi = lazy(() => import("./pages_syariah/Utility/Master/InformasiCustomer/ReferensiTransaksi/ReferensiTransaksi"));
const TujuanTransaksi = lazy(() => import("./pages_syariah/Utility/Master/InformasiCustomer/TujuanTransaksi/TujuanTransaksi"));
const AlasanBadCustomer = lazy(() => import("./pages_syariah/Utility/Master/InformasiCustomer/AlasanBadCustomer/AlasanBadCustomer"));
const SektorEkonomi = lazy(() => import("./pages_syariah/Utility/Master/InformasiCustomer/SektorEkonomi/SektorEkonomi"));
const AlasanBatal = lazy(() => import("./pages_syariah/Utility/Master/InformasiCustomer/AlasanBatal/AlasanBatal"));
const Pendidikan = lazy(() => import("./pages_syariah/Utility/Master/InformasiCustomer/Pendidikan/Pendidikan"));
const StatusRumah = lazy(() => import("./pages_syariah/Utility/Master/InformasiCustomer/StatusRumah/StatusRumah"));
const SumberDana = lazy(() => import("./pages_syariah/Utility/Master/InformasiCustomer/SumberDana/SumberDana"));
const BidangUsaha = lazy(() => import("./pages_syariah/Utility/Master/InformasiCustomer/BidangUsaha/BidangUsaha"));
const JabatanCustomer = lazy(() => import("./pages_syariah/Utility/Master/InformasiCustomer/JabatanCustomer/JabatanCustomer"));
const HasilFollowUp = lazy(() => import("./pages_syariah/Utility/Master/InformasiCustomer/HasilFollowUp/HasilFollowUp"));
const CaraFollowUp = lazy(() => import("./pages_syariah/Utility/Master/InformasiCustomer/CaraFollowUp/CaraFollowUp"));
const Produk = lazy(() => import("./pages_syariah/Utility/MasterSetting/Produk/ProdukGadaiNew/ProdukGadaiNew"));
const Program = lazy(() => import('./pages_syariah/Utility/Master/InformasiCustomer/Program/Program'))
const Level = lazy(() => import("./pages_syariah/Utility/UserManagement/Level/Level"));
const User = lazy(() => import("./pages_syariah/Utility/UserManagement/User/User"));
const CashFlow = lazy(() => import('./pages_syariah/Utility/MasterF&A/CashFlow/CashFlow'))
const UsedFor = lazy(() => import('./pages_syariah/Utility/MasterF&A/UsedFor/UsedFor'))
const ChartOfAccount = lazy(() => import('./pages_syariah/Utility/MasterF&A/ChartOfAccount/ChartOfAccount'))
const SettingAccounting = lazy(() => import('./pages_syariah/Utility/MasterF&A/SettingAccounting/SettingAccounting'))
const JenisBarang = lazy(() => import('./pages_syariah/Utility/Master/Barang/JenisBarang/JenisBarang'))
const JenisEmas = lazy(() => import('./pages_syariah/Utility/Master/Barang/JenisEmas/JenisEmas'))
const BarangEmas = lazy(() => import('./pages_syariah/Utility/Master/Barang/BarangEmas/BarangEmas'))
const HargaSTLE = lazy(() => import("./pages_syariah/Utility/MasterSetting/HargaSTLE/HargaSTLE"));
const Rate = lazy(() => import("./pages_syariah/Utility/MasterSetting/Produk/Rate/Rate"));
const Absen = lazy(() => import("./pages_syariah/Absen/Absen"));
const OpenCashier = lazy(() => import('./pages_syariah/Fa/Finance/OpenCashier/OpenCashier'))
const TaksirEmas = lazy(() => import('./pages_syariah/Aplikasi/Taksir/Taksir/TaksirEmas/TaksirEmas'))
const PerpanjanganTaksirEmas = lazy(() => import('./pages_syariah/Aplikasi/Taksir/PerpanjanganTaksir/TaksirEmas/TaksirEmas'))
const Customer = lazy(() => import('./pages_syariah/Utility/Master/Customer/Customer'))
const JenisReferensi = lazy(() => import("./pages_syariah/Utility/Master/InformasiCustomer/JenisReferensi/JenisReferensi"));
const Agama = lazy(() => import("./pages_syariah/Utility/Master/InformasiCustomer/Agama/Agama"));
const JenisTransaksi = lazy(() => import('./pages_syariah/Utility/MasterF&A/JenisTransaksi/JenisTransaksi'))
const MutasiInternalCabang = lazy(() => import('./pages_syariah/Fa/Finance/MutasiInternalCabang/MutasiInternalCabang'))
const PraTaksirEmas = lazy(() => import('./pages_syariah/Aplikasi/PraTaksir/Emas/PraTaksirEmas'))
const ApprovalTaksirEmas = lazy(() => import('./pages_syariah/Aplikasi/Taksir/ApprovalTaksir/TaksirEmas/TaksirEmas'))
const GenerateBlanko = lazy(() => import('./pages_syariah/Aplikasi/Blanko/GenerateBlanko/GenerateBlanko'))
const Gadai = lazy(() => import('pages_syariah/Aplikasi/Produk/GadaiNew/GadaiNew'))
const ApprovalGadai = lazy(() => import('pages_syariah/Aplikasi/Approval/ApprovalGadaiNew/ApprovalGadaiNew'))
const PencairanGadai = lazy(() => import('pages_syariah/Fa/Pembayaran/CairPerpanjang/PencairanGadai/PencairanGadai'))
const PencairanVoucher = lazy(() => import('pages_syariah/Fa/Pembayaran/CairPerpanjang/PencairanVoucher/PencairanVoucher'))
const MutasiBufferLibur = lazy(() => import('pages_syariah/Fa/Finance/MutasiBufferLibur/MutasiBufferLibur'))
const MutasiHoCabang = lazy(() => import('pages_syariah/Fa/Finance/MutasiHoCabang/MutasiHoCabang'))
const MutasiCabangUnit = lazy(() => import('pages_syariah/Fa/Finance/MutasiCabangUnit/MutasiCabangUnit'))
const InputTitipan = lazy(() => import('pages_syariah/Fa/Pembayaran/Pelunasan/InputTitipan/InputTitipan'))
const CloseCashier = lazy(() => import('./pages_syariah/Fa/Finance/CloseCashier/CloseCashier'))
const MasterBatch = lazy(() => import('./pages_syariah/Utility/MasterF&A/Batch/Batch'))
const SimulasiPelunasan = lazy(() => import('pages_syariah/Fa/Pembayaran/Pelunasan/SimulasiPelunasan/SimulasiPelunasan'))
const PelunasanGadai = lazy(() => import('pages_syariah/Fa/Pembayaran/Pelunasan/PelunasanGadai/PelunasanGadai'))
const ApprovalPelunasan = lazy(() => import('pages_syariah/Aplikasi/Approval/ApprovalPelunasan/ApprovalPelunasan'))
const Pengeluaran = lazy(() => import('pages_syariah/Aplikasi/Inventory/Pengeluaran/Pengeluaran'))
const LKH = lazy(() => import('./pages_syariah/Report/Cabang/LaporanKasHarian/LaporanKasHarian'))
const LaporanMutasiBank = lazy(() => import('./pages_syariah/Report/Cabang/LaporanMutasiBank/LaporanMutasiBank'))
const LaporanBooking = lazy(() => import('pages_syariah/Report/Cabang/LaporanBooking/LaporanBooking'))
const PenyerahanKeNasabah = lazy(() => import('pages_syariah/Aplikasi/Inventory/PenyerahanKeNasabah/PenyerahanKeNasabah'))
const UpdatePassword = lazy(() => import("./pages_syariah/Login/UpdatePassword"));
const Inventory = lazy(() => import('pages_syariah/Aplikasi/Inventory/Inventory/Inventory'))
const JointFinancing = lazy(() => import('pages_syariah/Fa/Treasury/JointFinancing/JointFinancing'))
const KasMarketing = lazy(() => import('pages_syariah/Fa/Finance/KasMarketing/KasMarketing'))
const KasMarketingHoCabang = lazy(() => import('pages_syariah/Fa/Finance/KasMarketingHoCabang/KasMarketingHoCabang'))
const KasMarketingCabangUnit = lazy(() => import('pages_syariah/Fa/Finance/KasMarketingCabangUnit/KasMarketingCabangUnit'))
const FullfillBufferHarian = lazy(() => import('pages_syariah/Fa/Finance/FullfillBufferHarian/FullfillBufferHarian'));
const DroppingBufferHarian = lazy(() => import('pages_syariah/Fa/Finance/DroppingBufferHarian/DroppingBufferHarian'));
const PenarikanBufferHarian = lazy(() => import('pages_syariah/Fa/Finance/PenarikanBufferHarian/PenarikanBufferHarian'));
const PengajuanBufferHarian = lazy(() => import('pages_syariah/Fa/Finance/PengajuanBufferHarian/PengajuanBufferHarian'));
const PlafonGiroCek = lazy(() => import('pages_syariah/Fa/Finance/PlafonGiroCek/PlafonGiroCek'));
const FullfillTakeOver = lazy(() => import('pages_syariah/Fa/Finance/FullfillTakeOver/FullfillTakeOver'));
const DroppingBufferTakeOver = lazy(() => import('pages_syariah/Fa/Finance/DroppingBufferTakeOver/DroppingBufferTakeOver'));
const PenarikanBufferTakeOver = lazy(() => import('pages_syariah/Fa/Finance/PenarikanBufferTakeOver/PenarikanBufferTakeOver'));
const BufferWalkIn = lazy(() => import('pages_syariah/Fa/Finance/BufferWalkIn/BuffeWalkIn'));
const PenarikanBufferWalkIn = lazy(() => import('pages_syariah/Fa/Finance/PenarikanBufferWalkIn/PenarikanBufferWalkIn'))
const DroppingBufferWalkIn = lazy(() => import('pages_syariah/Fa/Finance/DroppingBufferWalkIn/DroppingBufferWalkIn'))
const FullfillBufferWalkIn = lazy(() => import('pages_syariah/Fa/Finance/FullfillBufferWalkIn/FullfillBufferWalkIn'))
const LaporanPenggunaanSistem = lazy(() => import('./pages_syariah/ReportAudit/LaporanPenggunaanSistem/LaporanPenggunaanSistem'))
const LaporanPotensiTopUp = lazy(() => import('./pages_syariah/Report/Cabang/LaporanPotensiSiTopUp/LaporanPotensiTopUp'))

const LaporanBufferLibur = lazy(() => import('pages_syariah/Report/Cabang/LaporanBufferLibur/LaporanBufferLibur'))
const LaporanBufferWalkin = lazy(() => import('pages_syariah/Report/Cabang/LaporanBufferWalkin/LaporanBufferWalkIn'))
const LaporanBufferHarian = lazy(() => import('pages_syariah/Report/Cabang/LaporanBufferHarian/LaporanBufferHarian'))
const LaporanBufferTakeover = lazy(() => import('pages_syariah/Report/Cabang/LaporanBufferTakeover/LaporanBufferTakeover'))
const LaporanBufferLiburHO = lazy(() => import('pages_syariah/Report/Ho/LaporanBufferLibur/LaporanBufferLibur'))
const LaporanBufferWalkinHO = lazy(() => import('pages_syariah/Report/Ho/LaporanBufferWalkin/LaporanBufferWalkIn'))
const LaporanBufferHarianHO = lazy(() => import('pages_syariah/Report/Ho/LaporanBufferHarian/LaporanBufferHarian'))
const LaporanBufferTakeoverHO = lazy(() => import('pages_syariah/Report/Ho/LaporanBufferTakeover/LaporanBufferTakeover'))
const FotoSelfiePencairanNasabah = lazy(() => import('./pages_syariah/Fa/Pembayaran/CairPerpanjang/FotoSelfiePencairanNasabah/FotoselfiePencairanNasabah'))
const LaporanApTransfer = lazy(() => import('./pages_syariah/Report/Ho/LaporanApTransfer/LaporanApTransfer'))
const LaporanPenjualanJaminan = lazy(()=> import('./pages_syariah/Report/Cabang/LaporanPenjualanJaminan/LaporanPenjualanJaminan'))
const LaporanPenjualanJaminanHO = lazy(()=> import('./pages_syariah/Report/Ho/LaporanPenjualanJaminan/LaporanPenjualanJaminan'))

const AppSyariah = ({ props }) => {
  return (
    <Routes>
      <Route path="/" element={<Navigate replace to="/login" />} />
      <Route exact path="/login" element={<Login />} />
      <Route exact path="/home" element={<PrivateRoute><div></div> </PrivateRoute>} />
      <Route exact path="/reset-password" element={<ResetPassword />} />
      <Route exact path="/cetak" element={<GeneralPrint />} />
      <Route exact path="/cetakStoring" element={<PrintStoring />} />
      <Route exact path="/cetakPelunasan" element={<PelunasanPrint />} />
      <Route exact path="/cetakPencairan" element={<PencairanPrint />} />
      <Route exact path="/cetakfin" element={<FinPrint />} />
      <Route exact path="/cetakltv" element={<LTVPrint />} />
      <Route exact path="/cetakbap" element={<BapPrint />} />
      <Route exact path="/cetaksbg" element={<SbgPrint />} />
      <Route exact path="/reset-password" element={<UpdatePassword />} />
      <Route exact path="/ubah-password" element={<PrivateRoute><UbahPassword /></PrivateRoute>} />
      <Route exact path="/home" element={<PrivateRoute><div></div> </PrivateRoute>} />
      <Route exact path="/absen" element={<Absen />} />
      <Route exact path="/utility" element={<PrivateRoute></PrivateRoute>} />
      <Route exact path="/utility/master/informasi-geografis/kelurahan" element={<PrivateRoute><Kelurahan /> </PrivateRoute>} />
      <Route exact path="/utility/master/informasi-geografis/wilayah" element={<PrivateRoute><Wilayah /> </PrivateRoute>} />
      <Route exact path="/utility/master/informasi-geografis/area" element={<PrivateRoute><Area /> </PrivateRoute>} />

      <Route exact path="/utility/master/informasi-cabang/cabang" element={<PrivateRoute><Cabang /> </PrivateRoute>} />
      <Route exact path="/utility/master/informasi-cabang/jenis-cabang" element={<PrivateRoute><JenisCabang /> </PrivateRoute>} />
      <Route exact path="/utility/master/informasi-cabang/grade-cabang" element={<PrivateRoute><GradeCabang /> </PrivateRoute>} />
      <Route exact path="/utility/master/informasi-cabang/limit-approval" element={<PrivateRoute><LimitApproval /> </PrivateRoute>} />

      <Route exact path="/utility/master/informasi-karyawan/divisi" element={<PrivateRoute><Divisi /> </PrivateRoute>} />
      <Route exact path="/utility/master/informasi-karyawan/jabatan" element={<PrivateRoute><Jabatan /> </PrivateRoute>} />
      <Route exact path="/utility/master/informasi-karyawan/grade-karyawan" element={<PrivateRoute><GradeKaryawan /> </PrivateRoute>} />
      <Route exact path="/utility/master/informasi-karyawan/karyawan-internal" element={<PrivateRoute><KaryawanInternal /> </PrivateRoute>} />
      <Route exact path="/utility/master/informasi-karyawan/karyawan-eksternal" element={<PrivateRoute><KaryawanEksternal /> </PrivateRoute>} />

      <Route exact path="/utility/master/informasi-customer/pekerjaan" element={<PrivateRoute><InfoCustPekerjaan /> </PrivateRoute>} />
      <Route exact path="/utility/master/informasi-customer/referensi-customer" element={<PrivateRoute><ReferensiCustomer /> </PrivateRoute>} />
      <Route exact path="/utility/master/informasi-customer/referensi-transaksi" element={<PrivateRoute><ReferensiTransaksi /> </PrivateRoute>} />
      <Route exact path="/utility/master/informasi-customer/tujuan-transaksi" element={<PrivateRoute><TujuanTransaksi /> </PrivateRoute>} />
      <Route exact path="/utility/master/informasi-customer/alasan-bad-customer" element={<PrivateRoute><AlasanBadCustomer /> </PrivateRoute>} />
      <Route exact path="/utility/master/informasi-customer/sektor-ekonomi" element={<PrivateRoute><SektorEkonomi /> </PrivateRoute>} />
      <Route exact path="/utility/master/informasi-customer/alasan-batal" element={<PrivateRoute><AlasanBatal /> </PrivateRoute>} />
      <Route exact path="/utility/master/informasi-customer/pendidikan" element={<PrivateRoute><Pendidikan /> </PrivateRoute>} />
      <Route exact path="/utility/master/informasi-customer/status-rumah" element={<PrivateRoute><StatusRumah /> </PrivateRoute>} />
      <Route exact path="/utility/master/informasi-customer/sumber-dana" element={<PrivateRoute><SumberDana /> </PrivateRoute>} />
      <Route exact path="/utility/master/informasi-customer/bidang-usaha" element={<PrivateRoute><BidangUsaha /> </PrivateRoute>} />
      <Route exact path="/utility/master/informasi-customer/jabatan-customer" element={<PrivateRoute><JabatanCustomer /> </PrivateRoute>} />
      <Route exact path="/utility/master/informasi-customer/hasil-follow-up" element={<PrivateRoute><HasilFollowUp /> </PrivateRoute>} />
      <Route exact path="/utility/master/informasi-customer/cara-follow-up" element={<PrivateRoute><CaraFollowUp /> </PrivateRoute>} />
      <Route exact path="/utility/master/informasi-customer/program" element={<PrivateRoute><Program /> </PrivateRoute>} />
      <Route exact path="/utility/master/informasi-customer/jenis-referensi" element={<PrivateRoute><JenisReferensi /> </PrivateRoute>} />
      <Route exact path="/utility/master/informasi-customer/agama" element={<PrivateRoute><Agama /> </PrivateRoute>} />

      <Route exact path="/utility/master/barang/jenis-barang" element={<PrivateRoute><JenisBarang /> </PrivateRoute>} />
      <Route exact path="/utility/master/barang/jenis-emas" element={<PrivateRoute><JenisEmas /> </PrivateRoute>} />
      <Route exact path="/utility/master/barang/barang-emas" element={<PrivateRoute><BarangEmas /> </PrivateRoute>} />

      <Route exact path="/utility/master/inventory/brankas" element={<PrivateRoute><Brankas /> </PrivateRoute>} />
      <Route exact path="/utility/master/informasi-partner/tipe-partner" element={<PrivateRoute><TipePartner /> </PrivateRoute>} />
      <Route exact path="/utility/master/informasi-partner/partner" element={<PrivateRoute><Partner /> </PrivateRoute>} />

      <Route exact path="/utility/master-fa/bank" element={<PrivateRoute><Bank /> </PrivateRoute>} />
      <Route exact path="/utility/master-fa/cash-flow" element={<PrivateRoute><CashFlow /> </PrivateRoute>} />
      <Route exact path="/utility/master-fa/used-for" element={<PrivateRoute><UsedFor /> </PrivateRoute>} />
      <Route exact path="/utility/master-fa/Chart-Of-Account" element={<PrivateRoute><ChartOfAccount /> </PrivateRoute>} />
      <Route exact path="/utility/master-fa/Batch" element={<PrivateRoute><MasterBatch /> </PrivateRoute>} />
      <Route exact path="/utility/master-fa/setting-accounting" element={<PrivateRoute><SettingAccounting /> </PrivateRoute>} />
      <Route exact path="/utility/master-fa/jenis-transaksi" element={<PrivateRoute><JenisTransaksi /> </PrivateRoute>} />
      <Route exact path="/utility/master-fa/generate-cek" element={<PrivateRoute><GenerateCek /> </PrivateRoute>} />
      <Route exact path="/utility/master/alasan-pengeluaran" element={<PrivateRoute><AlasanPengeluaran /> </PrivateRoute>} />

      <Route exact path="/utility/master/blanko/jenis-blanko" element={<PrivateRoute><JenisBlanko /> </PrivateRoute>} />

      <Route exact path="/utility/master/collection/hasil-penanganan" element={<PrivateRoute><HasilPenanganan /> </PrivateRoute>} />
      <Route exact path="/utility/master/collection/jenis-penanganan" element={<PrivateRoute><JenisPenanganan /> </PrivateRoute>} />


      <Route exact path="/aplikasi" element={<PrivateRoute></PrivateRoute>} />
      <Route exact path="/aplikasi/pra-taksir/emas" element={<PrivateRoute><PraTaksirEmas /> </PrivateRoute>} />
      <Route exact path="/aplikasi/taksir/taksir/taksir-emas" element={<PrivateRoute><TaksirEmas /> </PrivateRoute>} />
      <Route exact path="/aplikasi/taksir/perpanjangan-taksir/taksir-emas" element={<PrivateRoute><PerpanjanganTaksirEmas /> </PrivateRoute>} />
      <Route exact path="/aplikasi/taksir/approval-taksir/taksir-emas" element={<PrivateRoute><ApprovalTaksirEmas /> </PrivateRoute>} />
      <Route exact path="/aplikasi/blanko/generate-blanko" element={<PrivateRoute><GenerateBlanko /> </PrivateRoute>} />
      <Route exact path="/aplikasi/blanko/mutasi-blanko" element={<PrivateRoute><MutasiBlanko /> </PrivateRoute>} />
      <Route exact path="/aplikasi/inventory/inventory" element={<PrivateRoute><Inventory /> </PrivateRoute>} />
      <Route exact path="/aplikasi/inventory/penerimaan" element={<PrivateRoute><Penerimaan /> </PrivateRoute>} />
      <Route exact path="/aplikasi/inventory/pengeluaran" element={<PrivateRoute><Pengeluaran /> </PrivateRoute>} />
      <Route exact path="/aplikasi/inventory/mutasi-sbg" element={<PrivateRoute><MutasiSbg /> </PrivateRoute>} />
      <Route exact path="/aplikasi/inventory/mutasi-barang" element={<PrivateRoute><MutasiBarang /> </PrivateRoute>} />
      <Route exact path="/aplikasi/produk/gadai" element={<PrivateRoute><Gadai /></PrivateRoute>} />
      <Route exact path="/aplikasi/approval/approval-gadai" element={<PrivateRoute><ApprovalGadai /></PrivateRoute>} />
      <Route exact path="/aplikasi/approval/approval-pelunasan" element={<PrivateRoute><ApprovalPelunasan /></PrivateRoute>} />
      <Route exact path="/aplikasi/inventory/penyerahan-ke-nasabah" element={<PrivateRoute><PenyerahanKeNasabah /> </PrivateRoute>} />
      <Route exact path="/aplikasi/penjualan/penjualan-barang-jaminan" element={<PrivateRoute><PenjualanBarangJaminan /> </PrivateRoute>} />
      <Route exact path="/aplikasi/penjualan/pengajuan-penjualan-barang-jaminan" element={<PrivateRoute><PengajuanBarangJaminan /> </PrivateRoute>} />
      <Route exact path="/aplikasi/penjualan/approval-penjualan-barang-jaminan" element={<PrivateRoute><ApprovalPenjualanBarangJaminan /> </PrivateRoute>} />
      <Route exact path="/aplikasi/tools/update-proses-gadai" element={<PrivateRoute><UpdateProsesGadai /></PrivateRoute>} />
      <Route exact path="/aplikasi/tools/update-status-nasabah" element={<PrivateRoute><UpdateStatusNasabah /></PrivateRoute>} />
      <Route exact path="/aplikasi/tools/flegding-cetak-ulang-storing" element={<PrivateRoute><UpdateButtonStoring /> </PrivateRoute>} />
      <Route exact path="/aplikasi/collection/query-overdue-customer" element={<PrivateRoute><QueryOvdCustomer /> </PrivateRoute>} />
      <Route exact path="/aplikasi/collection/penanganan-ovd" element={<PrivateRoute><PenangananOvd /> </PrivateRoute>} />
      <Route exact path="/aplikasi/collection/penanganan-nasabah" element={<PrivateRoute><PenangananNasabah /> </PrivateRoute>} />

      <Route exact path="/fa" element={<PrivateRoute></PrivateRoute>} />
      <Route exact path="/fa/finance/open-cashier" element={<PrivateRoute><OpenCashier /> </PrivateRoute>} />
      <Route exact path="/fa/finance/close-cashier" element={<PrivateRoute><CloseCashier /> </PrivateRoute>} />
      <Route exact path="/fa/finance/mutasi-internal-cabang" element={<PrivateRoute><MutasiInternalCabang /> </PrivateRoute>} />
      <Route exact path="/fa/finance/mutasi-buffer-urgent-&-libur" element={<PrivateRoute><MutasiBufferLibur /> </PrivateRoute>} />
      <Route exact path="/fa/finance/mutasi-ho-cabang" element={<PrivateRoute><MutasiHoCabang /> </PrivateRoute>} />
      <Route exact path="/fa/finance/mutasi-bank-antar-unit" element={<PrivateRoute><MutasiBankAntarUnit /> </PrivateRoute>} />
      <Route exact path="/fa/finance/setoran-ho" element={<PrivateRoute><SetoranHO /> </PrivateRoute>} />
      <Route exact path="/fa/finance/mutasi-cabang-unit" element={<PrivateRoute><MutasiCabangUnit /> </PrivateRoute>} />
      <Route exact path="/fa/finance/bap" element={<PrivateRoute><BAP /> </PrivateRoute>} />
      <Route exact path="/fa/finance/advance-internal" element={<PrivateRoute><AdvanceInternal /> </PrivateRoute>} />
      <Route exact path="/fa/finance/advance-eksternal" element={<PrivateRoute><AdvanceEksternal /> </PrivateRoute>} />
      <Route exact path="/fa/finance/rekon-bank" element={<PrivateRoute><RekonBank /> </PrivateRoute>} />
      <Route exact path="/fa/finance/suspend" element={<PrivateRoute><Suspend /> </PrivateRoute>} />
      <Route exact path="/fa/pembayaran/cair-perpanjang/pencairan-gadai" element={<PrivateRoute><PencairanPerpanjangan /> </PrivateRoute>} />
      <Route exact path="/fa/finance/kas-marketing" element={<PrivateRoute><KasMarketing /> </PrivateRoute>} />
      <Route exact path="/fa/finance/kas-marketing-ho-cabang" element={<PrivateRoute><KasMarketingHoCabang /> </PrivateRoute>} />
      <Route exact path="/fa/finance/kas-marketing-cabang-unit" element={<PrivateRoute><KasMarketingCabangUnit /> </PrivateRoute>} />
      <Route exact path="/fa/finance/full-fill-buffer-harian" element={<PrivateRoute><FullfillBufferHarian /></PrivateRoute>} />
      <Route exact path="/fa/finance/dropping-buffer-harian" element={<PrivateRoute><DroppingBufferHarian /></PrivateRoute>} />
      <Route exact path="/fa/finance/penarikan-buffer-harian" element={<PrivateRoute><PenarikanBufferHarian /></PrivateRoute>} />
      <Route exact path="/fa/finance/pengajuan-buffer-harian" element={<PrivateRoute><PengajuanBufferHarian /></PrivateRoute>} />
      <Route exact path="/fa/finance/plafon-giro-cek" element={<PrivateRoute><PlafonGiroCek /> </PrivateRoute>} />
      <Route exact path="/fa/finance/buffer-takeover" element={<PrivateRoute><MutasiBufferTakeOver /> </PrivateRoute>} />
      <Route exact path="/fa/finance/full-fill-takeover" element={<PrivateRoute><FullfillTakeOver /></PrivateRoute>} />
      <Route exact path="/fa/finance/pengajuan-buffer-takeover" element={<PrivateRoute><PengajuanBufferTakeover /></PrivateRoute>} />
      <Route exact path="/fa/finance/dropping-buffer-takeover" element={<PrivateRoute><DroppingBufferTakeOver /></PrivateRoute>} />
      <Route exact path="/fa/finance/penarikan-buffer-takeover" element={<PrivateRoute><PenarikanBufferTakeOver /></PrivateRoute>} />
      <Route exact path="/fa/finance/buffer-walk-in" element={<PrivateRoute><BufferWalkIn /></PrivateRoute>} />
      <Route exact path="/fa/finance/full-fill-buffer-walk-in" element={<PrivateRoute><FullfillBufferWalkIn /></PrivateRoute>} />
      <Route exact path="/fa/finance/dropping-buffer-walk-in" element={<PrivateRoute><DroppingBufferWalkIn /></PrivateRoute>} />
      <Route exact path="/fa/finance/penarikan-buffer-walk-in" element={<PrivateRoute><PenarikanBufferWalkIn /></PrivateRoute>} />
      <Route exact path="/fa/finance/ap-transfer" element={<PrivateRoute><APTransfer /></PrivateRoute>} />


      <Route exact path="/fa/finance/buffer-libur" element={<PrivateRoute><MutasiBufferLiburv2 /> </PrivateRoute>} />
      <Route exact path="/fa/finance/pengajuan-buffer-libur" element={<PrivateRoute><PengajuanBufferLibur /> </PrivateRoute>} />
      <Route exact path="/fa/finance/full-fill-buffer-libur" element={<PrivateRoute><FullfillBufferLibur /> </PrivateRoute>} />
      <Route exact path="/fa/finance/dropping-buffer-libur" element={<PrivateRoute><DroppingBufferLibur /> </PrivateRoute>} />
      <Route exact path="/fa/finance/penarikan-buffer-libur" element={<PrivateRoute><PenarikanBufferLibur /> </PrivateRoute>} />

      {/* <Route exact path="/fa/pembayaran/cair-perpanjang/pencairan-perpanjangan" element={<PrivateRoute><PencairanPerpanjangan /> </PrivateRoute>} /> */}
      <Route exact path="/fa/pembayaran/query-sbg-cair/query-gadai" element={<PrivateRoute><QueryGadai /> </PrivateRoute>} />
      <Route exact path="/fa/pembayaran/cair-perpanjang/pencairan-voucher" element={<PrivateRoute><PencairanVoucher /> </PrivateRoute>} />
      <Route exact path="/fa/pembayaran/pelunasan/input-titipan" element={<PrivateRoute><InputTitipan /> </PrivateRoute>} />
      <Route exact path="/fa/pembayaran/pelunasan/simulasi-pelunasan" element={<PrivateRoute><SimulasiPelunasan /> </PrivateRoute>} />
      <Route exact path="/fa/pembayaran/pelunasan/pelunasan-gadai" element={<PrivateRoute><PelunasanGadai /> </PrivateRoute>} />
      <Route exact path="/fa/finance/unit-bank-fullfill" element={<PrivateRoute><UnitBankFullFill /> </PrivateRoute>} />
      <Route exact path="/fa/pembayaran/pelunasan/mutasi-titipan" element={<PrivateRoute><MutasiTitipan /> </PrivateRoute>} />

      <Route exact path="/fa/treasury/funding" element={<PrivateRoute><Funding /> </PrivateRoute>} />
      <Route exact path="/fa/treasury/mutasi-flag" element={<PrivateRoute><MutasiFlag /> </PrivateRoute>} />
      <Route exact path="/fa/treasury/joint-financing" element={<PrivateRoute><JointFinancing /> </PrivateRoute>} />
      <Route exact path="/fa/treasury/piutang-fintech" element={<PrivateRoute><PiutangFintech /> </PrivateRoute>} />
      <Route exact path="/fa/treasury/hutang-fintech" element={<PrivateRoute><HutangFintech /> </PrivateRoute>} />

      <Route exact path="/closing" element={<PrivateRoute></PrivateRoute>} />
      <Route exact path="/closing/closing/operasional-harian" element={<PrivateRoute><OperasionalHarian /></PrivateRoute>} />

      <Route exact path="/report" element={<PrivateRoute></PrivateRoute>} />

      <Route exact path="/utility/master-setting/produk/produk-gadai" element={<PrivateRoute><Produk /> </PrivateRoute>} />
      <Route exact path="/utility/master-setting/produk/rate" element={<PrivateRoute><Rate /> </PrivateRoute>} />
      <Route exact path="/utility/master-setting/harga-stle" element={<PrivateRoute><HargaSTLE /> </PrivateRoute>} />
      <Route exact path="/utility/master-setting/setting-jf" element={<PrivateRoute><SettingJF /> </PrivateRoute>} />

      <Route exact path="/utility/master-setting/harga-diskon-penjualan" element={<PrivateRoute><DiskonJual /> </PrivateRoute>} />
      <Route exact path="/utility/master-setting/harga-jual" element={<PrivateRoute><HargaJual /> </PrivateRoute>} />

      <Route exact path="/utility/user-management/level" element={<PrivateRoute><Level /> </PrivateRoute>} />
      <Route exact path="/utility/user-management/user" element={<PrivateRoute><User /> </PrivateRoute>} />
      <Route exact path="/utility/master/customer" element={<PrivateRoute><Customer /> </PrivateRoute>} />
      <Route exact path="utility/master/inventory/brankas" element={<PrivateRoute><Brankas /> </PrivateRoute>} />
      <Route exact path="utility/master/inventory/Laci" element={<PrivateRoute><Laci /> </PrivateRoute>} />
      <Route exact path="utility/master/inventory/Lajur" element={<PrivateRoute><Lajur /> </PrivateRoute>} />

      <Route exact path="/report/cabang/laporan-kas-harian" element={<PrivateRoute><LKH /> </PrivateRoute>} />
      <Route exact path="/report/cabang/laporan-outstanding" element={<PrivateRoute><LaporanOutsanding /> </PrivateRoute>} />
      <Route exact path="/report/cabang/laporan-booking" element={<PrivateRoute><LaporanBooking /> </PrivateRoute>} />
      <Route exact path="/report/cabang/laporan-pelunasan" element={<PrivateRoute><LaporanPelunasan /> </PrivateRoute>} />
      <Route exact path="/report/cabang/laporan-mutasi-bank" element={<PrivateRoute><LaporanMutasiBank /> </PrivateRoute>} />
      <Route exact path="/report/cabang/laporan-saldo-kas-bank" element={<PrivateRoute><LaporanSaldoKasBank /> </PrivateRoute>} />
      <Route exact path="/report/cabang/laporan-az" element={<PrivateRoute><LaporanAZ /> </PrivateRoute>} />
      <Route exact path="/report/cabang/laporan-penjualan" element={<PrivateRoute><LaporanPenjualan /> </PrivateRoute>} />
      <Route exact path="/report/cabang/laporan-barang-jaminan" element={<PrivateRoute><LaporanBarangJaminan /> </PrivateRoute>} />
      <Route exact path="/report/cabang/laporan-ovd-customer" element={<PrivateRoute><QueryOvdCustomer /> </PrivateRoute>} />
      <Route exact path="/report/cabang/laporan-performance-cabang-harian" element={<PrivateRoute><LaporanPerfomanceCabang /> </PrivateRoute>} />
      <Route exact path="/report/cabang/laporan-kas-bank-wilayah" element={<PrivateRoute><LaporanKasBankWilayah /> </PrivateRoute>} />
      <Route exact path="/report/cabang/laporan-penjualan-gadai" element={<PrivateRoute><LaporanPenjualanGadai /> </PrivateRoute>} />
      <Route exact path="/report/ho/laporan-barang-jaminan" element={<PrivateRoute><LaporanBarangJaminanHO /> </PrivateRoute>} />
      <Route exact path="/report/ho/laporan-outstanding" element={<PrivateRoute><LaporanOutsandingHO /> </PrivateRoute>} />
      <Route exact path="/report/ho/laporan-booking" element={<PrivateRoute><LaporanBookingHO /> </PrivateRoute>} />
      <Route exact path="/report/ho/laporan-pelunasan" element={<PrivateRoute><LaporanPelunasanHO /> </PrivateRoute>} />
      <Route exact path="/report/ho/laporan-direksi" element={<PrivateRoute><LaporanDireksi /> </PrivateRoute>} />
      <Route exact path="/report/ho/laporan-average-os" element={<PrivateRoute><LaporanAverageOS /> </PrivateRoute>} />
      <Route exact path="/report/ho/laporan-os-vs-target-os" element={<PrivateRoute><LaporanOsVsTargetOs /> </PrivateRoute>} />
      <Route exact path="/report/ho/laporan-summary-bap" element={<PrivateRoute><LaporanSummmaryBap /> </PrivateRoute>} />
      <Route exact path="/report/ho/laporan-detail-bap" element={<PrivateRoute><LaporanDetailBAP /> </PrivateRoute>} />
      <Route exact path="/report/ho/laporan-booking-detail" element={<PrivateRoute><LaporanBookingDetail /> </PrivateRoute>} />
      <Route exact path="/report/ho/laporan-booking-summary" element={<PrivateRoute><LaporanBookingSummary /> </PrivateRoute>} />
      <Route exact path="/report/ho/laporan-penjualan-gadai" element={<PrivateRoute><LaporanPenjualanGadaiHO /> </PrivateRoute>} />
      <Route exact path="/report/tools/merge-excel" element={<PrivateRoute><CombineExcel /> </PrivateRoute>} />
      <Route exact path="/aplikasi/report/flegging-voucher" element={<PrivateRoute><FleggingVoucher /> </PrivateRoute>} />

      <Route exact path="/audit" element={<PrivateRoute></PrivateRoute>} />
      <Route exact path="/audit/audit-virtual/finance/open-cashier" element={<PrivateRoute><OpenCashierAudit /> </PrivateRoute>} />
      <Route exact path="/audit/audit-virtual/finance/close-cashier" element={<PrivateRoute><CloseCashierAudit /> </PrivateRoute>} />
      <Route exact path="/audit/audit-virtual/finance/bap" element={<PrivateRoute><BapAudit /> </PrivateRoute>} />
      <Route exact path="/audit/audit-virtual/customer/pencairan" element={<PrivateRoute><PencairanCustomer /> </PrivateRoute>} />
      <Route exact path="/audit/audit-virtual/customer/pelunasan" element={<PrivateRoute><PelunasanAudit /> </PrivateRoute>} />
      <Route exact path="/audit/general-audit" element={<PrivateRoute><GeneralAudit /></PrivateRoute>} />
      <Route exact path="/audit/general-audit/:outlet" element={<PrivateRoute><DaftarAudit /></PrivateRoute>} />
      <Route exact path="/audit/general-audit/:outlet/:cabang" element={<PrivateRoute><DetailAudit /></PrivateRoute>} />
      <Route exact path="/report-audit" element={<PrivateRoute></PrivateRoute>} />
      <Route exact path="/report-audit/audit-virtual" element={<PrivateRoute><ReportAuditVirtual /></PrivateRoute>} />
      <Route exact path="/audit/general-audit/report/:outlet/:cabang" element={<PrivateRoute><ReportAuditGeneral /></PrivateRoute>} />

      <Route exact path="/report/cabang/laporan-buffer-libur" element={<PrivateRoute><LaporanBufferLibur /> </PrivateRoute>} />
      <Route exact path="/report/cabang/laporan-buffer-harian" element={<PrivateRoute><LaporanBufferHarian /> </PrivateRoute>} />
      <Route exact path="/report/cabang/laporan-buffer-takeover" element={<PrivateRoute><LaporanBufferTakeover /> </PrivateRoute>} />
      <Route exact path="/report/cabang/laporan-buffer-walkin" element={<PrivateRoute><LaporanBufferWalkin /> </PrivateRoute>} />
      <Route exact path="/report/cabang/laporan-penjualan-barang-jaminan" element={<PrivateRoute><LaporanPenjualanJaminan/></PrivateRoute>}/>

      <Route exact path="/report/ho/laporan-buffer-libur" element={<PrivateRoute><LaporanBufferLiburHO /> </PrivateRoute>} />
      <Route exact path="/report/ho/laporan-buffer-harian" element={<PrivateRoute><LaporanBufferHarianHO /> </PrivateRoute>} />
      <Route exact path="/report/ho/laporan-buffer-takeover" element={<PrivateRoute><LaporanBufferTakeoverHO /> </PrivateRoute>} />
      <Route exact path="/report/ho/laporan-buffer-walkin" element={<PrivateRoute><LaporanBufferWalkinHO /> </PrivateRoute>} />   
      <Route exact path="/report/ho/laporan-ap-transfer" element={<PrivateRoute><LaporanApTransfer /> </PrivateRoute>} />   
      <Route exact path="/report-audit/laporan-penggunaan-sistem" element={<PrivateRoute><LaporanPenggunaanSistem /></PrivateRoute>} />
      <Route exact path="/report/cabang/laporan-potensi-topup" element={<PrivateRoute><LaporanPotensiTopUp /> </PrivateRoute>} />
      <Route exact path="/fa/pembayaran/foto-nasabah-pencairan" element={<PrivateRoute><FotoSelfiePencairanNasabah /> </PrivateRoute>} />
      <Route exact path="/report/ho/laporan-penjualan-barang-jaminan" element={<PrivateRoute><LaporanPenjualanJaminanHO/></PrivateRoute>}/>

    </Routes>
  );
};

export default AppSyariah;
