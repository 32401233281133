import { useState } from "react";
import MenuItems from "./MenuItems";
const Dropdown = ({ submenus, dropdown, depthLevel,setDropdown, clicked,userId }) => {
    const [MobileShow, setMobileShow] = useState(null)
  depthLevel = depthLevel + 1;
  const dropdownClass = depthLevel > 1 ? "dropdown-submenu" : "";


    return (
      <ul  className={`dropdown ${dropdownClass} ${dropdown ? "show" : ""}`}>
        {submenus.map((submenu, index) => (
          <MenuItems userId={userId} items={submenu} key={index} depthLevel={depthLevel} onClick={clicked} />
        ))}
      </ul>
    );
};

export default Dropdown;
