import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import { setLoadingAsync } from "services/globals/loading.reducer";
import "./FullfillBufferLibur.css";
import Dropping from "./Dropping";
import History from "./History";

const DroppingPettyCash = ({ history, dispatch, isLoading, isProfile }) => {
  const [tabActive, setTabActive] = useState({
    dropping: true,
    history: false,
  });

  const setActive = (tab) => {
    if (tab === "dropping") {
      setTabActive({ ...tabActive, dropping: true, history: false });
    }
    if (tab === "history") {
      setTabActive({ ...tabActive, dropping: false, history: true });
    }
  };

  return (
    <>
      <div className="row bg-tab mb-2">
        <div className="col-12">
          <div className="row text-center">
            <div className={tabActive.dropping ? "col-1 tab-style tab-active pointer" : "col-1 tab-style pointer"} onClick={() => setActive("dropping")}>
              <span>Dropping</span>
            </div>
            <div className={tabActive.history ? "col-1 tab-style tab-active pointer" : "col-1 tab-style pointer"} onClick={() => setActive("history")}>
              <span>History</span>
            </div>
          </div>
        </div>
      </div>
      {tabActive?.dropping ? <Dropping dispatch={dispatch} isProfile={isProfile} isLoading={isLoading} /> : <History dispatch={dispatch} isProfile={isProfile} isLoading={isLoading} />}
    </>
  );
};

const mapStateToProps = function (state) {
  return {
    isLoading: state.global.isLoading,
    isProfile: state.profile,
  };
};

export default connect(mapStateToProps)(DroppingPettyCash);
