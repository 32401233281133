import {
  STAFF_ADMIN_PROPINSI,
  ADMIN,
  KASIR,
  PENAKSIR,
  KA_CAB,
  KA_UNIT,
  KA_UNIT_MIGRASI,
  FINANCE,
  KA_WIL,
  KA_AREA,
  CSDO,
  FINANCE_STAFF,
  ALL_MODUL_KA_UNIT,
  KA_UNIT_PENAKSIR,
  PENAKSIR_KASIR,
  WAKACAB,
  AUDIT_HEAD,
  AUDIT_STAFF,
  RISK,
  OPR_MANAGER,
  OFS,
  DIREKTUR,
  DIRUT,
  PENAKSIR_KASIR_MIGRASI,
  KADEPT_CPA,
  KADIV_CPA,
} from "utils/ConstJabatan";

export const FASyari = [
  //Pembayaran
  {
    title: "Pembayaran",
    akses: [ADMIN, KASIR, OPR_MANAGER, KA_UNIT, ALL_MODUL_KA_UNIT, KA_UNIT_PENAKSIR, PENAKSIR_KASIR, AUDIT_HEAD, AUDIT_HEAD, FINANCE, FINANCE_STAFF, AUDIT_HEAD, AUDIT_STAFF, RISK, OFS],
    submenu: [
      {
        title: "Cair / Perpanjang",
        akses: [ADMIN, KASIR, ALL_MODUL_KA_UNIT, PENAKSIR_KASIR],
        submenu: [
          {
            title: "Pencairan Gadai",
            link: "/fa/pembayaran/cair-perpanjang/pencairan-gadai",
            akses: [ADMIN, KASIR, ALL_MODUL_KA_UNIT, PENAKSIR_KASIR],
          },
          // {
          //   title: "Foto nasabah pencairan",
          //   link: "/fa/pembayaran/foto-nasabah-pencairan",
          //   akses: [ADMIN, KASIR, ALL_MODUL_KA_UNIT, PENAKSIR_KASIR, PENAKSIR_KASIR_MIGRASI],
          // },
          // {
          //     title: "Pencairan Cicilan",
          //     akses: [ADMIN, KASIR, ALL_MODUL_KA_UNIT, PENAKSIR_KASIR],
          //     link: "/fa/pembayaran/cair-perpanjang/pencairan-cicilan",
          // },
          {
            title: "Pencairan Voucher",
            akses: [ADMIN, KASIR, ALL_MODUL_KA_UNIT, PENAKSIR_KASIR],
            link: "/fa/pembayaran/cair-perpanjang/pencairan-voucher",
          },
          //   {
          //     title: "Pencairan Perpanjangan",
          //     link: "/fa/pembayaran/cair-perpanjang/pencairan-perpanjangan",
          //     akses: [ADMIN, KASIR],
          // },
          // {
          //     title: "Input Dana Talangan",
          //     akses: [ADMIN, KASIR, ALL_MODUL_KA_UNIT, PENAKSIR_KASIR],
          //     // link: ""
          // },
        ],
      },
      {
        title: "Pelunasan",
        akses: [ADMIN, KASIR, KA_UNIT, ALL_MODUL_KA_UNIT, PENAKSIR_KASIR, KA_UNIT_PENAKSIR, RISK],
        submenu: [
          {
            title: "Simulasi Pelunasan",
            akses: [ADMIN, KASIR, ALL_MODUL_KA_UNIT, PENAKSIR_KASIR, RISK],
            link: "/fa/pembayaran/pelunasan/simulasi-pelunasan",
          },
          // {
          //     title: "Simulasi Pelunasan Cicilan",
          //     akses: [ADMIN, KASIR, ALL_MODUL_KA_UNIT, PENAKSIR_KASIR, RISK],
          //     link: "/fa/pembayaran/pelunasan/simulasi-pelunasan-cicilan"
          // },
          {
            title: "Pelunasan Gadai",
            akses: [ADMIN, KASIR, ALL_MODUL_KA_UNIT, PENAKSIR_KASIR],
            link: "/fa/pembayaran/pelunasan/pelunasan-gadai",
          },
          // {
          //     title: "Pelunasan Cicilan",
          //     akses: [ADMIN, KASIR, ALL_MODUL_KA_UNIT, PENAKSIR_KASIR],
          //     link: "/fa/pembayaran/pelunasan/pelunasan-cicilan"
          // },
          // {
          //     title: "Pembayaran Cicilan ",
          //     akses: [ADMIN, KASIR, ALL_MODUL_KA_UNIT, PENAKSIR_KASIR],
          //     link: "/fa/pembayaran/pelunasan/pembayaran-cicilan"
          // },
          // {
          //   title: "Pelunasan Percepat Cicilan",
          //   akses: [ADMIN, KASIR, ALL_MODUL_KA_UNIT, PENAKSIR_KASIR],
          //   link: "/fa/pembayaran/pelunasan/pelunasan-percepat-cicilan"
          // },
          {
            title: "Input Titipan",
            akses: [ADMIN, KASIR, ALL_MODUL_KA_UNIT, PENAKSIR_KASIR],
            link: "/fa/pembayaran/pelunasan/input-titipan",
          },
          {
            title: "Mutasi Titipan",
            akses: [ADMIN, KASIR, KA_UNIT, ALL_MODUL_KA_UNIT, PENAKSIR_KASIR, KA_UNIT_PENAKSIR],
            link: "/fa/pembayaran/pelunasan/mutasi-titipan",
          },
        ],
      },
      {
        title: "Query SBG Cair",
        akses: [ADMIN, KASIR, KA_UNIT, OPR_MANAGER, PENAKSIR, ALL_MODUL_KA_UNIT, PENAKSIR_KASIR, AUDIT_HEAD, AUDIT_STAFF, FINANCE, FINANCE_STAFF, RISK, OFS],
        submenu: [
          {
            title: "Query Gadai",
            akses: [ADMIN, KASIR, KA_UNIT, OPR_MANAGER, ALL_MODUL_KA_UNIT, PENAKSIR, PENAKSIR_KASIR, AUDIT_HEAD, AUDIT_STAFF, FINANCE, FINANCE_STAFF, RISK, OFS],
            link: "/fa/pembayaran/query-sbg-cair/query-gadai",
          },
          // {
          //     title: "Query Cicilan",
          //     akses: [ADMIN, KASIR, KA_UNIT, ALL_MODUL_KA_UNIT, PENAKSIR, PENAKSIR_KASIR,AUDIT_HEAD,AUDIT_STAFF, FINANCE, FINANCE_STAFF, RISK],
          //     link: "/fa/pembayaran/query-sbg-cair/query-cicilan"
          // },
        ],
      },
    ],
  },
  // Finance
  {
    title: "Finance",
    akses: [
      ADMIN,
      KA_UNIT,
      KASIR,
      KA_CAB,
      FINANCE,
      KA_WIL,
      KA_AREA,
      FINANCE_STAFF,
      CSDO,
      KA_WIL,
      ALL_MODUL_KA_UNIT,
      PENAKSIR_KASIR,
      WAKACAB,
      STAFF_ADMIN_PROPINSI,
      AUDIT_HEAD,
      AUDIT_STAFF,
      OPR_MANAGER,
      RISK,
      OFS,
      DIREKTUR,
      DIRUT,
      PENAKSIR_KASIR_MIGRASI,
      KADEPT_CPA,
      KADIV_CPA
    ],
    submenu: [
      {
        title: "Open Cashier",
        link: "/fa/finance/open-cashier",
        akses: [ADMIN, KA_UNIT, KASIR, FINANCE, FINANCE_STAFF, ALL_MODUL_KA_UNIT, OPR_MANAGER, PENAKSIR_KASIR, AUDIT_HEAD, AUDIT_STAFF, OFS],
      },
      // {
      //   title: "Petty Cash",
      //   akses: [ADMIN, KASIR,KA_UNIT, ALL_MODUL_KA_UNIT, PENAKSIR_KASIR, STAFF_ADMIN_PROPINSI],
      //   link: "/fa/finance/petty-cash"
      // },
      // {
      //   title: "Petty Cash HO - Cabang",
      //   link: "/fa/finance/petty-cash-ho-cabang",
      //   akses: [ADMIN, FINANCE]
      // },
      // {
      //   title: "Petty Cash Cabang - Unit/Pos",
      //   link: "/fa/finance/petty-cash-cabang-unit",
      //   akses: [ADMIN, KA_CAB, KA_UNIT, KASIR, ALL_MODUL_KA_UNIT, PENAKSIR_KASIR]
      // },
      // {
      //   title: "Kas Marketing",
      //   akses: [ADMIN, KASIR,KA_UNIT, ALL_MODUL_KA_UNIT, PENAKSIR_KASIR],
      //   link: "/fa/finance/kas-marketing"
      // },
      {
        title: "Full Fill Buffer Harian",
        akses: [ADMIN, FINANCE_STAFF, FINANCE],
        link: "/fa/finance/full-fill-buffer-harian",
      },
      {
        title: "Penarikan Buffer Harian",
        akses: [ADMIN, KA_UNIT],
        link: "/fa/finance/penarikan-buffer-harian",
      },
      {
        title: "Pengajuan Buffer Harian",
        akses: [ADMIN, OFS],
        link: "/fa/finance/pengajuan-buffer-harian",
      },
      {
        title: "Dropping Buffer Harian",
        akses: [ADMIN, OFS],
        link: "/fa/finance/dropping-buffer-harian",
      },
      {
        title: "Kas Marketing HO - Cabang",
        akses: [ADMIN, FINANCE],
        link: "/fa/finance/kas-marketing-ho-cabang",
      },
      {
        title: "Kas Marketing Cabang - Unit/Pos",
        link: "/fa/finance/kas-marketing-cabang-unit",
        akses: [ADMIN, KA_CAB, KA_UNIT, KASIR, ALL_MODUL_KA_UNIT, PENAKSIR_KASIR],
      },
      // {
      //   title: "Mutasi Buffer Urgent & Libur",
      //   akses: [ADMIN, KA_UNIT, RISK, FINANCE, FINANCE_STAFF, KA_CAB, KA_WIL, KASIR, ALL_MODUL_KA_UNIT, PENAKSIR_KASIR],
      //   link: "/fa/finance/mutasi-buffer-urgent-&-libur",
      // },
      {
        title: "Mutasi Bank HO - Cabang",
        link: "/fa/finance/mutasi-ho-cabang",
        akses: [ADMIN, FINANCE],
      },
      {
        title: "Plafon Giro Cek",
        akses: [ADMIN, KA_UNIT],
        link: "/fa/finance/plafon-giro-cek",
      },
      {
        title: "Buffer Takeover",
        akses: [ADMIN, KA_UNIT, KA_UNIT_MIGRASI],
        link: "/fa/finance/buffer-takeover",
      },
      {
        title: "Pengajuan Buffer Takeover",
        akses: [ADMIN, KA_CAB, KADIV_CPA, KADEPT_CPA],
        link: "/fa/finance/pengajuan-buffer-takeover",
      },
      {
        title: "Full Fill Buffer Take Over",
        akses: [ADMIN, FINANCE_STAFF, FINANCE],
        link: "/fa/finance/full-fill-takeover",
      },
      {
        title: "Penarikan Buffer Take Over",
        akses: [ADMIN, KA_UNIT],
        link: "/fa/finance/penarikan-buffer-takeover",
      },
      {
        title: "Dropping Buffer Take Over",
        akses: [ADMIN, OFS],
        link: "/fa/finance/dropping-buffer-takeover",
      },
      {
        title: "Full Fill Buffer WalkIn",
        akses: [ADMIN, FINANCE_STAFF, FINANCE],
        link: "/fa/finance/full-fill-buffer-walk-in",
      },
      {
        title: "Buffer WalkIn",
        akses: [ADMIN, KA_UNIT],
        link: "/fa/finance/buffer-walk-in",
      },
      {
        title: "Dropping Buffer WalkIn",
        akses: [ADMIN, OFS],
        link: "/fa/finance/dropping-buffer-walk-in",
      },
      {
        title: "Penarikan Buffer WalkIn",
        akses: [ADMIN, PENAKSIR_KASIR_MIGRASI, PENAKSIR_KASIR, KASIR],
        link: "/fa/finance/penarikan-buffer-walk-in",
      },
      // {
      //   title: "Mutasi Bank Cabang - HO *",
      //   akses: [ADMIN, KASIR, ALL_MODUL_KA_UNIT, PENAKSIR_KASIR],
      //   // link: "/utility/user-management/level"
      // },
      {
        title: "Mutasi Bank Internal Cabang",
        link: "/fa/finance/mutasi-internal-cabang",
        akses: [ADMIN, KA_UNIT, KASIR, ALL_MODUL_KA_UNIT, PENAKSIR_KASIR],
      },
      {
        title: "Mutasi Bank Cabang - Unit/Pos",
        link: "/fa/finance/mutasi-cabang-unit",
        akses: [ADMIN, KA_CAB, KA_UNIT, KASIR, ALL_MODUL_KA_UNIT, PENAKSIR_KASIR],
      },
      {
        title: "Mutasi Bank Antar Unit",
        link: "/fa/finance/mutasi-bank-antar-unit",
        akses: [ADMIN, FINANCE, FINANCE_STAFF],
      },
      {
        title: "Buffer Libur",
        akses: [ADMIN, KA_UNIT, KA_UNIT_MIGRASI],
        link: "/fa/finance/buffer-libur",
      },
      {
        title: "Full Fill Buffer Libur",
        akses: [ADMIN, FINANCE_STAFF, FINANCE],
        link: "/fa/finance/full-fill-buffer-libur",
      },
      {
        title: "Penarikan Buffer Libur",
        akses: [ADMIN, KA_UNIT, KA_UNIT_MIGRASI],
        link: "/fa/finance/penarikan-buffer-libur",
      },
      {
        title: "Pengajuan Buffer Libur",
        akses: [ADMIN, OFS, WAKACAB],
        link: "/fa/finance/pengajuan-buffer-libur",
      },
      {
        title: "Dropping Buffer Libur",
        akses: [ADMIN, OFS],
        link: "/fa/finance/dropping-buffer-libur",
      },
      {
        title: "Setoran HO",
        link: "/fa/finance/setoran-ho",
        akses: [ADMIN, KA_CAB, KA_UNIT, FINANCE, FINANCE_STAFF],
      },
      {
        title: "Unit Bank FullFill",
        link: "/fa/finance/unit-bank-fullfill",
        akses: [ADMIN, FINANCE, FINANCE_STAFF],
      },

      {
        title: "Advance Internal *",
        akses: [ADMIN, KASIR, KA_UNIT, ALL_MODUL_KA_UNIT, PENAKSIR_KASIR],
        // link: "/fa/finance/advance-internal",
      },
      {
        title: "Advance Eksternal *",
        akses: [ADMIN, KASIR, ALL_MODUL_KA_UNIT, PENAKSIR_KASIR, KA_UNIT, KA_CAB, FINANCE],
        // link: "/fa/finance/advance-eksternal",
      },
      {
        title: "Payment Request *",
        akses: [ADMIN, KASIR, ALL_MODUL_KA_UNIT, PENAKSIR_KASIR],
        // link: "/utility/user-management/user"
      },
      {
        title: "Close Cashier",
        link: "/fa/finance/close-cashier",
        akses: [ADMIN, KA_UNIT, FINANCE, FINANCE_STAFF, KASIR, ALL_MODUL_KA_UNIT, OPR_MANAGER, PENAKSIR_KASIR, AUDIT_HEAD, AUDIT_STAFF, OFS],
      },
      // {
      //   title: "Query Saldo Kas & Bank *",
      //   akses: [ADMIN, KA_UNIT, KASIR, ALL_MODUL_KA_UNIT, PENAKSIR_KASIR],
      //   link: "/utility/user-management/user"
      // },
      {
        title: "Rekon Bank",
        akses: [ADMIN, KASIR, KA_UNIT, ALL_MODUL_KA_UNIT, PENAKSIR_KASIR, FINANCE, WAKACAB, KA_CAB],
        link: "/fa/finance/rekon-bank",
      },
      {
        title: "Advance Transfer *",
        akses: [ADMIN],
        // link: "/utility/user-management/user"
      },
      {
        title: "Payment Request HO *",
        akses: [ADMIN],
        // link: "/utility/user-management/user"
      },
      {
        title: "Correction *",
        akses: [ADMIN],
        // link: "/utility/user-management/user"
      },
      {
        title: "Suspend",
        akses: [ADMIN, FINANCE, FINANCE_STAFF],
        link: "/fa/finance/suspend",
      },
      {
        title: "BAP",
        akses: [ADMIN, KA_UNIT, KASIR, ALL_MODUL_KA_UNIT, PENAKSIR_KASIR],
        link: "/fa/finance/bap",
      },
      {
        title: "Mutasi Bank HO Holding - Propinsi *",
        akses: [ADMIN],
        // link: "/utility/user-management/user"
      },
      {
        title: "Mutasi Bank Propinsi - HO Holding *",
        akses: [ADMIN],
        // link: "/utility/user-management/user"
      },
      {
        title: "AP Transfer",
        akses: [ADMIN, FINANCE_STAFF, FINANCE],
        link: "/fa/finance/ap-transfer",
      },
      // {
      //   title: "Laporan Kas Harian",
      //   link: "/fa/finance/laporan-kas-harian",
      //   akses: [ADMIN, KA_UNIT, KA_CAB, KA_WIL, KA_AREA, FINANCE, KASIR],
      // },
      // {
      //   title: "Laporan Mutasi Bank",
      //   link: "/fa/finance/laporan-mutasi-bank",
      //   akses: [ADMIN, KA_UNIT, KA_CAB, KA_WIL, KA_AREA, FINANCE],
      // },
    ],
  },
  // Treasury
  {
    title: "Treasury",
    akses: [ADMIN, FINANCE, FINANCE_STAFF],
    submenu: [
      {
        title: "Funding",
        akses: [ADMIN, FINANCE, FINANCE_STAFF],
        link: "/fa/treasury/funding",
      },
      {
        title: "Piutang Fintech",
        akses: [ADMIN, FINANCE, FINANCE_STAFF],
        link: "/fa/treasury/piutang-fintech",
      },
      {
        title: "Hutang Fintech",
        akses: [ADMIN, FINANCE, FINANCE_STAFF],
        link: "/fa/treasury/hutang-fintech",
      },
      {
        title: "Pinjaman Bank *",
        akses: [ADMIN, FINANCE, FINANCE_STAFF],
        // link: "/utility/user-management/level"
      },
      {
        title: "Mutasi Flag Status Barang",
        akses: [ADMIN, FINANCE, FINANCE_STAFF],
        link: "/fa/treasury/mutasi-flag",
      },
      {
        title: "Rekon Fintech *",
        akses: [ADMIN, FINANCE, FINANCE_STAFF],
        // link: "/utility/user-management/level"
      },
      {
        title: "Saldo VA *",
        akses: [ADMIN, FINANCE, FINANCE_STAFF],
        // link: "/utility/user-management/user"
      },
      // {
      //   title: "Generate CEK *",
      //   akses: [ADMIN, FINANCE, FINANCE_STAFF],
      //   // link: "/utility/user-management/level"
      // },
      {
        title: "Joint Financing",
        akses: [ADMIN, FINANCE, FINANCE_STAFF],
        link: "/fa/treasury/joint-financing",
      },
    ],
  },
  // Accounting
  {
    title: "Accounting",
    akses: [ADMIN],
    submenu: [
      {
        title: "Adjustment Memorial *",
        akses: [ADMIN],
        // link: "/fa/finance/open-cashier"
      },
      {
        title: "GL Auto *",
        akses: [ADMIN],
        // link: "/utility/user-management/level"
      },
      {
        title: "Cadangan Piutang *",
        akses: [ADMIN],
        // link: "/utility/user-management/user"
      },
      {
        title: "Perhitungan BAK *",
        akses: [ADMIN],
        // link: "/utility/user-management/level"
      },
      {
        title: "Recurring *",
        akses: [ADMIN],
        // link: "/utility/user-management/user"
      },
      {
        title: "Monitoring Oracle *",
        akses: [ADMIN],
        // link: "/utility/user-management/level"
      },
      {
        title: "Jurnal Insentif *",
        akses: [ADMIN],
        // link: "/utility/user-management/user"
      },
    ],
  },
];
