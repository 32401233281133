import axios from "axios";
import Api from "../../utils/api";

const MastersApi = {
  Getkelurahan: function (param) {
    return Api()
      .Get(`msgadaimasmaster/v1/master/kelurahan?${param}`)
      .then(({ Response }) => {
        return Response;
      });
  },
  PostKelurahan: function (data) {
    return Api()
      .postRaw("msgadaimasmaster/v1/master/kelurahan", data, { rawJSON: true })
      .then(({ data }) => {
        return data;
      });
  },
  GetKecamatan: function (param) {
    return Api()
      .Get(`msgadaimasmaster/v1/master/kecamatan?${param}`)
      .then(({ Response }) => {
        return Response.data;
      });
  },
  getMasterKabupatenOptions: function () {
    return Api()
      .Get(`msgadaimasmaster/v1/master/kabupaten`)
      .then(({ Response }) => {
        return Response.data;
      });
  },
  getMasterProvinsiOptions: function () {
    return Api()
      .Get(`msgadaimasmaster/v1/master/provinsi?isActive=1`)
      .then(({ Response }) => {
        return Response.data;
      });
  },
  GetWilayah: function (params) {
    return Api()
      .Get(`msgadaimasuser/v1/master/paginate/wilayah?${params}`)
      .then(({ Response }) => {
        return Response;
      });
  },
  PostWilayah: function (data) {
    return Api()
      .postRaw("msgadaimasuser/v1/master/createUpdate/wilayah", data, { rawJSON: true })
      .then(({ data }) => {
        return data;
      });
  },
  getArea: function (params) {
    return Api()
      .Get(`msgadaimasmaster/v1/master/area?${params}`)
      .then(({ Response }) => {
        return Response;
      });
  },
  cuArea: function (data) {
    return Api()
      .postRaw("msgadaimasmaster/v1/master/area", data, { rawJSON: true })
      .then(({ data }) => {
        return data;
      });
  },
  getMasterCabang: function (params) {
    return Api()
      .Get(`msgadaimasmaster/v1/master/cabang${params}`)
      .then(({ Response }) => {
        return Response.data;
      });
  },
  cuMasterCabang: function (data) {
    return Api()
      .postRaw("msgadaimasmaster/v1/master/cabang", data, { rawJSON: true })
      .then(({ data }) => {
        return data;
      });
  },
  getMasterJenisCabang: function (params) {
    return Api()
      .Get(`msgadaimasmaster/v1/master/jenisCabang${params}`)
      .then(({ Response }) => {
        return Response.data;
      });
  },
  cuMasterJenisCabang: function (data) {
    return Api()
      .postRaw("msgadaimasmaster/v1/master/jenisCabang", data, { rawJSON: true })
      .then(({ data }) => {
        return data;
      });
  },
  getJenisCabangOptions: function (params = "") {
    return Api()
      .Get(`msgadaimasmaster/v1/master/jenisCabang?isActive=1${params}`)
      .then(({ Response }) => {
        return Response.data;
      });
  },
  getWilayahOptions: function () {
    return Api()
      .Get(`msgadaimasuser/v1/master/getWilayah?isActive=1`)
      .then(({ Response }) => {
        return Response.data;
      });
  },
  getAreaOptions: function () {
    return Api()
      .Get(`msgadaimasmaster/v1/master/area?isActive=1`)
      .then(({ Response }) => {
        return Response.data;
      });
  },
  getCabangOptions: function () {
    return Api()
      .Get(`msgadaimasmaster/v1/master/cabang?page=1&page_size=1000&id_jenis_cabang=4`)
      .then(({ Response }) => {
        return Response.data;
      });
  },
  getCabangOptionAll: function () {
    return Api()
      .Get(`msgadaimasmaster/v1/master/cabang?page=1&page_size=1000`)
      .then(({ Response }) => {
        return Response.data;
      });
  },
  getUnitOptions: function () {
    return Api()
      .Get(`msgadaimasmaster/v1/master/cabang?idJenisCabang=5`)
      .then(({ Response }) => {
        return Response.data;
      });
  },
  getUnitMaster: function (params) {
    return Api()
      .Get(`msgadaimasmaster/v1/master/cabang?idJenisCabang=5${params}`)
      .then(({ Response }) => {
        return Response.data;
      });
  },
  getKaryawanInternalOption: function (params) {
    return Api()
      .Get(`msgadaimasmaster/v1/master/karyawanInternal?isActive=1&idJabatanKaryawan=${params}`)
      .then(({ Response }) => {
        return Response.data;
      });
  },
  getCoaOptions: function () {
    return Api()
      .Get(`msgadaimasmaster/v1/master/coatemplate?isActive=1`)
      .then(({ Response }) => {
        return Response.data;
      });
  },
  getCoaOptionsCabang: function () {
    return Api()
      .Get(`msgadaimasmaster/v1/master/coatemplate?isActive=1&searchCoaCabang=1971`)
      .then(({ Response }) => {
        return Response.data;
      });
  },
  getGradeCabangOptions: function (params = "") {
    return Api()
      .Get(`msgadaimasmaster/v1/master/gradeCabang?isActive=1${params}`)
      .then(({ Response }) => {
        return Response.data;
      });
  },
  getMasterGradeCabang: function (params) {
    return Api()
      .Get(`msgadaimasmaster/v1/master/gradeCabang${params}`)
      .then(({ Response }) => {
        return Response.data;
      });
  },
  cuMasterGradeCabang: function (data) {
    return Api()
      .postRaw("msgadaimasmaster/v1/master/gradeCabang", data, { rawJSON: true })
      .then(({ data }) => {
        return data;
      });
  },
  getMasterCabangOptions: function () {
    return Api()
      .Get(`msgadaimasmaster/v1/master/cabang?isActive=1`)
      .then(({ Response }) => {
        return Response.data;
      });
  },
  getMasterLimitApproval: function (params) {
    return Api()
      .Get(`msgadaimasmaster/v1/master/limitApproval${params}`)
      .then(({ Response }) => {
        return Response.data;
      });
  },
  cuMasterLimitApproval: function (data) {
    return Api()
      .postRaw("msgadaimasmaster/v1/master/limitApproval", data, { rawJSON: true })
      .then(({ data }) => {
        return data;
      });
  },
  GetBank: function (params) {
    return Api()
      .Get(`msgadaimasmaster/v1/master/bank?${params}`)
      .then(({ Response }) => {
        return Response;
      });
  },
  cuBank: function (data) {
    return Api()
      .postRaw("msgadaimasmaster/v1/master/bank", data, { rawJSON: true })
      .then(({ data }) => {
        return data;
      });
  },
  getMasterDivisi: function (params) {
    return Api()
      .Get(`msgadaimasmaster/v1/master/divisi${params}`)
      .then(({ Response }) => {
        return Response.data;
      });
  },
  cuMasterDivisi: function (data) {
    return Api()
      .postRaw("msgadaimasmaster/v1/master/divisi", data, { rawJSON: true })
      .then(({ data }) => {
        return data;
      });
  },
  getJabatanKaryawan: function (params) {
    return Api()
      .Get(`msgadaimasmaster/v1/master/jabatanKaryawan${params}`)
      .then(({ Response }) => {
        return Response.data;
      });
  },
  cuJabatanKaryawan: function (data) {
    return Api()
      .postRaw("msgadaimasmaster/v1/master/jabatanKaryawan", data, { rawJSON: true })
      .then(({ data }) => {
        return data;
      });
  },
  getJabatanOptions: function () {
    return Api()
      .Get(`msgadaimasmaster/v1/master/jabatanKaryawan?isActive=1`)
      .then(({ Response }) => {
        return Response.data;
      });
  },
  getGradeKaryawan: function (params) {
    return Api()
      .Get(`msgadaimasmaster/v1/master/gradeKaryawan${params}`)
      .then(({ Response }) => {
        return Response.data;
      });
  },
  cuGradeKaryawan: function (data) {
    return Api()
      .postRaw("msgadaimasmaster/v1/master/gradeKaryawan", data, { rawJSON: true })
      .then(({ data }) => {
        return data;
      });
  },
  getMasterDivisiOption: function () {
    return Api()
      .Get(`msgadaimasmaster/v1/master/divisi?isActive=1`)
      .then(({ Response }) => {
        return Response.data;
      });
  },
  getMasterJabatanKaryawanOption: function () {
    return Api()
      .Get(`msgadaimasmaster/v1/master/jabatanKaryawan?isActive=1&jenisKaryawan=Internal`)
      .then(({ Response }) => {
        return Response.data;
      });
  },
  getMasterJabatanKaryawanEksternalOption: function () {
    return Api()
      .Get(`msgadaimasmaster/v1/master/jabatanKaryawan?isActive=1&jenisKaryawan=Eksternal`)
      .then(({ Response }) => {
        return Response.data;
      });
  },
  getMasterCabangOption: function () {
    return Api()
      .Get(`msgadaimasmaster/v1/master/cabang?isActive=1`)
      .then(({ Response }) => {
        return Response.data;
      });
  },
  getMasterKelurahanOption: function () {
    return Api()
      .Get(`msgadaimasmaster/v1/master/kelurahan?isActive=1`)
      .then(({ Response }) => {
        return Response.data;
      });
  },
  getMasterKaryawanInternal: function (params) {
    return Api()
      .Get(`msgadaimasmaster/v1/master/karyawanInternal${params}`)
      .then(({ Response }) => {
        return Response.data;
      });
  },
  cuMasterKaryawanInternal: function (data) {
    return Api()
      .postRaw("msgadaimasmaster/v1/master/karyawanInternal", data, { rawJSON: true })
      .then(({ data }) => {
        return data;
      });
  },
  getMasterKaryawanEksternal: function (params) {
    return Api()
      .Get(`msgadaimasmaster/v1/master/karyawanEksternal${params}`)
      .then(({ Response }) => {
        return Response.data;
      });
  },
  cuMasterKaryawanEksternal: function (data) {
    return Api()
      .postRaw("msgadaimasmaster/v1/master/karyawanEksternal", data, { rawJSON: true })
      .then(({ data }) => {
        return data;
      });
  },
  getMasterPekerjaan: function (params) {
    return Api()
      .Get(`msgadaimasmaster/v1/master/pekerjaan${params}`)
      .then(({ Response }) => {
        return Response.data;
      });
  },
  cuMasterPekerjaan: function (data) {
    return Api()
      .postRaw("msgadaimasmaster/v1/master/pekerjaan", data, { rawJSON: true })
      .then(({ data }) => {
        return data;
      });
  },
  getReferensiCustomer: function (params) {
    return Api()
      .Get(`msgadaimasmaster/v1/master/referensicustomer${params}`)
      .then(({ Response }) => {
        return Response.data;
      });
  },
  cuReferensiCustomer: function (data) {
    return Api()
      .postRaw("msgadaimasmaster/v1/master/referensicustomer", data, { rawJSON: true })
      .then(({ data }) => {
        return data;
      });
  },
  getReferensiTransaksi: function (params) {
    return Api()
      .Get(`msgadaimasmaster/v1/customer/referensiTransaksi${params}`)
      .then(({ Response }) => {
        return Response.data;
      });
  },
  cuReferensiTransaksi: function (data) {
    return Api()
      .postRaw("msgadaimasmaster/v1/customer/referensiTransaksi", data, { rawJSON: true })
      .then(({ data }) => {
        return data;
      });
  },
  getTujuanTransaksi: function (params) {
    return Api()
      .Get(`msgadaimasmaster/v1/master/tujuantransaksi${params}`)
      .then(({ Response }) => {
        return Response.data;
      });
  },
  cuTujuanTransaksi: function (data) {
    return Api()
      .postRaw("msgadaimasmaster/v1/master/tujuantransaksi", data, { rawJSON: true })
      .then(({ data }) => {
        return data;
      });
  },
  getAlasanBadCustomer: function (params) {
    return Api()
      .Get(`msgadaimasmaster/v1/customer/alasanBad${params}`)
      .then(({ Response }) => {
        return Response.data;
      });
  },
  cuAlasanBadCustomer: function (data) {
    return Api()
      .postRaw("msgadaimasmaster/v1/customer/alasanBad", data, { rawJSON: true })
      .then(({ data }) => {
        return data;
      });
  },
  getOptionsTujuan: function () {
    return Api()
      .Get(`msgadaimasmaster/v1/master/tujuantransaksi?isActive=1`)
      .then(({ Response }) => {
        return Response.data;
      });
  },
  getSektorEkonomi: function (params) {
    return Api()
      .Get(`msgadaimasmaster/v1/master/sektorekonomi${params}`)
      .then(({ Response }) => {
        return Response.data;
      });
  },
  cuSektorEkonomi: function (data) {
    return Api()
      .postRaw("msgadaimasmaster/v1/master/sektorekonomi", data, { rawJSON: true })
      .then(({ data }) => {
        return data;
      });
  },
  getAlasanBatal: function (params) {
    return Api()
      .Get(`msgadaimasmaster/v1/customer/alasanBatal${params}`)
      .then(({ Response }) => {
        return Response.data;
      });
  },
  cuAlasanBatal: function (data) {
    return Api()
      .postRaw("msgadaimasmaster/v1/customer/alasanBatal", data, { rawJSON: true })
      .then(({ data }) => {
        return data;
      });
  },
  getMasterPendidikan: function (params) {
    return Api()
      .Get(`msgadaimasmaster/v1/master/pendidikan${params}`)
      .then(({ Response }) => {
        return Response.data;
      });
  },
  cuMasterPendidikan: function (data) {
    return Api()
      .postRaw("msgadaimasmaster/v1/master/pendidikan", data, { rawJSON: true })
      .then(({ data }) => {
        return data;
      });
  },
  getMasterStatusRumah: function (params) {
    return Api()
      .Get(`msgadaimasmaster/v1/master/statusrumah${params}`)
      .then(({ Response }) => {
        return Response.data;
      });
  },
  cuMasterStatusRumah: function (data) {
    return Api()
      .postRaw("msgadaimasmaster/v1/master/statusrumah", data, { rawJSON: true })
      .then(({ data }) => {
        return data;
      });
  },
  getMasterSumberDana: function (params) {
    return Api()
      .Get(`msgadaimasmaster/v1/master/sumberdana${params}`)
      .then(({ Response }) => {
        return Response.data;
      });
  },
  cuMasterSumberDana: function (data) {
    return Api()
      .postRaw("msgadaimasmaster/v1/master/sumberdana", data, { rawJSON: true })
      .then(({ data }) => {
        return data;
      });
  },
  getMasterBidangUsaha: function (params) {
    return Api()
      .Get(`msgadaimasmaster/v1/master/bidangusaha${params}`)
      .then(({ Response }) => {
        return Response.data;
      });
  },
  cuMasterBidangUsaha: function (data) {
    return Api()
      .postRaw("msgadaimasmaster/v1/master/bidangusaha", data, { rawJSON: true })
      .then(({ data }) => {
        return data;
      });
  },
  getJabatanCustomer: function (params) {
    return Api()
      .Get(`msgadaimasmaster/v1/customer/jabatanCustomer${params}`)
      .then(({ Response }) => {
        return Response.data;
      });
  },
  cuJabatanCustomer: function (data) {
    return Api()
      .postRaw("msgadaimasmaster/v1/customer/jabatanCustomer", data, { rawJSON: true })
      .then(({ data }) => {
        return data;
      });
  },
  getHasilFollowUp: function (params) {
    return Api()
      .Get(`msgadaimasmaster/v1/customer/hasilFU${params}`)
      .then(({ Response }) => {
        return Response.data;
      });
  },
  cuHasilFollowUp: function (data) {
    return Api()
      .postRaw("msgadaimasmaster/v1/customer/hasilFU", data, { rawJSON: true })
      .then(({ data }) => {
        return data;
      });
  },
  getCaraFollowUp: function (params) {
    return Api()
      .Get(`msgadaimasmaster/v1/customer/caraFU${params}`)
      .then(({ Response }) => {
        return Response.data;
      });
  },
  cuCaraFollowUp: function (data) {
    return Api()
      .postRaw("msgadaimasmaster/v1/customer/caraFU", data, { rawJSON: true })
      .then(({ data }) => {
        return data;
      });
  },
  GetProgram: function (params) {
    return Api()
      .Get(`msgadaimasmaster/v1/customer/program${params}`)
      .then(({ Response }) => {
        return Response.data;
      });
  },
  cuProgram: function (data) {
    return Api()
      .postRaw("msgadaimasmaster/v1/customer/program", data, { rawJSON: true })
      .then(({ data }) => {
        return data;
      });
  },
  getLevel: function (params) {
    return Api()
      .Get(`msgadaimasmaster/v1/managementUser/level${params}`)
      .then(({ Response }) => {
        return Response.data;
      });
  },
  cuLevel: function (data) {
    return Api()
      .postRaw("msgadaimasmaster/v1/managementUser/level", data, { rawJSON: true })
      .then(({ data }) => {
        return data;
      });
  },
  getUser: function (params) {
    return Api()
      .Get(`msgadaimasuser/v1/master/user${params}`)
      .then(({ Response }) => {
        return Response.data;
      });
  },
  GetProduk: function (params) {
    return Api()
      .Get(`msgadaimasproduk/v1/produk/gadai${params}`)
      .then(({ Response }) => {
        return Response.data;
      });
  },
  cuUser: function (data) {
    return Api()
      .PostFile("msgadaimasuser/v1/user/registrasi", data, { rawJSON: true })
      .then(({ data }) => {
        return data;
      });
  },

  getLevelOptions: function () {
    return Api()
      .Get(`msgadaimasmaster/v1/managementUser/level?isActive=1`)
      .then(({ Response }) => {
        return Response.data;
      });
  },
  getCashFlow: function (params) {
    return Api()
      .Get(`msgadaimasmaster/v1/master/cashflow${params}`)
      .then(({ Response }) => {
        return Response.data;
      });
  },
  cuCashFlow: function (data) {
    return Api()
      .postRaw("msgadaimasmaster/v1/master/cashflow", data, { rawJSON: true })
      .then(({ data }) => {
        return data;
      });
  },
  getUsedFor: function (params) {
    return Api()
      .Get(`msgadaimasmaster/v1/master/coausedfor${params}`)
      .then(({ Response }) => {
        return Response.data;
      });
  },
  cuUsedFor: function (data) {
    return Api()
      .postRaw("msgadaimasmaster/v1/master/coausedfor", data, { rawJSON: true })
      .then(({ data }) => {
        return data;
      });
  },
  getChartOfAccount: function (params) {
    return Api()
      .Get(`msgadaimasmaster/v1/master/coatemplate${params}`)
      .then(({ Response }) => {
        return Response.data;
      });
  },
  cuChartOfAccount: function (data) {
    return Api()
      .postRaw("msgadaimasmaster/v1/master/coatemplate", data, { rawJSON: true })
      .then(({ data }) => {
        return data;
      });
  },
  getCabangOptions2: function (param) {
    return Api()
      .Get(`msgadaimasmaster/v1/master/cabang?idCabang=${param}`)
      .then(({ Response }) => {
        return Response.data;
      });
  },
  getCabangOptions3: function (param) {
    return Api()
      .Get(`msgadaimasmaster/v1/master/cabang?isActive=1&idCabang=${param}`)
      .then(({ Response }) => {
        return Response.data;
      });
  },
  cuProduk: function (data) {
    return Api()
      .postRaw("msgadaimasproduk/v1/produk/gadai", data, { rawJSON: true })
      .then(({ data }) => {
        return data;
      });
  },
  GetMasterRateOptions: function () {
    return Api()
      .Get(`msgadaimasproduk/v1/rate/jenisRate?isActive=1`)
      .then(({ Response }) => {
        return Response.data;
      });
  },
  getSettingAccounting: function (params) {
    return Api()
      .Get(`msgadaimasmaster/v1/master/settingaccounting${params}`)
      .then(({ Response }) => {
        return Response.data;
      });
  },
  cuSettingAccounting: function (data) {
    return Api()
      .postRaw("msgadaimasmaster/v1/master/settingaccounting", data, { rawJSON: true })
      .then(({ data }) => {
        return data;
      });
  },
  getCashFlowOptions: function () {
    return Api()
      .Get(`msgadaimasmaster/v1/master/cashflow?isActive=1`)
      .then(({ Response }) => {
        return Response.data;
      });
  },
  getCashFlow: function (params) {
    return Api()
      .Get(`msgadaimasmaster/v1/master/cashflow?isActive=1${params}`)
      .then(({ Response }) => {
        return Response.data;
      });
  },
  getUsedForOptions: function () {
    return Api()
      .Get(`msgadaimasmaster/v1/master/coausedfor?isActive=1`)
      .then(({ Response }) => {
        return Response.data;
      });
  },
  GetBankOptions: function () {
    return Api()
      .Get(`msgadaimasmaster/v1/master/bank?isActive=1`)
      .then(({ Response }) => {
        return Response.data;
      });
  },
  getWilayahOptions2: function (param) {
    return Api()
      .Get(`msgadaimasuser/v1/master/getWilayah?id_wilayah=${param}`)
      .then(({ Response }) => {
        return Response.data;
      });
  },
  GetProdukById: function (param) {
    return Api()
      .Get(`msgadaimasproduk/v1/produk/gadai?idProduk=${param}`)
      .then(({ Response }) => {
        return Response.data;
      });
  },
  GetJenisBarangOption: function () {
    return Api()
      .Get(`msgadaimasproduk/v1/barang/jenisbarang?isActive=1`)
      .then(({ Response }) => {
        return Response.data;
      });
  },
  getJenisBarang: function (params) {
    return Api()
      .Get(`msgadaimasproduk/v1/barang/jenisbarang${params}`)
      .then(({ Response }) => {
        return Response.data;
      });
  },
  cuJenisBarang: function (data) {
    return Api()
      .postRaw("msgadaimasproduk/v1/barang/jenisbarang", data, { rawJSON: true })
      .then(({ data }) => {
        return data;
      });
  },
  getJenisEmas: function (params) {
    return Api()
      .Get(`msgadaimasproduk/v1/barang/jenisemas${params}`)
      .then(({ Response }) => {
        return Response.data;
      });
  },
  cuJenisEmas: function (data) {
    return Api()
      .postRaw("msgadaimasproduk/v1/barang/jenisemas", data, { rawJSON: true })
      .then(({ data }) => {
        return data;
      });
  },
  getBarangEmas: function (params) {
    return Api()
      .Get(`msgadaimasproduk/v1/barang/barangemas${params}`)
      .then(({ Response }) => {
        return Response.data;
      });
  },
  cuBarangEmas: function (data) {
    return Api()
      .postRaw("msgadaimasproduk/v1/barang/barangemas", data, { rawJSON: true })
      .then(({ data }) => {
        return data;
      });
  },
  getJenisEmasOptions: function () {
    return Api()
      .Get(`msgadaimasproduk/v1/barang/jenisemas?isActive=1`)
      .then(({ Response }) => {
        return Response.data;
      });
  },
  getJenisEmasOptions2: function () {
    return Api()
      .Get(`msgadaimasproduk/v1/barang/jenisemas`)
      .then(({ Response }) => {
        return Response.data;
      });
  },
  getHargaSTLE: function (params) {
    return Api()
      .Get(`msgadaimasproduk/v1/stle/stle${params}`)
      .then(({ Response }) => {
        return Response.data;
      });
  },
  cuHargaSTLE: function (data) {
    return Api()
      .postRaw("msgadaimasproduk/v1/stle/stle", data, { rawJSON: true })
      .then(({ data }) => {
        return data;
      });
  },
  getRate: function (params) {
    return Api()
      .Get(`msgadaimasproduk/v1/rate/jenisRate${params}`)
      .then(({ Response }) => {
        return Response.data;
      });
  },
  cuRate: function (data) {
    return Api()
      .postRaw("msgadaimasproduk/v1/rate/jenisRate", data, { rawJSON: true })
      .then(({ data }) => {
        return data;
      });
  },
  getCoaTemplate: function (params) {
    return Api()
      .Get(`msgadaimasmaster/v1/master/coatemplate${params}`)
      .then(({ Response }) => {
        return Response.data;
      });
  },
  getDetailOpenCashier: function (params) {
    return Api()
      .Get(`msgadaimasfinance/v1/transaksi/detail`)
      .then(({ Response }) => {
        return Response.data;
      });
  },
  getOpenCashier: function (params) {
    return Api()
      .Get(`msgadaimasfinance/v1/transaksi/internal${params}`)
      .then(({ Response }) => {
        return Response.data;
      });
  },
  cuOpenCashier: function (data) {
    return Api()
      .postRaw("msgadaimasfinance/v1/transaksi/internal", data, { rawJSON: true })
      .then(({ data }) => {
        return data;
      });
  },
  cuOpenCashierInternal: function (data) {
    return Api()
      .postRaw("msgadaimasfinance/v1/transaksi/internal", data, { rawJSON: true })
      .then(({ data }) => {
        return data;
      });
  },
  OcrKTP: function (data) {
    return Api()
      .PostFileAbsen("msgadaimasupload/v1/upload/ocr", data, { rawJSON: true, withoutToken: true })
      .then(({ data }) => {
        return data;
      });
  },
  getCustomer: function (params) {
    return Api()
      .Get(`msgadaimasuser/v1/customer/customer${params}`)
      .then(({ Response }) => {
        return Response.data;
      });
  },
  getCustomerPolos: function (params) {
    return Api()
      .Get(`msgadaimasuser/v1/customer/customer${params}`)
      .then(({ Response }) => {
        return Response.data;
      });
  },
  GetMasterStatusRumahOptions: function (params) {
    return Api()
      .Get(`msgadaimasmaster/v1/master/statusrumah?isActive=`)
      .then(({ Response }) => {
        return Response.data;
      });
  },
  GetMasterSumberDanaOptions: function (params) {
    return Api()
      .Get(`msgadaimasmaster/v1/master/sumberdana?isActive=1`)
      .then(({ Response }) => {
        return Response.data;
      });
  },
  GetMasterPendidikanOptions: function (params) {
    return Api()
      .Get(`msgadaimasmaster/v1/master/pendidikan?isActive=1`)
      .then(({ Response }) => {
        return Response.data;
      });
  },
  GetMasterKaryawanInternalOptions: function (params) {
    return Api()
      .Get(`msgadaimasmaster/v1/master/karyawanInternal?isActive=1`)
      .then(({ Response }) => {
        return Response.data;
      });
  },
  FotoSelfie: function (data) {
    return Api()
      .PostFileAbsen("msgadaimasupload/v1/upload/selfie", data, { rawJSON: true, withoutToken: true })
      .then(({ data }) => {
        return data;
      });
  },
  cuCustomer: function (data) {
    return Api()
      .postRaw2("msgadaimasuser/v1/customer/customer", data, { rawJSON: true })
      .then(({ data }) => {
        return data?.Response?.data;
      });
  },
  GetJabatanCustomerOptions: function (params) {
    return Api()
      .Get(`msgadaimasmaster/v1/customer/jabatanCustomer?isActive=1`)
      .then(({ Response }) => {
        return Response.data;
      });
  },
  GetSektorEkonomiOptions: function (params) {
    return Api()
      .Get(`msgadaimasmaster/v1/master/sektorekonomi?isActive=1`)
      .then(({ Response }) => {
        return Response.data;
      });
  },
  GetJenisReferensiOptions: function (params) {
    return Api()
      .Get(`msgadaimasmaster/v1/master/jenisReferensi?isActive=1`)
      .then(({ Response }) => {
        return Response.data;
      });
  },
  GetMasterPekerjaanOptions: function (params) {
    return Api()
      .Get(`msgadaimasmaster/v1/master/pekerjaan?isActive=1`)
      .then(({ Response }) => {
        return Response.data;
      });
  },
  getMasterBidangUsahaOptions: function (params) {
    return Api()
      .Get(`msgadaimasmaster/v1/master/bidangusaha?isActive=1`)
      .then(({ Response }) => {
        return Response.data;
      });
  },
  getMasterReferensiJenis: function (params) {
    return Api()
      .Get(`msgadaimasmaster/v1/master/jenisReferensi${params}`)
      .then(({ Response }) => {
        return Response.data;
      });
  },
  cuReferensiJenis: function (data) {
    return Api()
      .postRaw("msgadaimasmaster/v1/master/jenisReferensi", data, { rawJSON: true })
      .then(({ data }) => {
        return data;
      });
  },
  getMasterAgama: function (params) {
    return Api()
      .Get(`msgadaimasmaster/v1/master/agama${params}`)
      .then(({ Response }) => {
        return Response.data;
      });
  },
  cuAgama: function (data) {
    return Api()
      .postRaw("msgadaimasmaster/v1/master/agama", data, { rawJSON: true })
      .then(({ data }) => {
        return data;
      });
  },
  getAgamaOptions: function (params) {
    return Api()
      .Get(`msgadaimasmaster/v1/master/agama?isActive=1`)
      .then(({ Response }) => {
        return Response.data;
      });
  },
  FotoSelfieKasir: function (data, param = "") {
    return Api()
      .PostFileAbsen(`msgadaimasupload/v1/upload/selfie${param}`, data, { multipartForm: true, withoutToken: true })
      .then((res) => {
        return res.data;
      });
  },
  FotoDenomFullFill: function (data, param = "") {
    return Api()
      .PostFileAbsen(`msgadaimasupload/v1/upload/droping/image?type=droping`, data, { multipartForm: true, withoutToken: true })
      .then((res) => {
        return res.data;
      });
  },
  FotoApprovalFullFill: function (data, param = "") {
    return Api()
      .PostFileAbsen(`msgadaimasupload/v1/upload/droping/selfie`, data, { multipartForm: true, withoutToken: true })
      .then((res) => {
        return res.data;
      });
  },
  FotoSelfieDropping: function (data) {
    return Api()
      .PostFileAbsen(`msgadaimasupload/v1/upload/selfie/fullfill/dropingcabangunit`, data, { multipartForm: true, withoutToken: true })
      .then((res) => {
        return res.data;
      });
  },
  AprrovalOpenKasir: function (data) {
    return Api()
      .PutOpenCashier("msgadaimasfinance/v1/transaksi/internal", data, { rawJSON: true })
      .then(({ data }) => {
        return data;
      });
  },
  DeleteFile: function (param) {
    return Api()
      .postRaw(`msgadaimasupload/v1/delete/file${param}`, {}, { withoutToken: true })
      .then(({ data }) => {
        return data;
      });
  },
  getMasterJenisTransaksi: function (params) {
    return Api()
      .Get(`msgadaimasmaster/v1/master/jenistransaksi${params}`)
      .then(({ Response }) => {
        return Response.data;
      });
  },
  cuJenisTransaksi: function (data) {
    return Api()
      .postRaw("msgadaimasmaster/v1/master/jenistransaksi", data, { rawJSON: true })
      .then(({ data }) => {
        return data;
      });
  },
  getMasterMenu: function (params) {
    return Api()
      .Get(`msgadaimasuser/v1/user/master/menu${params}`, { withoutToken: true })
      .then(({ Response }) => {
        return Response.data;
      });
  },
  cekVoucher: function (data) {
    return Api()
      .postRaw2(`msgadaimasuser/v1/customer/checkvoucher`, data, { rawJSON: true })
      .then(({ data }) => {
        console.log("voucher", data.Response.data);
        return data.Response.data;
      });
  },
  getDetailTaksiranEmas: function (params = "") {
    return Api()
      .Get(`msgadaimastaksasi/v1/taksiran/detail${params}`)
      .then(({ Response }) => {
        return Response.data;
      });
  },
  getKtp: function (param) {
    return Api()
      .Get(`msgadaimasuser/v1/customer/ktp${param}`)
      .then(({ Response }) => {
        return Response.data;
      });
  },
  postFotoJaminan: function (data, param) {
    return Api()
      .PostFileAbsen(`msgadaimasupload/v1/upload/jaminan${param}`, data, { rawJSON: true, withoutToken: true })
      .then(({ data }) => {
        return data;
      });
  },
  hitungTaksir: function (data) {
    return Api()
      .postRaw2("msgadaimastaksasi/v1/taksiran/hitung", data, { rawJSON: true })
      .then((res) => {
        return res.data.Response.data;
      });
  },
  cuTaksirEmas: function (data) {
    return Api()
      .postRaw2("msgadaimastaksasi/v1/taksiran/emas", data, { rawJSON: true })
      .then(({ data }) => {
        return data;
      });
  },
  getTaksirEmas: function (params) {
    return Api()
      .Get(`msgadaimastaksasi/v1/taksiran/emas${params}`)
      .then(({ Response }) => {
        return Response.data;
      });
  },
  uploadJaminan: function (data) {
    return Api()
      .PostFileAbsen("msgadaimasupload/v1/upload/taksiranAwal", data, { rawJSON: true, withoutToken: true })
      .then(({ data }) => {
        return data;
      });
  },
  getTaksirAwal: function (params) {
    return Api()
      .Get(`msgadaimastaksasi/v1/taksiran/awal${params}`)
      .then(({ Response }) => {
        return Response.data;
      });
  },
  cuTaksirAwal: function (data) {
    return Api()
      .postRaw2("msgadaimastaksasi/v1/taksiran/awal", data, { rawJSON: true })
      .then(({ data }) => {
        return data?.Response?.data;
      });
  },
  getKTPTaksir: function (params) {
    return Api()
      .Get(`msgadaimasuser/v1/customer/ktp${params}`)
      .then(({ Response }) => {
        return Response.data;
      });
  },
  postFotoSelfieKaunit: function (data) {
    return Api()
      .PostFileAbsen(`msgadaimasupload/v1/upload/selfie/kepalaunit`, data, { rawJSON: true, withoutToken: true })
      .then(({ data }) => {
        return data;
      });
  },
  getGenerateBlanko: function (params) {
    return Api()
      .Get(`msgadaimasuser/v1/blanko/blanko${params}`)
      .then(({ Response }) => {
        return Response.data;
      });
  },
  cuGenerateBlanko: function (data) {
    return Api()
      .postRaw("msgadaimasuser/v1/cabang/generateBlanko", data, { rawJSON: true })
      .then(({ data }) => {
        return data;
      });
  },
  getJenisBlanko: function (params) {
    return Api()
      .Get(`msgadaimasuser/v1/blanko/blankojenis${params}`)
      .then(({ Response }) => {
        return Response.data;
      });
  },
  getReportLKH: function (params) {
    return Api()
      .Get(`msgadaimaslaporan/v1/laporan/lkhCabang${params}`)
      .then(({ Response }) => {
        return Response.data;
      });
  },
  getProdukGadai: function (params) {
    return Api()
      .Get(`msgadaimasproduk/v1/produk/gadai${params}`)
      .then(({ Response }) => {
        return Response.data;
      });
  },
  getListFAPG: function (params) {
    return Api()
      .Get(`msgadaimastransaksi/v1/gadai/nofapg${params}`)
      .then(({ Response }) => {
        return Response.data;
      });
  },
  postDetailGadai: function (data) {
    return Api()
      .postRaw2("msgadaimastransaksi/v1/gadai/detail", data, { rawJSON: true })
      .then(({ data }) => {
        return data?.Response?.data;
      });
  },
  getAsalJaminan: function (params) {
    return Api()
      .Get(`msgadaimastransaksi/v1/gadai/asaljaminan`)
      .then(({ Response }) => {
        return Response.data;
      });
  },
  getAsalJaminanOptions: function () {
    return Api()
      .Get(`msgadaimastransaksi/v1/gadai/asaljaminan?isActive=1`)
      .then(({ Response }) => {
        return Response.data;
      });
  },
  getTransaksiGadaiTitipan: function (params) {
    return Api()
      .Get(`msgadaimastransaksi/v2/gadai/nosbg${params}`, { isSignature: true })
      .then(({ Response }) => {
        return Response.data;
      });
  },
  getTransaksiGadai: function (params) {
    return Api()
      .Get(`msgadaimastransaksi/v1/gadai/nosbg${params}`)
      .then(({ Response }) => {
        return Response.data;
      });
  },
  cuTransaksiGadai: function (data) {
    return Api()
      .postRaw2("msgadaimastransaksi/v1/gadai/createUpdate", data, { rawJSON: true })
      .then(({ data }) => {
        return data;
      });
  },
  getTransaksiFAPG: function (params) {
    return Api()
      .Get(`msgadaimastransaksi/v1/gadai/listfapg${params}`)
      .then(({ Response }) => {
        return Response.data;
      });
  },
  postPencairan: function (data) {
    return Api()
      .postRaw2("msgadaimastransaksi/v1/gadai/pencairan", data, { rawJSON: true })
      .then(({ data }) => {
        return data?.Response?.data;
      });
  },
  getStoring: function (params) {
    return Api()
      .Get(`msgadaimastransaksi/v1/user/master/storing${params}`)
      .then(({ Response }) => {
        return Response.data;
      });
  },
  postStoring: function (data) {
    return Api()
      .postRaw2("msgadaimastransaksi/v1/user/storing", data, { rawJSON: true })
      .then(({ data }) => {
        return data?.Response?.data;
      });
  },
  getBrankas: function (params) {
    return Api()
      .Get(`msgadaimasmaster/v1/master/brankaslajur${params}`)
      .then(({ Response }) => {
        return Response.data;
      });
  },
  getListBlanko: function (params) {
    return Api()
      .Get(`msgadaimasuser/v1/blanko/blanko${params}`)
      .then(({ Response }) => {
        return Response.data;
      });
  },
  generateSBG: function (params, data) {
    return Api()
      .postRaw2(`msgadaimastransaksi/v1/gadai/generateSBG${params}`, data, { rawJSON: true })
      .then(({ data }) => {
        return data?.Response?.data;
      });
  },
  generateSBG2: function (params, data) {
    return Api()
      .postRaw2(`msgadaimasuser/v1/generate/sbg${params}`, data, { rawJSON: true, isHtml: true })
      .then(({ data }) => {
        return data;
      });
  },
  postSelfieApproval: function (data, param = "") {
    return Api()
      .PostFileAbsen(`msgadaimasupload/v1/upload/selfie/approval/gadai${param}`, data, { multipartForm: true, withoutToken: true })
      .then(({ data }) => {
        return data;
      });
  },
  postSelfieStoring: function (data, param = "") {
    return Api()
      .PostFileAbsen(`msgadaimastransaksi/v1/upload/selfie/storing${param}`, data, { multipartForm: true, withoutToken: true })
      .then(({ data }) => {
        return data;
      });
  },
  cuMasterBrankas: function (data) {
    return Api()
      .postRaw2("msgadaimasmaster/v1/master/brankas", data, { rawJSON: true })
      .then(({ data }) => {
        return data?.Response?.data;
      });
  },
  getBrankasMaster: function (params) {
    return Api()
      .Get(`msgadaimasmaster/v1/master/brankas${params}`)
      .then(({ Response }) => {
        return Response.data;
      });
  },
  cuMasterLaci: function (data) {
    return Api()
      .postRaw2("msgadaimasmaster/v1/master/brankaslaci", data, { rawJSON: true })
      .then(({ data }) => {
        return data?.Response?.data;
      });
  },
  getLaciMaster: function (params) {
    return Api()
      .Get(`msgadaimasmaster/v1/master/brankaslaci${params}`)
      .then(({ Response }) => {
        return Response.data;
      });
  },
  cuMasterLajur: function (data) {
    return Api()
      .postRaw2("msgadaimasmaster/v1/master/brankaslajur", data, { rawJSON: true })
      .then(({ data }) => {
        return data?.Response?.data;
      });
  },
  getLajurMaster: function (params) {
    return Api()
      .Get(`msgadaimasmaster/v1/master/brankaslajur${params}`)
      .then(({ Response }) => {
        return Response.data;
      });
  },
  postClosing: function (params = "", data = {}) {
    return Api()
      .postRaw2(`msgadaimasuser/v1/action/closingHistory${params}`, data, { rawJSON: true, withoutToken: true })
      .then(({ data }) => {
        return data?.Response?.data;
      });
  },
  getMasterCashback: function (params) {
    return Api()
      .Get(`msgadaimastransaksi/v1/user/cashback${params}`)
      .then(({ Response }) => {
        return Response.data;
      });
  },
  postPencairanCashback: function (data, param = "") {
    return Api()
      .postRaw2(`msgadaimastransaksi/v1/user/generatecashback${param}`, data, { rawJSON: true })
      .then(({ data }) => {
        return data;
      });
  },
  getMasterFufill: function (params) {
    return Api()
      .Get(`msgadaimasfinance/v1/fullfill/hocabang/kacab${params}`)
      .then(({ Response }) => {
        return Response.data;
      });
  },
  getMasterFufillFinance: function (params) {
    return Api()
      .Get(`msgadaimasfinance/v1/fullfill/hocabang/finance${params}`)
      .then(({ Response }) => {
        return Response.data;
      });
  },
  getMasterBuffer: function (params = "") {
    return Api()
      .Get(`msgadaimasmaster/v1/master/jenisfullfill${params}`)
      .then(({ Response }) => {
        return Response.data;
      });
  },
  getDataFullFill: function (params = "") {
    return Api()
      .Get(`msgadaimasfinance/v1/fullfill/kacab/ajukan${params}`)
      .then(({ Response }) => {
        return Response.data;
      });
  },
  postPengajuanFullfill: function (data) {
    return Api()
      .postRaw2("msgadaimasfinance/v1/fullfill/kacab/ajukan", data, { rawJSON: true })
      .then(({ data }) => {
        return data?.Response?.data;
      });
  },
  getBankKeluar: function (params = "") {
    return Api()
      .Get(`msgadaimasfinance/v1/fullfill/finance/bank?statusBank=keluar${params}`)
      .then(({ Response }) => {
        return Response.data;
      });
  },
  getBankMasuk: function (params = "") {
    return Api()
      .Get(`msgadaimasfinance/v1/fullfill/finance/bank?statusBank=masuk${params}`)
      .then(({ Response }) => {
        return Response.data;
      });
  },
  getProsesFulFill: function (params = "") {
    return Api()
      .Get(`msgadaimasfinance/v1/fullfill/hocabang/finance/proses${params}`)
      .then(({ Response }) => {
        return Response.data;
      });
  },
  postProsesFullfill: function (data) {
    return Api()
      .postRaw2("msgadaimasfinance/v1/fullfill/hocabang/finance/proses", data, { rawJSON: true })
      .then(({ data }) => {
        return data?.Response?.data;
      });
  },
  cetakKwitansiFullfill: function (data) {
    return Api()
      .postRaw2("msgadaimasfinance/v1/fullfill/kwitansi", data, { rawJSON: true })
      .then(({ data }) => {
        return data?.Response?.data;
      });
  },
  getFullfillCabangUnit: function (params) {
    return Api()
      .Get(`msgadaimasfinance/v1/fullfill/cabangunit/droping${params}`)
      .then(({ Response }) => {
        return Response.data;
      });
  },
  postDropingCabangUnit: function (data) {
    return Api()
      .postRaw2("msgadaimasfinance/v1/fullfill/cabangunit/droping", data, { rawJSON: true })
      .then(({ data }) => {
        return data?.Response?.data;
      });
  },
  getClosing: function (params) {
    return Api()
      .Get(`msgadaimasuser/v1/master/closing/operasional${params}`)
      .then(({ Response }) => {
        return Response.data;
      });
  },
  FotoSelfieTitipan: function (data) {
    return Api()
      .PostFileAbsen(`msgadaimasupload/v1/upload/titipan/selfie`, data, { multipartForm: true, withoutToken: true })
      .then((res) => {
        return res.data;
      });
  },
  FotoDenomTitipan: function (data) {
    return Api()
      .PostFileAbsen(`msgadaimasupload/v1/upload/titipan/denom`, data, { multipartForm: true, withoutToken: true })
      .then((res) => {
        return res.data;
      });
  },
  postTitipan: function (data) {
    return Api()
      .postRaw("msgadaimastransaksi/v1/gadai/titipan", data, { rawJSON: true })
      .then(({ data }) => {
        return data;
      });
  },
  getMasterTitipan: function (params) {
    return Api()
      .Get(`msgadaimastransaksi/v1/user/vouchertitipan${params}`)
      .then(({ Response }) => {
        return Response.data;
      });
  },
  getMasterBatch: function (params) {
    return Api()
      .Get(`msgadaimasmaster/v1/master/fullfillbatch${params}`)
      .then(({ Response }) => {
        return Response.data;
      });
  },
  getBufferNormalFinance: function (params) {
    return Api()
      .Get(`msgadaimasfinance/v1/fullfill/bufferNormal/finance${params}`)
      .then(({ Response }) => {
        return Response.data;
      });
  },
  getGiroCek: function (params) {
    return Api()
      .Get(`msgadaimasmaster/v1/master/girocek${params}`)
      .then(({ Response }) => {
        return Response.data;
      });
  },
  postBufferNormal: function (data) {
    return Api()
      .postRaw("msgadaimasfinance/v1/fullfill/bufferNormal/finance", data, { rawJSON: true })
      .then(({ data }) => {
        return data;
      });
  },
  postBufferNormalTrans: function (data) {
    return Api()
      .postRaw("msgadaimasfinance/v1/fullfill/bufferNormal/transaksi", data, { rawJSON: true })
      .then(({ data }) => {
        return data;
      });
  },
  cuBatch: function (data) {
    return Api()
      .postRaw("/msgadaimasmaster/v1/master/fullfillbatch", data, { rawJSON: true })
      .then(({ data }) => {
        return data;
      });
  },
  cuGiro: function (data) {
    return Api()
      .postRaw("msgadaimasmaster/v1/master/girocek", data, { rawJSON: true })
      .then(({ data }) => {
        return data;
      });
  },
  getSimulasiSbg: function (params = "") {
    return Api()
      .Get(`msgadaimastransaksi/v1/payment/pelunasan/listNoSbg${params}`)
      .then(({ Response }) => {
        return Response.data;
      });
  },
  getSimulasiHitung: function (data) {
    return Api()
      .postRaw2("msgadaimastransaksi/v1/payment/pelunasan", data, { rawJSON: true })
      .then(({ data }) => {
        return data;
      });
  },
  cetakSimulasi: function (data) {
    return Api()
      .postRaw2("msgadaimastransaksi/v1/payment/generate/pelunasan", data, { rawJSON: true })
      .then(({ data }) => {
        return data;
      });
  },
  PostPelunasanGadai: function (data) {
    return Api()
      .postRaw2("msgadaimastransaksi/v1/payment/pelunasan", data, { rawJSON: true })
      .then(({ data }) => {
        return data;
      });
  },
  getSimulasiCicilan: function (params) {
    return Api()
      .Get(`msgadaimascicilan/v2/cicilan/pembayaran/cicilan${params}`, { isSignature: true })
      .then(({ Response }) => {
        return Response.data;
      });
  },
  postPembayaranCicilan: function (data) {
    return Api()
      .postRaw2("msgadaimascicilan/v2/cicilan/pembayaran/cicilan", { request: data }, { rawJSON: true, isSignature: true })
      .then(({ data }) => {
        return data?.Response?.data;
      });
  },
  getMasterPelunasan: function (params) {
    return Api()
      .Get(`msgadaimastransaksi/v1/payment/pelunasan/gadai${params}`)
      .then(({ Response }) => {
        return Response.data;
      });
  },
  getMasterCicilan: function (params) {
    return Api()
      .Get(`msgadaimascicilan/v2/cicilan/transaksi${params}`, { rawJSON: true, isSignature: true })
      .then(({ Response }) => {
        return Response.data;
      });
  },
  getMasterPengeluaran: function (params) {
    return Api()
      .Get(`msgadaimastransaksi/v2/payment/pelunasan/nasabah${params}`, { isSignature: true })
      .then(({ Response }) => {
        return Response.data;
      });
  },
  getDetailBuffer: function (params) {
    return Api()
      .Get(`msgadaimasfinance/v1/fullfill/bufferLiburUrgent/kaunit${params}`)
      .then(({ Response }) => {
        return Response.data;
      });
  },
  FotoBufferNormal: function (data, params) {
    return Api()
      .PostFileAbsen(`msgadaimasupload/v1/upload/bufferUrgentLibur?type=${params}`, data, { multipartForm: true, withoutToken: true })
      .then((res) => {
        return res.data;
      });
  },
  getBufferLiburUrgent: function (params) {
    return Api()
      .Get(`msgadaimasfinance/v1/fullfill/bufferLiburUrgent${params}`)
      .then(({ Response }) => {
        return Response.data;
      });
  },
  postMutasiBufferUrgent: function (data) {
    return Api()
      .postRaw2("msgadaimasfinance/v1/fullfill/bufferLiburUrgent", data, { rawJSON: true })
      .then(({ data }) => {
        return data?.Response?.data;
      });
  },
  getPerpanjangan: function (params) {
    return Api()
      .Get(`msgadaimastaksasi/v1/taksiran/perpanjangan/gadai${params}`)
      .then(({ Response }) => {
        return Response.data;
      });
  },
  getPerpanjanganPencairan: function (params) {
    return Api()
      .Get(`msgadaimastransaksi/v1/gadai/perpanjangan/pinjaman${params}`)
      .then(({ Response }) => {
        return Response.data;
      });
  },
  generateLTV: function (data) {
    return Api()
      .postRaw2("msgadaimasuser/v1/generate/ltv", data, { rawJSON: true, isHtml: true })
      .then(({ data }) => {
        return data;
      });
  },
  generateUraian: function (data) {
    return Api()
      .postRaw2("msgadaimasuser/v1/generate/uraianbaranjaminan", data, { rawJSON: true, isHtml: true })
      .then(({ data }) => {
        return data;
      });
  },
  getLaporanBooking: function (params) {
    return Api()
      .Get(`msgadaimaslaporan/v1/laporan/booking${params}`)
      .then(({ Response }) => {
        return Response.data;
      });
  },
  getLaporanOutstanding: function (params) {
    return Api()
      .Get(`msgadaimaslaporan/v1/laporan/reportoutstanding${params}`)
      .then(({ Response }) => {
        return Response.data;
      });
  },
  getMasterBankOutlet: function (params) {
    return Api()
      .Get(`msgadaimasmaster/v1/master/bank/outlet${params}`)
      .then(({ Response }) => {
        return Response.data;
      });
  },
  getListBankOutlet: function (params) {
    return Api()
      .Get(`msgadaimasmaster/v1/master/bank/outlet${params}`)
      .then(({ Response }) => {
        return Response.data;
      });
  },
  cuBankOutlet: function (data) {
    return Api()
      .postRaw("msgadaimasmaster/v1/master/bank/outlet", data, { rawJSON: true })
      .then(({ data }) => {
        return data;
      });
  },
  getMasterJenisJf: function (params) {
    return Api()
      .Get(`msgadaimasmaster/v1/master/settingjf${params}`)
      .then(({ Response }) => {
        return Response.data;
      });
  },
  getMasterPartner: function (params) {
    return Api()
      .Get(`msgadaimasmaster/v1/master/partner${params}`)
      .then(({ Response }) => {
        return Response.data;
      });
  },
  getMasterTipePartner: function (params) {
    return Api()
      .Get(`msgadaimasmaster/v1/master/tipepartner${params}`)
      .then(({ Response }) => {
        return Response.data;
      });
  },
  getMasterCOA: function (params) {
    return Api()
      .Get(`msgadaimasmaster/v1/master/coa${params}`)
      .then(({ Response }) => {
        return Response.data;
      });
  },
  cuTipePartner: function (data) {
    return Api()
      .postRaw2("msgadaimasmaster/v1/master/tipepartner", data, { rawJSON: true })
      .then(({ data }) => {
        return data?.Response?.data;
      });
  },
  cuPartner: function (data) {
    return Api()
      .postRaw2("msgadaimasmaster/v1/master/partner", data, { rawJSON: true })
      .then(({ data }) => {
        return data?.Response?.data;
      });
  },
  cuSettingJf: function (data) {
    return Api()
      .postRaw2("msgadaimasmaster/v1/master/settingjf", data, { rawJSON: true })
      .then(({ data }) => {
        return data?.Response?.data;
      });
  },
  getMasterFunding: function (params) {
    return Api()
      .Get(`msgadaimasfinance/v1/treasury/funding${params}`)
      .then(({ Response }) => {
        return Response.data;
      });
  },
  PostUploadFilePledging: function (data, params) {
    return Api()
      .PostFileAbsen(`msgadaimasfinance/v1/treasury/upload/funding`, data, { multipartForm: true })
      .then((res) => {
        return res.data;
      });
  },
  PostProsesFilePledging: function (data, params) {
    return Api()
      .postRaw2(`msgadaimasfinance/v1/treasury/upload/funding/proses`, data, { rawJSON: true })
      .then((res) => {
        return res.data;
      });
  },
  PostUploadFileUnPledging: function (data, params) {
    return Api()
      .PostFileAbsen(`msgadaimasfinance/v1/treasury/upload/unPledgingfunding`, data, { multipartForm: true })
      .then((res) => {
        return res.data;
      });
  },
  PostProsesFileUnPledging: function (data, params) {
    return Api()
      .postRaw2(`msgadaimasfinance/v1/treasury/unpledging/funding/proses`, data, { rawJSON: true })
      .then((res) => {
        return res.data;
      });
  },
  DownloadSbgFunding: function (data) {
    return Api()
      .postRaw2("msgadaimasfinance/v1/treasury/download/sbgFunding", data, { rawJSON: true })
      .then(({ data }) => {
        return data?.Response?.data;
      });
  },
  PostUnplegding: function (data, params) {
    return Api()
      .postRaw2(`msgadaimasfinance/v1/treasury/unpledging/funding`, data, { rawJSON: true })
      .then((res) => {
        return res.data;
      });
  },
  cuhargaJual: function (data) {
    return Api()
      .postRaw2("/msgadaimasmaster/v1/master/hargajual", data, { rawJSON: true })
      .then(({ data }) => {
        return data?.Response?.data;
      });
  },
  getMasterHargaJual: function (params) {
    return Api()
      .Get(`/msgadaimasmaster/v1/master/hargajual${params}`)
      .then(({ Response }) => {
        return Response.data;
      });
  },
  cuDiskonJual: function (data) {
    return Api()
      .postRaw2("msgadaimasmaster/v1/master/diskonpenjualan", data, { rawJSON: true })
      .then(({ data }) => {
        return data?.Response?.data;
      });
  },
  getMasterDiskonJual: function (params) {
    return Api()
      .Get(`msgadaimasmaster/v1/master/diskonpenjualan${params}`)
      .then(({ Response }) => {
        return Response.data;
      });
  },
  CekSbgPenjualan: function (data) {
    return Api()
      .postRaw2("msgadaimastransaksi/v2/penjualan/create/check/sbg", { request: data }, { rawJSON: true, isSignature: true })
      .then(({ data }) => {
        return data?.Response?.data;
      });
  },
  getListSbgStoring: function (params = "") {
    return Api()
      .Get(`msgadaimastransaksi/v1/user/master/storing?isSerahkan=1${params}`)
      .then(({ Response }) => {
        return Response.data;
      });
  },
  getMasterPenjualan: function (params) {
    return Api()
      .Get(`msgadaimastransaksi/v1/penjualan/master${params}`)
      .then(({ Response }) => {
        return Response.data;
      });
  },
  FotoKtpPenjualan: function (data, params) {
    return Api()
      .PostFileAbsen(`msgadaimasupload/v1/upload/penjualan/ktp`, data, { multipartForm: true, withoutToken: true })
      .then((res) => {
        return res.data;
      });
  },
  updatePassword: function (data, params) {
    return Api()
      .PostFileAbsen(`msgadaimasuser/v1/user/update/passwordImage`, data, { multipartForm: true, withoutToken: true })
      .then((res) => {
        return res.data;
      });
  },
  resetPasswordDefault: function (data, params) {
    return Api()
      .PostFileAbsen(`msgadaimasuser/v1/user/update/passwordImage`, data, { multipartForm: true, withoutToken: true })
      .then((res) => {
        return res.data;
      });
  },
  getMasterPenjualanBarangJaminan: function (params) {
    return Api()
      .Get(`msgadaimastransaksi/v1/penjualan/pelunasanjual${params}`)
      .then(({ Response }) => {
        return Response.data;
      });
  },
  KasirPenjualan: function (data) {
    return Api()
      .postRaw2("msgadaimastransaksi/v1/penjualan/updatekasirpenjualan", data, { rawJSON: true })
      .then(({ data }) => {
        return data?.Response?.data;
      });
  },
  ambilBarangJaminan: function (data) {
    return Api()
      .postRaw2("msgadaimastransaksi/v1/user/storing", data, { rawJSON: true })
      .then(({ data }) => {
        return data?.Response?.data;
      });
  },
  TerimaBarangJaminan: function (data) {
    return Api()
      .postRaw2("msgadaimastransaksi/v1/user/storing", data, { rawJSON: true })
      .then(({ data }) => {
        return data?.Response?.data;
      });
  },
  Signature: function (data) {
    return Api()
      .postRaw2("msgadaimasuser/v1/actions/signature/api", data, { rawJSON: true, isSignature: true })
      .then(({ data }) => {
        return data?.Response?.data;
      });
  },
  getMasterAlasanPengeluaran: function (params) {
    return Api()
      .Get(`msgadaimasmaster/v1/master/pengeluaranbarang${params}`)
      .then(({ Response }) => {
        return Response.data;
      });
  },
  FotoPengembalian: function (data, params) {
    return Api()
      .PostFileAbsen(`msgadaimasupload/v1/upload/penjualan/sbgKtpNasabah`, data, { multipartForm: true, withoutToken: true })
      .then((res) => {
        return res.data;
      });
  },
  PengembalianBarangJamminan: function (data) {
    return Api()
      .postRaw2("msgadaimastransaksi/v1/penjualan/pengembalian", data, { rawJSON: true })
      .then(({ data }) => {
        return data?.Response?.data;
      });
  },
  getMasterMutasiBlangko: function (params) {
    return Api()
      .Get(`msgadaimasuser/v2/blanko/blankomutasi${params}`, { isSignature: true })
      .then(({ Response }) => {
        return Response.data;
      });
  },
  cuMutasiBlanko: function (data) {
    return Api()
      .postRaw2("msgadaimasuser/v2/blanko/blankomutasi", { request: data }, { rawJSON: true, isSignature: true })
      .then(({ data }) => {
        return data?.Response?.data;
      });
  },
  getMasterMutasiSbg: function (params) {
    return Api()
      .Get(`msgadaimastransaksi/v2/gadai/mutasiSbg${params}`, { rawJSON: true, isSignature: true })
      .then(({ Response }) => {
        return Response.data;
      });
  },
  cuMutasiSbg: function (body) {
    return Api()
      .PostFileAbsen("msgadaimastransaksi/v2/gadai/mutasiSbg", body, { multipartForm: true, rawJSON: true, isSignature: true })
      .then(({ data }) => {
        return data;
      });
  },
  getMasterMutasiBarang: function (params) {
    return Api()
      .Get(`msgadaimastransaksi/v2/gadai/mutasibarang${params}`, { rawJSON: true, isSignature: true })
      .then(({ Response }) => {
        return Response.data;
      });
  },
  cuMutasiBarang: function (data) {
    return Api()
      .postRaw2("msgadaimastransaksi/v2/gadai/mutasibarang", { request: data }, { rawJSON: true, isSignature: true })
      .then(({ data }) => {
        return data?.Response?.data;
      });
  },
  cuMutasiTitipan: function (body) {
    return Api()
      .PostFileAbsen("msgadaimastransaksi/v2/gadai/mutasititipan", { request: body }, { rawJSON: true, isSignature: true })
      .then(({ data }) => {
        return data;
      });
  },
  getMasterMutasiTitipan: function (params) {
    return Api()
      .Get(`msgadaimastransaksi/v2/gadai/mutasititipan${params}`, { rawJSON: true, isSignature: true })
      .then(({ Response }) => {
        return Response.data;
      });
  },
  getMasterPembayaranCicilan: function (params) {
    return Api()
      .Get(`msgadaimascicilan/v2/cicilan/master/pembayarann/cicilan${params}`, { rawJSON: true, isSignature: true })
      .then(({ Response }) => {
        return Response.data;
      });
  },
  getSbgTitipan: function (params) {
    return Api()
      .Get(`msgadaimastransaksi/v2/gadai/titipan${params}`, { rawJSON: true, isSignature: true })
      .then(({ Response }) => {
        return Response.data;
      });
  },
  getMasterInventory: function (params) {
    return Api()
      .Get(`msgadaimastransaksi/v2/gadai/nosbg${params}`, { isSignature: true })
      .then(({ Response }) => {
        return Response.data;
      });
  },
  getMasterMutasiObs: function (params) {
    return Api()
      .Get(`msgadaimasuser/v2/mutasi/mutasi/obs${params}`, { rawJSON: true, isSignature: true })
      .then(({ Response }) => {
        return Response.data;
      });
  },
  cuMutasiObs: function (body) {
    return Api()
      .PostFileAbsen("msgadaimasuser/v2/mutasi/mutasi/obs", body, { rawJSON: true, isSignature: true })
      .then(({ data }) => {
        return data;
      });
  },
  getMasterGradeKaUnit: function (params) {
    return Api()
      .Get(`msgadaimasmaster/v1/master/gradekaunit${params}`)
      .then(({ Response }) => {
        return Response.data;
      });
  },
  getListUnitPettyCash: function (params) {
    return Api()
      .Get(`msgadaimasfinance/v2/pettyCash/listUnit${params}`, { isSignature: true })
      .then(({ Response }) => {
        return Response.data;
      });
  },
  getMasterPettyCash: function (params) {
    return Api()
      .Get(`msgadaimasfinance/v2/pettyCash/realisasi/pettycash${params}`, { isSignature: true })
      .then(({ Response }) => {
        return Response.data;
      });
  },
  getMasterCostCenter: function (params) {
    return Api()
      .Get(`msgadaimasmaster/v1/master/costcenter${params}`)
      .then(({ Response }) => {
        return Response.data;
      });
  },
  cuPettyCashHOCabang: function (data) {
    return Api()
      .postRaw2("msgadaimasfinance/v2/pettyCash/pengajuan", { request: data }, { rawJSON: true, isSignature: true })
      .then(({ data }) => {
        return data;
      });
  },
  PettyCashTransfer: function (data) {
    return Api()
      .postRaw2("msgadaimasfinance/v2/pettyCash/transfer", { request: data }, { rawJSON: true, isSignature: true })
      .then(({ data }) => {
        return data;
      });
  },
  getDropingPettyCash: function (params) {
    return Api()
      .Get(`msgadaimasfinance/v2/pettyCash/fapettycash${params}`, { isSignature: true })
      .then(({ Response }) => {
        return Response.data;
      });
  },
  fotoKacabPettyCash: function (data) {
    return Api()
      .PostFileAbsen(`msgadaimasupload/v1/upload/selfie/kacab/pettycash`, data, { rawJSON: true, withoutToken: true })
      .then(({ data }) => {
        return data;
      });
  },
  postDropingPettyCashCabangUnit: function (data) {
    return Api()
      .postRaw2("msgadaimasfinance/v2/pettyCash/droping", { request: data }, { rawJSON: true, isSignature: true })
      .then(({ data }) => {
        return data?.Response?.data;
      });
  },
  getRealisasiPettyCash: function (params) {
    return Api()
      .Get(`msgadaimasfinance/v2/pettyCash/realisasi${params}`, { isSignature: true })
      .then(({ Response }) => {
        return Response.data;
      });
  },
  postFileRealisasi: function (body) {
    return Api()
      .PostFileAbsen("msgadaimasupload/v1/upload/pattycash?type=pettycash", body, { multipartForm: true, withoutToken: true })
      .then(({ data }) => {
        return data;
      });
  },
  cuPettyCash: function (data) {
    return Api()
      .postRaw2("msgadaimasfinance/v2/pettyCash/realisasi", { request: data }, { rawJSON: true, isSignature: true })
      .then(({ data }) => {
        return data?.Response?.data;
      });
  },
  fotoSelfiePettyCash: function (data) {
    return Api()
      .PostFileAbsen(`msgadaimasupload/v1/upload/selfie/denom/pettycash`, data, { multipartForm: true, withoutToken: true })
      .then((res) => {
        return res.data;
      });
  },
  fotoDenomPettyCash: function (data) {
    return Api()
      .PostFileAbsen(`msgadaimasupload/v1/upload/foto/denom/pettycash`, data, { multipartForm: true, withoutToken: true })
      .then((res) => {
        return res.data;
      });
  },
  fotoSbgDepan: function (data, sbg) {
    return Api()
      .PostFileAbsen(`msgadaimasupload/v1/upload/uploadUlangSbg?dir=sbgdepan&filename=${sbg}-D`, data, { multipartForm: true, withoutToken: true })
      .then((res) => {
        return res.data;
      });
  },
  fotoSbgBelakang: function (data, sbg) {
    return Api()
      .PostFileAbsen(`msgadaimasupload/v1/upload/uploadUlangSbg?dir=sbgbelakang&filename=${sbg}-B`, data, { multipartForm: true, withoutToken: true })
      .then((res) => {
        return res.data;
      });
  },
  postDropingPettyCashCabangUnit: function (data) {
    return Api()
      .postRaw2("msgadaimasfinance/v2/pettyCash/droping", { request: data }, { rawJSON: true, isSignature: true })
      .then(({ data }) => {
        return data?.Response?.data;
      });
  },
  postApprovalPettyCashCabangUnit: function (data) {
    return Api()
      .postRaw2("msgadaimasfinance/v2/pettyCash/approval", { request: data }, { rawJSON: true, isSignature: true })
      .then(({ data }) => {
        return data?.Response?.data;
      });
  },
  getLaporanJF: function (params) {
    return Api()
      .Get(`msgadaimaslaporan/v1/laporan/gadaijf${params}`)
      .then(({ Response }) => {
        return Response.data;
      });
  },
  getListUnitMarketing: function (params) {
    return Api()
      .Get(`/msgadaimasfinance/v2/kasmarketing/listUnit${params}`, { isSignature: true })
      .then(({ Response }) => {
        return Response.data;
      });
  },
  PostPengajuanKasMarketing: function (data) {
    return Api()
      .postRaw2("msgadaimasfinance/v2/kasmarketing/pengajuan", { request: data }, { rawJSON: true, isSignature: true })
      .then(({ data }) => {
        return data?.Response?.data;
      });
  },
  PostTransferKasMarketing: function (data) {
    return Api()
      .postRaw2("msgadaimasfinance/v2/kasmarketing/transfer", { request: data }, { rawJSON: true, isSignature: true })
      .then(({ data }) => {
        return data?.Response?.data;
      });
  },
  getMasterRealisasiKasMarketing: function (params) {
    return Api()
      .Get(`msgadaimasfinance/v2/kasmarketing/realisasi/kasmarketing${params}`, { isSignature: true })
      .then(({ Response }) => {
        return Response.data;
      });
  },
  cuMarketingRealiasi: function (data) {
    return Api()
      .postRaw2("msgadaimasfinance/v2/kasmarketing/realisasi/kasmarketing", { request: data }, { rawJSON: true, isSignature: true })
      .then(({ data }) => {
        return data?.Response?.data;
      });
  },
  getRealisasiMarketing: function (params) {
    return Api()
      .Get(`msgadaimasfinance/v2/kasmarketing/realisasi${params}`, { isSignature: true })
      .then(({ Response }) => {
        return Response.data;
      });
  },
  postFileRealisasiMarketing: function (body) {
    return Api()
      .PostFileAbsen("msgadaimasupload/v1/upload/filebukti?type=filebukti", body, { multipartForm: true, withoutToken: true })
      .then(({ data }) => {
        return data;
      });
  },
  getPiutangFintech: function (params) {
    return Api()
      .Get(`msgadaimastransaksi/v2/gadai/piutangfintech${params}`, { isSignature: true })
      .then(({ Response }) => {
        return Response.data;
      });
  },
  getMasterPiutangFintech: function (params) {
    return Api()
      .Get(`msgadaimastransaksi/v2/gadai/piutangfintech/jf${params}`, { isSignature: true })
      .then(({ Response }) => {
        return Response.data;
      });
  },
  postTransJF: function (data, params) {
    return Api()
      .postRaw2(`msgadaimastransaksi/v2/gadai/transjf`, { request: data }, { rawJSON: true, isSignature: true })
      .then((res) => {
        return res.data;
      });
  },
  getListUnitKasMarketing: function (params) {
    return Api()
      .Get(`msgadaimasfinance/v2/kasmarketing/listUnit${params}`, { isSignature: true })
      .then(({ Response }) => {
        return Response.data;
      });
  },
  getDropingKasMarketing: function (params) {
    return Api()
      .Get(`msgadaimasfinance/v2/kasmarketing/kasmarketing${params}`, { isSignature: true })
      .then(({ Response }) => {
        return Response.data;
      });
  },
  postDropingKasMarketingCabangUnit: function (data) {
    return Api()
      .postRaw2("msgadaimasfinance/v2/kasmarketing/droping", { request: data }, { rawJSON: true, isSignature: true })
      .then(({ data }) => {
        return data?.Response?.data;
      });
  },
  postApprovalKasMarketingCabangUnit: function (data) {
    return Api()
      .postRaw2("msgadaimasfinance/v2/kasmarketing/approvel", { request: data }, { rawJSON: true, isSignature: true })
      .then(({ data }) => {
        return data?.Response?.data;
      });
  },
  cuKasMarketingHOCabang: function (data) {
    return Api()
      .postRaw2("msgadaimasfinance/v2/kasmarketing/pengajuan", { request: data }, { rawJSON: true, isSignature: true })
      .then(({ data }) => {
        return data;
      });
  },
  KasMarketingTransfer: function (data) {
    return Api()
      .postRaw2("msgadaimasfinance/v2/kasmarketing/transfer", { request: data }, { rawJSON: true, isSignature: true })
      .then(({ data }) => {
        return data;
      });
  },
  fotoSelfieKasMarketing: function (data) {
    return Api()
      .PostFileAbsen(`msgadaimasupload/v1/upload/foto/selfie/kasmarketing`, data, { multipartForm: true, withoutToken: true })
      .then((res) => {
        return res.data;
      });
  },
  fotoDenomKasMarketing: function (data) {
    return Api()
      .PostFileAbsen(`msgadaimasupload/v1/upload/denom/kasmarketing`, data, { multipartForm: true, withoutToken: true })
      .then((res) => {
        return res.data;
      });
  },
  getMasterHutangFintech: function (params) {
    return Api()
      .Get(`msgadaimastransaksi/v2/gadai/pelunasan/jf${params}`, { isSignature: true })
      .then(({ Response }) => {
        return Response.data;
      });
  },
  getBayarJF: function (params) {
    return Api()
      .Get(`msgadaimastransaksi/v2/payment/bayarJf${params}`, { isSignature: true })
      .then(({ Response }) => {
        return Response.data;
      });
  },
  getPelunasanJF: function (params) {
    return Api()
      .Get(`msgadaimastransaksi/v2/gadai/pelunasangadai/jf${params}`, { isSignature: true })
      .then(({ Response }) => {
        return Response.data;
      });
  },
  createPelunasanJF: function (data, params) {
    return Api()
      .postRaw2(`msgadaimastransaksi/v2/gadai/pelunasangadai/jf`, { request: data }, { rawJSON: true, isSignature: true })
      .then((res) => {
        return res.data;
      });
  },
  getBAP: function (params) {
    return Api()
      .Get(`msgadaimasfinance/v2/bap/new${params}`, { isSignature: true })
      .then(({ Response }) => {
        return Response.data;
      });
  },
  createBAP: function (data, params) {
    return Api()
      .postRaw2(`msgadaimasfinance/v2/bap/create`, { request: data }, { rawJSON: true, isSignature: true })
      .then((res) => {
        return res.data;
      });
  },
  getMasterBAP: function (params) {
    return Api()
      .Get(`msgadaimasfinance/v2/bap/master${params}`, { isSignature: true })
      .then(({ Response }) => {
        return Response.data;
      });
  },
  fotoSelfieBAP: function (data, param = "") {
    return Api()
      .PostFileAbsen(`msgadaimasupload/v1/upload/selfie/approval/bap${param}`, data, { multipartForm: true, withoutToken: true })
      .then((res) => {
        return res.data;
      });
  },
  getMasterAdvanceInternal: function (params) {
    return Api()
      .Get(`msgadaimasfinance/v2/advance/master${params}`, { isSignature: true })
      .then(({ Response }) => {
        return Response.data;
      });
  },
  getNewAdvanceInternal: function (params) {
    return Api()
      .Get(`msgadaimasfinance/v2/advance/new${params}`, { isSignature: true })
      .then(({ Response }) => {
        return Response.data;
      });
  },
  cuAdvanceInternal: function (data) {
    return Api()
      .postRaw2("msgadaimasfinance/v2/advance/create", { request: data }, { rawJSON: true, isSignature: true })
      .then(({ data }) => {
        return data;
      });
  },
  cuAdvanceRealisasi: function (data) {
    return Api()
      .postRaw2("msgadaimasfinance/v2/advance/craeted/advancedetail", { request: data }, { rawJSON: true, isSignature: true })
      .then(({ data }) => {
        return data;
      });
  },
  getMasterAlasanPengeluaran: function (params) {
    return Api()
      .Get(`msgadaimasmaster/v1/master/pengeluaranbarang${params}`)
      .then(({ Response }) => {
        return Response.data;
      });
  },
  cuAlasanPengeluaranBarang: function (data) {
    return Api()
      .postRaw2("msgadaimasmaster/v1/master/pengeluaranbarang", data, { rawJSON: true })
      .then(({ data }) => {
        return data?.Response?.data;
      });
  },
  getLaporanAZ: function (params) {
    return Api()
      .Get(`msgadaimaslaporan/v1/laporan/reportaz${params}`)
      .then(({ Response }) => {
        return Response.data;
      });
  },
  postFileRealisasiInternal: function (body) {
    return Api()
      .PostFileAbsen("msgadaimasupload/v1/upload/advance", body, { multipartForm: true, withoutToken: true })
      .then(({ data }) => {
        return data;
      });
  },
  PostPelunasanGadaiAuto: function (data) {
    return Api()
      .postRaw2("msgadaimastransaksi/v1/payment/pelunasan/auto", data, { rawJSON: true })
      .then(({ data }) => {
        return data;
      });
  },
  getListBankRekon: function (params) {
    return Api()
      .Get(`msgadaimasfinance/v2/rekonbank/listBank${params}`, { rawJSON: true, isSignature: true })
      .then(({ Response }) => {
        return Response.data;
      });
  },
  getMasterRekonBank: function (params) {
    return Api()
      .Get(`msgadaimasfinance/v2/rekonbank/master${params}`, { isSignature: true })
      .then(({ Response }) => {
        return Response.data;
      });
  },
  cuRekonBank: function (data) {
    return Api()
      .postRaw2("msgadaimasfinance/v2/rekonbank/createApprove", { request: data }, { rawJSON: true, isSignature: true })
      .then(({ data }) => {
        return data?.Response?.data;
      });
  },
  FotoSelfieApprovalRekonBank: function (data, param = "") {
    return Api()
      .PostFileAbsen(`msgadaimasupload/v1/upload/selfie/recon/bank`, data, { multipartForm: true, withoutToken: true })
      .then((res) => {
        return res.data;
      });
  },
  getListBankReport: function (params) {
    return Api()
      .Get(`msgadaimasmaster/v1/master/listbank${params}`, { rawJSON: true, isSignature: false })
      .then(({ Response }) => {
        return Response.data;
      });
  },
  getSuspendMaster: function (params) {
    return Api()
      .Get(`msgadaimasfinance/v2/suspend/master${params}`, { isSignature: true })
      .then(({ Response }) => {
        return Response.data;
      });
  },
  cuSuspend: function (data) {
    return Api()
      .postRaw2("msgadaimasfinance/v2/suspend/createsuspend", { request: data }, { rawJSON: true, isSignature: true })
      .then(({ data }) => {
        return data?.Response?.data;
      });
  },
  getBankAdvanceFullFill: function (params) {
    return Api()
      .Get(`msgadaimasfinance/v2/user/bankFullfill${params}`, { isSignature: true })
      .then(({ Response }) => {
        return Response.data;
      });
  },
  getLaporanPelunasan: function (params) {
    return Api()
      .Get(`/msgadaimaslaporan/v1/laporan/reportPelunasan${params}`)
      .then(({ Response }) => {
        return Response.data;
      });
  },
  getLaporanKasBank: function (params) {
    return Api()
      .Get(`msgadaimaslaporan/v1/laporan/reportsaldokasbank${params}`)
      .then(({ Response }) => {
        return Response.data;
      });
  },
  getLaporanKasBankWilayah: function (params) {
    return Api()
      .Get(`/msgadaimaslaporan/v1/laporan/kasbank/wilayah${params}`)
      .then(({ Response }) => {
        return Response.data;
      });
  },
  getNewMutasiUnit: function (params) {
    return Api()
      .Get(`msgadaimasfinance/v2/user/newMutasiUnit`, { isSignature: true })
      .then(({ Response }) => {
        return Response.data;
      });
  },
  cuMutasiCabangUnit: function (data) {
    return Api()
      .postRaw2("msgadaimasfinance/v2/user/mutasiUnit", { request: data }, { rawJSON: true, isSignature: true })
      .then(({ data }) => {
        return data;
      });
  },
  FotoSelfieMutasiUnit: function (data, param = "") {
    return Api()
      .PostFileAbsen(`/msgadaimasupload/v1/upload/droping/selfie${param}`, data, { multipartForm: true, withoutToken: true })
      .then((res) => {
        return res.data;
      });
  },
  generateFAPG: function (data) {
    return Api()
      .postRaw2("msgadaimasuser/v1/generate/fapg", data, { rawJSON: true, isHtml: true })
      .then(({ data }) => {
        return data;
      });
  },
  generateKwintansi: function (data) {
    return Api()
      .postRaw2(`msgadaimasuser/v1/generate/pencairan`, data, { rawJSON: true })
      .then(({ data }) => {
        return data;
      });
  },
  generatePelunasan: function (data) {
    return Api()
      .postRaw2(`msgadaimasuser/v1/generate/pelunasan`, data, { rawJSON: true, isHtml: true })
      .then(({ data }) => {
        return data;
      });
  },
  generatePelunasanCicilan: function (data) {
    return Api()
      .postRaw2(`msgadaimasuser/v1/generate/pembayaran/cicilan`, data, { rawJSON: true, isHtml: true })
      .then(({ data }) => {
        return data;
      });
  },
  generateKwintansiOpenCashier: function (data) {
    return Api()
      .postRaw2(`msgadaimasuser/v1/generate/openkasir`, data, { rawJSON: true, isHtml: true })
      .then(({ data }) => {
        return data;
      });
  },
  generateKwintansiPenyerahanBarang: function (data) {
    return Api()
      .postRaw2(`msgadaimasuser/v1/generate/penyerahan/storing`, data, { rawJSON: true })
      .then(({ data }) => {
        return data;
      });
  },
  generateBAP: function (param, data) {
    return Api()
      .postRaw2(`msgadaimasuser/v1/generate/bap`, data, { rawJSON: true })
      .then(({ data }) => {
        return data;
      });
  },
  generateKwintasiInputTitipan: function (data) {
    return Api()
      .postRaw2("/msgadaimasuser/v1/generate/titipan", data, { rawJSON: true })
      .then(({ data }) => {
        return data;
      });
  },
  getLaporanpenjualan: function (params) {
    return Api()
      .Get(`msgadaimaslaporan/v1/laporan/reportpenjualan${params}`)
      .then(({ Response }) => {
        return Response.data;
      });
  },
  cetakKwintansiPenjualan: function (data) {
    return Api()
      .postRaw2("msgadaimasuser/v1/generate/penjualan", data, { rawJSON: true, isHtml: true })
      .then(({ data }) => {
        return data;
      });
  },
  cetakKwintansiPengembalian: function (data) {
    return Api()
      .postRaw2("msgadaimasuser/v1/generate/pengembalian", data, { rawJSON: true, isHtml: true })
      .then(({ data }) => {
        return data;
      });
  },
  cetakKwintansiPembayaranCicilan: function (data) {
    return Api()
      .postRaw2("msgadaimasuser/v1/generate/pembayaran/cicilan", data, { rawJSON: true, isHtml: true })
      .then(({ data }) => {
        return data;
      });
  },
  cetakKwintansiPelunasanCicilan: function (data) {
    return Api()
      .postRaw2("msgadaimasuser/v1/generate/pelunasan/cicilan", data, { rawJSON: true, isHtml: true })
      .then(({ data }) => {
        return data;
      });
  },
  generateKwintansiPencairan: function (data) {
    return Api()
      .postRaw2("msgadaimasuser/v1/generate/pencairan", data, { rawJSON: true })
      .then(({ data }) => {
        return data;
      });
  },
  generateFIN: function (data) {
    return Api()
      .postRaw2("msgadaimasuser/v1/generate/fincustomer", data, { rawJSON: true, isHtml: true })
      .then(({ data }) => {
        return data;
      });
  },
  getReportLBJ: function (params) {
    return Api()
      .Get(`msgadaimaslaporan/v1/laporan/barangjaminan${params}`)
      .then(({ Response }) => {
        return Response.data;
      });
  },
  getTransaksiCicilan: function (params) {
    return Api()
      .Get(`msgadaimascicilan/v2/cicilan/transaksi${params}`, { isSignature: true })
      .then(({ Response }) => {
        return Response.data;
      });
  },
  getPordukCicilan: function (params) {
    return Api()
      .Get(`msgadaimascicilan/v2/cicilan/produk${params}`, { isSignature: true })
      .then(({ Response }) => {
        return Response.data;
      });
  },
  postDetailCicilan: function (data) {
    return Api()
      .postRaw2("msgadaimascicilan/v2/cicilan/detail", { request: data }, { rawJSON: true, isSignature: true })
      .then(({ data }) => {
        return data?.Response?.data;
      });
  },
  cuTransaksiCicilan: function (data) {
    return Api()
      .postRaw2("msgadaimascicilan/v2/cicilan/transaksi", { request: data }, { rawJSON: true, isSignature: true })
      .then(({ data }) => {
        return data?.Response?.data;
      });
  },
  postPencairanCicilan: function (data) {
    return Api()
      .postRaw2("msgadaimascicilan/v2/cicilan/pencairan", { request: data }, { rawJSON: true, isSignature: true })
      .then(({ data }) => {
        return data?.Response?.data;
      });
  },
  generateSBGCicilan: function (params, data) {
    return Api()
      .postRaw2(`msgadaimasuser/v1/generate/sbgcicilan${params}`, data, { rawJSON: true, isHtml: true })
      .then(({ data }) => {
        return data;
      });
  },
  GetJenisProdukOption: function () {
    return Api()
      .Get(`msgadaimasproduk/v1/produk/jenis?isActive=1&page=1&page_size=1000`)
      .then(({ Response }) => {
        return Response.data;
      });
  },
  generateKwintansiPencairanCicilan: function (data) {
    return Api()
      .postRaw2("msgadaimasuser/v1/generate/pencairanCicilan", data, { rawJSON: true })
      .then(({ data }) => {
        return data;
      });
  },
  generateJadwalAngsuran: function (data) {
    return Api()
      .postRaw2("msgadaimasuser/v1/generate/ansuran", data, { rawJSON: true, isHtml: true })
      .then(({ data }) => {
        return data;
      });
  },
  postChangeFlaging: function (data) {
    return Api()
      .postRaw2("msgadaimasuser/v1/master/prosesGadai", data, { rawJSON: true, isSignature: false })
      .then(({ data }) => {
        return data?.Response?.data;
      });
  },
  getLaporanOvd: function (params) {
    return Api()
      .Get(`msgadaimaslaporan/v1/laporan/overdue${params}`)
      .then(({ Response }) => {
        return Response.data;
      });
  },
  getReportSummaryBap: function (params) {
    return Api()
      .Get(`msgadaimaslaporan/v1/laporan/summarybap${params}`, { isSignature: false })
      .then(({ Response }) => {
        return Response.data;
      });
  },
  getReportBapDetail: function (params) {
    return Api()
      .Get(`msgadaimaslaporan/v1/laporan/detailbapHO${params}`, { isSignature: false })
      .then(({ Response }) => {
        return Response.data;
      });
  },
  getReportBookingSummary: function (params) {
    return Api()
      .Get(`msgadaimaslaporan/v1/laporan/bookingsummary${params}`, { isSignature: false })
      .then(({ Response }) => {
        return Response.data;
      });
  },
  getReportDireksi: function (params) {
    return Api()
      .Get(`msgadaimaslaporan/v1/laporan/direksi${params}`, { isSignature: false })
      .then(({ Response }) => {
        return Response.data;
      });
  },
  getChangingStatusSbg: function (params) {
    return axios.get(`${process.env.REACT_APP_API_ENDPOINT}msgadaimasuser/v1/public/updateSA${params}`).then(({ data }) => {
      return data;
    });

    // return Api()
    //   .Get(``)
    //   .then(({data}) => {
    //   })
  },
  getSmsCicilan: function (params) {
    return Api()
      .Get(`/msgadaimasuser/v1/sms/master/cicilan${params}`, { isSignature: false })
      .then(({ Response }) => {
        return Response.data;
      });
  },
  postPesanCicilan: function (data) {
    return Api()
      .postRaw("msgadaimasuser/v1/sms/cicilan", data, { rawJSON: true })
      .then(({ data }) => {
        return data;
      });
  },
  generatePenerimaan: function (data) {
    return Api()
      .postRaw2("msgadaimasuser/v1/generate/barangstoring", data, { rawJSON: true, isHtml: true })
      .then(({ data }) => {
        return data;
      });
  },
  postUbahStatus: function (data) {
    return Api()
      .postRaw("msgadaimasuser/v1/master/cabang/iscetakulang", data, { rawJSON: true })
      .then(({ data }) => {
        return data;
      });
  },
  getJenisBlanko: function (params) {
    return Api()
      .Get(`msgadaimasuser/v1/blanko/blankojenis${params}`)
      .then(({ Response }) => {
        return Response.data;
      });
  },
  cuJenisBlanko: function (data) {
    return Api()
      .postRaw("msgadaimasuser/v1/blanko/blankojenis", data, { rawJSON: true })
      .then(({ data }) => {
        return data;
      });
  },
  postCheckVoucher: function (body) {
    return Api()
      .postRaw2("msgadaimastransaksi/v1/user/winback", body, { rawJSON: true })
      .then(({ data }) => {
        return data?.Response?.data;
      });
  },
  getMasterPenanganOvd: function (params) {
    return Api()
      .Get(`msgadaimasuser/v1/master/penangananOvd${params}`)
      .then(({ Response }) => {
        return Response.data;
      });
  },
  MasterhasilTerakhirPenanganan: function (params) {
    return Api()
      .Get(`msgadaimasmaster/v1/penanganan/master/hasilpenanganan${params}`)
      .then(({ Response }) => {
        return Response.data;
      });
  },
  MasterJenisPenangan: function (params) {
    return Api()
      .Get(`msgadaimasmaster/v1/penanganan/master/penanganan${params}`)
      .then(({ Response }) => {
        return Response.data;
      });
  },
  cuPenanganaOvd: function (data) {
    return Api()
      .postRaw("msgadaimasuser/v1/master/penangananOvd", data, { rawJSON: true })
      .then(({ data }) => {
        return data;
      });
  },
  postPelunasanCepat: function (data) {
    return Api()
      .postRaw2(`msgadaimascicilan/v2/cicilan/pelunasan/cepat`, { request: data }, { rawJSON: true, isSignature: true })
      .then(({ data }) => {
        return data?.Response?.data;
      });
  },
  getPelunasanCepat: function (params) {
    return Api()
      .Get(`msgadaimascicilan/v2/cicilan/detail/sbg/${params}`, { isSignature: true })
      .then(({ Response }) => {
        return Response.data;
      });
  },
  cuJenisPenangan: function (data) {
    return Api()
      .postRaw("msgadaimasmaster/v1/penanganan/penanganan", data, { rawJSON: true })
      .then(({ data }) => {
        return data;
      });
  },
  cuHasilPenangnan: function (data) {
    return Api()
      .postRaw("msgadaimasmaster/v1/penanganan/hasilpenanganan", data, { rawJSON: true })
      .then(({ data }) => {
        return data;
      });
  },
  getLaporanPenjualanGadai: function (params) {
    return Api()
      .Get(`msgadaimaslaporan/v1/laporan/reportpenjualangadai${params}`)
      .then(({ Response }) => {
        return Response.data;
      });
  },
  postFleggingVoucher: function (data) {
    return Api()
      .postRaw("msgadaimasuser/v1/master/updateVoucher", data, { rawJSON: true })
      .then(({ data }) => {
        return data;
      });
  },
  getAuditVirtualPencairanCustomer: function (params) {
    return Api()
      .Get(`msgadaimasic/v2/customer/pencairan${params}`, { isSignature: true })
      .then(({ Response }) => {
        return Response.data;
      });
  },
  getAuditVirtualFinance: function (params) {
    return Api()
      .Get(`msgadaimasic/v2/finance/openkasir${params}`, { isSignature: true })
      .then(({ Response }) => {
        return Response.data;
      });
  },
  postAuditVirtualFinance: function (data) {
    return Api()
      .postRaw2(`msgadaimasic/v1/user/audit`, { request: data }, { rawJSON: true, isSignature: false })
      .then(({ data }) => {
        return data?.Response?.data;
      });
  },
  getAuditVirtualFinancePettyCash: function (params) {
    return Api()
      .Get(`msgadaimasic/v2/finance/pettycash${params}`, { isSignature: true })
      .then(({ Response }) => {
        return Response.data;
      });
  },
  getAuditVirtualFinanceBAP: function (params) {
    return Api()
      .Get(`msgadaimasic/v2/finance/bap${params}`, { isSignature: true })
      .then(({ Response }) => {
        return Response.data;
      });
  },
  getAuditVirtualPelunasanCustomer: function (params) {
    return Api()
      .Get(`msgadaimasic/v2/customer/pelunasan${params}`, { isSignature: true })
      .then(({ Response }) => {
        return Response.data;
      });
  },
  getScoreAudit: function (params) {
    return Api()
      .Get(`msgadaimasic/v1/public/indikator${params}`, { isSignature: false, rawJSON: true, withoutToken: true })
      .then(({ Response }) => {
        return Response.data;
      });
  },
  getMasterGeneralAudit: function (params) {
    return Api()
      .Get(`msgadaimasic/v2/generalAudit/outlet${params}`, { isSignature: true, rawJSON: true })
      .then(({ Response }) => {
        return Response.data;
      });
  },
  getMasterDaftarAudit: function (params) {
    return Api()
      .Get(`msgadaimasic/v2/generalAudit/daftarAuditCabang${params}`, { isSignature: true, rawJSON: true })
      .then(({ Response }) => {
        return Response.data;
      });
  },
  postSelfieAuditor: function (data, param) {
    return Api()
      .PostFileAbsen(`msgadaimasupload/v1/filemanager/upload${param}`, data, { rawJSON: true, withoutToken: true })
      .then(({ data }) => {
        return data;
      });
  },
  postVerifikasiAuditor: function (data) {
    return Api()
      .postRaw2("msgadaimasic/v2/generalAudit/verifikasi", { request: data }, { rawJSON: true, isSignature: true })
      .then(({ data }) => {
        return data?.Response?.data;
      });
  },
  getStatusTask: function (params) {
    return Api()
      .Get(`msgadaimasic/v2/generalAudit/statusTask${params}`, { isSignature: true, rawJSON: true })
      .then(({ Response }) => {
        return Response.data;
      });
  },
  getDetailAudit: function (params) {
    return Api()
      .Get(`msgadaimasic/v2/generalAudit/detail${params}`, { isSignature: true, rawJSON: true })
      .then(({ Response }) => {
        return Response.data;
      });
  },
  postUangDanKas: function (data) {
    return Api()
      .postRaw2("msgadaimasic/v2/generalAudit/uangKas", { request: data }, { rawJSON: true, isSignature: true })
      .then(({ data }) => {
        return data?.Response?.data;
      });
  },
  downloadOpnameBJ: function (data) {
    return Api()
      .postRaw2("msgadaimasic/v2/export/opnameBJ", { request: data }, { rawJSON: true, isSignature: true })
      .then(({ data }) => {
        return data?.Response?.data;
      });
  },
  getReportAuditVirtual: function (params) {
    return Api()
      .Get(`msgadaimasic/v2/report/virtual${params}`, { isSignature: true })
      .then(({ Response }) => {
        return Response.data;
      });
  },
  postExportAuditVirtual: function (data) {
    return Api()
      .postRaw2("msgadaimasgenerate/v1/audit/report", { request: data }, { rawJSON: true, withoutToken: true })
      .then(({ data }) => {
        return data;
      });
  },
  postOperasional: function (data) {
    return Api()
      .postRaw2("msgadaimasic/v2/generalAudit/operasional", { request: data }, { rawJSON: true, isSignature: true })
      .then(({ data }) => {
        return data?.Response?.data;
      });
  },
  postBarangJaminan: function (data) {
    return Api()
      .postRaw2("msgadaimasic/v2/generalAudit/barangjaminan", { request: data }, { rawJSON: true, isSignature: true })
      .then(({ data }) => {
        return data?.Response?.data;
      });
  },
  postEmploye: function (data) {
    return Api()
      .postRaw2("msgadaimasic/v2/generalAudit/employe", { request: data }, { rawJSON: true, isSignature: true })
      .then(({ data }) => {
        return data?.Response?.data;
      });
  },
  postSaranaPrasarana: function (data) {
    return Api()
      .postRaw2("msgadaimasic/v2/generalAudit/prasarana", { request: data }, { rawJSON: true, isSignature: true })
      .then(({ data }) => {
        return data?.Response?.data;
      });
  },
  getReportAuditGeneral: function (params) {
    return Api()
      .Get(`msgadaimasic/v2/report/general${params}`, { isSignature: true })
      .then(({ Response }) => {
        return Response.data;
      });
  },
  getMasterAuditRutin: function (params) {
    return Api()
      .Get(`msgadaimasic/v2/rutinAudit/outlet${params}`, { isSignature: true, rawJSON: true })
      .then(({ Response }) => {
        return Response.data;
      });
  },
  getMasterDaftarAuditRutin: function (params) {
    return Api()
      .Get(`msgadaimasic/v2/rutinAudit/daftarAuditCabang${params}`, { isSignature: true, rawJSON: true })
      .then(({ Response }) => {
        return Response.data;
      });
  },
  getStatusTaskAuditRutin: function (params) {
    return Api()
      .Get(`msgadaimasic/v2/rutinAudit/statusTask${params}`, { isSignature: true, rawJSON: true })
      .then(({ Response }) => {
        return Response.data;
      });
  },
  postVerifikasiAuditorRutin: function (data) {
    return Api()
      .postRaw2("msgadaimasic/v2/rutinAudit/verifikasi", { request: data }, { rawJSON: true, isSignature: true })
      .then(({ data }) => {
        return data?.Response?.data;
      });
  },
  getDetailAuditRutin: function (params) {
    return Api()
      .Get(`msgadaimasic/v2/rutinAudit/${params}`, { isSignature: true, rawJSON: true })
      .then(({ Response }) => {
        return Response.data;
      });
  },
  postUangDanKasRutin: function (data) {
    return Api()
      .postRaw2("msgadaimasic/v2/rutinAudit/uangKas", { request: data }, { rawJSON: true, isSignature: true })
      .then(({ data }) => {
        return data?.Response?.data;
      });
  },
  downloadOpnameBJRutin: function (data) {
    return Api()
      .postRaw2("msgadaimasic/v2/export/opnameRutinBj", { request: data }, { rawJSON: true, isSignature: true })
      .then(({ data }) => {
        return data?.Response?.data;
      });
  },
  postBarangJaminanRutin: function (data) {
    return Api()
      .postRaw2("msgadaimasic/v2/rutinAudit/barangjaminan", { request: data }, { rawJSON: true, isSignature: true })
      .then(({ data }) => {
        return data?.Response?.data;
      });
  },
  postExportAuditGeneral: function (body) {
    return Api()
      .postRaw2("msgadaimasgenerate/v1/generate/detail/reportAG", body, { rawJSON: true, isHtml: true })
      .then(({ data }) => {
        return data;
      });
  },
  postExportAuditRutinOutlet: function (body) {
    return Api()
      .postRaw2("msgadaimasgenerate/v1/generate/detail/reportrutin", body, { rawJSON: true, isHtml: true })
      .then(({ data }) => {
        return data;
      });
  },
  postExportAuditGeneralWilayah: function (body) {
    return Api()
      .postRaw2("msgadaimasgenerate/v1/generate/general/reportAG", body, { rawJSON: true, isHtml: true })
      .then(({ data }) => {
        return data;
      });
  },
  postOperasionalRutin: function (data) {
    return Api()
      .postRaw2("msgadaimasic/v2/rutinAudit/operasional", { request: data }, { rawJSON: true, isSignature: true })
      .then(({ data }) => {
        return data?.Response?.data;
      });
  },
  getReportAuditVirtualWilayah: function (params) {
    return Api()
      .Get(`msgadaimasic/v2/report/virtual/perform${params}`, { isSignature: true })
      .then(({ Response }) => {
        return Response.data;
      });
  },
  postReportAuditVirtualWilayah: function (data) {
    return Api()
      .postRaw2(`msgadaimasgenerate/v1/generate/virtual/perform`, data, { rawJSON: true, isSignature: false })
      .then(({ data }) => {
        return data?.Response?.data;
      });
  },
  getReportAuditRutin: function (params) {
    return Api()
      .Get(`msgadaimasic/v2/report/rutin${params}`, { isSignature: true })
      .then(({ Response }) => {
        return Response.data;
      });
  },
  postExportAuditRutin: function (body) {
    return Api()
      .postRaw2(`msgadaimasgenerate/v1/generate/rutin/reportrutin`, body, { isSignature: false })
      .then(({ data }) => {
        return data;
      });
  },
  getLaporanAbsensi: function (params) {
    return Api()
      .Get(`msgadaimaslaporan/v1/laporan/reportAbsen${params}`)
      .then(({ Response }) => {
        return Response.data;
      });
  },
  getMasterBiayaPromosi: function (params) {
    return Api()
      .Get(`msgadaimasfinance/v2/promosi/masterbiayapromosi${params}`, { isSignature: true })
      .then(({ Response }) => {
        return Response.data;
      });
  },
  postMasterBiayaPromosi: function (body) {
    return Api()
      .postRaw2(`msgadaimasfinance/v2/promosi/createUpdatePromosi`, { request: body }, { isSignature: true, rawJSON: true })
      .then(({ data }) => {
        return data;
      });
  },
  getCabangBiayaPromosi: function (params) {
    return Api()
      .Get(`msgadaimasmaster/v1/master/biayapromosi/cabang${params}`, { isSignature: false })
      .then(({ Response }) => {
        return Response.data;
      });
  },
  getDetailPengajuanBiayaPromosi: function (params) {
    return Api()
      .Get(`msgadaimasfinance/v2/promosi/detailPengajuan${params}`, { isSignature: true })
      .then(({ Response }) => {
        return Response.data;
      });
  },
  getMasterBiayaPromosiPengajuan: function (params) {
    return Api()
      .Get(`msgadaimasfinance/v2/promosi/fabiayapromosi${params}`, { isSignature: true })
      .then(({ Response }) => {
        return Response.data;
      });
  },
  postMasterBiayaPromosiPengajuan: function (body) {
    return Api()
      .postRaw2(`msgadaimasfinance/v2/promosi/fabiayapromosi`, { request: body }, { isSignature: true, rawJSON: true })
      .then(({ data }) => {
        return data;
      });
  },
  FotoApprovePengajuanBiayaPromosi: function (data, param = "") {
    return Api()
      .PostFileAbsen(`msgadaimasupload/v1/filemanager/upload${param}`, data, { multipartForm: true, withoutToken: true })
      .then((res) => {
        return res.data;
      });
  },
  getmasterBiayaPromosiFinanceTransfer: function (params) {
    return Api()
      .Get(`msgadaimasfinance/v2/promosi/finance${params}`, { isSignature: true })
      .then(({ Response }) => {
        return Response.data;
      });
  },
  getReportAuditRutin: function (params) {
    return Api()
      .Get(`msgadaimasic/v2/report/rutin${params}`, { isSignature: true })
      .then(({ Response }) => {
        return Response.data;
      });
  },
  getLaporanPerfomanceHarian: function (params) {
    return Api()
      .Get(`msgadaimaslaporan/v1/laporan/performacabang/harian${params}`)
      .then(({ Response }) => {
        return Response.data;
      });
  },
  getAksesWilayah: function (params) {
    return Api()
      .Get(`msgadaimasuser/v1/master/akseswilayah${params}`)
      .then(({ Response }) => {
        return Response.data;
      });
  },
  postExportPerfomanceCabang: function (body) {
    return Api()
      .postRaw2(`msgadaimasgenerate/v1/excel/report/performacabang`, body, { isSignature: false })
      .then(({ data }) => {
        return data;
      });
  },
  postFinanceBiayaPromosi: function (body) {
    return Api()
      .postRaw2(`msgadaimasfinance/v2/promosi/finance`, { request: body }, { isSignature: true, rawJSON: true })
      .then(({ data }) => {
        return data;
      });
  },
  getRealisasiBiayaPromosi: function (params) {
    return Api()
      .Get(`msgadaimasfinance/v2/promosi/pengajuan/realisasi${params}`, { isSignature: true })
      .then(({ Response }) => {
        return Response.data;
      });
  },
  postFileRealisasiBiayaPromosi: function (body) {
    return Api()
      .PostFileAbsen("msgadaimasupload/v1/filemanager/upload", body, { multipartForm: true, withoutToken: true })
      .then(({ data }) => {
        return data;
      });
  },
  postBiayaPromosiRealisasi: function (data) {
    return Api()
      .postRaw2("msgadaimasfinance/v2/promosi/createUpdateRealisasi", { request: data }, { rawJSON: true, isSignature: true })
      .then(({ data }) => {
        return data?.Response?.data;
      });
  },
  getRealisasiBiayaPromosiKaUnit: function (params) {
    return Api()
      .Get(`msgadaimasfinance/v2/promosi/masterRealisasi${params}`, { isSignature: true })
      .then(({ Response }) => {
        return Response.data;
      });
  },
  getMasterPenjualanCicilan: function (params) {
    return Api()
      .Get(`msgadaimastransaksi/v2/penjualancicilan/master${params}`, { isSignature: true })
      .then(({ Response }) => {
        return Response.data;
      });
  },
  postGeneralUpload: function (data, param) {
    return Api()
      .PostFileAbsen(`msgadaimasupload/v1/filemanager/upload${param}`, data, { rawJSON: true, withoutToken: true })
      .then(({ data }) => {
        return data;
      });
  },
  CekSbgPenjualanCicilan: function (data) {
    return Api()
      .postRaw2("msgadaimastransaksi/v2/penjualancicilan/create/check/sbg", { request: data }, { rawJSON: true, isSignature: true })
      .then(({ data }) => {
        return data?.Response?.data;
      });
  },
  getMasterPenjualanBarangJaminanCicilan: function (params) {
    return Api()
      .Get(`msgadaimastransaksi/v2/penjualancicilan/pelunasanjual${params}`, { isSignature: true })
      .then(({ Response }) => {
        return Response.data;
      });
  },
  KasirPenjualanCicilan: function (data) {
    return Api()
      .postRaw2("msgadaimastransaksi/v2/penjualancicilan/updatekasirpenjualan", { request: data }, { rawJSON: true, isSignature: true })
      .then(({ data }) => {
        return data?.Response?.data;
      });
  },
  PengembalianBarangJaminanCicilan: function (data) {
    return Api()
      .postRaw2("msgadaimastransaksi/v2/penjualancicilan/pengembalian", { request: data }, { rawJSON: true, isSignature: true })
      .then(({ data }) => {
        return data?.Response?.data;
      });
  },
  getPlafonGiroCek: function (params) {
    return Api()
      .Get(`msgadaimasfinance/v2/girocekplafon/master${params}`, { isSignature: true })
      .then(({ Response }) => {
        return Response.data;
      });
  },
  postPlafonCek: function (data) {
    return Api()
      .postRaw2(`msgadaimasfinance/v2/girocekplafon/createupdate/girocekplafon`, { request: data }, { rawJSON: true, isSignature: true })
      .then(({ data }) => {
        return data;
      });
  },
  getMutasiBufferLibur: function (params) {
    return Api()
      .Get(`msgadaimasfinance/v2/buffer/libur${params}`, { isSignature: true })
      .then(({ Response }) => {
        return Response.data;
      });
  },
  postMutasiBufferLibur: function (data) {
    return Api()
      .postRaw2(`msgadaimasfinance/v2/buffer/libur`, { request: data }, { rawJSON: true, isSignature: true })
      .then(({ data }) => {
        return data;
      });
  },
  getAjukanBufferLibur: function (params) {
    return Api()
      .Get(`msgadaimasfinance/v2/buffer/libur/ajukan`, { isSignature: true })
      .then(({ Response }) => {
        return Response.data;
      });
  },
  getPemeriksaanFinanceBufferLibur: function (params) {
    return Api()
      .Get(`msgadaimasfinance/v2/buffer/libur/financePemeriksaan${params}`, { isSignature: true })
      .then(({ Response }) => {
        return Response.data;
      });
  },
  postPemerikaanFinanceBufferLibur: function (data) {
    return Api()
      .postRaw2(`msgadaimasfinance/v2/buffer/libur/finance`, { request: data }, { rawJSON: true, isSignature: true })
      .then(({ data }) => {
        return data;
      });
  },
  postDroppingBufferLibur: function (data) {
    return Api()
      .postRaw2(`msgadaimasfinance/v2/buffer/libur/unit`, { request: data }, { rawJSON: true, isSignature: true })
      .then(({ data }) => {
        return data;
      });
  },
  cetakKwitansiBufferLibur: function (params) {
    return Api()
      .GetHTML(`msgadaimasuser/v1/generate/bufferLibur?idTransaksiInternal=${params}`, { isHtml: true })
      .then(({ data }) => {
        return data;
      });
  },
  postPemeriksaanTakeOver: function (data) {
    return Api()
      .postRaw2(`msgadaimastransaksi/v2/buffer/approve`, { request: data }, { rawJSON: true, isSignature: true })
      .then(({ data }) => {
        return data;
      });
  },
  postDroppingTakeOverFinance: function (data) {
    return Api()
      .postRaw2(`msgadaimastransaksi/v2/buffer/dropping`, { request: data }, { rawJSON: true, isSignature: true })
      .then(({ data }) => {
        return data;
      });
  },
  getAjukanBufferTakeOver: function (params) {
    return Api()
      .Get(`msgadaimastransaksi/v2/buffer/ajukan`, { isSignature: true })
      .then(({ Response }) => {
        return Response.data;
      });
  },
  getMitraGadai: function (params) {
    return Api()
      .Get(`/msgadaimasmaster/v1/master/mitragadai${params}`)
      .then(({ Response }) => {
        return Response.data;
      });
  },
  postHitungGadai: function (data) {
    return Api()
      .postRaw2(`msgadaimastransaksi/v2/buffer/hitung`, { request: data }, { rawJSON: true, isSignature: true })
      .then(({ data }) => {
        return data;
      });
  },
  cuMitraGadai: function (data) {
    return Api()
      .postRaw("msgadaimasmaster/v1/master/createmitragadai", data, { rawJSON: true })
      .then(({ data }) => {
        return data;
      });
  },
  getPengajuanBufferTakeOver: function (params) {
    return Api()
      .Get(`msgadaimastransaksi/v2/buffer/approval/buffertakeover${params}`, { isSignature: true })
      .then(({ Response }) => {
        return Response.data;
      });
  },
  getDroppingTakeOver: function (params) {
    return Api()
      .Get(`msgadaimastransaksi/v2/buffer/dropingfinance${params}`, { isSignature: true })
      .then(({ Response }) => {
        return Response.data;
      });
  },
  postApproveBufferTO: function (data) {
    return Api()
      .postRaw2(`msgadaimastransaksi/v2/buffer/approve`, { request: data }, { rawJSON: true, isSignature: true })
      .then(({ data }) => {
        return data;
      });
  },
  getmutasiBufferTakeOver: function (params) {
    return Api()
      .Get(`msgadaimastransaksi/v2/buffer/masterbuffertakeover${params}`, { isSignature: true })
      .then(({ Response }) => {
        return Response.data;
      });
  },
  postMutasiBufferTakeOver: function (data) {
    return Api()
      .postRaw2(`msgadaimastransaksi/v2/buffer/ajukan`, { request: data }, { rawJSON: true, isSignature: true })
      .then(({ data }) => {
        return data;
      });
  },
  generateKwitansiTO: function (data) {
    return Api()
      .postRaw2(`msgadaimasuser/v1/generate/buffertakeover`, data, { rawJSON: true, isHtml: true })
      .then(({ data }) => {
        return data;
      });
  },
  getBatchFullfill: function () {
    return Api()
      .Get(`msgadaimasfinance/v2/bufferharian/masterbatch`, { isSignature: true })
      .then(({ Response }) => {
        return Response.data;
      });
  },
  getPengajuanBufferHarian: function (params) {
    return Api()
      .Get(`msgadaimasfinance/v2/bufferharian/masterpengajuan${params}`, { isSignature: true })
      .then(({ Response }) => {
        return Response.data;
      });
  },
  getHistoryPengajuanBufferHarian: function (params) {
    return Api()
      .Get(`msgadaimasfinance/v2/bufferharian/historypengajuan${params}`, { isSignature: true })
      .then(({ Response }) => {
        return Response.data;
      });
  },
  getDroppingBufferHarian: function (params) {
    return Api()
      .Get(`msgadaimasfinance/v2/bufferharian/masterdroping${params}`, { isSignature: true })
      .then(({ Response }) => {
        return Response.data;
      });
  },
  getDenomBufferHarian: function (params) {
    return Api()
      .Get(`msgadaimasfinance/v2/bufferharian/denomkaunit${params}`, { isSignature: true })
      .then(({ Response }) => {
        return Response.data;
      });
  },
  postPengajuanBufferHarian: function (data) {
    return Api()
      .postRaw2(`msgadaimasfinance/v2/bufferharian/pengajuan`, { request: data }, { rawJSON: true, isSignature: true })
      .then(({ data }) => {
        return data;
      });
  },
  postPemeriksaanBufferHarian: function (data) {
    return Api()
      .postRaw2(`msgadaimasfinance/v2/bufferharian/finance`, { request: data }, { rawJSON: true, isSignature: true })
      .then(({ data }) => {
        return data;
      });
  },
  postDroppingBufferHarianFinance: function (data) {
    return Api()
      .postRaw2(`msgadaimasfinance/v2/bufferharian/finance`, { request: data }, { rawJSON: true, isSignature: true })
      .then(({ data }) => {
        return data;
      });
  },
  postDroppingBufferHarianOFS: function (data) {
    return Api()
      .postRaw2(`msgadaimasfinance/v2/bufferharian/droppingUnit`, { request: data }, { rawJSON: true, isSignature: true })
      .then(({ data }) => {
        return data;
      });
  },
  generateKwitansiBufferHarian: function (data) {
    return Api()
      .postRaw2(`msgadaimasuser/v1/generate/bufferharian`, data, { rawJSON: true, isHtml: true })
      .then(({ data }) => {
        return data;
      });
  },
  getBufferWalkInList: function (params) {
    return Api()
      .Get(`msgadaimasfinance/v2/bufferwalkin/listpengajuan${params}`, { isSignature: true })
      .then(({ Response }) => {
        return Response.data;
      });
  },
  getBufferWalkInWilayah: function (params) {
    return Api()
      .Get(`msgadaimasfinance/v2/bufferwalkin/filterwilayah${params}`, { isSignature: true })
      .then(({ Response }) => {
        return Response.data;
      });
  },
  postPengajuanBufferWalkIn: function (data) {
    return Api()
      .postRaw2(`msgadaimasfinance/v2/bufferwalkin/pengajuan`, { request: data }, { rawJSON: true, isSignature: true })
      .then(({ data }) => {
        return data;
      });
  },
  getFinanceBufferWalkIn: function (params) {
    return Api()
      .Get(`msgadaimasfinance/v2/bufferwalkin/financebuffercash${params}`, { isSignature: true })
      .then(({ Response }) => {
        return Response.data;
      });
  },
  postFinanceWalkIn: function (data) {
    return Api()
      .postRaw2(`msgadaimasfinance/v2/bufferwalkin/finance`, { request: data }, { rawJSON: true, isSignature: true })
      .then(({ data }) => {
        return data;
      });
  },
  getDroppingOFSBufferWalkIn: function (params) {
    return Api()
      .Get(`msgadaimasfinance/v2/bufferwalkin/droppingbuffercash${params}`, { isSignature: true })
      .then(({ Response }) => {
        return Response.data;
      });
  },
  getBufferWalkIn: function (params) {
    return Api()
      .Get(`msgadaimasfinance/v2/bufferwalkin/master${params}`, { isSignature: true })
      .then(({ Response }) => {
        return Response.data;
      });
  },
  generateKwitansiBufferWalkIn: function (data) {
    return Api()
      .postRaw2(`msgadaimasuser/v1/generate/buffercash`, data, { rawJSON: true, isHtml: true })
      .then(({ data }) => {
        return data;
      });
  },
  postDroppingBufferWalkInKasir: function (data) {
    return Api()
      .postRaw2(`msgadaimasfinance/v2/bufferwalkin/droppingunit`, { request: data }, { rawJSON: true, isSignature: true })
      .then(({ data }) => {
        return data;
      });
  },
  getApTransferMaster: function (params) {
    return Api()
      .Get(`msgadaimasfinance/v2/aptransfer/master${params}`, { isSignature: true })
      .then(({ Response }) => {
        return Response.data;
      });
  },
  getDetailRekening: function (params) {
    return Api()
      .Get(`msgadaimasuser/v2/rekening/detail/norekening${params}`, { isSignature: true })
      .then(({ Response }) => {
        return Response.data;
      });
  },
  postApTransfer: function (data) {
    return Api()
      .postRaw2(`msgadaimastransaksi/v2/transfer/finance`, { request: data }, { rawJSON: true, isSignature: true })
      .then(({ data }) => {
        return data;
      });
  },
  GetBankCustomer: function () {
    return Api()
      .Get(`msgadaimasmaster/v1/master/listbankcustomer`)
      .then(({ Response }) => {
        return Response.data;
      });
  },
  getLaporanPettyCash: function (params) {
    return Api()
      .Get(`msgadaimaslaporan/v1/laporan/reportpettycash${params}`)
      .then(({ Response }) => {
        return Response.data;
      });
  },
  getMasterCabangPettyCash: function (params) {
    return Api()
      .Get(`msgadaimasfinance/v2/pettycashunit/cabang${params || ""}`, { isSignature: true ,  timestampV2: true})
      .then(({ Response }) => {
        return Response.data;
      });
  },
  getMasterPettyCashNew: function (params) {
    return Api()
      .Get(`msgadaimasfinance/v2/pettycashunit/master${params}`, { isSignature: true ,  timestampV2: true})
      .then(({ Response }) => {
        return Response.data;
      });
  },
  getMasterPettyCashNewHistori: function (params) {
    return Api()
      .Get(`msgadaimasfinance/v2/pettycashunit/master/histori${params}`, { isSignature: true,  timestampV2: true })
      .then(({ Response }) => {
        return Response.data;
      });
  },
  postRealisasiPettyCashNew: function (body) {
    return Api()
      .postRaw2(`msgadaimasfinance/v2/pettycashunit/create/kasir`, { request: body }, { isSignature: true, rawJSON: true, timestampV2: true })
      .then(({ data }) => {
        return data;
      });
  },
  postApprovalBerjenjangPettyCash: function (body) {
    return Api()
      .postRaw2(`msgadaimasfinance/v2/pettycashunit/approval/berjenjang`, { request: body }, { isSignature: true, rawJSON: true, timestampV2: true })
      .then(({ data }) => {
        return data;
      });
  },
  getFullFillPettyCash: function (params) {
    return Api()
      .Get(`msgadaimasfinance/v2/pettycashunit/master/finance${params}`, { isSignature: true,  timestampV2: true })
      .then(({ Response }) => {
        return Response.data;
      });
  },
  postFullFillPettyCash: function (body) {
    return Api()
      .postRaw2(`msgadaimasfinance/v2/pettycashunit/fullfill/finance`, { request: body }, { isSignature: true, rawJSON: true,  timestampV2: true })
      .then(({ data }) => {
        return data;
      });
  },
  PostDroppingPettyCashBFO: function (data) {
    return Api()
      .postRaw2(`msgadaimasfinance/v2/pettycashunit/fullfill/bfo`, { request: data }, { rawJSON: true, isSignature: true , timestampV2: true })
      .then(({ data }) => {
        return data;
      });
  },
  getMutasiBufferHarianRefund: function (params) {
    return Api()
      .Get(`msgadaimasfinance/v2/bufferharian/refund${params}`, { isSignature: true })
      .then(({ Response }) => {
        return Response.data;
      });
  },
  getMutasiBufferLiburRefund: function (params) {
    return Api()
      .Get(`msgadaimasfinance/v2/buffer/libur/refund${params}`, { isSignature: true })
      .then(({ Response }) => {
        return Response.data;
      });
  },
  postExportLaporanPettyCash: function (body) {
    return Api()
      .postRaw2(`msgadaimasgenerate/v1/buffer/pettycash`, body, { isSignature: false })
      .then(({ data }) => {
        return data;
      });
  },
  getReportBufferLibur: function (params) {
    return Api()
      .Get(`msgadaimaslaporan/v1/laporan/reportbufferlibur${params}`)
      .then(({ Response }) => {
        return Response.data;
      });
  },
  getMutasiBufferWalkInRefund: function (params) {
    return Api()
      .Get(`msgadaimasfinance/v2/bufferwalkin/refund${params}`, { isSignature: true })
      .then(({ Response }) => {
        return Response.data;
      });
  },
  postExportLaporanBufferLibur: function (body) {
    return Api()
      .postRaw2(`msgadaimasgenerate/v1/buffer/libur`, body, { isSignature: false })
      .then(({ data }) => {
        return data;
      });
  },
  getReportBufferWalkIn: function (params) {
    return Api()
      .Get(`msgadaimaslaporan/v1/laporan/reportbufferwalkin${params}`)
      .then(({ Response }) => {
        return Response.data;
      });
  },
  getmutasiBufferTakeOverRefund: function (params) {
    return Api()
      .Get(`msgadaimastransaksi/v2/buffer/takeover/refund${params}`, { isSignature: true })
      .then(({ Response }) => {
        return Response.data;
      });
  },
  postExportLaporanBufferWalkin: function (body) {
    return Api()
      .postRaw2(`msgadaimasgenerate/v1/buffer/walkin `, body, { isSignature: false })
      .then(({ data }) => {
        return data;
      });
  },
  getReportBufferHarian: function (params) {
    return Api()
      .Get(`msgadaimaslaporan/v1/laporan/reportbufferharian${params}`)
      .then(({ Response }) => {
        return Response.data;
      });
  },
  postRefundBufferharian: function (data) {
    return Api()
      .postRaw2(`msgadaimasfinance/v2/bufferharian/refundfinance`, { request: data }, { rawJSON: true, isSignature: true })
      .then(({ data }) => {
        return data;
      });
  },
  postReportBufferHarian: function (body) {
    return Api()
      .postRaw2(`msgadaimasgenerate/v1/buffer/harian`, body, { isSignature: false })
      .then(({ data }) => {
        return data;
      });
  },
  getReportBufferTakeover: function (params) {
    return Api()
      .Get(`msgadaimaslaporan/v1/laporan/reportbufferto${params}`)
      .then(({ Response }) => {
        return Response.data;
      });
  },
  postReportBufferTakeover: function (body) {
    return Api()
      .postRaw2(`msgadaimasgenerate/v1/buffer/takeover`, body, { isSignature: false })
      .then(({ data }) => {
        return data;
      });
  },
  getReportApTransfer: function (params) {
    return Api()
      .Get(`msgadaimaslaporan/v1/laporan/reportApTransfer${params}`)
      .then(({ Response }) => {
        return Response.data;
      });
  },
  postReportApTransfer: function (body) {
    return Api()
      .postRaw2(`msgadaimasgenerate/v1/excel/report/aptransfer`, body, { isSignature: false })
      .then(({ data }) => {
        return data;
      });
  },
  postRefundBufferTakeover: function (data) {
    return Api()
      .postRaw2(`msgadaimastransaksi/v2/buffer/takeover/refund`, { request: data }, { rawJSON: true, isSignature: true })
      .then(({ data }) => {
        return data;
      });
  },
  postRefundBufferWalkIn: function (data) {
    return Api()
      .postRaw2(`msgadaimasfinance/v2/bufferwalkin/refund`, { request: data }, { rawJSON: true, isSignature: true })
      .then(({ data }) => {
        return data;
      });
  },
  getLaporanPenggunaanSistem: function (params) {
    return Api()
      .Get(`msgadaimaslaporan/v1/laporan/penggunaansystem${params}`)
      .then(({ Response }) => {
        return Response.data;
      });
  },
  postLaporanPenggunaanSistem: function (data) {
    return Api()
      .postRaw2(`msgadaimasgenerate/v1/excel/penggunaansystem`, data, { rawJSON: true, isSignature: false })
      .then(({ data }) => {
        return data;
      });
  },
  getLaporanPotensiTopUp: function (params) {
    return Api()
      .Get(`msgadaimaslaporan/v1/laporan/potensitopup${params}`)
      .then(({ Response }) => {
        return Response.data;
      });
  },
  postRefundBufferLibur: function (data) {
    return Api()
      .postRaw2(`msgadaimasfinance/v2/buffer/libur/refund`, { request: data }, { rawJSON: true, isSignature: true })
      .then(({ data }) => {
        return data;
      });
  },
  getFotoBuktiPencairanGadai: function (params) {
    return Api()
      .Get(`msgadaimastransaksi/v2/gadai/master/selfie${params}`, { isSignature: true })
      .then(({ Response }) => {
        return Response.data;
      });
  },
  postFotoBuktiPencairanGadai: function (data) {
    return Api()
      .postRaw2(`msgadaimastransaksi/v2/gadai/update/selfie`, { request: data }, { rawJSON: true, isSignature: true })
      .then(({ data }) => {
        return data;
      });
  },
  GetProdukByIdV2: function (param) {
    return Api()
      .Get(`msgadaimasproduk/v2/produk/gadai?idProduk=${param}`, { isSignature: true })
      .then(({ Response }) => {
        return Response.data;
      });
  },
  cuProdukV2: function (data) {
    return Api()
      .postRaw2("msgadaimasproduk/v2/produk/gadai", { request: data }, { rawJSON: true, isSignature: true })
      .then(({ data }) => {
        return data;
      });
  },
  postDetailGadaiV2: function (data) {
    return Api()
      .postRaw2("msgadaimastransaksi/v2/gadai/detail", { request: data }, { rawJSON: true, isSignature: true })
      .then(({ data }) => {
        return data?.Response?.data;
      });
  },
  cuTransaksiGadaiV2: function (data) {
    return Api()
      .postRaw2("msgadaimastransaksi/v2/gadai/createApprove", { request: data }, { rawJSON: true, isSignature: true })
      .then(({ data }) => {
        return data;
      });
  },
  postDetailCicilanV2: function (data) {
    return Api()
      .postRaw2("msgadaimascicilan/v2/cicilan/gadai/detail", { request: data }, { rawJSON: true, isSignature: true })
      .then(({ data }) => {
        return data?.Response?.data;
      });
  },
  cuTransaksiCicilanV2: function (data) {
    return Api()
      .postRaw2("msgadaimascicilan/v2/cicilan/createApprove", { request: data }, { rawJSON: true, isSignature: true })
      .then(({ data }) => {
        return data?.Response?.data;
      });
  },
  getPenjualanBarangJaminan: function (params) {
    return Api()
      .Get(`msgadaimaslaporan/v1/laporan/reportpenjualanbarangjaminan${params}`)
      .then(({ Response }) => {
        return Response.data;
      });
  },
  GetAuditVirtualGradeReport: function (param) {
    return Api()
      .Get(`msgadaimasic/v2/report/grade/virtual${param}`,{ isSignature: true })
      .then(({ Response }) => {
        return Response.data;
      });
  },
  getMasterPlafonPettyCash: function (params) {
    return Api()
      .Get(`msgadaimasfinance/v2/plafon/pettycash/master${params}`, { isSignature: true, timestampV2: true })
      .then(({ Response }) => {
        return Response.data;
      });
  },
  postRevisiPlafonPettyCash: function (data) {
    return Api()
      .postRaw2(`msgadaimasfinance/v2/plafon/pettycash/pengajuan`, { request: data }, { rawJSON: true, timestampV2: true, isSignature: true })
      .then(({ data }) => {
        return data;
      });
  },
  getMasterPenambahanPettyCash: function (params) {
    return Api()
      .Get(`msgadaimasfinance/v2/plafon/pettycash/finance${params}`, { isSignature: true, timestampV2: true })
      .then(({ Response }) => {
        return Response.data;
      });
  },
  postPenambahanPlafonPettyCash: function (data) {
    return Api()
      .postRaw2(`msgadaimasfinance/v2/plafon/pettycash/fullfill`, { request: data }, { rawJSON: true, timestampV2: true, isSignature: true })
      .then(({ data }) => {
        return data;
      });
  },
  postPlafonPettyCashDropping: function (data) {
    return Api()
      .postRaw2(`msgadaimasfinance/v2/plafon/pettycash/droping`, { request: data }, { rawJSON: true, timestampV2: true, isSignature: true })
      .then(({ data }) => {
        return data;
      });
  },
};

export default MastersApi;
