import React from "react";
import "./ModalProfile.css";
import imageCamera from "../../styles/images/image-camera.svg";
import lock from "../../styles/images/lock.svg";
import logOut from "../../styles/images/log-out.svg";
import chevronRight from "../../styles/images/chevron-right.svg"
import AuthApi from "../../services/auth/auth.api";
import AuthApiSyariah from "../../services/auth/auth_syariah.api";

import { setLoadingAsync } from "../../services/globals/loading.reducer"
import { connect } from "react-redux";
import { getToken } from "../../utils/cookie";
import { useNavigate } from "react-router-dom";
import { HO } from "utils/ConstJabatan";
import defaultProfile from 'styles/images/default-profile.png'
import { setProfileAsync } from "services/globals/Profile.reducer";

const ModalProfile = ({dispatch, data, isProfile, hidemodal}) => {
  const navigate = useNavigate();


    const ubahPasswordHandle = () => {
      navigate('/ubah-password')
      hidemodal()
    }

    const handleLogOut = () => {
      console.log(isProfile)
      dispatch(setLoadingAsync(true));
      let refresh_token = getToken(process.env.REACT_APP_KEY_REFRESH_TOKEN);
      if(isProfile?.is_syariah === 1){
        let refresh_token = getToken(process.env.REACT_APP_KEY_TOKEN);
        AuthApiSyariah.postLogout({refresh_token}).then((res) => {
          dispatch(setLoadingAsync(false));
          dispatch(setProfileAsync(null));
          navigate("/login");
        }).catch((err) => {
          console.log("logout",err);
        })
      }else{
        AuthApi.postLogout({refresh_token}).then((res) => {
          dispatch(setLoadingAsync(false));
          dispatch(setProfileAsync(null));
          navigate("/login");
        }).catch((err) => {
          console.log("logout",err);
        })
      }
    }
    return(
        <>               
        <div className="blog-card box-item profile-modal p-4">
          <div className="row">
            <div className="col-12 text-center hand-click"  onClick={() => ubahPasswordHandle()}>
                <img className="img-rounded" src={isProfile?.profile?.image || defaultProfile} alt="" width="80" height="80" /><img className="img-camera" src={imageCamera} alt="" />
            </div>
          </div>
          <div className="row pt-2">
            <div className="col-12 text-center">
              <span className="text-name">{isProfile?.profile?.nama}</span>
            </div>
          </div>
          <div className="row">
            <div className="col-12 text-center">
              <span className="text-role">{isProfile?.profile?.kode_cabang} - {isProfile?.profile?.nama_cabang}</span>
            </div>
            <div className="col-12 text-center">
              <span className="text-role">{isProfile?.profile?.nama_jabatan_karyawan}</span>
            </div>
          </div>
          <div className="row pt-2" style={{display : isProfile?.profile?.id_jabatan_karyawan === HO ? "" : "none"}}>
            <div className="col-12 text-center">
                <button className="btn-polos">Ganti Propinsi</button>
            </div>
          </div>
          <hr/>
          <div className="row hover-klik hand-click" onClick={() => ubahPasswordHandle()}>
            <div className="col-2">
                <img src={lock} alt="" />
            </div>
            <div className="col-8">
               <span className="txt-ubah">Ubah Password</span>
            </div>
            <div className="col-2">
                <img src={chevronRight} alt="" className="font-14" />
            </div>
          </div>
          <div className="row pt-2 hover-klik hand-click" onClick={handleLogOut}>
            <div className="col-2">
                <img src={logOut} alt="" />
            </div>
            <div className="col-8">
               <span className="txt-ubah">Log Out</span>
            </div>
            <div className="col-2">
                <img src={chevronRight} alt="" className="font-14" />
            </div>
          </div>
        </div>
        </>
    )
}

const mapStateToProps = function (state) {

  return {
    isLoading: state.global.isLoading,
    isProfile:  state.profile
  };
};

export default connect(mapStateToProps)(ModalProfile);