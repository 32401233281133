import {ADMIN, DIREKTUR, DIRUT, KA_AREA, KA_CAB, KA_UNIT, KA_WIL, PENAKSIR, RISK, KASIR, ALL_MODUL_KA_UNIT, KA_UNIT_PENAKSIR, PENAKSIR_KASIR, AUDIT_STAFF, AUDIT_HEAD, OPR_MANAGER, OFS, OPR_SPV, KADEPT_CPA, KADIV_CPA } from "utils/ConstJabatan";

export const AplikasiSyari = [
    //Master User Management
  {
    title: "Pra Taksir",
    akses: [ADMIN, PENAKSIR, ALL_MODUL_KA_UNIT, KA_UNIT_PENAKSIR, PENAKSIR_KASIR],
    submenu: [
      {
        title: "Emas",
        akses: [ADMIN, PENAKSIR, ALL_MODUL_KA_UNIT, KA_UNIT_PENAKSIR, PENAKSIR_KASIR],
        link: "/aplikasi/pra-taksir/emas"
      },
      {
        title: "Umum *",
        akses: [ADMIN, PENAKSIR, ALL_MODUL_KA_UNIT, KA_UNIT_PENAKSIR, PENAKSIR_KASIR],
        // link: "/utility/user-management/level"
      }
    ],
  },
  {
    title: "Taksir",
    akses: [ADMIN, PENAKSIR, KA_UNIT, ALL_MODUL_KA_UNIT, KA_UNIT_PENAKSIR, PENAKSIR_KASIR],
    submenu: [
      {
        title: "Taksir",
        akses: [ADMIN, PENAKSIR, ALL_MODUL_KA_UNIT, KA_UNIT_PENAKSIR, PENAKSIR_KASIR],
        submenu: [
            {
                title: "Taksir Emas",
                akses: [ADMIN, PENAKSIR, ALL_MODUL_KA_UNIT, KA_UNIT_PENAKSIR, PENAKSIR_KASIR],
                link: "/aplikasi/taksir/taksir/taksir-emas"
            },
            {
                title: "Taksir Umum *",
                akses: [ADMIN, PENAKSIR, ALL_MODUL_KA_UNIT, KA_UNIT_PENAKSIR, PENAKSIR_KASIR],
                link: ""
            }
        ]
      },
      // {
      //   title: "Taksir Perpanjang",
      //   akses: [ADMIN, PENAKSIR, ALL_MODUL_KA_UNIT, KA_UNIT_PENAKSIR, PENAKSIR_KASIR],
      //   submenu: [
      //       {
      //           title: "Taksir Emas",
      //           akses: [ADMIN, PENAKSIR, ALL_MODUL_KA_UNIT, KA_UNIT_PENAKSIR, PENAKSIR_KASIR],
      //           link: "/aplikasi/taksir/perpanjangan-taksir/taksir-emas"
      //       },
      //       {
      //           title: "Taksir Umum",
      //           akses: [ADMIN, PENAKSIR, ALL_MODUL_KA_UNIT, KA_UNIT_PENAKSIR, PENAKSIR_KASIR],
      //           link: ""
      //       }
      //   ]
      // },
      {
        title: "Approval Taksir",
        akses: [ADMIN, KA_UNIT, ALL_MODUL_KA_UNIT, KA_UNIT_PENAKSIR],
        submenu: [
            {
                title: "Emas",
                link: "/aplikasi/taksir/approval-taksir/taksir-emas",
                akses: [ADMIN, KA_UNIT, ALL_MODUL_KA_UNIT, KA_UNIT_PENAKSIR],

            },
            {
                title: "Umum *",
                link: "",
                akses: [ADMIN, KA_UNIT, ALL_MODUL_KA_UNIT, KA_UNIT_PENAKSIR],
            }
        ]
      },
      // {
      //   title: "Approval Taksir 2",
      //   submenu: [
      //       {
      //           title: "Emas",
      //           link: ""
      //       },
      //       {
      //           title: "Umum",
      //           link: ""
      //       }
      //   ]
      // },
      // {
      //   title: "Approval Taksir 3",
      //   submenu: [
      //       {
      //           title: "Emas",
      //           link: ""
      //       },
      //       {
      //           title: "Umum",
      //           link: ""
      //       }
      //   ]
      // },
      // {
      //   title: "Approval Taksir 4",
      //   submenu: [
      //       {
      //           title: "Emas",
      //           link: ""
      //       },
      //       {
      //           title: "Umum",
      //           link: ""
      //       }
      //   ]
      // },
    ],
  },
  // produk
  {
    title: "Produk",
    akses: [ADMIN, KA_UNIT, PENAKSIR, ALL_MODUL_KA_UNIT, KA_UNIT_PENAKSIR, PENAKSIR_KASIR],
    submenu: [
      {
        title: "Gadai",
        akses: [ADMIN, KA_UNIT, PENAKSIR, ALL_MODUL_KA_UNIT, KA_UNIT_PENAKSIR, PENAKSIR_KASIR],
        link: "/aplikasi/produk/gadai"
      },
      // {
      //   title: "Cicilan",
      //   akses: [ADMIN, KA_UNIT, PENAKSIR, ALL_MODUL_KA_UNIT, KA_UNIT_PENAKSIR, PENAKSIR_KASIR],
      //   link: "/aplikasi/produk/cicilan"
      // },

    ],
  },
  // produk
  {
    title: "Approval",
    akses: [ADMIN, KA_UNIT, KA_CAB, RISK, ALL_MODUL_KA_UNIT, KA_UNIT_PENAKSIR,KADEPT_CPA, KADIV_CPA],
    submenu: [
      {
        title: "Approval Gadai",
        link: "/aplikasi/approval/approval-gadai",
        akses: [ADMIN, KA_UNIT, KA_CAB, ALL_MODUL_KA_UNIT, KA_UNIT_PENAKSIR, KADEPT_CPA,KADIV_CPA],
      },
      // {
      //   title: "Approval Cicilan",
      //   link: "/aplikasi/approval/approval-cicilan",
      //   akses: [ADMIN, KA_UNIT, KA_CAB, KA_AREA, KA_WIL, DIREKTUR, DIRUT, ALL_MODUL_KA_UNIT, KA_UNIT_PENAKSIR],
      // },
      {
        title: "Approval Pelunasan",
        link: "/aplikasi/approval/approval-pelunasan",
        akses: [ADMIN, RISK],
      }
    ],
  },
  // inventory
  {
    title: "Inventory",
    akses: [ADMIN, KA_UNIT,PENAKSIR, KASIR, ALL_MODUL_KA_UNIT, KA_UNIT_PENAKSIR, PENAKSIR_KASIR,AUDIT_STAFF,AUDIT_HEAD, OPR_MANAGER, OFS, OPR_SPV],
    submenu: [
      {
        title: "Inventory",
        link: "/aplikasi/inventory/inventory",
        akses: [ADMIN, KA_UNIT, ALL_MODUL_KA_UNIT, KA_UNIT_PENAKSIR,AUDIT_STAFF,AUDIT_HEAD, OPR_MANAGER, OFS, OPR_SPV],
      },
      {
        title: "Penerimaan",
        link: "/aplikasi/inventory/penerimaan",
        akses: [ADMIN, KA_UNIT, ALL_MODUL_KA_UNIT, KA_UNIT_PENAKSIR],
      },
      {
        title: "Pengeluaran",
        link: "/aplikasi/inventory/pengeluaran",
        akses: [ADMIN, KA_UNIT, ALL_MODUL_KA_UNIT, KA_UNIT_PENAKSIR],
      },
      {
        title: "Penyerahan ke Nasabah",
        link: "/aplikasi/inventory/penyerahan-ke-nasabah",
        akses: [ADMIN, PENAKSIR, PENAKSIR_KASIR, ALL_MODUL_KA_UNIT, KA_UNIT_PENAKSIR],
      },
      {
        title: "Mutasi Barang",
        akses: [ADMIN, KA_UNIT, ALL_MODUL_KA_UNIT, KA_UNIT_PENAKSIR,],
        link: "/aplikasi/inventory/mutasi-barang",
      },
      {
        title: "Pemeriksaan *",
        // link: "/utility/user-management/user",
        akses: [ADMIN, KA_UNIT, ALL_MODUL_KA_UNIT, KA_UNIT_PENAKSIR],
      },
      {
        title: "Barang Penjualan *",
        // link: "/utility/user-management/level",
        akses: [ADMIN, KA_UNIT, ALL_MODUL_KA_UNIT, KA_UNIT_PENAKSIR],
      },
      {
        title: "Mutasi SBG",
        akses: [ADMIN, KA_UNIT, ALL_MODUL_KA_UNIT, KA_UNIT_PENAKSIR],
        link: "/aplikasi/inventory/mutasi-sbg",
      },
      {
        title: "SBG Hilang *",
        akses: [ADMIN, KA_UNIT, ALL_MODUL_KA_UNIT, KA_UNIT_PENAKSIR],
        // link: "/utility/user-management/level"
      },
      {
        title: "Log Book *",
        akses: [ADMIN, PENAKSIR, KA_UNIT, KASIR, ALL_MODUL_KA_UNIT, KA_UNIT_PENAKSIR, PENAKSIR_KASIR],
        // link: "/utility/user-management/user"
      },
    ],
  },
  // collection
  {
    title: "Collection",
    akses: [ADMIN, KA_UNIT,KA_CAB, ALL_MODUL_KA_UNIT, KA_UNIT_PENAKSIR, AUDIT_HEAD, AUDIT_STAFF, PENAKSIR_KASIR, PENAKSIR, RISK],
    submenu: [
      {
        title: "Penanganan OVD",
        akses: [ADMIN, KA_UNIT, ALL_MODUL_KA_UNIT, KA_UNIT_PENAKSIR, AUDIT_HEAD, AUDIT_STAFF, PENAKSIR_KASIR, PENAKSIR, RISK],
        link: "/aplikasi/collection/penanganan-ovd"
      },
      {
        title: "Query Overdue Customer",
        akses: [ADMIN, KA_UNIT,KA_CAB, ALL_MODUL_KA_UNIT, KA_UNIT_PENAKSIR,KA_WIL],
        link: "/aplikasi/collection/query-overdue-customer"
      },
      {
        title: "Surat Pemberitahuan 1 *",
        akses: [ADMIN],
        // link: "/utility/user-management/user"
      },
      {
        title: "Surat Pemberitahuan *",
        akses: [ADMIN, KA_UNIT, ALL_MODUL_KA_UNIT, KA_UNIT_PENAKSIR],
        // link: "/utility/user-management/level"
      },
      {
        title: "Konfirmasi by Email/SMS *",
        akses: [ADMIN, KA_UNIT, ALL_MODUL_KA_UNIT, KA_UNIT_PENAKSIR],
        // link: "/utility/user-management/user"
      },
      {
        title: "Penanganan Nasabah",
        akses: [ADMIN, KA_UNIT, ALL_MODUL_KA_UNIT, KA_UNIT_PENAKSIR, AUDIT_HEAD, AUDIT_STAFF, PENAKSIR_KASIR, PENAKSIR, RISK],
        link: "/aplikasi/collection/penanganan-nasabah"
      },
    ],
  },
  // Blanko
  {
    title: "Blanko",
    akses: [ADMIN, KA_UNIT, ALL_MODUL_KA_UNIT, KA_UNIT_PENAKSIR],
    submenu: [
      {
        title: "Generate Blanko",
        link: "/aplikasi/blanko/generate-blanko",
        akses: [ADMIN, KA_UNIT, ALL_MODUL_KA_UNIT, KA_UNIT_PENAKSIR],
      },
      {
        title: "Mutasi Blanko",
        akses: [ADMIN, KA_UNIT, ALL_MODUL_KA_UNIT, KA_UNIT_PENAKSIR],
        link: "/aplikasi/blanko/mutasi-blanko"
      },
    ],
  },
  // Penjualan
  {
    title: "Penjualan",
    akses: [ADMIN, KA_UNIT,KA_CAB,KASIR,PENAKSIR, ALL_MODUL_KA_UNIT, KA_UNIT_PENAKSIR, PENAKSIR_KASIR, KADEPT_CPA, KADIV_CPA],
    submenu: [
      {
        title: "Pengajuan Penjualan Barang Jaminan",
        akses: [ADMIN, KA_UNIT, ALL_MODUL_KA_UNIT, KA_UNIT_PENAKSIR],
        link: "/aplikasi/penjualan/pengajuan-penjualan-barang-jaminan"
      },
      {
        title: "Approval Penjualan Barang Jaminan",
        akses: [ADMIN, KA_CAB,KADEPT_CPA, KADIV_CPA],
        link:  "/aplikasi/penjualan/approval-penjualan-barang-jaminan"
      },
      {
        title: "Penjualan Barang Jaminan",
        akses: [ADMIN, KASIR,PENAKSIR,KA_UNIT, ALL_MODUL_KA_UNIT, KA_UNIT_PENAKSIR, PENAKSIR_KASIR],
        link: "/aplikasi/penjualan/penjualan-barang-jaminan"
      },
    ],
  },
  // Insentif
  {
    title: "Insentif",
    akses: [ADMIN, KA_UNIT, ALL_MODUL_KA_UNIT, KA_UNIT_PENAKSIR],
    submenu: [
      {
        title: "Pembagian Insentif Admin *",
        akses: [ADMIN, KA_UNIT, ALL_MODUL_KA_UNIT, KA_UNIT_PENAKSIR],
        // link: "/utility/user-management/user"
      },
      {
        title: "Upload Insentif *",
        akses: [ADMIN],
        // link: "/utility/user-management/level"
      },
    ],
  },
  // ORM
  {
    title: "ORM",
    akses: [ADMIN],
    submenu: [
      {
        title: "Detail Event *",
        akses: [ADMIN],
        // link: "/utility/user-management/user"
      },
      {
        title: "Open *",
        akses: [ADMIN],
        // link: "/utility/user-management/level"
      },
      {
        title: "Close *",
        akses: [ADMIN],
        // link: "/utility/user-management/level"
      },
    ],
  },
  {
    title: "Tools",
    akses: [ADMIN],
    submenu: [
      {
        title: "SMS Jatuh Tempo Cicilan",
        akses: [ADMIN],
        link: "/aplikasi/tools/sms-jatuh-tempo-cicilan"
      },
      {
        title: "Ubah Flaging Transaksi",
        akses: [ADMIN],
        link: "/aplikasi/tools/update-proses-gadai"
      },
      {
        title: "Ubah Status Nasabah",
        akses: [ADMIN],
        link: "/aplikasi/tools/update-status-nasabah"
      },
      {
        title: "Ubah Flaging Cetak Ulang Storing",
        akses: [ADMIN],
        link: "/aplikasi/tools/flegding-cetak-ulang-storing"
      },
      {
        title: "Fleging Kode Voucher",
        akses: [ADMIN],
        link: "/aplikasi/report/flegging-voucher"
      }
    ]
  }
  
];