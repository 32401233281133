import React from 'react'

const Checkbox = ({ inputType, inputLabel, inputValue, inputClick, inputName, placeholder, disabled, checked, fullWidth, isRequired }) => {
  return (
    //   <div class="mt-3 col-lg-6 d-flex justify-content-between">
    //       
    //   <input type={inputType} className="input-table-modal" value={inputValue} placeholder={placeholder} disabled={disabled} onClick={(v) => inputClick(v)} name={inputName} checked={checked}/>
    // </div>
    <div className={`mt-3 ${fullWidth ? "col-lg-12" : "col-lg-6"} d-flex`}>
      <div className={`${fullWidth ? "col-lg-3" : "col-lg-5"}`}>
        <label class="txt-body-modal">{inputLabel} <span style={{ color: `${isRequired ? 'red' : ''}` }}>
          {isRequired ? '*' : ''}</span>
        </label>
      </div>
      <div className={`${fullWidth ? "col-lg-9" : "col-lg-7"}`}>
        <input type={inputType} className="input-checkbox" value={inputValue} placeholder={placeholder} disabled={disabled} onClick={(v) => inputClick(v)} name={inputName} checked={checked} />
      </div>
    </div>
  )
}

export default Checkbox