import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import './FullfillBufferLibur.css'
import Formatting from "utils/formattingJson";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import InputGroup from "components/GroupInput/InputGroup";
import dayjs from "dayjs";

const ModalView = ({ title, show, parsingData, hideModal, obj, dispatch, isProfile }) => {
  const [modalView, setModalView] = useState(false)
  const [modalData, setModalData] = useState("")
  const [keterangan, setketerangan] = useState("")

  useEffect(() => {
    console.log("test",obj)
    setketerangan(obj?.master_approval_berjenjang?.filter((x) => x?.keterangan_approval)?.pop()?.keterangan_approval)
  }, [obj])



  const informationForm = [
    {
      formType: "text",
      label: "No Voucher",
      value: obj?.kode_voucher,
      disabled: true
    },
    {
      formType: ""
    },
    {
      formType: "text",
      label: "Tanggal Request",
      value: dayjs(obj?.tanggal_realisasi).format('DD/MM/YYYY'),
      disabled: true
    },
    {
      formType: ""
    },
    {
      formType: "text",
      label: "Tanggal Nota",
      value: dayjs(obj?.tanggal_nota).format('DD/MM/YYYY'),
      disabled: true
    },
  ]


    
  const onClickView = (v) => {
    setModalView(true)
    setModalData(v)
  }


  

  return (
    <>
      <Modal
            isOpen={modalView}
            centered
            size="lg"
            scrollable
            toggle={() => setModalView(false)}
        >
            <ModalHeader toggle={() => setModalView(false)}>Foto Nota</ModalHeader>
            <ModalBody>
                  <div className="col-12 mt-4 text-center">
                        <img src={modalData} style={{objectFit: "cover"}} />
                    </div>
            </ModalBody>
            <ModalFooter>
                <Button color="primary" onClick={() => setModalView(false)}>OK</Button>
            </ModalFooter>
        </Modal>
      <Modal isOpen={show} fullscreen scrollable>
        <ModalHeader toggle={() => hideModal()}>{title}</ModalHeader>
        <ModalBody>
          <div className="row d-flex align-items-center" style={{ marginLeft: 'auto', marginRight: 'auto' }}>
            <div className="col-8">
              <div className="col-12 my-4">
                <InputGroup formGroup={informationForm} />
              </div>
              <div className="container mt-2 table-bordered">
                <table width={"100%"}>
                  <thead style={{textAlign: "center"}}>
                    <th>No</th>
                    <th>Keterangan</th>
                    <th>COA</th>
                    <th>COA Deskripsi</th>
                    <th>Cost Center</th>
                    <th>Nominal</th>
                  </thead>
                  {obj?.master_detail_transaksi?.map((v, i) => (
                    <tr>
                      <td>{(i + 1)}</td>
                      <td>{v?.keterangan}</td>
                      <td>{v?.coa_template}</td>
                      <td>{v?.deskripsi_coa}</td>
                      <td>{v?.nama_cost_center}</td>
                      <td>{!v?.nominal ? "-" : Formatting.currency(v.nominal)}</td>
                    </tr>
                  ))}
                  <tr>
                    <td colSpan={5}>Total</td>
                    <td>{Formatting.currency(obj?.total_realisasi)}</td>
                  </tr>
                </table>
              </div>

            </div>
            <div className="col-4">
              <p className="mt-4">Foto Kwintansi</p>
              <div className="row">
                <img className="hand-click" src={obj?.file_bukti} width="100" height="300" style={{objectFit: "cover"}} onClick={() => onClickView(obj?.file_bukti)}  alt="kwintansi" />
              </div>
            </div>

            <div className="container mt-4" style={{display: keterangan ? "" : "none"}}>
                  <InputGroup formGroup={[{formType: "textArea", disabled: true, readOnly: true, value: keterangan, label: "keterangan"}]} />
            </div>
          </div>
        </ModalBody>
        <ModalFooter className="d-flex justify-content-start">
          <Button color="primary" onClick={() => hideModal()}>
            Tutup
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}

const mapStateToProps = function (state) {

  return {
    isLoading: state.global.isLoading,
    isProfile: state.profile
  };
};

export default connect(mapStateToProps)(ModalView);