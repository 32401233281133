import { Navigate } from "react-router-dom";
import { getToken } from "../../utils/cookie";
import Layout from "./Layout";


function PrivateRoute({ children, ...rest }) {
  const auth = getToken(process.env.REACT_APP_KEY_TOKEN);
  return auth ? <Layout>{children}</Layout> : <Navigate replace to="/login" />;
}

export default PrivateRoute;
