import React, { useRef } from 'react'

const Text = ({inputType,inputLabel,inputValue,defaultValue,onKeyUp,inputChange,inputName,placeholder,disabled,maxLength,minLength,max,min,readonly,ref, inputClick,clickable, fullWidth,area,isRequired,isBold }) => {

  return (
    // <div className="mt-3 col-lg-6 d-flex justify-content-between">
    //     <label class="txt-body-modal">{inputLabel}</label>
    //     <input type={inputType} defaultValue={defaultValue} autoComplete="off" className="input-table-modal pointer" value={inputValue} placeholder={placeholder} disabled={disabled} onChange={(v) => inputChange(v)} name={inputName} maxLength={maxLength} minLength={minLength} max={max} min={min} readOnly={readonly} onClick={inputClick}/>
    // </div>
    <div className={`mt-3 ${fullWidth? "col-lg-12" : "col-lg-6"} d-flex`} style={{fontWeight: isBold ? 'bold' : 'normal'}}>
      <div className={`${fullWidth? "col-lg-3 col-6" : "col-lg-5 col-6"}`}>
      <label class="txt-body-modal">{inputLabel} <span style={{color: `${isRequired ? 'red' : ''}`}}>
        {isRequired ? '*' : ''}</span>
      </label>
      </div>
      <div className={`${fullWidth? "col-lg-9 col-6 ml-full" : "col-6 col-lg-7"}`}>
        <input ref={ref} type={inputType} onKeyUp={onKeyUp} style={{fontWeight: isBold ? 'bold' : 'normal'}} defaultValue={defaultValue} autoComplete="off" className={` ${fullWidth? "input-table-modal-full" : "input-table-modal"} pointer`} value={inputValue} placeholder={placeholder} disabled={disabled} onChange={(v) => inputChange(v)} name={inputName} maxLength={maxLength} minLength={minLength} max={max} min={min} readOnly={readonly} onClick={inputClick}/>
      </div>
    </div>
  )
}

export default Text