import React from "react";
import MenuList from './MenuList';
import Select, { createFilter } from "react-select";
import "./Select.css";

const customStyles = {
  control: (provided, props) => {
    const isError =
      props.selectProps.className.split(" ").findIndex((x) => x === "error") !==
      -1;

    if (props.isFocused && !isError) {
      return {
        ...provided,
        borderRadius: 3,
        border: props.isFocused ? "1px solid #26245E" : "",
        boxShadow: "none",
        height: 30,
        minHeight: 30

      };
    }
    return {
      ...provided,
      borderRadius: 3,
      border: isError ? "1px solid #e57373" : "",
      boxShadow: "none",
      height: 30,
      minHeight: 30

    };
  },
  dropdownIndicator: (base) => ({
    ...base,
    padding: 2,
  }),
  clearIndicator: (base) => ({
    ...base,
    padding: 2,
  }),
  multiValue: (base) => ({
    ...base,
  }),
  valueContainer: (base) => ({
    ...base,
  }),
  input: (base) => ({
    ...base,
    margin: 0,
    padding: 0,
  }),
};

const ReactSelect = ({
  options,
  placeholder,
  onChange,
  value,
  name,
  id,
  onBlur,
  className,
  onInputChange,
  disabled
}) => {
  return (
    <>
      <Select
        onInputChange={onInputChange}
        filterOption={createFilter({ ignoreAccents: false })}
        name={name}
        id={id}
        // components={{MenuList}}
        isDisabled={disabled}
        value={value}
        onChange={onChange}
        options={options}
        styles={customStyles}
        placeholder={placeholder}
        className={`select ${className}`}
        onBlur={onBlur}
      />
    </>
  );
};

export default ReactSelect;
