import { useState } from "react";
import MenuItems from "./MenuItems";
import MenuItemsMobile from "./MenuItemsMobile.";
const NavbarMobile = ({data, userId, hidemodal, hideModalMobile}) => {

  const [PrevParams, setPrevParams] = useState(null)

  const hideModalsMobile = (evt) => {
    hidemodal()
    if(window.innerWidth < 660){


      if(window.location.pathname.split('/').length >= 4 && evt.target.type !== "button"){
          setPrevParams(window.location.pathname)
          hideModalMobile()
      }
    }
  }


  return (
    <nav>
      <ul onClick={(v) => hideModalsMobile(v)} className="menus">
        {data?.map((menu, index) => {
          const depthLevel = 0;
          return <MenuItemsMobile hideModals={() => hidemodal()} userId={userId} items={menu} key={index} depthLevel={depthLevel} />;
        })}
      </ul>
    </nav>
  );
};

export default NavbarMobile;
