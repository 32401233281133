import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import OptionsCekPlafonList from "components/ModalOptions/OptionsCekPlafonList";
import { useEffect, useState } from "react";
import Formatting from "utils/formattingJson";
import InputGroup from "components/GroupInput/InputGroup";
import { useDebounce } from "use-debounce";
import Swal from "sweetalert2";
import MastersApi from "services/masters/masters.api";
import { setLoadingAsync } from "services/globals/loading.reducer";
import ModalView from 'pages/Fa/Finance/PettyCashTransaction/ModalView';
import dayjs from "dayjs";


const ModalViewTrx = ({ title, show, parsingData, hideModal, obj, dispatch, isProfile }) => {
  const [modalView, setModalView] = useState(false)
  const [detailData, setDetailData] = useState(null)
  const [detailData2, setDetailData2] = useState(null)


  console.log(obj)

  useEffect(() => {
    console.log(obj)
    dispatch(setLoadingAsync(true))
    MastersApi.getFullFillPettyCash(`?id_buffer_pettycash=${obj?.id_buffer_petty_cash}`).then((res) => {
      
      let detailTemp = []

      // res?.master_detail_petty_cash?.map((x) => {
      //   if(x?.id_buffer_petty_cash === obj?.id_buffer_petty_cash){
      //     detailTemp?.push(x)
      //   }
      // })

      setDetailData(res)
      dispatch(setLoadingAsync(false))
    });
  }, []);

  const cancel = () => {
    hideModal();
  };


console.log(detailData)

  const form = [
    {
      formType: "text",
      label: "Nama Unit",
      value: obj?.nama_unit,
      name: "noVoucherTransferFinance",
      disabled: true,
    },
    {
      formType: "text",
      label: "Kode Unit",
      value: obj?.kode_unit,
      name: "plafon",
      disabled: true,
    },
    {
      formType: "text",
      label: "Kode Voucher",
      value: obj?.no_voucher,
      name: "plafon",
      disabled: true,
    },
    {
      formType: "text",
      label: "Tanggal Voucher",
      value: dayjs(obj?.tanggal_voucher).format("DD/MM/YYYY"),
      name: "noVoucherDroppingKacab",
      disabled: true,
    },
 
  ];


  return (
    <>
      {modalView && <ModalView obj={detailData2} title="History Petty Cash" hideModal={() => setModalView(!modalView)} show={modalView} />}
      <Modal isOpen={show} centered fullscreen scrollable>
        <ModalHeader toggle={() => cancel()}>{title}</ModalHeader>
        <ModalBody>
          <InputGroup formGroup={form} />
          <div className="row mt-4 table-bordered">
            <table width={"100%"} style={{textAlign: "center"}}>
              <thead>
                <th>No</th>
                <th>Tanggal Voucher</th>
                <th>Tanggal nota</th>
                <th>No. Voucher</th>
                <th>Sisa plafon</th>
                <th>Total Transaksi</th>
                <th>Status</th>
                <th>Detail Transaksi</th>
              </thead>
              {detailData?.data?.master_detail_petty_cash?.map((v, i) => (
                <tr>
                  <td>{i + 1}</td>
                  <td>{v?.tanggal_realisasi?.split("T")[0]}</td>
                  <td>{dayjs(v?.tanggal_nota).format("DD/MM/YYYY")}</td>
                  <td>{v?.kode_voucher}</td>
                  <td>{Formatting.currency(v?.total_realisasi)}</td>
                  <td>{Formatting.currency(v?.total_realisasi)}</td>
                  <td>{v?.is_status === 1 ? "Done" : "Pending"}</td>
                  <td><button className="btn-primary btn p-1" onClick={() => { setModalView(!modalView); setDetailData2(v); }} >Detail</button></td>
                  </tr>
              ))}
            </table>
          </div>
            
        </ModalBody>
        <ModalFooter className="d-flex justify-content-start">
          <Button color="primary" onClick={() => cancel()}>
            Tutup
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default ModalViewTrx;
