const SET_PROFILE = "GLOBAL/PROFILE"

export default function reducer(state = [] , action) {
    switch (action.type) {
        // do reducer stuff
        case SET_PROFILE:
            return action.payload ;
            default:
            return state;
    }
}

export function setProfile(isProfile) {
    return { type: SET_PROFILE, payload: isProfile };
}

export function setProfileAsync(isProfile) {
    return (dispatch) => {
        if (isProfile) {
            dispatch(setProfile(isProfile));
            return;
        }
    };
}