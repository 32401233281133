import React from 'react'

const Text = ({ inputType, inputLabel, inputValue, isRequired, defaultValue, inputChange, inputName, placeholder, disabled, maxLength, minLength, max, min, readonly, inputClick, clickable, fullWidth, area, isBold }) => {
  return (
    // <div className="mt-3 col-lg-6 d-flex justify-content-between">
    //     <label class="txt-body-modal">{inputLabel}</label>
    //     <input type={inputType} defaultValue={defaultValue} autoComplete="off" className="input-table-modal pointer" value={inputValue} placeholder={placeholder} disabled={disabled} onChange={(v) => inputChange(v)} name={inputName} maxLength={maxLength} minLength={minLength} max={max} min={min} readOnly={readonly} onClick={inputClick}/>
    // </div>
    <>
      <div className={`mt-3 col-lg-6 d-flex`} style={{ fontWeight: isBold ? 'bold' : 'normal' }}>
        <div className={`${fullWidth ? "col-lg-5 col-6" : "col-lg-5 col-6"}`}>
          <label class="txt-body-modal">{inputLabel} <span style={{ color: `${isRequired ? 'red' : ''}` }}>
            {isRequired ? '*' : ''}</span>
          </label>
        </div>
        <div className={`${fullWidth ? "col-lg-6 col-6" : "col-6 col-lg-7"}`}>
          <textarea type={inputType} style={{ fontWeight: isBold ? 'bold' : 'normal'}} defaultValue={defaultValue} autoComplete="off" className={` ${fullWidth ? "input-table-modal-full" : "input-table-modal"} pointer`} value={inputValue} placeholder={placeholder} disabled={disabled} onChange={(v) => inputChange(v)} name={inputName} maxLength={maxLength} minLength={minLength} max={max} min={min} readOnly={readonly} onClick={inputClick} />
        </div>
      </div>
      <div className="col-6" style={{display: fullWidth ? "" : "none"}}></div>
    </>
  )
}


export default Text