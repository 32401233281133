import * as axios from "axios";
import qs from "querystring";
import {Buffer} from 'buffer';
import { deleteAllToken, getToken, saveToken } from "./cookie";
import { toast } from 'react-toastify';
import Formatting from "./formattingJson";
import dayjs from "dayjs";

axios.interceptors.response.use(
  function (response) {
      return response;
  },

  function (error) {
    const originalRequest = error.config;
    console.log("config axios", error.response)
    if (error.response === undefined) {
      toast.error("Ups, terjadi kesalahan! Error : Undefined");
    }

    if(error?.response?.status === 413){
      toast.error("Ups, terjadi kesalahan! Error : 413");
      return Promise.reject(error)
    }
    if(error?.response?.statusCode === 502){
      toast.error("Ups, terjadi kesalahan! Error : 502");
      return Promise.reject(error)
    }

    if (error?.response?.status === 401) {
      if(error?.response?.data?.Response?.errorMessage === "token contains an invalid number of segments"){
        deleteAllToken();
        toast.error("[401&segments] Session Expired, Silakan Login Kembali")
        setTimeout(() => {
          window.location.reload()
          
        },5000);
      }else{
        deleteAllToken();
        toast.error("[401&Invalid] Session Expired, Silakan Login Kembali")
        setTimeout(() => {
          window.location.reload()
          
        },5000);
      }

      return Promise.reject(error);
    }
    return Promise.reject(error);
  }
);

const Api = () => ({
  Config: ({ withoutToken = false, multipartForm, rawJSON, isSignature, body, url, isLogin = false, isHtml = false, method = "get" }) => {
    let api_token = getToken(process.env.REACT_APP_KEY_TOKEN);
    let basicAuth = Buffer.from(`${process.env.REACT_APP_UNAME_AUTH}:${process.env.REACT_APP_PASSWD_AUTH}`, 'utf8').toString('base64');

    let headers = {
      "Content-Type": "application/x-www-form-urlencoded",
      "api-key" : "vSqJ7AEvXSBUUSHQ9ojrMpc9m02qXCsw3L924Mtt8AjtyvVG9D",
    };

    if(url?.includes("v2") || url?.includes("dashoard") || url?.includes("user/registrasi") || isSignature){
      let timestamp = dayjs().format('YYYYMMDDHHmm');
      let jwt = multipartForm ? Formatting.GenerateJWTFormData(body, timestamp, process.env.REACT_APP_API_ENDPOINT+url?.split("?")[0]) : Formatting.GenerateJWT(method === "get" ? body : body?.hasOwnProperty("request") ? body : {request : body}, timestamp, process.env.REACT_APP_API_ENDPOINT+url?.split("?")[0])
      headers["api-key"] = jwt?.split('.')[2];
      headers["timestamps"] = timestamp;
    }
    
    if (withoutToken) {
      headers["Authorization"] = `Basic ${basicAuth}`;
    }else{
      headers["Authorization"] = `Bearer ${api_token}`;
    }

    if (multipartForm) {
      headers["Content-Type"] = "multipart/form-data";
    }

    if (isHtml) {
      headers["Accept"] = "text/html";
    }


    if (rawJSON) {
      headers["Content-Type"] = "application/json";
    }
      

    const client = {
      baseURL: process.env.REACT_APP_API_ENDPOINT,
      headers: headers,
    };
    return client;
  },
  Get: (url = "", config) => {
    return axios
      .get(url, Api().Config({ withoutToken: config?.withoutToken, isSignature : config?.isSignature, body: {}, url, isTimestamp: config?.isTimestamp, method: "get" }))
      .then((res) => res?.data || {});
  },
  Post: (url, body, config) => {
    return axios
      .post(
        url,
        qs.stringify(body),
        Api().Config({ withoutToken: config?.withoutToken, method: "post"})
      )
      .then((Response) => Response);
  },
  PostFile: (url, body, config) => {
    return axios
      .post(url, body, Api().Config({ withoutToken: config?.withoutToken, multipartForm: config?.multipartForm, method: "post" }))
      .then((res) => res?.Response || {});
  },
  PostFileAbsen: (url, body, config) => {
    return axios
      .post(url, body, Api().Config({ withoutToken: config?.withoutToken, multipartForm: config?.multipartForm, isSignature: config?.isSignature, body, url, method: "post" }))
      .then((res) => res?.data.Response || {});
  },
  GetHTML: (url = "", config) => {
    return axios
      .get(url, Api().Config({ withoutToken: config?.withoutToken, isSignature : config?.isSignature, body: {}, url, isTimestamp: config?.isTimestamp, isHtml: config?.isHtml  }))
      .then((res) => res);
  },
  postRaw: (url, body, config) => {
    return axios
      .post(url, body, Api().Config({ withoutToken: config?.withoutToken, rawJSON: config?.rawJSON, method: "post" }))
      .then((res) => res?.Response || {});
  },
  postRaw2: (url, body, config,) => {
    return axios
      .post(url, url.includes("v1")  ? body : body?.hasOwnProperty("request") ? body : {request: body}, Api().Config({ withoutToken: config?.withoutToken, rawJSON: config?.rawJSON, isSignature: config?.isSignature, body, url,isHtml: config?.isHtml, method: "post" }))
      .then((res) => res);
  },
  postRaw3: (url, body, config,) => {
    return axios
      .post(url, body, Api().Config({ withoutToken: config?.withoutToken, rawJSON: config?.rawJSON, isSignature: config?.isSignature, body, url,isHtml: config?.isHtml, method: "post" }))
      .then((res) => res);
  },
  PutOpenCashier: (url, body, config) => {
    return axios
      .put(url, body?.hasOwnProperty("request") ? body : {request: body}, Api().Config({ withoutToken: config?.withoutToken, rawJSON: config?.rawJSON, method: "put", url }))
      .then((res) => res?.Response || {});
  },
  postLogin: (url, body, config) => {
    return axios
      .post(url, body, Api().Config({ withoutToken: config?.withoutToken, rawJSON: config?.rawJSON, isLogin: config?.isLogin, method: "post" }))
      .then((res) => res?.data?.Response || {});
  },
  postLogout: (url, body, config) => {
    return axios
      .post(url, body, Api().Config({body, withoutToken: config?.withoutToken, rawJSON: config?.rawJSON, isSignature: config?.isSignature, isLogin: config?.isLogin, method: "post"  }))
      .then(() => true);
  },
});

export default Api;
