import Formatting from "../../utils/formattingJson";

const SET_MENU = "GLOBAL/SET_MENU";

export default function reducer(state = { isMenu: false }, action) {
  
  switch (action.type) {
    // do reducer stuff
    case SET_MENU:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}

export function setMenu(isMenu) {
  
  return { type: SET_MENU, payload: { isMenu } };
}

export function setMenuAsync(isMenu) {
  return (dispatch) => {
    if (isMenu) {
      dispatch(setMenu(isMenu));
      return;
    }

    Formatting.delayExecution(600).then(() => {
      dispatch(setMenu(isMenu));
    });
  };
}
