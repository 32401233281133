import React, { useEffect, useState } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import InputGroup from "components/GroupInput/InputGroup";
import { decodeAuth } from "utils/cookie";
import DataTable from "react-data-table-component";
import { customStyles } from "components/CustomStyles/CustomStyles";
import MastersApi from "services/masters/masters.api";
import Formatting from "utils/formattingJson";
import { setLoadingAsync } from "services/globals/loading.reducer";
import { useDebounce } from "use-debounce";
import Swal from "sweetalert2";

const TambahDetailModal = ({ show, hideModal, detailEmas, parseData, obj, setDataEdit, dispatch, isLoading }) => {
  const [formData, setFormData] = useState({
    namaBarangEmas: "",
    keterangan: "",
    jumlah: 0,
    karat: 0,
    beratBersih: 0,
    taksiran: 0,
    isActive: 1,
  });

  const [SelectedJenisEmas, setSelectedJenisEmas] = useState();
  const [jenisBarangOptions, setJenisBarangOptions] = useState();

  const handleOnChange = (evt) => {
    // if(evt.target.name == 'karat'){
    //     taksirHandle({
    //         karat: parseInt(evt.target.value),
    //         nilaiSTLE: SelectedJenisEmas?.detail?.harga,
    //         gram: parseFloat(formData?.beratBersih),
    //     })
    // }
    if (evt.target.name == "beratBersih") {
      setFormData({ ...formData, [evt.target.name]: evt.target.value });
    } else if (evt.target.name == "jumlah") {
      if (evt.target.value < 1) {
        setFormData({ ...formData, jumlah: 0 });
      } else {
        setFormData({ ...formData, jumlah: evt.target.value });
      }
    } else {
      setFormData({ ...formData, [evt.target.name]: evt.target.value });
    }
  };

  const handleOnChangeSelect = (evt, name) => {
    if (name == "idJenisEmas") {
      setSelectedJenisEmas(evt);

      setFormData({
        ...formData,
        idBarangEmas: evt.value,
        idJenisEmas: evt.detail.id_jenis_emas,
        namaJenisEmas: evt.detail.nama_jenis_emas,
        taksir: detailEmas.filter((v) => v.idJenisEmas == evt.detail.id_jenis_emas)[0]?.harga,
        min_karat: detailEmas.filter((v) => v.idJenisEmas == evt.detail.id_jenis_emas)[0]?.minimalKarat,
        namaBarangEmas: evt.detail.nama_barang_emas,
      });

      console.log(detailEmas.filter((v) => v.idJenisEmas == evt.detail.id_jenis_emas)[0]?.harga);

      // taksirHandle({
      //     karat: parseInt(formData?.karat),
      //     nilaiSTLE: evt?.detail?.harga,
      //     gram: parseFloat(evt.target.value),
      // })
    }
  };

  useEffect(() => {
    dispatch(setLoadingAsync(true));
    MastersApi.getBarangEmas(`?page=1&page_size=1000&isActive=1`).then((res) => {
      dispatch(setLoadingAsync(false));
      setJenisBarangOptions(Formatting.formatRcDetail(res?.data, "id_barang_emas", "kode_barang_emas", "nama_barang_emas"));
    });
  }, []);

  const [delay, setDelay] = useState(0);

  let timer;
  let waitTime = 100;

  // const hitungTaksirHandle = () => {
  //     clearTimeout(timer);

  //     // Wait for X ms and then process the request
  //     timer = setTimeout(() => {
  //  if(formData?.beratBersih && formData?.karat ){
  //     MastersApi.hitungTaksir({
  //         karat: parseInt(formData?.karat),
  //         nilaiSTLE: formData?.taksir,
  //         gram: parseFloat(formData?.beratBersih),
  //     }).then((res) => {
  //         console.log('taksir',res)
  //         setFormData({...formData, taksiran: res?.taksiran})
  //     })
  //  }
  //     }, waitTime);
  // }

  const [karat] = useDebounce(formData.karat, 1000);
  const [taksir] = useDebounce(formData?.taksir, 1000);
  const [beratBersih] = useDebounce(formData.beratBersih, 1000);

  useEffect(() => {
    if (formData?.beratBersih && formData?.karat) {
      dispatch(setLoadingAsync(true));
      MastersApi.hitungTaksir({
        karat: parseInt(formData?.karat),
        nilaiSTLE: formData?.taksir,
        gram: parseFloat(formData?.beratBersih),
      }).then((res) => {
        console.log("taksir", res);
        dispatch(setLoadingAsync(false));
        setFormData({ ...formData, taksiran: res?.taksiran });
      });
    }
  }, [karat, beratBersih, taksir]);

  useEffect(() => {
    // if(!obj){
    //     MastersApi.hitungTaksir({
    //         karat: parseInt(formData?.karat),
    //         nilaiSTLE: SelectedJenisEmas?.detail?.harga,
    //         gram: parseFloat(formData?.beratBersih),
    //     }).then((res) => {
    //         console.log('taksir',res)
    //         setFormData({...formData, taksiran: res?.taksiran})
    //     })
    // }else if(formData?.karat !== obj?.karat || formData?.beratBersih !== obj?.beratBersih || SelectedJenisEmas?.value == obj?.idJenisEmas ){
    //     console.log('tes')
    //     setFormData({...formData, taksiran: ''})
    // }
    // MastersApi.hitungTaksir({
    //     karat: parseInt(formData?.karat),
    //     nilaiSTLE: formData?.taksir,
    //     gram: parseFloat(formData?.beratBersih),
    // }).then((res) => {
    //     console.log('taksir',res)
    //     setFormData({...formData, taksiran: res?.taksiran})
    // })
    // setDelay(500)
    // Formatting.delayExecution(delay,hitungTaksirHandle())
  }, [formData?.karat, formData?.beratBersih, SelectedJenisEmas?.value]);

  // useEffect(() => {
  // },[delay])

  const hideModalHandle = () => {
    hideModal();
    setDataEdit("");
  };

  const form = [
    {
      formType: "dropdown",
      label: "Barang Emas",
      value: SelectedJenisEmas,
      name: "idJenisEmas",
      option: jenisBarangOptions,
      onChange: handleOnChangeSelect,
    },

    {
      formType: "text",
      label: "Status Antam",
      value: formData.namaJenisEmas,
      name: "namaJenisEmas",
      disabled: true,
    },
    {
      formType: "number",
      label: "Jumlah Barang",
      value: parseInt(formData.jumlah),
      name: "jumlah",
      onChange: handleOnChange,
    },
    {
      formType: "textArea",
      label: "Keterangan",
      value: formData.keterangan,
      name: "keterangan",
      onChange: handleOnChange,
    },
    {
      formType: "float",
      label: "Berat Bersih (gram)",
      value: formData.beratBersih,
      name: "beratBersih",
      readonly: isLoading ? true : false,
      onChange: handleOnChange,
    },
    {
      formType: "number",
      label: "Karat",
      value: formData.karat,
      name: "karat",
      readonly: isLoading? true : false,
      onChange: handleOnChange,
    },
    {
      formType: "rupiah",
      label: "Harga STLE",
      value: formData.taksir,
      name: "namaBarangEmas",
      disabled: true,
    },
    {
      formType: "text",
      label: "Nomor Seri",
      value: formData.nomorseri,
      name: "nomorseri",
      onChange: handleOnChange,
    },
    {
      formType: "text",
      label: "Taksir",
      value: Formatting.currency(formData.taksiran),
      name: "taksiran",
      disabled: true,
    },
  ];

  const isReadySubmit = () => {
    if (
      Formatting.checkLoopEmptyObject({
        idBarangEmas: formData.idBarangEmas,
        jumlah: formData.jumlah === "0" ? 0 : formData.jumlah,
        beratKotor: formData.beratKotor === "0" ? 0 : formData.beratKotor,
        beratBersih: formData.beratBersih === "0" ? 0 : formData.beratBersih,
        karat: formData.karat || "",
        taksir: formData.taksiran,
        keterangan: formData?.keterangan,
        // nomorseri: formData?.nomorseri
      })
    ) {
      return true;
    }

    return false;
  };

  const submit = () => {
    if (formData?.karat < formData?.min_karat) {
      Swal.fire("Warning", `Min Karat tidak boleh kurang dari ${formData?.min_karat}`, "warning");
      return;
    }
    if (formData?.karat > 24) {
      Swal.fire("Warning", `Min Karat tidak boleh Lebih dari 24`, "warning");
      return;
    }
    if (parseInt(formData?.jumlah) < 1) {
      Swal.fire("Warning", `Jumlah Barang Tidak Boleh Kosong`, "warning");
      return;
    }
    parseData(formData);
  };

  console.log("ssssss", formData);

  return (
    <Modal isOpen={show} centered size="xl" scrollable toggle={() => console.log}>
      <ModalHeader toggle={() => hideModalHandle()}>Tambah Detail Emas</ModalHeader>
      <ModalBody>
        <InputGroup formGroup={form} />
      </ModalBody>
      <ModalFooter>
        <Button color="primary" disabled={isReadySubmit()} onClick={() => submit()}>
          Simpan
        </Button>{" "}
      </ModalFooter>
    </Modal>
  );
};

export default TambahDetailModal;
