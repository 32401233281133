import {
  ADMIN,
  KA_UNIT,
  KA_UNIT_MIGRASI,
  KA_CAB,
  KA_AREA,
  KA_WIL,
  DIREKTUR,
  DIRUT,
  FINANCE,
  KASIR,
  PENAKSIR,
  KA_POS,
  ALL_MODUL_KA_UNIT,
  KA_UNIT_PENAKSIR,
  PENAKSIR_KASIR,
  PENAKSIR_KASIR_MIGRASI,
  AUDIT_STAFF,
  AUDIT_HEAD,
  STAFF_BISNIS,
  OPR_MANAGER,
  OFS,
  OPR_SPV,
  RISK,
  FINANCE_STAFF,
  ACC_HEAD,
  ACC_STAFF,
} from "utils/ConstJabatan";

export const ReportSyari = [
  //Report
  {
    title: "Report Cabang",
    akses: [ADMIN, KA_UNIT, KA_CAB, KA_AREA, KA_WIL, FINANCE, DIREKTUR, DIRUT, KASIR, PENAKSIR, KA_POS, ALL_MODUL_KA_UNIT, KA_UNIT_PENAKSIR, PENAKSIR_KASIR, AUDIT_STAFF, AUDIT_HEAD, OPR_MANAGER, OFS, OPR_SPV, RISK, ACC_HEAD, ACC_STAFF],
    submenu: [
      {
        title: "Laporan Kas Harian",
        link: "/report/cabang/laporan-kas-harian",
        akses: [ADMIN, KA_UNIT, KA_CAB, KA_AREA, KA_WIL, FINANCE, DIREKTUR, DIRUT, KASIR, PENAKSIR, KA_POS, ALL_MODUL_KA_UNIT, KA_UNIT_PENAKSIR, PENAKSIR_KASIR, AUDIT_STAFF, AUDIT_HEAD, OPR_MANAGER, OFS, OPR_SPV, RISK],
      },
      {
        title: "Laporan Mutasi Bank",
        link: "/report/cabang/laporan-mutasi-bank",
        akses: [ADMIN, KA_UNIT, KA_CAB, KA_AREA, KA_WIL, FINANCE, DIREKTUR, DIRUT, KASIR, PENAKSIR, KA_POS, ALL_MODUL_KA_UNIT, KA_UNIT_PENAKSIR, PENAKSIR_KASIR, AUDIT_STAFF, AUDIT_HEAD, OPR_MANAGER, OFS, OPR_SPV, RISK, ACC_HEAD, ACC_STAFF],
      },
      {
        title: "Laporan Booking",
        akses: [ADMIN, KA_UNIT, KA_CAB, KA_AREA, KA_WIL, FINANCE, DIREKTUR, DIRUT, KASIR, PENAKSIR, KA_POS, ALL_MODUL_KA_UNIT, KA_UNIT_PENAKSIR, PENAKSIR_KASIR, AUDIT_STAFF, AUDIT_HEAD, OPR_MANAGER, OFS, OPR_SPV, RISK],
        link: "/report/cabang/laporan-booking",
      },
      {
        title: "Laporan Outstanding",
        akses: [ADMIN, KA_UNIT, KA_CAB, KA_AREA, KA_WIL, FINANCE, DIREKTUR, DIRUT, KASIR, PENAKSIR, KA_POS, ALL_MODUL_KA_UNIT, KA_UNIT_PENAKSIR, PENAKSIR_KASIR, AUDIT_STAFF, AUDIT_HEAD, OPR_MANAGER, OFS, OPR_SPV, RISK],
        link: "/report/cabang/laporan-outstanding",
      },
      {
        title: "Laporan Pelunasan",
        akses: [ADMIN, KA_UNIT, KA_CAB, KA_AREA, KA_WIL, FINANCE, DIREKTUR, DIRUT, KASIR, PENAKSIR, KA_POS, ALL_MODUL_KA_UNIT, KA_UNIT_PENAKSIR, PENAKSIR_KASIR, AUDIT_STAFF, AUDIT_HEAD, OPR_MANAGER, OFS, OPR_SPV, RISK],
        link: "/report/cabang/laporan-pelunasan",
      },
      {
        title: "Laporan Saldo Kas Bank",
        akses: [ADMIN, KA_UNIT, KA_CAB, KA_AREA, KA_WIL, FINANCE, DIREKTUR, DIRUT, KASIR, PENAKSIR, KA_POS, ALL_MODUL_KA_UNIT, KA_UNIT_PENAKSIR, PENAKSIR_KASIR, AUDIT_STAFF, AUDIT_HEAD, OPR_MANAGER, OFS, OPR_SPV, RISK, ACC_HEAD, ACC_STAFF],
        link: "/report/cabang/laporan-saldo-kas-bank",
      },
      {
        title: "Laporan Penjualan",
        akses: [ADMIN, KA_UNIT, KA_CAB, KA_AREA, KA_WIL, FINANCE, DIREKTUR, DIRUT, KASIR, PENAKSIR, KA_POS, ALL_MODUL_KA_UNIT, KA_UNIT_PENAKSIR, PENAKSIR_KASIR, AUDIT_STAFF, AUDIT_HEAD, OPR_MANAGER, OFS, OPR_SPV, RISK],
        link: "/report/cabang/laporan-penjualan",
      },
      {
        title: "Laporan Barang Jaminan",
        akses: [ADMIN, KA_UNIT, KA_CAB, KA_AREA, KA_WIL, FINANCE, DIREKTUR, DIRUT, KASIR, PENAKSIR, KA_POS, ALL_MODUL_KA_UNIT, KA_UNIT_PENAKSIR, PENAKSIR_KASIR, AUDIT_STAFF, AUDIT_HEAD, OPR_MANAGER, OFS, OPR_SPV, RISK],
        link: "/report/cabang/laporan-barang-jaminan",
      },
      {
        title: "Laporan AZ",
        akses: [ADMIN, AUDIT_HEAD, OPR_MANAGER, OFS, OPR_SPV, RISK],
        link: "/report/cabang/laporan-az",
      },
      // {
      //   title: "Laporan Performance Cabang Harian",
      //   akses: [ADMIN, KA_UNIT, KA_CAB, KA_AREA, KA_WIL,FINANCE, DIREKTUR, DIRUT, KASIR, PENAKSIR, KA_POS, ALL_MODUL_KA_UNIT, KA_UNIT_PENAKSIR, PENAKSIR_KASIR,AUDIT_STAFF,AUDIT_HEAD, OPR_MANAGER, OFS, OPR_SPV, RISK],
      //   link: "/report/cabang/laporan-performance-cabang-harian"
      // },
      {
        title: "Laporan Ovd Customer",
        akses: [ADMIN, KA_UNIT, KA_CAB, KA_AREA, KA_WIL, FINANCE, DIREKTUR, DIRUT, KASIR, PENAKSIR, KA_POS, ALL_MODUL_KA_UNIT, KA_UNIT_PENAKSIR, PENAKSIR_KASIR, AUDIT_STAFF, AUDIT_HEAD, OPR_MANAGER, OFS, OPR_SPV, RISK],
        link: "/report/cabang/laporan-ovd-customer",
      },
      {
        title: "Laporan Penjualan Gadai",
        akses: [ADMIN, KA_UNIT, KA_CAB, KA_AREA, KA_WIL, FINANCE, DIREKTUR, DIRUT, KASIR, PENAKSIR, KA_POS, ALL_MODUL_KA_UNIT, KA_UNIT_PENAKSIR, PENAKSIR_KASIR, AUDIT_STAFF, AUDIT_HEAD, OPR_MANAGER, OFS, OPR_SPV, RISK],
        link: "/report/cabang/laporan-penjualan-gadai",
      },
      {
        title: "Laporan Buffer Harian",
        akses: [ADMIN, KA_UNIT, KA_CAB, KA_AREA, KA_WIL, FINANCE, DIREKTUR, DIRUT, KASIR, PENAKSIR, KA_POS, ALL_MODUL_KA_UNIT, KA_UNIT_PENAKSIR, PENAKSIR_KASIR, AUDIT_STAFF, AUDIT_HEAD, OPR_MANAGER, OFS, OPR_SPV, RISK],
        // akses: [ADMIN, KA_UNIT, KA_UNIT_MIGRASI, KA_CAB, KA_AREA, KA_WIL,FINANCE, DIREKTUR, DIRUT, KASIR, PENAKSIR, KA_POS, ALL_MODUL_KA_UNIT, KA_UNIT_PENAKSIR, PENAKSIR_KASIR, PENAKSIR_KASIR_MIGRASI,AUDIT_STAFF,AUDIT_HEAD, OPR_MANAGER, OPR_SPV, OFS, RISK],
        link: "/report/cabang/laporan-buffer-harian",
      },
      {
        title: "Laporan Buffer Libur",
        akses: [ADMIN, KA_UNIT, KA_CAB, KA_AREA, KA_WIL, FINANCE, DIREKTUR, DIRUT, KASIR, PENAKSIR, KA_POS, ALL_MODUL_KA_UNIT, KA_UNIT_PENAKSIR, PENAKSIR_KASIR, AUDIT_STAFF, AUDIT_HEAD, OPR_MANAGER, OFS, OPR_SPV, RISK],
        // akses: [ADMIN, KA_UNIT, KA_UNIT_MIGRASI, KA_CAB, KA_AREA, KA_WIL,FINANCE, DIREKTUR, DIRUT, KASIR, PENAKSIR, KA_POS, ALL_MODUL_KA_UNIT, KA_UNIT_PENAKSIR, PENAKSIR_KASIR, PENAKSIR_KASIR_MIGRASI,AUDIT_STAFF,AUDIT_HEAD, OPR_MANAGER, OPR_SPV, OFS, RISK],
        link: "/report/cabang/laporan-buffer-libur",
      },
      {
        title: "Laporan Buffer Takeover",
        akses: [ADMIN, KA_UNIT, KA_CAB, KA_AREA, KA_WIL, FINANCE, DIREKTUR, DIRUT, KASIR, PENAKSIR, KA_POS, ALL_MODUL_KA_UNIT, KA_UNIT_PENAKSIR, PENAKSIR_KASIR, AUDIT_STAFF, AUDIT_HEAD, OPR_MANAGER, OFS, OPR_SPV, RISK],
        // akses: [ADMIN, KA_UNIT, KA_UNIT_MIGRASI, KA_CAB, KA_AREA, KA_WIL,FINANCE, DIREKTUR, DIRUT, KASIR, PENAKSIR, KA_POS, ALL_MODUL_KA_UNIT, KA_UNIT_PENAKSIR, PENAKSIR_KASIR, PENAKSIR_KASIR_MIGRASI,AUDIT_STAFF,AUDIT_HEAD, OPR_MANAGER, OPR_SPV, OFS, RISK],
        link: "/report/cabang/laporan-buffer-takeover",
      },
      {
        title: "Laporan Buffer Walk In",
        akses: [ADMIN, KA_UNIT, KA_CAB, KA_AREA, KA_WIL, FINANCE, DIREKTUR, DIRUT, KASIR, PENAKSIR, KA_POS, ALL_MODUL_KA_UNIT, KA_UNIT_PENAKSIR, PENAKSIR_KASIR, AUDIT_STAFF, AUDIT_HEAD, OPR_MANAGER, OFS, OPR_SPV, RISK],
        // akses: [ADMIN, KA_UNIT, KA_UNIT_MIGRASI, KA_CAB, KA_AREA, KA_WIL,FINANCE, DIREKTUR, DIRUT, KASIR, PENAKSIR, KA_POS, ALL_MODUL_KA_UNIT, KA_UNIT_PENAKSIR, PENAKSIR_KASIR, PENAKSIR_KASIR_MIGRASI,AUDIT_STAFF,AUDIT_HEAD, OPR_MANAGER, OPR_SPV, OFS, RISK],
        link: "/report/cabang/laporan-buffer-walkin",
      },
      {
        title: "Laporan Potensi Top UP",
        akses: [ADMIN, KA_UNIT, KA_CAB, KA_AREA, KA_WIL,FINANCE, DIREKTUR, DIRUT, KASIR, PENAKSIR, KA_POS, ALL_MODUL_KA_UNIT, KA_UNIT_PENAKSIR, PENAKSIR_KASIR,AUDIT_STAFF,AUDIT_HEAD, OPR_MANAGER, OPR_SPV, OFS, RISK],
        link: "/report/cabang/laporan-potensi-topup"
      }, 
      {
        title: "Laporan Penjualan Barang Jaminan",
        akses: [ADMIN, AUDIT_HEAD, AUDIT_STAFF, KA_WIL, KA_CAB, OFS, KA_UNIT, KA_UNIT_MIGRASI, KASIR, PENAKSIR, PENAKSIR_KASIR, PENAKSIR_KASIR_MIGRASI, KA_UNIT_PENAKSIR],
        link: "/report/cabang/laporan-penjualan-barang-jaminan"
      },
    ],
  },
  {
    title: "Report HO",
    akses: [ADMIN, AUDIT_HEAD, AUDIT_STAFF, STAFF_BISNIS, OPR_MANAGER, OFS, OPR_SPV, RISK, FINANCE, FINANCE_STAFF, ACC_HEAD, ACC_STAFF, KA_WIL],
    submenu: [
      {
        title: "Laporan Booking",
        akses: [ADMIN, AUDIT_HEAD, AUDIT_STAFF, STAFF_BISNIS, OPR_MANAGER, OFS, OPR_SPV, RISK, FINANCE, FINANCE_STAFF, KA_WIL, ACC_HEAD, ACC_STAFF],
        link: "/report/ho/laporan-booking",
      },
      {
        title: "Laporan Outstanding",
        akses: [ADMIN, AUDIT_HEAD, AUDIT_STAFF, STAFF_BISNIS, OPR_MANAGER, OFS, OPR_SPV, RISK, FINANCE, FINANCE_STAFF, KA_WIL],
        link: "/report/ho/laporan-outstanding",
      },
      {
        title: "Laporan Pelunasan",
        akses: [ADMIN, AUDIT_HEAD, AUDIT_STAFF, STAFF_BISNIS, OPR_MANAGER, OFS, OPR_SPV, RISK, FINANCE, FINANCE_STAFF],
        link: "/report/ho/laporan-pelunasan",
      },
      {
        title: "Laporan Kas Bank Wilayah",
        akses: [ADMIN, AUDIT_HEAD, AUDIT_STAFF, STAFF_BISNIS, OPR_MANAGER, OFS, OPR_SPV, RISK, FINANCE, FINANCE_STAFF, ACC_HEAD, ACC_STAFF, KA_WIL],
        link: "/report/cabang/laporan-kas-bank-wilayah",
      },
      {
        title: "Laporan Barang Jaminan",
        akses: [ADMIN, AUDIT_HEAD, AUDIT_STAFF, STAFF_BISNIS, OPR_MANAGER, OFS, OPR_SPV, RISK, FINANCE, FINANCE_STAFF, KA_WIL],
        link: "/report/ho/laporan-barang-jaminan",
      },
      {
        title: "Laporan Summary BAP",
        akses: [ADMIN, AUDIT_HEAD, AUDIT_STAFF, STAFF_BISNIS, OPR_MANAGER, OFS, OPR_SPV, RISK, FINANCE, FINANCE_STAFF],
        link: "/report/ho/laporan-summary-bap",
      },
      {
        title: "Laporan Detail BAP",
        akses: [ADMIN, AUDIT_HEAD, AUDIT_STAFF, STAFF_BISNIS, OPR_MANAGER, OFS, OPR_SPV, RISK, FINANCE, FINANCE_STAFF],
        link: "/report/ho/laporan-detail-bap",
      },
      {
        title: "Laporan Booking Summary",
        akses: [ADMIN, AUDIT_HEAD, AUDIT_STAFF, STAFF_BISNIS, OPR_MANAGER, OFS, OPR_SPV, RISK, FINANCE, FINANCE_STAFF],
        link: "/report/ho/laporan-booking-summary",
      },
      {
        title: "Laporan Direksi",
        akses: [ADMIN, AUDIT_HEAD, AUDIT_STAFF, STAFF_BISNIS, OPR_MANAGER, OFS, OPR_SPV, RISK, FINANCE, FINANCE_STAFF, ACC_HEAD, ACC_STAFF],
        link: "/report/ho/laporan-direksi",
      },
      {
        title: "Laporan Penjualan Gadai",
        akses: [ADMIN, AUDIT_HEAD, AUDIT_STAFF, STAFF_BISNIS, OPR_MANAGER, OFS, OPR_SPV, RISK, FINANCE, FINANCE_STAFF, KA_WIL],
        link: "/report/ho/laporan-penjualan-gadai",
      },
      {
        title: "Laporan Buffer Harian",
        akses: [ADMIN, KA_UNIT, KA_CAB, OFS, FINANCE, FINANCE_STAFF, KA_WIL],
        // akses: [ADMIN, KA_UNIT, KA_UNIT_MIGRASI, KA_CAB, KA_AREA, KA_WIL,FINANCE, DIREKTUR, DIRUT, KASIR, PENAKSIR, KA_POS, ALL_MODUL_KA_UNIT, KA_UNIT_PENAKSIR, PENAKSIR_KASIR, PENAKSIR_KASIR_MIGRASI,AUDIT_STAFF,AUDIT_HEAD, OPR_MANAGER, OPR_SPV, OFS, RISK],
        link: "/report/ho/laporan-buffer-harian",
      },
      {
        title: "Laporan Buffer Libur",
        akses: [ADMIN, KA_UNIT, KA_CAB, OFS, FINANCE, FINANCE_STAFF, KA_WIL],
        // akses: [ADMIN, KA_UNIT, KA_UNIT_MIGRASI, KA_CAB, KA_AREA, KA_WIL,FINANCE, DIREKTUR, DIRUT, KASIR, PENAKSIR, KA_POS, ALL_MODUL_KA_UNIT, KA_UNIT_PENAKSIR, PENAKSIR_KASIR, PENAKSIR_KASIR_MIGRASI,AUDIT_STAFF,AUDIT_HEAD, OPR_MANAGER, OPR_SPV, OFS, RISK],
        link: "/report/ho/laporan-buffer-libur",
      },
      {
        title: "Laporan Buffer Takeover",
        akses: [ADMIN, KA_UNIT, KA_CAB, OFS, FINANCE, FINANCE_STAFF, KA_WIL],
        // akses: [ADMIN, KA_UNIT, KA_UNIT_MIGRASI, KA_CAB, KA_AREA, KA_WIL,FINANCE, DIREKTUR, DIRUT, KASIR, PENAKSIR, KA_POS, ALL_MODUL_KA_UNIT, KA_UNIT_PENAKSIR, PENAKSIR_KASIR, PENAKSIR_KASIR_MIGRASI,AUDIT_STAFF,AUDIT_HEAD, OPR_MANAGER, OPR_SPV, OFS, RISK],
        link: "/report/ho/laporan-buffer-takeover",
      },
      {
        title: "Laporan Buffer Walk In",
        akses: [ADMIN, KASIR, KA_UNIT, KA_CAB, OFS, FINANCE, FINANCE_STAFF, KA_WIL],
        // akses: [ADMIN, KA_UNIT, KA_UNIT_MIGRASI, KA_CAB, KA_AREA, KA_WIL,FINANCE, DIREKTUR, DIRUT, KASIR, PENAKSIR, KA_POS, ALL_MODUL_KA_UNIT, KA_UNIT_PENAKSIR, PENAKSIR_KASIR, PENAKSIR_KASIR_MIGRASI,AUDIT_STAFF,AUDIT_HEAD, OPR_MANAGER, OPR_SPV, OFS, RISK],
        link: "/report/ho/laporan-buffer-walkin",
      },
      {
        title: "Laporan Ap Transfer",
        akses: [ADMIN, AUDIT_HEAD, AUDIT_STAFF, OPR_MANAGER, OPR_SPV, OFS, RISK, FINANCE, FINANCE_STAFF, ACC_HEAD, ACC_STAFF],
        // akses: [ADMIN, KA_UNIT, KA_UNIT_MIGRASI, KA_CAB, KA_AREA, KA_WIL,FINANCE, DIREKTUR, DIRUT, KASIR, PENAKSIR, KA_POS, ALL_MODUL_KA_UNIT, KA_UNIT_PENAKSIR, PENAKSIR_KASIR, PENAKSIR_KASIR_MIGRASI,AUDIT_STAFF,AUDIT_HEAD, OPR_MANAGER, OPR_SPV, OFS, RISK],
        link: "/report/ho/laporan-ap-transfer",
      },
      {
        title: "Laporan Penjualan Barang Jaminan",
        akses: [ADMIN, AUDIT_HEAD, AUDIT_STAFF, OPR_MANAGER, OPR_SPV, OFS, RISK, FINANCE, FINANCE_STAFF, ACC_HEAD, ACC_STAFF],
        // akses: [ADMIN, KA_UNIT, KA_UNIT_MIGRASI, KA_CAB, KA_AREA, KA_WIL,FINANCE, DIREKTUR, DIRUT, KASIR, PENAKSIR, KA_POS, ALL_MODUL_KA_UNIT, KA_UNIT_PENAKSIR, PENAKSIR_KASIR, PENAKSIR_KASIR_MIGRASI,AUDIT_STAFF,AUDIT_HEAD, OPR_MANAGER, OPR_SPV, OFS, RISK],
        link: "/report/ho/laporan-penjualan-barang-jaminan",
      },
      // {
      //   title: "Laporan Average OS",
      //   akses: [ADMIN, AUDIT_HEAD, AUDIT_STAFF, STAFF_BISNIS, OPR_MANAGER, OFS, OPR_SPV, RISK, FINANCE, FINANCE_STAFF],
      //   link: "/report/ho/laporan-average-os"
      // },

      // {
      //   title: "Laporan OS VS Target OS",
      //   akses: [ADMIN, AUDIT_HEAD, AUDIT_STAFF, STAFF_BISNIS, OPR_MANAGER, OFS, OPR_SPV, RISK, FINANCE, FINANCE_STAFF],
      //   link: "/report/ho/laporan-os-vs-target-os"
      // },
      // {
      //   title: "Laporan Booking Detail",
      //   akses: [ADMIN, AUDIT_HEAD, AUDIT_STAFF, STAFF_BISNIS, OPR_MANAGER, OFS, OPR_SPV, RISK, FINANCE, FINANCE_STAFF],
      //   link: "/report/ho/laporan-booking-detail"
      // },
    ],
  },
  {
    title: "Tools",
    akses: [ADMIN, KA_UNIT, KA_CAB, KA_AREA, KA_WIL, FINANCE, DIREKTUR, DIRUT, KASIR, PENAKSIR, KA_POS, ALL_MODUL_KA_UNIT, KA_UNIT_PENAKSIR, PENAKSIR_KASIR, AUDIT_STAFF, AUDIT_HEAD, OPR_MANAGER, OFS, OPR_SPV, RISK],
    submenu: [
      {
        title: "Merge Excel",
        akses: [ADMIN, KA_UNIT, KA_CAB, KA_AREA, KA_WIL, FINANCE, DIREKTUR, DIRUT, KASIR, PENAKSIR, KA_POS, ALL_MODUL_KA_UNIT, KA_UNIT_PENAKSIR, PENAKSIR_KASIR, AUDIT_STAFF, AUDIT_HEAD, OPR_MANAGER, OFS, OPR_SPV, RISK],
        link: "/report/tools/merge-excel",
      },
    ],
  },
];
