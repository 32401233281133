import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import DataTable from 'react-data-table-component';
import Swal from 'sweetalert2';
import MastersApi from "services/masters/masters.api";
import { setLoadingAsync } from "services/globals/loading.reducer"
import './FullfillBufferLibur.css'
import Formatting from "utils/formattingJson";
import dayjs from "dayjs";
import { customStylesPageOnly } from "components/CustomStyles/CustomStylesPageOnly";
import InputGroup from "components/GroupInput/InputGroup";
import { KA_UNIT } from "utils/ConstJabatan";
import ReactSelect from "components/Select/Select";
import PettyCashHistoryTable from "components/HistoryTable/PettyCashHistoryTable";
import ModalViewTrx from "./ModalViewTrx"
import HelperFunction from "utils/HelperFunctions";


const History = ({ history, dispatch, isLoading, isProfile }) => {

  const [wilayahOptions, setWilayahOptions] = useState()
  const [selectedWilayah, setSelectedWilayah] = useState(null)

  const [cabangOptions, setCabangOptions] = useState()
  const [selectedCabang, setSelectedCabang] = useState(null)

  const [unitOptions, setUnitOptions] = useState()
  const [selectedUnit, setSelectedUnit] = useState(null)
  const [modalView, setModalView] = useState(false)
  const [dataEdit, setDataEdit] = useState(null);
  const [search, setSearch] = useState({
    tglAwal: "",
    tglAkhir: "",

  });

  const [searchOpen, setSearchOpen] = useState(true);

 

  const [paramsFetch, setParamsFetch] = useState();

  useEffect(() => {
    // dispatch(setLoadingAsync(true));
    dispatch(setLoadingAsync(true));
    fetchData().then((res) => {
      res?.master_cabang_petty_cash?.map((x) => {
        x.master_detail_petty_cash = res?.master_detail_petty_cash?.filter((y) => y?.id_buffer_petty_cash === x?.id_buffer_petty_cash)
      })
      console.log("test",res)
      setParamsFetch(res);
      dispatch(setLoadingAsync(false));
    })
  }, [])

  const fetchData = (page = 1, page_size = 10, idWilayah = selectedWilayah?.value, idCabang = selectedCabang?.value, idUnit = selectedUnit?.value) => {
    return MastersApi.getFullFillPettyCash(`?page=${page}&page_size=${page_size}&tgl_awal=${search?.tglAwal}&tgl_akhir=${search?.tglAkhir}`);
  }

  const onClickSearch = () => {
    dispatch(setLoadingAsync(true));
    fetchData(1, 10).then((res) => {
      res?.master_cabang_petty_cash?.map((x) => {
        x.master_detail_petty_cash = res?.master_detail_petty_cash?.filter((y) => y?.id_buffer_petty_cash === x?.id_buffer_petty_cash)
      })
      setParamsFetch(res);
      dispatch(setLoadingAsync(false));
    }).catch((err) => {
      dispatch(setLoadingAsync(false));
      Swal.fire(
        'Maaf',
        err.response.data.Response.errorMessage,
        'error'
      );
    })
  }


  const onChangeDate = (v) => {
    setSearch({ ...search, [v.target.name]: v.target.value })
  }

  const onChangePaginationHis = (v) => {
    setParamsFetch({ ...paramsFetch, page: v });
    dispatch(setLoadingAsync(true));
    fetchData(v, paramsFetch?.page_size).then((res) => {
      setParamsFetch(res);
      dispatch(setLoadingAsync(false));
    }).catch((err) => {
      dispatch(setLoadingAsync(false));
      Swal.fire(
        'Maaf',
        err.response.data.Response.errorMessage,
        'error'
      );
    })
  }

  const onChangePageSizeHis = (v) => {
    setParamsFetch({ ...paramsFetch, page_size: v });
    dispatch(setLoadingAsync(true));
    fetchData(1, v).then((res) => {
      setParamsFetch(res);
      dispatch(setLoadingAsync(false));
    }).catch((err) => {
      dispatch(setLoadingAsync(false));
      Swal.fire(
        'Maaf',
        err.response.data.Response.errorMessage,
        'error'
      );
    })
  }

  const cetakKwitansi = (v) => {
    HelperFunction.windowOpen(v?.file_kwitansi)
  }


  return (
    <>
      {modalView && <ModalViewTrx dispatch={dispatch} obj={dataEdit} title="History Petty Cash" hideModal={() => setModalView(!modalView)} show={modalView} />}

     
      <div className="row table-search mb-0">
          <span className="txt-body hand-click" onClick={() => setSearchOpen(!searchOpen)}>{searchOpen ? `-` : `+`} Search </span>
          <hr className="mt-2" />
        </div>
        <div className="row table-search-body" style={{ display: searchOpen ? "" : "none" }}>
          <div className="row mt-2">
            <div className="col-1">
              <span className="txt-body" >Tgl Awal</span>
            </div>
            <div className="col-6 col-md-3">
              <input className="input-table" type="date" value={search?.tglAwal} name="tglAwal" onChange={onChangeDate}></input>
            </div>
            <div className="col-1">
              <span className="txt-body" >Tgl Akhir</span>
            </div>
            <div className="col-6 col-md-3">
              <input className="input-table" type="date" value={search?.tglAkhir} name="tglAkhir" onChange={onChangeDate}></input>
            </div>
          </div>

          <div className="col-12">
            <button className="btn-table mt-2" onClick={onClickSearch}>View</button>
          </div>
        </div>

      <div className="col-12 table-bordered mt-4">
        <table width={"100%"} style={{textAlign: "center"}}>
          <tr>
            <th>No</th>
            <th>No Voucher</th>
            <th>Tanggal Voucher</th>
            <th>Kode Unit</th>
            <th>Nama Unit</th>
            <th>Nominal Transaksi</th>
            <th>Jenis Transaksi</th>
            <th>Status</th>
            <th>Action</th>

          </tr>

          {paramsFetch?.data?.master_cabang_petty_cash?.map((v, i) => (
            <tr>
              <td>{(i + 1)}</td>
              <td className="hand-click text-primary" onClick={() => { setDataEdit(v); setModalView(true) }} > {!v?.no_voucher ? "-" : v.no_voucher} </td>
              <td>{!v?.tanggal_voucher ? "-" : dayjs(v?.tanggal_voucher).format("YYYY/MM/DD")}</td>
              <td>{!v?.kode_unit ? "-" : v?.kode_unit}</td>
              <td>{!v?.nama_unit ? "-" : v?.nama_unit}</td>
              <td>{v?.total_pengajuan ? Formatting.currency(v?.total_pengajuan) : "-"}</td>
              <td>Petty Cash</td>
              <td>{v?.is_status === 0 ? "Need Approval Kaunit" : v?.is_status === 1 ? "Done" : v?.is_status === 9 ?  "Need Approval Kacab"  : v?.is_status === 10 ? "Need Approval Kawil" : v?.is_status === 11 ? "Checking BFO" : v?.is_status === 12 ? "Request FullFill" : v?.is_status === 13 && v?.is_transfer === 1  ? "Dropping Finance" : v?.is_status === 14  ? "Dropping BFO" :  v?.is_status === 3 & v?.is_reversal === 1 ? "Reversal" :  v?.is_status === 3 & v?.is_reversal === 2 ? "Reversal By Sistem"  : "Reject"}</td>              <td><div><button style={{ display: v?.is_status === 1 ? "" : "none" }} className="border-gray" onClick={() => cetakKwitansi(v)}>Cetak Kwitansi</button></div></td>
            </tr>
          ))}

        </table>
      </div>
      <DataTable
  data={paramsFetch?.data?.master_cabang_petty_cash}
  customStyles={customStylesPageOnly}
  pagination
  dense
  persistTableHead
  paginationServer
  paginationTotalRows={paramsFetch?.total}
  onChangeRowsPerPage={onChangePageSizeHis}
  onChangePage={onChangePaginationHis}
/>
    </>
  );
}

const mapStateToProps = function (state) {

  return {
    isLoading: state.global.isLoading,
    isProfile: state.profile
  };
};

export default connect(mapStateToProps)(History);