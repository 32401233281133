import { HmacSHA256 } from "crypto-js";
import dayjs from "dayjs";
import cloneDeep from "lodash.clonedeep";
import "../styles/css/custom.css";
var CurrencyFormat = require("react-currency-format");
var Buffer = require('buffer/').Buffer  
const jwt = require('jwt-encode');

const Formatting = {
  /**
   * @param {*} dataJson is data from api need to formatting for React Select.
   */
  formatRCRegist: function (dataJson, keyValue) {
    let tempJobs = [];
    if (dataJson) {
      dataJson.forEach((v) => {
        tempJobs.push({
          label: v["keterangan"] || "-",
          value: v[keyValue],
        });
      });
    }

    return tempJobs;
  },
  formatRC2: function (dataJson, keyValue, keyLabel) {

    let tempJobs = [];
    if (dataJson) {
      dataJson.forEach((v) => {
        tempJobs.push({
          label: v[keyLabel] || "-",
          value: v[keyValue],
        });
      });
    }

    return tempJobs;
  },
  formatRC: function (dataJson, keyValue, keyLabel, nullValue = "") {

    let tempJobs = [];
    if (dataJson) {
      tempJobs.push({ label: 'Pilih', value: nullValue });
      dataJson.forEach((v) => {
        tempJobs.push({
          label: v[keyLabel] || "-",
          value: v[keyValue],
        });
      });
    }

    return tempJobs;
  },
  formatNPWP: function (value) {

  },
  formatRCDouble: function (dataJson, keyValue, keyLabel, keyLabel2) {
    let tempJobs = [];
    if (dataJson) {
      dataJson.forEach((v) => {
        tempJobs.push({
          label: v[keyLabel] + " - " + v[keyLabel2] || "-",
          value: v[keyValue],
          detailLabel: v[keyLabel],
          detailLabel2: v[keyLabel2]
        });
      });
    }

    return tempJobs;
  },
  formatRcDetail: function (dataJson, keyValue, keyLabel, keyLabel2) {
    let tempJobs = [];
    if (dataJson) {
      dataJson.forEach((v) => {
        tempJobs.push({
          label: v[keyLabel] + " - " + v[keyLabel2] || "-",
          value: v[keyValue],
          detail: v
        });
      });
    }

    return tempJobs;
  },
  formatRcDetail2: function (dataJson, keyValue, keyLabel, keyLabel2) {
    let tempJobs = [];
    if (dataJson) {
      dataJson.forEach((v) => {
        tempJobs.push({
          label: v[keyLabel] || "-",
          value: v[keyValue],
          detail: v
        });
      });
    }

    return tempJobs;
  },
  formatRCDouble2: function (dataJson, keyValue, keyLabel, keyLabel2) {
    let tempJobs = [];
    if (dataJson) {
      dataJson.forEach((v) => {
        tempJobs.push({
          label: v[keyLabel] || "-",
          value: v[keyValue],
          detailLabel: v[keyLabel],
          detailLabel2: v[keyLabel2]
        });
      });
    }

    return tempJobs;
  },
  formatRCGrade: function (dataJson, keyValue, keyLabel, kode_grade, Jenis_outlet) {
    let tempJobs = [];
    if (dataJson) {
      dataJson.forEach((v) => {
        tempJobs.push({
          label: `${v[kode_grade]} - ${v[keyLabel]}` || "-",
          value: v[keyValue],
          kode_grade: v[kode_grade],
          jenis_outlet: v[Jenis_outlet]
        });
      });
    }

    return tempJobs;
  },
  formatRCBank: function (dataJson, keyValue, keyLabel, keyLabel2, id_bank) {
    let tempJobs = [];
    if (dataJson) {
      dataJson.forEach((v) => {
        tempJobs.push({
          label: v[keyLabel] + " - " + v[keyLabel2] || "-",
          value: v[keyValue],
          id_bank: v[id_bank],
          nama_bank: v[keyLabel2]
        });
      });
    }

    return tempJobs;
  },
  isJson: function (str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  },
  // formatRCRegistPekerjaan: function (dataJson) {
  //   let tempJobs = [];
  //   dataJson.map((v, i) => {
  //     return tempJobs.push({ label: v.keterangan, value: v.idPekerjaan });;
  //   });
  //   return tempJobs;
  // },
  // formatRCRegistProvinsi: function (dataJson) {
  //   let tempJobs = [];
  //   dataJson.map((v, i) => {
  //     return tempJobs.push({ label: v.keterangan, value: v.idPropinsi });;
  //   });
  //   return tempJobs;
  // },
  // formatRCRegistKota: function (dataJson) {
  //   let tempJobs = [];
  //   dataJson.map((v, i) => {
  //     return tempJobs.push({ label: v.keterangan, value: v.idKota });;
  //   });
  //   return tempJobs;
  // },
  currency: function (value, isInputFormat = false, isNull = false) {

    if (!value) {
      if (value === "" && isNull) {
        return "";
      }
      return 0;
    }

    if (isInputFormat) {
      var angka = value.toString();
      if (value.toString().substr(0, 1) === "0") {
        angka = value.toString().substr(1);
      }
      var number_string = angka.replace(/[^,\d]/g, "").toString(),
        split = number_string.split(","),
        sisa = split[0].length % 3,
        rupiah = split[0].substr(0, sisa),
        ribuan = split[0].substr(sisa).match(/\d{3}/gi);

      if (ribuan) {
        var separator = sisa ? "." : "";
        rupiah += separator + ribuan.join(".");
      }

      rupiah = split[1] !== undefined ? rupiah + "," + split[1] : rupiah;


      if (value.toString().includes('-')) {
        rupiah = '-' + rupiah
      }

      return rupiah;
      // return new Intl.NumberFormat({
      //   style: "currency",
      // }).format(value);
    }

    const sparateDecimalNumber = (value + "").split(".");
    // console.log("sparateDecimalNumber[1]", sparateDecimalNumber[1]);
    let isHaveDecimal = sparateDecimalNumber[1] || false;

    if (!isHaveDecimal) {
      var number_strings = value
        .toString()
        .replace(/[^,\d]/g, "")
        .toString(),
        splits = number_strings.split(","),
        sisaa = splits[0].length % 3,
        rupiahh = splits[0].substr(0, sisaa),
        ribuann = splits[0].substr(sisaa).match(/\d{3}/gi);

      if (ribuann) {
        var separatorr = sisaa ? "." : "";
        rupiahh += separatorr + ribuann.join(".");
      }

      rupiahh = splits[1] !== undefined ? rupiahh + "," + splits[1] : rupiahh;

      if (value.toString().includes('-')) {
        rupiahh = '-' + rupiahh
      }

      return rupiahh;

      // return new Intl.NumberFormat({ style: "currency" }).format(
      //   sparateDecimalNumber[0]
      // );
    }

    return (
      <span>
        {" "}
        {
          <CurrencyFormat
            value={sparateDecimalNumber[0]}
            displayType={"text"}
            thousandSeparator={true}
          />
        }
        {"."}
        <sub> {sparateDecimalNumber[1]} </sub>{" "}
      </span>
    );
  },
  currency2: function (value, isNull = false) {
    if (!value) {
      if (isNull) {
        return "";
      }
      return 0;
    }

    const sparateDecimalNumber = (value + "").split(".");
    // console.log("sparateDecimalNumber[1]", sparateDecimalNumber[1]);
    let isHaveDecimal = sparateDecimalNumber[1] || false;

    if (!isHaveDecimal) {
      return (
        <span>
          {" "}
          {
            <CurrencyFormat
              value={sparateDecimalNumber[0]}
              displayType={"text"}
              thousandSeparator={true}
            />
          }{" "}
        </span>
      );
    }

    return (
      <span>
        {" "}
        {
          <CurrencyFormat
            value={sparateDecimalNumber[0]}
            displayType={"text"}
            thousandSeparator={true}
          />
        }
        {"."}
        <sub> {sparateDecimalNumber[1].substring(0, 2)} </sub>{" "}
      </span>
    );
  },
  currencyProduk: function (value) {
    if (!value) {
      return "0,00";
    }

    const sparateDecimalNumber = (value + "").split(".");
    // console.log("sparateDecimalNumber[1]", sparateDecimalNumber[1]);
    let isHaveDecimal = sparateDecimalNumber[1] || false;

    if (!isHaveDecimal) {
      var number_strings = value
        .toString()
        .replace(/[^,\d]/g, "")
        .toString(),
        splits = number_strings.split(","),
        sisaa = splits[0].length % 3,
        rupiahh = splits[0].substr(0, sisaa),
        ribuann = splits[0].substr(sisaa).match(/\d{3}/gi);

      if (ribuann) {
        var separatorr = sisaa ? "." : "";
        rupiahh += separatorr + ribuann.join(".");
      }

      rupiahh = splits[1] !== undefined ? rupiahh + "," + splits[1] : rupiahh + ",00";
      return rupiahh;

      // return new Intl.NumberFormat({ style: "currency" }).format(
      //   sparateDecimalNumber[0]
      // );
    }

    var number_strings = sparateDecimalNumber[0]
      .toString()
      .replace(/[^,\d]/g, "")
      .toString(),
      splits = number_strings.split(","),
      sisaa = splits[0].length % 3,
      rupiahh = splits[0].substr(0, sisaa),
      ribuann = splits[0].substr(sisaa).match(/\d{3}/gi);

    if (ribuann) {
      var separatorr = sisaa ? "." : "";
      rupiahh += separatorr + ribuann.join(".");
    }

    rupiahh = splits[1] !== undefined ? rupiahh + "," + splits[1] : rupiahh + "," + sparateDecimalNumber[1];
    return rupiahh;
  },
  onlyNumber: function (value) {
    const removedSparator = value?.toString().replace(/,/g, "");

    if (!value) {
      return "";
    }

    if (!RegExp(/^[0-9]*$/).test(removedSparator)) {
      return removedSparator.toString().replace(/\D/g, "");
    }

    return removedSparator;
  },
  floatNumber: function (value) {
    const removedSparator = value?.toString().replace(/,/g, "");

    if (!value) {
      return "";
    }

    if (!RegExp(/^[0-9]*\.?[0-9]*$/).test(removedSparator)) {
      return removedSparator.toString().replace(/\D/g, "");
    }

    return removedSparator;
  },
  floatNumber2Comma: function (value) {
    let removedSparator = value?.toString().replace(/,/g, "");

    if (!value) {
      return "";
    }

    if (!RegExp(/^[0-9]*\.?[0-9]*$/).test(removedSparator)) {
      removedSparator.toString().replace(/\D/g, "")
      // removedSparator = removedSparator.toString().substring(0,4);
      return removedSparator.toString().substring(0,5);
    }

    return removedSparator.toString().substring(0,5);
  },
  alfaNumberic: function (value) {
    const removedSparator = value.replace(/,/g, "");

    if (!value) {
      return "";
    }

    if (!RegExp(/^[a-zA-Z0-9_]*$/).test(removedSparator)) {
      return removedSparator.replace(/\D/g, "");
    }

    return removedSparator;
  },
  getAllMonth: function (value) {
    let month = [
      "Januari",
      "Februari",
      "Maret",
      "April",
      "Mei",
      "Juni",
      "Juli",
      "Agustus",
      "September",
      "Oktober",
      "November",
      "Desember",
    ];

    const result = month.map((v, i) => {
      return { label: v, value: i + 1 };
    });

    return result;
  },
  getAllMonthRegist: function (value) {
    let month = [
      "Januari",
      "Februari",
      "Maret",
      "April",
      "Mei",
      "Juni",
      "Juli",
      "Agustus",
      "September",
      "Oktober",
      "November",
      "Desember",
    ];

    const result = month.map((v, i) => {
      return { label: v, value: i > 9 ? "" + (i + 1) : "0" + (i + 1) };
    });

    return result;
  },
  getAllYears: function (value) {
    let startYear = new Date().getFullYear() - 1;
    let currentYear = new Date().getFullYear() + 1,
      years = [];
    while (startYear <= currentYear) {
      currentYear--;
      years.push({ label: currentYear, value: currentYear });
    }
    return years;
  },
  getAllYearsRegist: function (value) {
    let startYear = 1961;
    let currentYear = new Date().getFullYear() - 20,
      years = [];
    while (startYear <= currentYear) {
      currentYear--;
      years.push({ label: currentYear, value: "" + currentYear });
    }
    return years;
  },
  getAllDatesRegist: function (value) {
    let tempDate = [];
    for (let i = 1; i <= dayjs().daysInMonth(); i++) {
      tempDate.push({ label: i, value: i > 9 ? "" + i : "0" + i });
    }
    return tempDate;
  },
  getAllDates: function (value) {
    let tempDate = [];
    for (let i = 1; i <= dayjs().daysInMonth(); i++) {
      tempDate.push({ label: i, value: i });
    }
    return tempDate;
  },
  checkLoopEmptyObject: function (v) {
    if (!v) {
      return;
    }

    var value = cloneDeep(v);
    delete value.is_active;
    delete value.isActive;

    var arrayObject = Object.keys(value).map(function (key) {
      return value[key];
    });

    // const arrayObject = Object.values(value);
    const countAllValidated = arrayObject.filter((v) => v !== "" && v !== 0).length;

    if (countAllValidated !== arrayObject.length) {
      return true;
    }
    return false;
  },
  checkLoopEmptyObject3: function (v) {
    if (!v) {
      return;
    }

    var value = cloneDeep(v);
    delete value.is_active;
    delete value.isActive;

    var arrayObject = Object.keys(value).map(function (key) {
      return value[key];
    });

    // const arrayObject = Object.values(value);
    const countAllValidated = arrayObject.filter((v) => v !== "" && v !== 0 && v !== "00").length;

    if (countAllValidated !== arrayObject.length) {
      return true;
    }
    return false;
  },
  checkLoopEmptyObject2: function (v) {
    if (!v) {
      return;
    }

    var value = cloneDeep(v);
    delete value.is_active;
    delete value.isActive;

    var arrayObject = Object.keys(value).map(function (key) {
      return value[key];
    });

    // const arrayObject = Object.values(value);
    const countAllValidated = arrayObject.filter((v) => v !== "").length;

    if (countAllValidated !== arrayObject.length) {
      return true;
    }
    return false;
  },

  checkLoopEmptyObject4: function (v) {
    if (!v) {
      return;
    }

    var value = cloneDeep(v);
    delete value.is_active;
    delete value.isActive;

    var arrayObject = Object.keys(value).map(function (key) {
      return value[key];
    });

    // const arrayObject = Object.values(value);
    const countAllValidated = arrayObject.filter((v) => Object.keys(v).length !== 0).length;

    if (countAllValidated !== arrayObject.length) {
      return true;
    }
    return false;
  },


  checkLoopEmptyObjectCOA: function (v) {
    if (!v) {
      return;
    }

    var value = cloneDeep(v);
    delete value.is_active;
    delete value.isActive;
    delete value.begin_balance;
    delete value.is_default;
    delete value.coa_type;
    delete value.kode_bank;
    delete value.nama_bank;
    delete value.head_account;


    var arrayObject = Object.keys(value).map(function (key) {
      return value[key];
    });

    // const arrayObject = Object.values(value);
    const countAllValidated = arrayObject.filter((v) => v !== "" && v !== 0).length;

    if (countAllValidated !== arrayObject.length) {
      return true;
    }
    return false;
  },
  checkPassword: function (value) {
    if (!value) {
      return;
    }
    // const arrayObject = Object.values(value);
    var arrayObject = Object.keys(value).map(function (key) {
      return value[key];
    });

    const countAllValidated = arrayObject.filter((v) => v.length > 5).length;
    if (countAllValidated !== arrayObject.length) {
      return true;
    }
    return false;
  },
  scorePassword: function (pass) {
    var score = 0;
    if (!pass) return score;

    // award every unique letter until 5 repetitions
    var letters = Object.create(null);
    for (var i = 0; i < pass.length; i++) {
      letters[pass[i]] = (letters[pass[i]] || 0) + 1;
      score += 5.0 / letters[pass[i]];
    }

    // bonus points for mixing it up
    var variations = {
      digits: /\d/.test(pass),
      lower: /[a-z]/.test(pass),
      upper: /[A-Z]/.test(pass),
      nonWords: /\W/.test(pass),
    };

    var variationCount = 0;
    for (var check in variations) {
      variationCount += variations[check] === true ? 1 : 0;
    }
    score += (variationCount - 1) * 10;

    return parseInt(score);
  },
  checkPasswordStrength: function (pass) {
    if (pass.length < 5) {
      return;
    }

    var score = Formatting.scorePassword(pass);

    if (score > 80)
      return <lebel className="label-green">{"Password kuat!"}</lebel>;
    if (score > 40)
      return <lebel className="label-green">{"Password sedang!"}</lebel>;
    if (score >= 10)
      return <lebel className="label-error">{"Password lemah!"}</lebel>;
    return "";
  },
  delayExecution(delay, value) {
    return new Promise((resolve) => setTimeout(resolve, delay, value));
  },
  startCountDown(timeleft) {
    var downloadTimer = setInterval(function () {
      timeleft--;
      return timeleft;
    }, 1000);
    return downloadTimer;
  },
  capitalizeFirstLetter(string) {
    const finalSentence = string?.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());
    return finalSentence;
  },
  GenerateJWT(body, timestamp, url = "") {
    let data = cloneDeep(body)
    if(Object.keys(data).length === 0){
      data.get = url
    }
    data.timestamp = timestamp;

    let header = {
      "alg": "HS256",
      "typ": "JWT"
    };

  const secret = 'vSqJ7AEvXSBUUSHQ9ojrMpc9m02qXCsw3L924Mtt8AjtyvVG9D';
  const signedToken = jwt(data, secret, header);

  return signedToken
},
GenerateJWTFormData(body, timestamp, url = "") {
  let data = cloneDeep(body)
  if(Object.keys(data).length === 0){
    data.formdata = url
  }
  data.timestamp = timestamp;

  let header = {
    "alg": "HS256",
    "typ": "JWT"
  };

const secret = 'vSqJ7AEvXSBUUSHQ9ojrMpc9m02qXCsw3L924Mtt8AjtyvVG9D';
const signedToken = jwt(data, secret, header);

return signedToken
},
RemoveDuplicatesAOB(originalArray, prop) {
  var newArray = [];
  var lookupObject  = {};

  for(var i in originalArray) {
     lookupObject[originalArray[i][prop]] = originalArray[i];
  }

  for(i in lookupObject) {
      newArray.push(lookupObject[i]);
  }
   return newArray;
},
onlyNumberString: function (value) {
  const removedSparator = value?.toString().replace(/,/g, "");

  if (!value) {
    return "";
  }

  if (!RegExp(/^[A-Za-z0-9 ]*$/).test(removedSparator)) {
    return removedSparator.toString().replace(/[^a-z^A-Z^0-9^ ]+/g, "");
  }

  return removedSparator;
},

};

export default Formatting;
