import axios from "axios";
import { Link } from "react-router-dom";

const HelperFunction = {
    windowOpen: (url) => {
        var parentOpener = window.opener;
        window.opener = null;
        window.open(url, "_blank");
        window.opener = parentOpener;
    },
    downloadFile: (url,name) => {
        fetch(url).then(response => {
            response.blob().then(blob => {
                const fileURL = window.URL.createObjectURL(blob);
                let alink = document.createElement('a');
                alink.href = fileURL;
                alink.download = url.split("/")[5];
                alink.click();
            })
        })
    },
    ValidatePassword: (password) => {
        const minLength = 8;
        const hasUppercase = /[A-Z]/.test(password);
        const hasLowercase = /[a-z]/.test(password);
        const hasSymbol = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\-]/.test(password);
        const hasNumber = /\d/.test(password);
      
        const errors = [];
      
        if (password.length < minLength) {
          errors.push('panjang password harus 8 karakter.');
        }
      
        if (!hasUppercase) {
            errors.push('Password harus memiliki huruf besar');
        }
      
        if (!hasLowercase) {
            errors.push('Password harus memiliki huruf kecil');
        }
      
        if (!hasSymbol) {
          errors.push(`Password harus memiliki simbol, contoh simbol yang di ijinkan !@#$%^&*()_+{}[]:;<>,.?~-`);
        }
      
        if (!hasNumber) {
          errors.push('Password harus memiliki Nomer.');
        }
      
        if (errors.length > 0) {
          return {
            valid: false,
            errors: errors,
          };
        } else {
          return {
            valid: true,
            message: 'Password is valid.',
          };
        }      
    }
}

export default HelperFunction

// (!@#$%^&*()_+{}[]:;<>,.?~-).