import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import DataTable from "react-data-table-component";
import { customStyles } from "components/CustomStyles/CustomStyles";
import Swal from "sweetalert2";
import MastersApi from "services/masters/masters.api";
import { setLoadingAsync } from "services/globals/loading.reducer";
import "./MutasiHoCabang.css";
import Formatting from "utils/formattingJson";
import InputGroup from "components/GroupInput/InputGroup";
import cloneDeep from "lodash.clonedeep";
import { Button } from "reactstrap";
import dayjs from "dayjs";
import FotoSelfiePengajuan from "./FotoSelfiePengajuan";
import { customStylesPageOnly } from "components/CustomStyles/CustomStylesPageOnly";
import ReactSelect from "components/Select/Select";
import { KA_UNIT } from "utils/ConstJabatan";
import ModalViewPengajuanBufferTakeOver from "./ModalViewPengajuanBufferTakeOver";

const PengajuanBufferTakeover = ({ history, dispatch, isLoading, isProfile }) => {
  const [statusKasir, setStatusKasir] = useState(0);
  const [wilayahOptions, setWilayahOptions] = useState();
  const [selectedWilayah, setSelectedWilayah] = useState(null);
  const [cabangOptions, setCabangOptions] = useState();
  const [selectedCabang, setSelectedCabang] = useState(null);
  const [unitOptions, setUnitOptions] = useState();
  const [selectedUnit, setSelectedUnit] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState({ value: "", label: "Pilih Status BFO" });
  const [searchOpen, setSearchOpen] = useState(false);
  const [dataFullFill, setDataFullFill] = useState([]);
  const [modalFotoSelfie, setModalFotoSelfie] = useState(false);
  const [imageSelfie, setImageSelfie] = useState();
  const [statusApproval, setStatusApproval] = useState(0);
  const [toggledClearRows, setToggleClearRows] = React.useState(false);
  const [keterangan, setKeterangan] = useState();
  const [totalFullFill, setTotalFullFill] = useState(0);
  const [selectedRows, setSelectedRows] = useState([]);
  const [modalView, setModalView] = useState(false);
  const [dataView, setDataView] = useState();
  const [searchTanggal, setSearchTanggal] = useState({
    tglAwal: dayjs(isProfile?.system_date).format("YYYY-MM-DD"),
    tglAkhir: dayjs(isProfile?.system_date).format("YYYY-MM-DD"),
  });
  const [tabActive, setTabActive] = useState({
    pengajuan: true,
    history: false,
  });
  const [paramsFetch, setParamsFetch] = useState({
    page: 1,
    page_size: 10,
    data: [],
    total: 0,
    total_page: 10,
  });
  useEffect(() => {
    setStatusKasir(isProfile?.status_cabang?.status_outlet === "Close" ? 0 : 1);
    if (isProfile?.status_cabang?.status_outlet === "Close") {
      Swal.fire({
        title: "Halaman tidak dapat diakses",
        text: "Silakan lakukan Open Kasir terlebih dahulu",
        icon: "warning",
        confirmButtonColor: "#3085d6",
        confirmButtonText: "OK",
      });
    } else {
      dispatch(setLoadingAsync(true));
      MastersApi.getAksesWilayah(`?id_karyawan=${isProfile?.profile?.id_karyawan}`)
        .then((res) => {
          setWilayahOptions(Formatting.formatRcDetail(res?.Wilayah, "id_cabang", "kode_cabang", "nama_cabang"));
          setCabangOptions(Formatting.formatRcDetail(res?.Cabang, "id_cabang", "kode_cabang", "nama_cabang"));
          setUnitOptions(Formatting.formatRcDetail(res?.Unit, "id_cabang", "kode_cabang", "nama_cabang"));

          if (res?.Wilayah?.length === 1) {
            setSelectedWilayah({ value: res?.Wilayah[0]?.id_cabang, label: res?.Wilayah[0]?.nama_cabang, detail: { id_wilayah: res?.Wilayah[0]?.id_wilayah } });
          }

          if (res?.Cabang?.length === 1) {
            setSelectedCabang({ value: res?.Cabang[0]?.id_cabang, label: res?.Cabang[0]?.nama_cabang });
            MastersApi.getAksesWilayah(`?id_karyawan=${isProfile?.profile?.id_karyawan}&id_cabang=${res?.Cabang[0]?.id_cabang}`).then((res) => {
              setUnitOptions(Formatting.formatRcDetail(res?.Unit, "id_cabang", "kode_cabang", "nama_cabang"));
            });
          }

          if (res?.Unit?.length === 1) {
            setSelectedUnit({ value: res?.Unit[0]?.id_cabang, label: res?.Unit[0]?.nama_cabang, detail: res?.Unit[0] });
          }
        })
        .then(() => {
          fetchData().then((res) => {
            let details = [];
            res?.data?.map((v, index) => {
              v.pengajuan_kacab = v.pengajuanKaunit;
              v.isSelected = false;
              v.index = index;
              let detailsData = {
                index,
                pengajuan_kacab: parseInt(Formatting.onlyNumber(v.pengajuanKaunit)) || 0,
              };
              details.push(detailsData);
            });
            setDataFullFill(res);
            console.log("data", res);
            dispatch(setLoadingAsync(false));
          });
        });
    }
  }, []);

  console.log(selectedWilayah);

  const handleClearRows = () => {
    setToggleClearRows(!toggledClearRows);
  };

  const fetchData = (page = 1, page_size = 10, params = "") => {
    return MastersApi.getPengajuanBufferTakeOver(`?page=${page}&page_size=${page_size}&statusTab=pengajuan`);
  };

  const fetchDataHistory = (page = 1, page_size = 10, idUnit = "", tglAwal = searchTanggal?.tglAwal, tglAkhir = searchTanggal?.tglAkhir, cabang = selectedCabang?.value, wilayah = selectedWilayah?.detail?.id_wilayah) => {
    return MastersApi.getPengajuanBufferTakeOver(`?id_cabang=${cabang}&id_wilayah=${wilayah}&idUnit=${idUnit}&page=${page}&page_size=${page_size}&tgl_awal=${tglAwal}&tgl_akhir=${tglAkhir}`);
  };

  const onChangeHandle = (v) => {
    setKeterangan(v.target.value);
  };

  let bottomForm = [
    {
      formType: "text",
      label: "Total Unit",
      value: selectedRows?.length,
      disabled: true,
    },
    {
      formType: "",
    },
    {
      formType: "text",
      label: "Total Request",
      value: Formatting.currency(totalFullFill),
      disabled: true,
    },
  ];

  const keteranganForm = [
    {
      formType: "textArea",
      label: "Keterangan",
      value: keterangan,
      onChange: onChangeHandle,
    },
  ];

  const columns = [
    {
      name: "Detail",
      selector: (row) => (
        <>
          <button className="border-gray" onClick={() => clickView(row)}>
            Detail
          </button>
        </>
      ),
      center: true,
      // sortable: true,
    },
    {
      name: "Request Unit",
      selector: (row) => <div style={{ direction: "ltr" }}>{Formatting.currency(row.requestUnit)}</div>,
      center: true,
      // sortable: true,
    },
    {
      name: "Hutang TO",
      selector: (row) => Formatting.currency(row.hutangTO),
      center: true,
      // sortable: true,
    },
    {
      name: "Saldo Unit",
      selector: (row) => Formatting.currency(row.saldoUnit),
      center: true,
      // sortable: true,
    },
    {
      name: "Plafon",
      selector: (row) => Formatting.currency(row.plafon),
      center: true,
      // sortable: true,
    },
    {
      name: "Nama Unit",
      selector: (row) => row.namaCabang,
      center: true,
      // sortable: true,
    },
    {
      name: "Kode Unit",
      selector: (row) => row.kodeCabang,
      center: true,
      // sortable: true,
    },
    {
      name: "Tgl Pengajuan",
      selector: (row) => <div style={{ direction: "ltr" }}>{dayjs(row.tanggal).format("DD MMMM YYYY")}</div>,
      center: true,
      // sortable: true,
    },
    {
      name: "No",
      cell: (row, i) => i + 1,
      center: true,
      width: "5%",
    },
  ];

  const setActive = (tab) => {
    if (tab === "pengajuan") {
      dispatch(setLoadingAsync(true));
      fetchData()
        .then((res) => {
          let details = [];
          res?.data?.map((v, index) => {
            v.pengajuan_kacab = v.pengajuanKaunit;
            v.isSelected = false;
            v.index = index;
            let detailsData = {
              index,
              pengajuan_kacab: parseInt(Formatting.onlyNumber(v.pengajuanKaunit)) || 0,
            };
            details.push(detailsData);
          });
          setParamsFetch(res);
          setDataFullFill(res);
          setTabActive({ ...tabActive, history: false, pengajuan: true });
          dispatch(setLoadingAsync(false));
          console.log("data", res);
        })
        .catch((err) => {
          console.log("gagal");
        });
    } else {
      dispatch(setLoadingAsync(true));
      fetchDataHistory().then((res) => {
        setParamsFetch(res);
        setTabActive({ ...tabActive, history: true, pengajuan: false });
        dispatch(setLoadingAsync(false));
      });
    }
  };

  const handleChangeCheck = ({ allSelected, selectedRows, selectedCount }) => {
    setSelectedRows(selectedRows);
    let total = 0;
    selectedRows?.map((v) => {
      total = total + v.requestUnit;
    });
    setTotalFullFill(total);
  };

  const clickView = (evt) => {
    setDataView(evt);
    console.log("every", evt);
    setModalView(true);
  };

  const rowSelectCritera = (row) => selectedRows.filter((x) => x.index === row.index).length > 0;

  const submit = (statusApproval) => {
    setStatusApproval(statusApproval);
    setModalFotoSelfie(true);
  };

  const formPengajuanSelfie = (v) => {
    let Data = [];
    selectedRows?.map((item) => {
      Data.push({
        IdBufferTO: item.idBufferTO,
      });
    });
    let postData = {
      Data,
      IdJabatanKaryawan: isProfile?.profile?.id_jabatan_karyawan,
      IsStatus: statusApproval,
      Selfie: v,
      Keterangan: keterangan,
    };
    console.log("postData", postData);
    dispatch(setLoadingAsync(true));
    MastersApi.postApproveBufferTO(postData)
      .then(() => {
        dispatch(setLoadingAsync(false));
        fetchDataHistory().then((res) => {
          setDataFullFill(res);
          Swal.fire("Berhasil", `${statusApproval === 1 ? "Approve Berhasil" : "Reject Berhasil"}`, "success").then(() => {
            handleClearRows();
            setKeterangan("");
            setStatusApproval(0);
            setSelectedRows([]);
            setTotalFullFill(0);
          });
          setParamsFetch(res);
          dispatch(setLoadingAsync(false));
          setTabActive({ ...tabActive, history: true, pengajuan: false });
        });
      })
      .catch((err) => {
        dispatch(setLoadingAsync(false));
        Swal.fire("Maaf", err.response.data.Response.errorMessage, "error");
      });
  };

  const onChangePageSizeApp = (v) => {
    setParamsFetch({ ...paramsFetch, page_size: v });
    dispatch(setLoadingAsync(true));
    fetchData(1, v)
      .then((res) => {
        setParamsFetch(res);
        dispatch(setLoadingAsync(false));
      })
      .catch((err) => {
        dispatch(setLoadingAsync(false));
        Swal.fire("Maaf", err.response.data.Response.errorMessage, "error");
      });
  };

  const onChangePaginationApp = (v) => {
    setParamsFetch({ ...paramsFetch, page: v });
    dispatch(setLoadingAsync(true));
    fetchData(v, paramsFetch?.page_size)
      .then((res) => {
        setParamsFetch(res);
        dispatch(setLoadingAsync(false));
      })
      .catch((err) => {
        dispatch(setLoadingAsync(false));
        Swal.fire("Maaf", err.response.data.Response.errorMessage, "error");
      });
  };

  const onChangePaginationHis = (v) => {
    setParamsFetch({ ...paramsFetch, page: v });
    dispatch(setLoadingAsync(true));
    fetchDataHistory(v, paramsFetch?.page_size)
      .then((res) => {
        setParamsFetch(res);
        dispatch(setLoadingAsync(false));
      })
      .catch((err) => {
        dispatch(setLoadingAsync(false));
        Swal.fire("Maaf", err.response.data.Response.errorMessage, "error");
      });
  };

  const onChangePageSizeHis = (v) => {
    setParamsFetch({ ...paramsFetch, page_size: v });
    dispatch(setLoadingAsync(true));
    fetchDataHistory(1, v)
      .then((res) => {
        setParamsFetch(res);
        dispatch(setLoadingAsync(false));
      })
      .catch((err) => {
        dispatch(setLoadingAsync(false));
        Swal.fire("Maaf", err.response.data.Response.errorMessage, "error");
      });
  };

  const handleOnChangeSelect = (v, name) => {
    if (name === "wilayah") {
      setSelectedWilayah(v);
      setSelectedCabang(null);
      setCabangOptions([]);
      setSelectedUnit(null);
      setUnitOptions([]);
      dispatch(setLoadingAsync(true));
      MastersApi.getAksesWilayah(`?id_karyawan=${isProfile?.profile?.id_karyawan}&idWilayah=${v.value}`).then((res) => {
        dispatch(setLoadingAsync(false));
        if (res?.Cabang?.length === 1) {
          setSelectedCabang({ value: res?.Cabang[0]?.id_cabang, label: res?.Cabang[0]?.nama_cabang });
          MastersApi.getAksesWilayah(`?id_karyawan=${isProfile?.profile?.id_karyawan}&idWilayah=${v.value}&id_cabang=${res?.Cabang[0]?.id_cabang}`).then((resd) => {
            dispatch(setLoadingAsync(false));
            setUnitOptions(Formatting.formatRcDetail(resd?.Unit, "id_cabang", "kode_cabang", "nama_cabang"));
            setCabangOptions(Formatting.formatRcDetail(res?.Cabang, "id_cabang", "kode_cabang", "nama_cabang"));
          });
        } else {
          setCabangOptions(Formatting.formatRcDetail(res?.Cabang, "id_cabang", "kode_cabang", "nama_cabang"));
        }
      });
    }

    if (name === "cabang") {
      setSelectedCabang(v);
      setSelectedUnit(null);
      setUnitOptions([]);
      dispatch(setLoadingAsync(true));
      MastersApi.getAksesWilayah(`?id_karyawan=${isProfile?.profile?.id_karyawan}&idWilayah=${selectedWilayah?.value}&id_cabang=${v.value}`).then((res) => {
        dispatch(setLoadingAsync(false));
        setUnitOptions(Formatting.formatRcDetail(res?.Unit, "id_cabang", "kode_cabang", "nama_cabang"));
      });
    }

    if (name === "unit") {
      setSelectedUnit(v);
    }

    if (name === "isStatus") {
      setSelectedStatus(v);
    }
  };

  const onClickClear = () => {
    setSearchTanggal({
      tglAwal: dayjs(isProfile?.system_date).format("YYYY-MM-DD"),
      tglAkhir: dayjs(isProfile?.system_date).format("YYYY-MM-DD"),
    });
    if (wilayahOptions.length > 1) {
      setSelectedWilayah(null);
      setSelectedCabang(null);
      setSelectedUnit(null);
      setCabangOptions([]);
      setUnitOptions([]);
    }

    if (cabangOptions.length > 1) {
      setSelectedCabang(null);
      setSelectedUnit(null);
      setUnitOptions([]);
    }

    if (unitOptions.length > 1) {
      setSelectedUnit(null);
    }

    setSelectedStatus({ value: "", label: "Pilih Status BFO" });
  };

  const onClickSearch = () => {
    dispatch(setLoadingAsync(true));
    fetchDataHistory(paramsFetch?.page, paramsFetch?.page_size, selectedUnit?.value, searchTanggal?.tglAwal, searchTanggal?.tglAkhir)
      .then((res) => {
        console.log("Sssssss", res);
        // console.log("sdada", selectedUnit?.detail?.nama_cabang);
        setParamsFetch(res);
        dispatch(setLoadingAsync(false));
      })
      .catch((err) => {
        dispatch(setLoadingAsync(false));
        Swal.fire("Maaf", err.response.data.Response.errorMessage, "error");
      });
  };

  const onChangeDate = (v) => {
    console.log(v.target.name);
    if (v.target.name == "tglAwal") {
      setSearchTanggal({ ...searchTanggal, tglAwal: v.target.value, tglAkhir: "" });
    } else {
      setSearchTanggal({ ...searchTanggal, tglAkhir: v.target.value });
    }
  };

  if (statusKasir === 0) {
    return "";
  } else {
    if (tabActive?.pengajuan) {
      return (
        <>
          {modalFotoSelfie && <FotoSelfiePengajuan setModalFotoSelfie={setModalFotoSelfie} modalFotoSelfie={modalFotoSelfie} dispatch={dispatch} setForm={formPengajuanSelfie} />}
          {modalView && <ModalViewPengajuanBufferTakeOver isProfile={isProfile} dispatch={dispatch} title={"Detail Approval Buffer Take Over"} show={modalView} hideModal={() => setModalView(!modalView)} obj={dataView} />}
          <div className="row bg-tab mb-2">
            <div className="col-12">
              <div className="row text-center">
                <div className={tabActive.pengajuan ? "col-1 tab-style tab-active pointer" : "col-1 tab-style pointer"} onClick={() => setActive("pengajuan")}>
                  <span>Pengajuan</span>
                </div>
                <div className={!tabActive.pengajuan ? "col-1 tab-style tab-active pointer" : "col-1 tab-style pointer"} onClick={() => setActive("history")}>
                  <span>History</span>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div>
              <DataTable
                columns={columns}
                data={dataFullFill}
                customStyles={customStyles}
                highlightOnHover
                pagination
                dense
                responsive
                persistTableHead
                fixedHeader
                paginationServer
                selectableRows
                direction={"RTL"}
                selectableRowsHighlight={true}
                onSelectedRowsChange={handleChangeCheck}
                selectableRowSelected={rowSelectCritera}
                clearSelectedRows={toggledClearRows}
                onChangeRowsPerPage={onChangePageSizeApp}
                onChangePage={onChangePaginationApp}
              />
            </div>
            <div className="col-12 mt-4">
              <InputGroup formGroup={bottomForm.reverse()} />
              <InputGroup formGroup={keteranganForm} />
            </div>
            <div className="col-12 mb-4 mt-4">
              <Button color="primary" disabled={selectedRows?.length === 0} onClick={() => submit(1)}>
                Approve
              </Button>{" "}
              <Button color="danger" disabled={selectedRows?.length === 0} onClick={() => submit(2)}>
                Reject
              </Button>
            </div>
          </div>
        </>
      );
    } else {
      return (
        <>
          {modalView && <ModalViewPengajuanBufferTakeOver isProfile={isProfile} dispatch={dispatch} title={"Detail Approval Buffer Take Over"} show={modalView} hideModal={() => setModalView(!modalView)} obj={dataView} />}
          <div className="row bg-tab mb-2">
            <div className="col-12">
              <div className="row text-center">
                <div className={tabActive.pengajuan ? "col-1 tab-style tab-active pointer" : "col-1 tab-style pointer"} onClick={() => setActive("pengajuan")}>
                  <span>Pengajuan</span>
                </div>
                <div className={!tabActive.pengajuan ? "col-1 tab-style tab-active pointer" : "col-1 tab-style pointer"} onClick={() => setActive("history")}>
                  <span>History</span>
                </div>
              </div>
            </div>
          </div>
          <div className="row table-search mb-0">
            <span className="txt-body hand-click" onClick={() => setSearchOpen(!searchOpen)}>
              {searchOpen ? `-` : `+`} Search{" "}
            </span>
            <hr className="" />
          </div>
          <div className="row table-search-body" style={{ display: searchOpen ? "" : "none" }}>
            <div className="row">
              <div className="col-1">
                <span className="txt-body">Wilayah</span>
              </div>
              <div className="col-6 col-md-3">
                <ReactSelect className={`search-select zIndex2`} disabled={wilayahOptions?.length === 1 ? true : false} value={selectedWilayah} options={wilayahOptions} onChange={(e) => handleOnChangeSelect(e, "wilayah")}></ReactSelect>
              </div>

              <div className="col-1">
                <span className="txt-body">Cabang</span>
              </div>
              <div className="col-6 col-md-3">
                <ReactSelect className={`search-select zIndex2`} value={selectedCabang} options={cabangOptions} onChange={(e) => handleOnChangeSelect(e, "cabang")}>
                  {" "}
                </ReactSelect>
              </div>
              <div className="col-1">
                <span className="txt-body">Outlet</span>
              </div>
              <div className="col-6 col-md-3">
                <ReactSelect className={`search-select zIndex2`} disabled={unitOptions?.length === 1 ? true : false} value={selectedUnit} options={unitOptions} onChange={(e) => handleOnChangeSelect(e, "unit")}>
                  {" "}
                </ReactSelect>
              </div>
            </div>
            <div className="row mt-2">
              <div className="col-1">
                <span className="txt-body">Tanggal Awal</span>
              </div>
              <div className="col-6 col-md-3">
                <input className="input-table" type="date" value={searchTanggal.tglAwal} name="tglAwal" onChange={onChangeDate}></input>
              </div>
              <div className="col-1">
                <span className="txt-body">Tanggal Akhir</span>
              </div>
              <div className="col-6 col-md-3">
                <input className="input-table" type="date" value={searchTanggal.tglAkhir} name="tglAkhir" onChange={onChangeDate}></input>
              </div>
            </div>
            <div className="col-12">
              <button className="btn-table mt-2" onClick={onClickSearch}>
                View
              </button>
              <button className="btn-table mt-2" onClick={onClickClear}>
                Clear
              </button>
            </div>
          </div>
          <div className="col-12 mt-4">
            <span className="txt-body">Total Data : {paramsFetch?.total}</span>
          </div>
          <div className="col-12 mt-2 table table-bordered">
            <table class="table" width={"100%"}>
              <tr>
                <th rowSpan={2}>No</th>
                <th rowSpan={2}>Tgl Pengajuan</th>
                <th rowSpan={2}>Plafon</th>
                <th rowSpan={2}>Saldo Unit</th>
                <th rowSpan={2}>Nama Borrower</th>
                <th rowSpan={2}>No Sbg TO</th>
                <th rowSpan={2}>Pengajuan</th>
                <th rowSpan={2}>Asal Gadai</th>
                <th rowSpan={2}>Dropping</th>
                <th rowSpan={2}>Final Approval</th>
                <th rowSpan={2}>Approval Terakhir</th>
                <th colSpan={2}>No Voucher</th>
                <th rowSpan={2}>Status</th>
              </tr>
              <tr>
                <th colSpan={1}>Full Fill</th>
                <th colSpan={1}>Dropping</th>
              </tr>
              {paramsFetch?.data?.map((v, i) => (
                <tr>
                  <td>{i + 1}</td>
                  <td>{dayjs(v?.tanggal).format("DD MMMM YYYY")}</td>
                  <td>{v?.plafon ? Formatting.currency(v.plafon) : "-"}</td>
                  <td>{!v?.saldoPengajuan ? "-" : Formatting.currency(v.saldoPengajuan)}</td>
                  <td>{v?.namaPeminjam}</td>
                  <td onClick={() => clickView(v)} className="hand-clickk" style={{ color: "blue" }}>
                    {v?.noSbgTO}
                  </td>
                  <td>{!v?.pengajuanKaunit ? "-" : Formatting.currency(v.pengajuanKaunit)}</td>
                  <td>{!v?.namaMitraPegadaian ? "-" : v?.namaMitraPegadaian}</td>
                  <td>{!v?.dibayar ? "0" : Formatting.currency(v.dibayar)}</td>
                  <td>{!v?.finalApproval ? "-" : v?.finalApproval}</td>
                  <td>{!v?.approvalTerakhir ? "-" : v?.approvalTerakhir}</td>
                  <td>{!v?.noVoucherFinance ? "-" : v?.noVoucherFinance}</td>
                  <td>{!v?.noVoucherUnit ? "-" : v?.noVoucherUnit}</td>
                  <td>{v?.isStatus === 12 ? "Refund" : v?.isStatus === 1 ? "Done" : v?.isStatus === 2 ? "Reject" : "Pending"}</td>
                </tr>
              ))}
            </table>
          </div>
          <DataTable
            data={paramsFetch?.data}
            customStyles={customStylesPageOnly}
            pagination
            dense
            persistTableHead
            paginationServer
            paginationTotalRows={paramsFetch?.total}
            onChangeRowsPerPage={onChangePageSizeHis}
            onChangePage={onChangePaginationHis}
          />
        </>
      );
    }
  }
};

const mapStateToProps = function (state) {
  return {
    isLoading: state.global.isLoading,
    isProfile: state.profile,
  };
};

export default connect(mapStateToProps)(PengajuanBufferTakeover);
