import { ADMIN, AUDIT_HEAD, AUDIT_STAFF } from "utils/ConstJabatan";

export const ReportAuditSyari = [
    {
        title: "Audit Virtual",
        akses: [ADMIN,AUDIT_HEAD,AUDIT_STAFF],
        link: "/report-audit/audit-virtual"
    },
    {
        title: "Laporan penggunaan Sistem",
        akses: [ADMIN, AUDIT_HEAD, AUDIT_STAFF],
        link: "/report-audit/laporan-penggunaan-sistem"
    },
]