import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles/css/index.css';
import './styles/css/custom.css';
import './styles/css/menu.css';
import './styles/fonts/futura.woff';
import 'react-pro-sidebar/dist/css/styles.css';
import { AppKonven } from './AppKonven';
import { AppSyariah } from './AppSyariah';
import { App } from 'App';
import dayjs from "dayjs";
import reportWebVitals from './reportWebVitals';
import configureStore from "./store/configureStore";
import { Provider } from "react-redux";
import { Slide, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/css/bootstrap.css';
import { decodeAuth } from 'utils/cookie';
var isSameOrBefore = require('dayjs/plugin/isSameOrBefore')
var utc = require('dayjs/plugin/utc')
var timezone = require('dayjs/plugin/timezone')

dayjs.extend(utc)
dayjs.extend(timezone)
require("dayjs/locale/id");
dayjs.extend(isSameOrBefore)


const store = configureStore();
dayjs.locale("id");


// console.warn = function(){};
// console.error = function(){};


// if(process.env.REACT_APP_API_ENDPOINT === "https://prodgo-promas.gadaimas.com/" ){
  console.log = function(){};
// }



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
      <Provider store={store}>
      <ToastContainer
        theme="colored"
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        closeOnClick
        rtl={false}
        newestOnTop
        draggable
        pauseOnHover
        transition={Slide}
      />
        <App />
      </Provider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
