import React from "react";
import { ProSidebar, Menu, MenuItem } from 'react-pro-sidebar';
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { setMenuAsync } from "../../services/globals/menu.reducer";
import Formatting from "../../utils/formattingJson";

const MenuReport = ({isMenu, dispatch, isLoading}) => {
  const onClickMenu = () => {
    Formatting.delayExecution(100).then(() => {
      console.log("elementPro", checkElement())
      if(checkElement() > 0){
        dispatch(setMenuAsync(true));
      }else{
        dispatch(setMenuAsync(false));
      }
    })
  }

  const checkElement = () => {
    const allWithClass = Array.from(
      document.getElementsByClassName('pro-sub-menu open')
    );
    return allWithClass.length;
  }
  
    return (
          <ProSidebar className={!isLoading && isMenu ? "height100" : "heightAuto"} onClick={onClickMenu}>
            <Menu>
                  <MenuItem>Report Cabang</MenuItem>
                  <MenuItem>Report HO</MenuItem>
            </Menu>
          </ProSidebar>
    );
};

const mapStateToProps = function (state) {
  
      return {
        isLoading: state.global.isLoading,
        isMenu: state.menu.isMenu,
      };
    };
    
    export default connect(mapStateToProps)(MenuReport);