import Api from "../../utils/api_syariah";
import { saveTokenSyariah, deleteAllToken } from "../../utils/cookie";

const AuthApi = {
   postAbsen: function (absen,data) {
      return Api()
        .PostFileAbsen(`mssyariahuser/v1/user/absen?keterangan=${absen}`,data,{withoutToken:true, rawJSON:true})
        .then((datas) => {
            return datas;
        })
    },
    postLogin: function (data) {
      return Api()
        .postLogin(`mssyariahuser/v1/user/login`,data,{withoutToken:true, rawJSON:true, isLogin: true })
        .then(({data}) => {
          saveTokenSyariah(data?.access_token);
            return data;
        })
    },
    postLogout: function (data) {
      return Api()
        .postLogout(`mssyariahuser/v1/user/ouath2/logout`,{request: data,},{rawJSON:true, isSignature: true})
        .then(() => {
            deleteAllToken();
            return true;
        })
    },
    getProfile: function () {
      return Api()
        .Get(`mssyariahuser/v1/user/dashoard`)
        .then(({Response}) => {
            return Response.data;
        })
    },
    getMenu: function (params) {
      return Api()
        .Get(`mssyariahuser/v2/user/menu`, {isSignature: false})
        .then(({Response}) => {
            return Response.data;
        })
    },
  
};

export default AuthApi;
