import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import InputGroup from "../GroupInput/InputGroup";
import MastersApi from "../../services/masters/masters.api";
import DataTable from 'react-data-table-component';
import { setLoadingAsync } from '../../services/globals/loading.reducer';
import { customStyles } from "../CustomStyles/CustomStyles";
import Formatting from "utils/formattingJson";
import MastersApiSyariah from "../../services/masters/masters_syariah.api";
import { decodeAuth } from "utils/cookie";

const OptionsBrankas = ({dispatch, title, show, parsingData, setShow, hideModal }) => {

    const [selectedOptions, setSelectedOptions] = useState(null);
    
    const columns = [
      {
        name: 'Pilih',
        cell: (row,index) => <input type="radio" name="pilih_kelurahan" onClick={() => setSelectedOptions(row)} />,
        center: true,
        // sortable: true,
    },
    {
      name: 'Kode Lajur',
      selector: row => row.kode_lajur,
      center: true,
      // sortable: true,
  },
    {
        name: 'Nama Lajur',
        selector: row => row.nama_lajur,
        center: true,
        // sortable: true,
    },
    {
      name: 'Kode Laci',
      selector: row => row.kode_laci,
      center: true,
      // sortable: true,
    },
    {
      name: 'Kode Brankas',
      selector: row => row.kode_brankas,
      center: true,
      // sortable: true,
    },
    {
      name: 'Status',
      selector: row => row.is_active ? "Aktif" : "Tidak Aktif",
      center: true,
      // sortable: true,
    },
    ];

    const [searchOpen, setSearchOpen] = useState(false);
    const [paramsFetch, setParamsFetch] = useState({
        page: 1,
        page_size: 10,
        data: [],
        total: 1,
        total_page: 10,
    });
    
    const [search, setSearch] = useState({
      searchKodeLajur: "",
      searchKodeCabang: ""
    })

    useEffect(() => {
        dispatch(setLoadingAsync(true))
        fetchData(1).then((res) => {
          setParamsFetch(res)
          console.log("resss",res)
          dispatch(setLoadingAsync(false))

        })
    }, [])

    const fetchData = (page = 1, page_size = 10, ) => {
      return decodeAuth().isSyariah === 1 ? MastersApiSyariah.getBrankas(`?page=${page}&page_size=${page_size}&searchKodeLajur=${search?.searchKodeLajur}&searchKodeCabang=${search?.searchKodeCabang}`) : MastersApi.getBrankas(`?page=${page}&page_size=${page_size}&searchKodeLajur=${search?.searchKodeLajur}&searchKodeCabang=${search?.searchKodeCabang}`)
    }

    const onChange = (v) => {
        setSearch({ ...search, [v.target.name]: v.target.value })
    }
    
      const onChangePageSize = (v) => {
        dispatch(setLoadingAsync(true))
        fetchData(1, v ).then((res) => {
          setParamsFetch(res)
          dispatch(setLoadingAsync(false))
        })
      }
    
      const onChangePagination = (v) => {
        dispatch(setLoadingAsync(true))
        fetchData(v, paramsFetch?.page_size ).then((res) => {
          setParamsFetch(res)
          dispatch(setLoadingAsync(false))
        })
      }
    
      const onClickSearch = () => {
        dispatch(setLoadingAsync(true))
        fetchData(1, paramsFetch.page_size).then((res) => {
          setParamsFetch(res)
          dispatch(setLoadingAsync(false))
        })
      }
    return (
        <Modal
            isOpen={show}
            centered
            size="xl"
            scrollable
            toggle={() => hideModal()}
        >
            <ModalHeader toggle={() => hideModal()}>{title}</ModalHeader>
            <ModalBody>
            <div className="row table-search mb-0">
          <span className="txt-body hand-click" onClick={() => setSearchOpen(!searchOpen)}>{searchOpen ? `-` : `+`} Search </span>
          <hr className="mt-2"/>
    </div>
    <div className="row table-search-body" style={{display: searchOpen ? "" : "none"}}>
        <div className="col-6 col-md-3">
          <span className="txt-body">Kode Lajur</span>
        </div>
        <div className="col-6 col-md-3">
          <input className="input-table" name="searchKodeLajur" onChange={onChange}></input>
        </div>
        <div className="col-6 col-md-3">
          <span className="txt-body">Kode Cabang</span>
        </div>
        <div className="col-6 col-md-3">
          <input className="input-table" name="searchKodeCabang" onChange={onChange}></input>
        </div>
        <div className="col-12">
          <button className="btn-table mt-2" onClick={onClickSearch}>View</button>
        </div>
    </div>
    <div className="row">
      <div className="col-12">
        <span className="txt-body">Total Data : {paramsFetch?.total}</span>
      </div>
      <div className="col-12">
        <DataTable
            columns={columns}
            data={paramsFetch?.data}
            customStyles={customStyles}
            highlightOnHover
            pagination
            dense
            responsive
            persistTableHead
            fixedHeader
            paginationServer
            paginationTotalRows={paramsFetch?.total}
            onChangeRowsPerPage={onChangePageSize}
            onChangePage={onChangePagination}

            // sortServer
            // onSort={handleSort}
            // progressPending={loading}
        />
      </div>
        
    </div>
            </ModalBody>
            <ModalFooter>
            <Button color="secondary" onClick={() => hideModal()}>Batal</Button>

                <Button color="primary" disabled={selectedOptions === null} onClick={() => parsingData(selectedOptions)}>Simpan</Button>{' '}
            </ModalFooter>
        </Modal>
    );
}

const mapStateToProps = function (state) {

  return {
    isLoading: state.global.isLoading,
    isProfile: state.profile
  };
};

export default connect(mapStateToProps)(OptionsBrankas);