import React from "react";
import { ProSidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { setMenuAsync } from "../../services/globals/menu.reducer";
import Formatting from "../../utils/formattingJson";

const MenuGadai = ({isMenu, dispatch, isLoading}) => {
  const navigate = useNavigate();
  let pathname = window.location.pathname.split("/");
  const onClickMenu = () => {
    Formatting.delayExecution(100).then(() => {
      console.log("elementPro", checkElement())
      if(checkElement() > 0){
        dispatch(setMenuAsync(true));
      }else{
        dispatch(setMenuAsync(false));
      }
    })
  }

  const checkElement = () => {
    const allWithClass = Array.from(
      document.getElementsByClassName('pro-sub-menu open')
    );
    return allWithClass.length;
  }
  
    return (
          <ProSidebar className={isMenu ? "height100" : "heightAuto"} onClick={onClickMenu}>
            <Menu>
                <SubMenu title="Taksir">
                  <SubMenu title="Taksir">
                    <MenuItem onClick={() => navigate("/aplikasi/taksir/taksir/taksir-emas")} active={pathname[pathname.length-1] === "taksir-emas"}>Taksir Emas</MenuItem>
                    <MenuItem>Taksir Umum</MenuItem>
                  </SubMenu>
                  <SubMenu title="Approval Taksir 1">
                    <MenuItem>Emas</MenuItem>
                    <MenuItem>Umum</MenuItem>
                  </SubMenu>
                  <SubMenu title="Approval Taksir 2">
                    <MenuItem>Emas</MenuItem>
                    <MenuItem>Umum</MenuItem>
                  </SubMenu>
                  <SubMenu title="Approval Taksir 3">
                    <MenuItem>Emas</MenuItem>
                    <MenuItem>Umum</MenuItem>
                  </SubMenu>
                  <SubMenu title="Approval Taksir 4">
                    <MenuItem>Emas</MenuItem>
                    <MenuItem>Umum</MenuItem>
                  </SubMenu>
                </SubMenu>

                <SubMenu title="Produk">
                  <MenuItem>Gadai</MenuItem>
                  <MenuItem>Cicilan</MenuItem>
                </SubMenu>

                <SubMenu title="Approval">
                  <MenuItem>Approval Gadai</MenuItem>
                  <MenuItem>Approval Cicilan</MenuItem>
                </SubMenu>

                <SubMenu title="Inventory">
                  <MenuItem>Inventory</MenuItem>
                  <MenuItem onClick={() => navigate("/aplikasi/inventory/penerimaan")} active={pathname[pathname.length-1] === "penerimaan"}>Penerimaan</MenuItem>
                  <MenuItem>Pengeluaran</MenuItem>
                  <MenuItem>Mutasi Barang</MenuItem>
                  <MenuItem>Pemeriksaan</MenuItem>
                  <MenuItem>Barang Penjualan</MenuItem>
                  <MenuItem>Mutasi SBG</MenuItem>
                  <MenuItem>SBG Hilang</MenuItem>
                  <MenuItem>Log Book</MenuItem>
                </SubMenu>

                <SubMenu title="Collection">
                  <MenuItem>Penanganan OVD</MenuItem>
                  <MenuItem>Query Overdue Customer</MenuItem>
                  <MenuItem>Surat Pemberitahuan 1</MenuItem>
                  <MenuItem>Surat Pemberitahuan</MenuItem>
                  <MenuItem>Konfirmasi by Email/Sms</MenuItem>
                  <MenuItem>Penanganan Nasabah</MenuItem>
                </SubMenu>
                <SubMenu title="Blanko">
                  <MenuItem>Generate Blanko</MenuItem>
                  <MenuItem>Mutasi Blanko</MenuItem>
                </SubMenu>
                <SubMenu title="Penjualan">
                  <MenuItem>Penjualan Barang Jaminan</MenuItem>
                </SubMenu>
                <SubMenu title="Insentif">
                  <MenuItem>Pembagian Insentif Admin</MenuItem>
                  <MenuItem>Upload Insentif</MenuItem>
                </SubMenu>
                <SubMenu title="ORM">
                  <MenuItem>Detail Event</MenuItem>
                  <MenuItem>Open</MenuItem>
                  <MenuItem>Close</MenuItem>
                </SubMenu>

            </Menu>
          </ProSidebar>
    );
};

const mapStateToProps = function (state) {
  
  return {
    isLoading: state.global.isLoading,
    isMenu: state.menu.isMenu,
  };
};

export default connect(mapStateToProps)(MenuGadai);