import React, { useState, useRef, useEffect } from "react";
import Webcam from "react-webcam";
import { Button, Modal, ModalBody, ModalHeader, ModalFooter } from "reactstrap";
import Swal from "sweetalert2";
import { setLoadingAsync } from "services/globals/loading.reducer";
import MastersApi from "services/masters/masters.api";

const FotoSelfiePengajuan = ({dispatch,modalFotoSelfie,setModalFotoSelfie,setForm }) => {
  const [modalNew, setModalNew] = useState(false);
  const videoConstraints = {
    width: 1280,
    height: 720,
    facingMode: "user",
  };
  const [btnDisabled, setBtnDisabled] = useState(true)

  // const [imagePreview, setImagePreview] = useState(null);
  const refClick = useRef(null);

  const onClickBoxPhoto = async () => {
    // console.log("refwid", refClick.current.getScreenshot());
    setBtnDisabled(true);
    dispatch(setLoadingAsync(true));
    const b64toBlob = (b64Data, contentType = "", sliceSize = 512) => {
      const byteCharacters = atob(b64Data);
      const byteArrays = [];

      for (
        let offset = 0;
        offset < byteCharacters.length;
        offset += sliceSize
      ) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
      }

      const blob = new Blob(byteArrays, { type: contentType });
      return blob;
    };

    const imageBase64 = refClick.current.getScreenshot();
    
    var base64result = imageBase64.split(",")[1];
    const blob = b64toBlob(base64result, "image/jpeg");

    const file2 = new File([blob], "foto_selfie.jpg", { type: blob.type });
    const formData = new FormData();
    formData.append("file",file2)
    formData.append("namaFolder","selfie-pengajuan-buffer-take-over/")
    formData.append("typeFile","img")

    MastersApi.postGeneralUpload(formData,"").then((res) => {
      dispatch(setLoadingAsync(false));
      setBtnDisabled(false);
      setForm(res?.FileS3)
      setModalFotoSelfie(!modalFotoSelfie)
      }).catch((err) => {
        dispatch(setLoadingAsync(false));
        setBtnDisabled(false);
        Swal.fire(
          'Failed',
          `gagal Menyimpan Gambar`,
          'error'
      )
      })

      setModalFotoSelfie(!modalFotoSelfie);
  };

  return (
    <>
      <Modal
        isOpen={modalFotoSelfie}
        centered
        size="xl"
        scrollable
        toggle={() => console.log}
      >
        <ModalHeader toggle={() => setModalFotoSelfie(!modalFotoSelfie)}>Ambil Foto Selfie</ModalHeader>
        <ModalBody>
          <div className="container">
            <Webcam
              audio={false}
              height={500}
              onUserMedia={() => setBtnDisabled(false)}
                onUserMediaError={() => {Swal.fire('Info','Camera tidak ditemukan','warning');setBtnDisabled(true)}}
              ref={refClick}
              screenshotFormat="image/jpeg"
              width={600}
              videoConstraints={videoConstraints}
            />
          </div>
        </ModalBody>
        <ModalFooter>
        <Button color="secondary" onClick={() => setModalFotoSelfie(!modalFotoSelfie)}>
            Batal
          </Button>
          <Button color="primary" disabled={btnDisabled} onClick={() => onClickBoxPhoto()}>
            Ambil Foto
          </Button>
          
        </ModalFooter>
      </Modal>
    
    </>
  );
};

export default FotoSelfiePengajuan;
