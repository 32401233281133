import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import InputGroup from "../GroupInput/InputGroup";
import MastersApi from "../../services/masters/masters.api";
import DataTable from 'react-data-table-component';
import { setLoadingAsync } from '../../services/globals/loading.reducer';
import { customStyles } from "../CustomStyles/CustomStyles";
import Formatting from "utils/formattingJson";
import MastersApiSyariah from "../../services/masters/masters_syariah.api";
import { decodeAuth } from "utils/cookie";

const OptionsCek = ({dispatch, title, show, parsingData, setShow, hideModal, dataOptions, isProfile, params}) => {

    const [selectedOptions, setSelectedOptions] = useState(null);
    
    const columns = [
      {
        name: 'Pilih',
        cell: (row,index) => <input type="radio" name="pilih_kelurahan" onClick={() => setSelectedOptions(row)} />,
        center: true,
        // sortable: true,
    },
    {
      name: 'Nama Bank',
      selector: row => row.nama_bank,
      center: true,
      // sortable: true,
    },
    {
      name: 'Nomor Giro Cek',
      selector: row => row.nomor_cek,
      center: true,
      // sortable: true,
    },
    ];

    const [searchOpen, setSearchOpen] = useState(false);
    const [paramsFetch, setParamsFetch] = useState([]);
    
    const [search, setSearch] = useState({
      nomorcek: '',
    })

    useEffect(() => {
        dispatch(setLoadingAsync(true))
        fetchData(1).then((res) => {
          setParamsFetch(res)
          console.log("resss",res)
          dispatch(setLoadingAsync(false))
        }).catch((err) => {
          dispatch(setLoadingAsync(false))
        })
    }, [])

    const fetchData = () => {
      return MastersApi.getPlafonGiroCek(`?nomorcek=${search.nomorcek}${params}`)
    }

    const onChange = (v) => {
        setSearch({ ...search, [v.target.name]: v.target.value })
    }
    
      const onChangePageSize = (v) => {
        dispatch(setLoadingAsync(true))
        fetchData(1, v ).then((res) => {
          setParamsFetch(res)
          dispatch(setLoadingAsync(false))
        })
      }
    
      const onChangePagination = (v) => {
        dispatch(setLoadingAsync(true))
        fetchData(v, paramsFetch?.page_size ).then((res) => {
          setParamsFetch(res)
          dispatch(setLoadingAsync(false))
        })
      }
    
      const onClickSearch = () => {
        dispatch(setLoadingAsync(true))
        fetchData(1, paramsFetch.page_size).then((res) => {
          setParamsFetch(res)
          dispatch(setLoadingAsync(false))
        }).catch((err) => {
          setParamsFetch([])
          dispatch(setLoadingAsync(false))
        })
      }
    return (
        <Modal
            isOpen={show}
            centered
            size="xl"
            scrollable
            toggle={() => hideModal()}
        >
            <ModalHeader toggle={() => hideModal()}>{title}</ModalHeader>
            <ModalBody>
            <div className="row table-search mb-0">
          <span className="txt-body hand-click" onClick={() => setSearchOpen(!searchOpen)}>{searchOpen ? `-` : `+`} Search </span>
          <hr className="mt-2"/>
    </div>
    <div className="row table-search-body" style={{display: searchOpen ? "" : "none"}}>
        <div className="col-6 col-md-3">
          <span className="txt-body">Nomor Giro Cek</span>
        </div>
        <div className="col-6 col-md-3">
          <input className="input-table" name="nomorcek" onChange={onChange}></input>
        </div>
        <div className="col-12">
          <button className="btn-table mt-2" onClick={onClickSearch}>View</button>
        </div>
    </div>
    <div className="row">
      <div className="col-12">
        <DataTable
            columns={columns}
            data={paramsFetch}
            customStyles={customStyles}
            highlightOnHover
            // pagination
            dense
            responsive
            persistTableHead
            fixedHeader
            paginationServer
            paginationTotalRows={paramsFetch?.total}
            // onChangeRowsPerPage={onChangePageSize}
            // onChangePage={onChangePagination}
            // sortServer
            // onSort={handleSort}
            // progressPending={loading}
        />
      </div>
        
    </div>
            </ModalBody>
            <ModalFooter>
            <Button color="secondary" onClick={() => hideModal()}>Batal</Button>

                <Button color="primary" disabled={selectedOptions === null} onClick={() => parsingData(selectedOptions)}>Simpan</Button>{' '}
            </ModalFooter>
        </Modal>
    );
}

const mapStateToProps = function (state) {

  return {
    isLoading: state.global.isLoading,
    isProfile:  state.profile
  };
};

export default connect(mapStateToProps)(OptionsCek);