export const KA_UNIT = 35
export const KA_POS = 28
export const KA_CAB = 20
export const KA_WIL = 24
export const WAKACAB = 32
export const DIREKTUR =  2
export const DIRUT = 1
export const KA_AREA = 24
export const HO = 33
export const FINANCE = 14
export const KASIR = 22
export const PENAKSIR = 21
export const ADMIN = 7
export const RISK = 45
export const FINANCE_STAFF = 15
export const CSDO = 46
export const ALL_MODUL_KA_UNIT = 54
export const PENAKSIR_KASIR = 55
export const KA_UNIT_PENAKSIR = 56
export const STAFF_BISNIS = 27
export const AUDIT_HEAD = 18
export const AUDIT_STAFF = 19
export const HR_STAFF = 9
export const HR_HEAD = 8
export const STAFF_ADMIN_PROPINSI = 23
export const OPR_MANAGER = 31
export const OPR_SPV = 38
export const ACC_STAFF = 13
export const ACC_HEAD = 12
export const COLLECTION = 57
export const KA_UNIT_MIGRASI = 58
export const PENAKSIR_KASIR_MIGRASI = 59
export const BISNIS_SUPPORT_WILAYAH = 60
export const KADIV_BISNIS = 4
export const OFS = 62
export const KADEPT_CPA = 63
export const KADIV_CPA = 64
export const OJT_PENAKSIR = 66