import React, { useRef } from 'react'

const Button = ({inputType,label,disabled,maxLength,minLength,max,min,readonly,ref, inputClick,clickable, fullWidth,isBold }) => {
 
  return (
  
    <div className={`mt-3 ${fullWidth? "col-lg-12" : "col-lg-6"} d-flex`} style={{fontWeight: isBold ? 'bold' : 'normal'}}>
      <button onClick={() => inputClick()} disabled={disabled} className="btn-cek2 btn-primary ">
      {label}
      </button >
    </div>
  )
}

export default Button