import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import chevronBottom from "../../styles/images/chevron-bottom.svg";
import chevronRight from "../../styles/images/chevron-right.svg";

import Dropdown from "./Dropdown";

const MenuItems = ({ items, depthLevel,userId,hideModals,hidemodalMobile }) => {
  const navigate = useNavigate();
  const [dropdown, setDropdown] = useState(false);

  let ref = useRef();

  useEffect(() => {
    const handler = (event) => {
      if (dropdown && ref.current && !ref.current.contains(event.target)) {
        setDropdown(false);
      }
    };
    document.addEventListener("mousedown", handler);
    document.addEventListener("touchstart", handler);
    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", handler);
      document.removeEventListener("touchstart", handler);
    };
  }, [dropdown]);

  const onMouseEnter = () => {
    window.innerWidth > 960 && setDropdown(true);
  };

  const onMouseLeave = () => {
    window.innerWidth > 960 && setDropdown(false);
  };

  const onClickMobileVersion = (v) => {
    if(window.innerWidth < 660 ){
      setDropdown(!dropdown)
    }
    if(v.target.classList.contains("arrow")){
      setDropdown(!dropdown)

    }
    console.log(v.target.getAttribute('aria-expanded'))
  }


  return (
    <li
      className="menu-items"
      ref={ref}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      style={{display: items?.akses?.includes(userId) ? "" : "none"}}
      onClick={(v) => onClickMobileVersion(v)}
    >
      {items.submenu ? (
        <>
          <button
            type="button"
            aria-haspopup="menu"
            aria-expanded={dropdown ? "true" : "false"}
          >
            {items.title}{" "}
            {depthLevel > 0 ? <img src={chevronRight} className="arrow-right" /> : <img src={chevronBottom} className="arrow" />}
          </button>
          <Dropdown
          setDropdown={setDropdown}
            depthLevel={depthLevel}
            submenus={items.submenu}
            dropdown={dropdown}
            userId={userId}
          />
        </>
      ) : (
        <a style={{cursor: "pointer"}} onClick={() => navigate(items.link) }>{items.title}</a>
      )}
    </li>
  );
};

export default MenuItems;
