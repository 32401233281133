import React from 'react'
import ReactSelect from '../Select/Select';

const Dropdown = ({ inputLabel, inputValue, disabled, inputChange, inputName, inputOptions, isRequired, classNames='search-select', fullWidth, isBold }) => {
  // console.log(inputValue)
  // console.log(inputOptions)

  return (
    // <div className="col-lg-6 mt-3 d-flex justify-content-between">
    //   <label className="txt-body-modal">{inputLabel}</label>
    //   <ReactSelect value={inputValue} disabled={disabled} onChange={(v) => inputChange(v, inputName)} className={classNames} options={inputOptions}>  </ReactSelect>
    // </div>

    <div className={`mt-3 ${fullWidth? "col-12 col-lg-12" : "col-lg-6 col-12"} d-flex`}>
      <div className={`${fullWidth? "col-lg-3 col-6" : "col-lg-5 col-6"}`}>
      <label class="txt-body-modal">{inputLabel} <span style={{color: `${isRequired ? 'red' : ''} `, fontWeight: isBold ? 'bold' : 'normal'}}>
        {isRequired ? '*' : ''}</span>
      </label>
    </div>
    <div className={`${fullWidth? "col-lg-9 ml-full col-6" : "col-lg-7 col-6"}`} >
    <ReactSelect value={inputValue} disabled={disabled} onChange={(v) => inputChange(v, inputName)} className={`${classNames} ${fullWidth? "search-select-full" : ""}`} options={inputOptions}>  </ReactSelect>
    </div>
    </div>
  )
}

export default Dropdown