import { ClapSpinner } from "react-spinners-kit";

const Loading = () => {
  return (
    <div className="outer-container">
      <div className="inner-container">
          <div className="inner-loading">
            <div className="centered-content">
            <ClapSpinner
              size={45}
              frontColor="#4440A8"
              backColor="#4440A8"
            />
            </div>
          </div>
          <div>
            <span className="text-loading color-green">Loading<span className="menunggu">...</span></span>
          </div>
      </div>
    </div>
  );
};

export default Loading;