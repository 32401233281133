import * as axios from "axios";
import qs from "querystring";
import { Buffer } from 'buffer';
import { deleteAllToken, getToken, saveToken } from "./cookie";
import { toast } from 'react-toastify';
import Formatting from "./formattingJson";
import dayjs from "dayjs";

axios.interceptors.response.use(
  function (response) {
    return response;
  },

  function (error) {
    const originalRequest = error.config;
    console.log("config axios", error.response)
    if (error.response === undefined) {
      toast.error("Ups, terjadi kesalahan! Error : Undefined");
    }

    if (error?.response?.status === 413) {
      toast.error("Ups, terjadi kesalahan! Error : 413");
      return Promise.reject(error)
    }
    if (error?.response?.statusCode === 502) {
      toast.error("Ups, terjadi kesalahan! Error : 502");
      return Promise.reject(error)
    }

    // if (error?.response?.status === 401 && originalRequest?.url !== 'msgadaimasuser/v1/user/oauth2/refresh') {
    //   toast.error("Ups, terjadi kesalahan!");
    //   return Promise.reject(error)
    // }

    if (error?.response?.status === 401 && originalRequest?.url === 'msgadaimasuser/v1/user/oauth2/refresh') {
      let token = getToken(process.env.REACT_APP_KEY_TOKEN);
      let headers = {
        "Content-Type": "application/x-www-form-urlencoded",
        "api-key": "vSqJ7AEvXSBUUSHQ9ojrMpc9m02qXCsw3L924Mtt8AjtyvVG9D",
        "Authorization": `Bearer ${token}`
      };

      const client = {
        baseURL: process.env.REACT_APP_API_ENDPOINT,
        headers: originalRequest?.headers,
      };
      axios.post("msgadaimasuser/v1/user/ouath2/logout", {}, client).then((res) => {
        deleteAllToken();
        toast.error("[401&oauth2Refresh] Session Expired, Silahkan Login Kembali")
        setTimeout(() => {
          window.location.reload()

        }, 5000);
      });
      return Promise.reject(error);
    }

    if (error?.response?.status === 401 && originalRequest?.url !== 'msgadaimasuser/v1/user/oauth2/refresh') {
      if (error?.response?.data?.Response?.errorMessage === "token contains an invalid number of segments") {
        deleteAllToken();
        toast.error("[401&segments] Session Expired, Silakan Login Kembali")
        setTimeout(() => {
          window.location.reload()

        }, 5000);
      } else if (error?.response?.data?.Response?.errorMessage === "signature is invalid") {
        deleteAllToken();
        toast.error("[401&Invalid] Session Expired, Silakan Login Kembali")
        setTimeout(() => {
          window.location.reload()

        }, 5000);
      } else {
        let basicAuth = Buffer.from(`${process.env.REACT_APP_UNAME_AUTH}:${process.env.REACT_APP_PASSWD_AUTH}`, 'utf8').toString('base64');
        let headers = originalRequest?.headers;
        headers.Authorization = `Basic ${basicAuth}`
        // {
        //   "Content-Type": "application/x-www-form-urlencoded",
        //   "api-key" : "vSqJ7AEvXSBUUSHQ9ojrMpc9m02qXCsw3L924Mtt8AjtyvVG9D",
        //   "Authorization": `Basic ${basicAuth}`
        // };
        let refresh_token = getToken(process.env.REACT_APP_KEY_REFRESH_TOKEN);

        if (refresh_token) {
          const client = {
            baseURL: process.env.REACT_APP_API_ENDPOINT,
            headers: headers,
          };

          return axios.post("msgadaimasuser/v1/user/oauth2/refresh", JSON.stringify({ refresh_token }), client).then((res) => {
            saveToken(res?.data?.Response?.data?.access_token, res?.data?.Response?.data?.refresh_token);
            error.config.headers['Authorization'] = 'Bearer ' + res?.data?.Response?.data?.access_token;
            return axios.request(error.config).then((res) => res || {});
          }).catch((err) => {
            deleteAllToken();
            toast.error("[401&Undefined] Session Expired, Silakan Login Kembali")
            setTimeout(() => {
              window.location.reload()

            }, 5000);
          });
        }
      }


      return Promise.reject(error);
    }
    return Promise.reject(error);
  }
);

const Api = () => ({
  Config: ({ withoutToken = false, multipartForm, rawJSON, isSignature, body, url, isTimestamp = true, isHtml = false, timestampV2 = false }) => {
    let api_token = getToken(process.env.REACT_APP_KEY_TOKEN);
    let basicAuth = Buffer.from(`${process.env.REACT_APP_UNAME_AUTH}:${process.env.REACT_APP_PASSWD_AUTH}`, 'utf8').toString('base64');

    let headers = {
      "Content-Type": "application/x-www-form-urlencoded",
      "api-key": "vSqJ7AEvXSBUUSHQ9ojrMpc9m02qXCsw3L924Mtt8AjtyvVG9D",
    };

    if (withoutToken) {
      headers["Authorization"] = `Basic ${basicAuth}`;
    } else {
      headers["Authorization"] = `Bearer ${api_token}`;
    }

    if (multipartForm) {
      headers["Content-Type"] = "multipart/form-data";
    }

    if (isHtml) {
      headers["Accept"] = "text/html";
    }


    if (rawJSON) {
      headers["Content-Type"] = "application/json";
    }

    // console.log(url)

    if (isSignature) {
      let timestamp = timestampV2 ? dayjs().tz("Asia/Jakarta").format("YYYY-MM-DDTHH:mm+[07:00]") : dayjs().format('YYYYMMDDHHmm');
      console.log(timestamp)
      let jwt = multipartForm ? Formatting.GenerateJWTFormData(body, timestamp, process.env.REACT_APP_API_ENDPOINT + url?.split("?")[0]) : Formatting.GenerateJWT(body, timestamp, process.env.REACT_APP_API_ENDPOINT + url?.split("?")[0])
      // console.log("jwt",jwt)
      headers["api-key"] = jwt?.split('.')[2];
      headers["timestamps"] =  timestamp;

    }

    const client = {
      baseURL: process.env.REACT_APP_API_ENDPOINT,
      headers: headers,
      timeout: 60000
    };
    return client;
  },
  Get: (url = "", config) => {
    return axios
      .get(url, Api().Config({ withoutToken: config?.withoutToken, isSignature: config?.isSignature, timestampV2: config?.timestampV2, body: {}, url, isTimestamp: config?.isTimestamp }))
      .then((res) => res?.data || {});
  },
  GetHTML: (url = "", config) => {
    return axios
      .get(url, Api().Config({ withoutToken: config?.withoutToken, isSignature: config?.isSignature, body: {}, url, isTimestamp: config?.isTimestamp, isHtml: config?.isHtml }))
      .then((res) => res);
  },
  Post: (url, body, config) => {
    return axios
      .post(
        url,
        qs.stringify(body),
        Api().Config({ withoutToken: config?.withoutToken })
      )
      .then((Response) => Response);
  },
  PostFile: (url, body, config) => {
    return axios
      .post(url, body, Api().Config({ withoutToken: config?.withoutToken, multipartForm: config?.multipartForm }))
      .then((res) => res?.Response || {});
  },
  PostFileAbsen: (url, body, config) => {
    return axios
      .post(url, body, Api().Config({ withoutToken: config?.withoutToken, multipartForm: config?.multipartForm, isSignature: config?.isSignature, body, url }))
      .then((res) => res?.data.Response || {});
  },
  postRaw: (url, body, config) => {
    return axios
      .post(url, body, Api().Config({ withoutToken: config?.withoutToken, rawJSON: config?.rawJSON }))
      .then((res) => res?.Response || {});
  },
  postResetPassword: (url, body, config) => {
    return axios
      .post(url, body, Api().Config({ withoutToken: config?.withoutToken, rawJSON: config?.rawJSON }))
      .then((res) => res || {});
  },
  postRaw2: (url, body, config,) => {
    return axios
      .post(url, body, Api().Config({ withoutToken: config?.withoutToken, rawJSON: config?.rawJSON, isSignature: config?.isSignature, timestampV2: config?.timestampV2, body, url, isHtml: config?.isHtml }))
      .then((res) => res);
  },
  PutOpenCashier: (url, body, config) => {
    return axios
      .put(url, body, Api().Config({ withoutToken: config?.withoutToken, rawJSON: config?.rawJSON }))
      .then((res) => res?.Response || {});
  },
  postLogin: (url, body, config) => {
    return axios
      .post(url, body, Api().Config({ withoutToken: config?.withoutToken, rawJSON: config?.rawJSON }))
      .then((res) => res?.data?.Response || {});
  },
  postLogout: (url, body, config) => {
    return axios
      .post(url, body, Api().Config({ withoutToken: config?.withoutToken, rawJSON: config?.rawJSON }))
      .then(() => true);
  },
});

export default Api;
