import React, { useEffect, useState } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import InputGroup from "components/GroupInput/InputGroup";
import MastersApi from "services/masters/masters.api";
import Formatting from "utils/formattingJson";
import { setLoadingAsync } from "services/globals/loading.reducer";
import cloneDeep from "lodash.clonedeep";
import dayjs from "dayjs";
import { useRef } from "react";
import Swal from "sweetalert2";
import { customStyles } from "components/CustomStyles/CustomStyles";
import DataTable from "react-data-table-component";
import FotoSelfiePengajuan from "./FotoSelfiePengajuan";
import OptionsProduk from "components/ModalOptions/OptionsProduk";
import { useDebounce } from "use-debounce";
import HelperFunction from "utils/HelperFunctions";
import TambahDetailModal from "../BufferTakeoverNew/TambahDetailEmasModal";

const ModalViewBufferTakeOver = ({ title, show, parsingData, hideModal, dispatch, isProfile, obj }) => {
  const [formData, setFormData] = useState({
    id_jenis_full_fill: 0,
    keterangan: "",
  });
  const [produkDetail, setProdukDetail] = useState(null);
  const [produkModal, setProdukModal] = useState(false);

  const [modalFotoSelfie, setModalFotoSelfie] = useState(false);
  const [imageSelfie, setImageSelfie] = useState();
  const [paramsFetch, setParamsFetch] = useState([]);
  const [detailUnit, setDetailUnit] = useState();
  const [nominalRequest, setNominalRequest] = useState(0);
  const [keterangan, setKeterangan] = useState("");
  const [modalDetailBarang, setModalDetailBarang] = useState(false);
  const [modalEditBarang, setModalEditBarang] = useState(false);
  const [jenisEmasOptions, setJenisEmasOptions] = useState();
  const [stleForm, setStleForm] = useState([]);
  const [selectedMitraGadai, setSelectedMitraGadai] = useState(null);
  const [mitraGadaiOptions, setMitraGadaiOptions] = useState([]);
  const [totalTaksir, setTotalTaksir] = useState(0);
  const [dataEdit, setDataEdit] = useState();
  const [dataHitungGadai, setDataHitungGadai] = useState(null);

  const [dataTaksir] = useDebounce(paramsFetch, 1000);
  const [rateFlat] = useDebounce(formData?.RateFlat, 1000);
  const [NilaiPinjaman] = useDebounce(formData?.NilaiPinjaman, 1000);

  const toFixed = (num, fixed) => {
    var re = new RegExp('^-?\\d+(?:\.\\d{0,' + (fixed || -1) + '})?');
    return num?.toString().match(re)[0] || "";
}

  useEffect(() => {
    console.log(obj);
    dispatch(setLoadingAsync(true));
    MastersApi.getmutasiBufferTakeOver(`?idBufferTO=${obj?.idBufferTO}`)
      .then((res) => {
        MastersApi.getAjukanBufferTakeOver().then((res) => {
          let dataItem = [];

          res?.STLE?.map((item) => {
            dataItem.push({
              formType: "text",
              label: `STLE ${item?.namaJenisEmas}`,
              value: Formatting.currency(item?.harga),
              disabled: true,
            });
          });

          setStleForm(dataItem);
        });
        setDetailUnit(res?.detailUnit);
        setFormData({ ...res?.BuffertakeoverTaksiran, ...res?.detailUnit, historyApprove: res?.HistoryApprovalBufferTakeOver });
        setJenisEmasOptions(res?.STLE);

        MastersApi.getMitraGadai(`?is_Active=1&=10000`).then((res) => {
          setMitraGadaiOptions(Formatting.formatRC(res, "id_mitra_pegadaian", "nama_mitra_pegadaian"));
          dispatch(setLoadingAsync(false));
        });
      })
      .catch((err) => {
        dispatch(setLoadingAsync(false));
        hideModal();
        Swal.fire("Info", err.response.data.Response.errorMessage, "warning");
      });
  }, []);

  const hitungGadai = () => {
    dispatch(setLoadingAsync(true));

    let cloneArr = cloneDeep(paramsFetch);
    let newArr = [];

    cloneArr.map((x) => {
      x.jumlah = parseInt(x.jumlah);
      delete x.beratBersih;
      delete x.taksir;
      delete x.taksiran;
      delete x.min_karat;

      newArr.push(x);
    });

    let data = {
      idProduk: produkDetail?.id_produk,
      detailJaminan: newArr,
      totalTaksiran: totalTaksir,
      nilaiPinjaman: formData?.NilaiPinjaman || 0,
      rate: parseFloat(formData?.RateFlat) || 0,
    };

    MastersApi.postHitungGadai(data)
      .then((res) => {
        dispatch(setLoadingAsync(false));
        setDataHitungGadai(res?.Response?.data);
        console.log(res?.Response?.data);
        setFormData({
          ...formData,
          RateFlat: !formData?.RateFlat ? res?.Response?.data?.rateMin : formData?.RateFlat,
          Ltv: !formData?.Ltv ? res?.Response?.data?.ltv : formData?.Ltv,
          NilaiPinjaman: !formData?.NilaiPinjaman ? res?.Response?.data?.nilaiPinjaman : formData?.NilaiPinjaman,
        });
      })
      .catch((err) => {
        dispatch(setLoadingAsync(false));
      });
  };

  useEffect(() => {
    if (paramsFetch.length === 0 || !produkDetail) return;

    hitungGadai();
  }, [dataTaksir, rateFlat, NilaiPinjaman, produkDetail]);

  const handleOnChangeSelect = (v, name) => {
    if (name === "idMitraGadai") {
      setSelectedMitraGadai(v);
    }
  };

  const handleOnChange = (evt, name) => {
    if (evt.target.name === "NilaiPinjaman") {
      if (parseInt(Formatting.onlyNumber(evt.target.value)) > parseInt(totalTaksir)) return;

      setFormData({ ...formData, [evt.target.name]: parseInt(Formatting.onlyNumber(evt.target.value)) });
      return;
    }
    setFormData({ ...formData, [evt.target.name]: evt.target.value });
  };

  const form = [
    {
      formType: "text",
      label: "Nama Unit",
      value: detailUnit?.namaCabang,
      name: "nama_cabang",
      disabled: true,
    },
    {
      formType: "text",
      label: "Tanggal Sistem",
      value: dayjs(detailUnit?.tanggal).format("DD MMMM YYYY"),
      disabled: true,
    },
    {
      formType: "text",
      label: "No Ktp",
      value: formData?.noKtp,
      onChange: handleOnChange,
      name: "noKtp",
      disabled: true,
    },
    {
      formType: "text",
      label: "Nama Peminjam",
      value: formData?.namaPeminjam,
      name: "namaPeminjam",
      onChange: handleOnChange,
      disabled: true,
    },
    {
      formType: "text",
      label: "Asal Gadai TO",
      option: mitraGadaiOptions,
      onChange: handleOnChangeSelect,
      value: formData?.namaMitraPegadaian,
      disabled: true,
    },
    {
      formType: "text",
      label: "Nomor SBG TO",
      value: formData?.noSbgTO,
      name: "noSbgTO",
      disabled: true,
    },
    {
      formType: "textArea",
      label: "Keterangan",
      value: formData?.keterangan,
      name: "keterangan",
      disabled: true,
    },
    {
      formType: "text",
      label: "Total Hutang TO",
      value: Formatting.currency(formData?.hutangTo, true),
      name: "hutangTo",
      disabled: true,
    },
  ];

  const isDisabled = () => {
    return false;
  };

  const columnsTransfer = [
    {
      name: "Nama Emas",
      selector: (row) => row.namaJenisEmas,
      center: true,
      // sortable: true,
    },
    {
      name: "Jumlah",
      selector: (row) => Formatting.currency(parseInt(row.jumlah)),
      center: true,
      // sortable: true,
    },
    {
      name: "Keterangan",
      selector: (row) => row.keterangan,
      center: true,
      // sortable: true,
    },
    {
      name: "Karat",
      selector: (row) => Formatting.currency(parseInt(row.karat)),
      center: true,
      // sortable: true,
    },
    {
      name: "Gram",
      selector: (row) => Formatting.currency(parseInt(row.beratBersih)),
      center: true,
      // sortable: true,
    },
    {
      name: "Status Antam",
      selector: (row) => row.namaJenisEmas,
      center: true,
      // sortable: true,
    },
    {
      name: "Nilai Taksir",
      selector: (row) => Formatting.currency(row.taksiran),
      center: true,
      // sortable: true,
    },
  ];

  const keteranganForm = [
    {
      formType: "text",
      label: "Kode Produk",
      value: formData?.namaProduk,
      name: "kode_produk",
      placeholder: "Pilih Kode Produk",
      disabled: true,
    },
    {
      formType: "text",
      label: "Total Taksiran",
      value: Formatting.currency(formData?.totalTaksiran),
      name: "nama_peminjam",
      disabled: true,
    },
    {
      formType: "text",
      label: "Nilai Pinjaman",
      onChange: handleOnChange,
      value: Formatting.currency(formData?.nilaiPinjaman),
      name: "NilaiPinjaman",
      disabled: !dataHitungGadai ? true : false,
    },
    {
      formType: "text",
      label: "LTV",
      onChange: handleOnChange,
      value:  toFixed(formData?.ltv, 2) + " %",
      name: "Ltv",
      disabled: true,
    },

    {
      formType: "text",
      label: "Rate",
      onChange: handleOnChange,
      value: formData?.rateFlat,
      name: "RateFlat",
      disabled: !dataHitungGadai ? true : false,
    },
  ];

  const approvalForm = [
    {
      formType: "text",
      label: "Final Approval",
      value: formData?.jenisApproval?.filter((x) => x.jenisApproval === "appFinal")[0]?.levelApproval,
      name: "nama_peminjam",
      disabled: true,
    },
    {
      formType: "text",
      label: "Approval Nilai Pinjaman",
      value: formData?.jenisApproval?.filter((x) => x.jenisApproval === "ltv")[0]?.levelApproval,
      name: "nama_peminjam",
      disabled: true,
    },
    {
      formType: "text",
      label: "Approval LTV",
      value: formData?.jenisApproval?.filter((x) => x.jenisApproval === "nilaiPinjaman")[0]?.levelApproval,
      name: "nama_peminjam",
      disabled: true,
    },
    {
      formType: "text",
      label: "Approval Rate",
      value: formData?.jenisApproval?.filter((x) => x.jenisApproval === "rate")[0]?.levelApproval,
      name: "nama_peminjam",
      disabled: true,
    },
  ];

  console.log("formData", formData);

  const fileUploadForm = [
    {
      formType: "button",
      label: 'Form Pernyataan Nasabah',
      onClick: () => {
        HelperFunction.downloadFile(formData?.fileDanaTalangan, "file_bukti_talangan");
      },
      name: "fileDanaTalangan",
    },
    {
      formType: "",
    },
    {
      formType: "button",
      label: "Foto KTP",
      name: "fileKtp",
      onClick: () => {
        HelperFunction.downloadFile(formData?.fileKtp, "file_bukti_ktp");
      },
    },
    {
      formType: "",
    },
    {
      formType: "button",
      label: "Foto SBG",
      value: "Kacab",
      name: "fileSbg",
      onClick: () => {
        HelperFunction.downloadFile(formData?.fileSbg, "file_bukti_sbg");
      },
    },
    {
      formType: "",
    },
    {
      formType: "button",
      label: "Foto Nasabah",
      value: "Kacab",
      name: "fileFotoNasabah",
      onClick: () => {
        HelperFunction.downloadFile(formData?.fileFotoNasabah, "file_foto_nasabah");
      },
    },
    {
      formType: "",
    },
    {
      formType: "button",
      label: "Selfie KaUnit",
      value: "selfieKaunit",
      name: "selfieKaunit",
      onClick: () => {
        HelperFunction.downloadFile(formData?.selfieKaunit, "selfie_kaunit");
      },
    },
  ];

  const addBarangJaminan = (evt) => {
    console.log("barang jaminan", evt);

    evt.gram = parseFloat(evt.beratBersih);
    evt.nilaiTaksir = parseInt(evt.taksiran);
    evt.karat = evt.min_karat;

    let totals = 0;

    paramsFetch?.map((v) => {
      totals += parseInt(v.taksiran);
    });

    totals += parseInt(evt.taksiran);
    setTotalTaksir(totals);
    setParamsFetch([...paramsFetch, evt]);

    setModalDetailBarang(false);
  };

  const columnsApproval = [
    {
      name: "Level Approval",
      selector: (row) => row.levelApproval,
      center: true,
      // sortable: true,
    },
    {
      name: "Status Approval",
      selector: (row) => (row.statusApproval === 1 ? "Approve" : row?.statusApproval === 2 ? "Reject" : "Pending"),
      center: true,
      // sortable: true,
    },
    {
      name: "Keterangan",
      selector: (row) => row.keteranganApproval,
      center: true,
      // sortable: true,
    },
    {
      name: "Time Approval",
      selector: (row) => (row.timeApproval === "0001-01-01T00:00:00Z" || !row?.timeApproval ? "-" : dayjs(row.timeApproval).format("DD-MMM-YYYY, HH:mm")),
      center: true,
      // sortable: true,
    },
  ];

  return (
    <>
      {modalDetailBarang && (
        <TambahDetailModal
          dispatch={dispatch}
          detailEmas={jenisEmasOptions}
          hideModal={() => {
            setModalDetailBarang(false);
          }}
          show={modalDetailBarang}
          // obj={dataEdit}
          parseData={addBarangJaminan}
        />
      )}

      <Modal isOpen={show} centered size="xl" scrollable toggle={() => hideModal()}>
        <ModalHeader toggle={() => hideModal()}>{title}</ModalHeader>
        <ModalBody>
          <InputGroup formGroup={form} />
          <InputGroup formGroup={stleForm} />
          <div className="col-12 mt-4">
            <p className="txt-body text-center bg-border py-2">Detail Emas</p>
          </div>
          <div style={{ marginTop: "20px" }}>
            <DataTable
              columns={columnsTransfer}
              data={formData?.detail}
              customStyles={customStyles}
              highlightOnHover
              // pagination
              dense
              responsive
              persistTableHead
              fixedHeader
            />
          </div>
          <InputGroup formGroup={keteranganForm} />
          <InputGroup formGroup={approvalForm} />
          <InputGroup formGroup={fileUploadForm} />
          <div className="col-12 mt-4">
            <p className="txt-body text-center bg-border py-2">Detail Approval</p>
          </div>
          <div style={{ marginTop: "20px" }}>
            <DataTable
              columns={columnsApproval}
              data={formData?.historyApprove}
              customStyles={customStyles}
              highlightOnHover
              // pagination
              dense
              responsive
              persistTableHead
              fixedHeader
            />
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={() => hideModal()}>
            Tutup
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default ModalViewBufferTakeOver;
