import Api from "../../utils/api";
import { saveToken, deleteAllToken } from "../../utils/cookie";

const AuthApi = {
  postAbsen: function (absen, data) {
    return Api()
      .PostFileAbsen(`msgadaimasuser/v1/user/absen?keterangan=${absen}`, data, { withoutToken: true, rawJSON: true })
      .then((datas) => {
        return datas;
      });
  },
  postLogin: function (data) {
    return Api()
      .postLogin(`msgadaimasuser/v1/user/oauth2/login`, data, { withoutToken: true, rawJSON: true })
      .then(({ data }) => {
        // console.log("response login", data);
        saveToken(data?.access_token, data?.refresh_token);
        return data;
      });
  },
  postLogout: function (data) {
    return Api()
      .postLogout(`msgadaimasuser/v1/user/ouath2/logout`, data, { rawJSON: true })
      .then(() => {
        deleteAllToken();
        return true;
      });
  },
  getProfile: function () {
    return Api()
      .Get(`msgadaimasuser/v1/user/dashoard`)
      .then(({ Response }) => {
        return Response.data;
      });
  },
  getMenu: function (params) {
    return Api()
      .Get(`msgadaimasuser/v1/user/menu`, { isSignature: false })
      .then(({ Response }) => {
        return Response.data;
      });
  },

  postResetPassword: function (data) {
    return Api()
      .postRaw("msgadaimasuser/v1/user/resetpassword", data, { withoutToken: true, rawJSON: true })
      .then((res) => {
        return res;
      });
  },
  postGetPasswordDefault: function (data) {
    return Api()
      .postResetPassword("msgadaimasuser/v1/user/resetpassword", data, { withoutToken: true, rawJSON: true })
      .then(({data}) => {
        return data;
      });
  },
};

export default AuthApi;
