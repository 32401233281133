import dayjs from 'dayjs';
import React, { useState } from 'react'
import Formatting from 'utils/formattingJson';
import DataTable from 'react-data-table-component';
import { customStylesPageOnly } from 'components/CustomStyles/CustomStylesPageOnly';
import ModalView from 'pages/Fa/Finance/PettyCashTransaction/ModalView';

const PettyCashHistoryTable = ({ paramsFetch, onChangePageSizeHis, onChangePaginationHis }) => {
    const [modalView, setModalView] = useState(false)
    const [detailData, setDetailData] = useState(null)

    return (
        <>
            {modalView && <ModalView obj={detailData} title="History Petty Cash" hideModal={() => setModalView(!modalView)}  show={modalView} />}
            <div className="row table-bordered mt-2">
                <table width={"100%"}>
                    <tr>
                        <th>No</th>
                        <th>Tgl Voucher</th>
                        <th>No Voucher</th>
                        <th>Tanggal nota</th>
                        <th>Nama Cabang</th>
                        <th>Kode Unit</th>
                        <th>Nama Unit</th>
                        <th> Total Transaksi</th>
                        <th>Detail Transaksi</th>
                        <th>Status Approval</th>
                        <th>Final Approval</th>
                        <th>Time KA Unit</th>
                        <th>Time  kacab</th>
                        <th>Time  RCEO</th>
                        <th>Time BFO</th>

                    </tr>
                    {paramsFetch?.data?.map((v, i) => (
                        <tr>
                            <td>{(i + 1)}</td>
                            <td>{!v?.tanggal_realisasi ? "-" : dayjs(v?.tanggal_realisasi).format("YYYY-MM-DD")}</td>
                            <td>{v?.kode_voucher}</td>
                            <td>{!v?.tanggal_nota ? "-" : dayjs(v?.tanggal_nota).format("YYYY-MM-DD")}</td>
                            <td>{v?.nama_cabang}</td>
                            <td>{v?.kode_unit}</td>
                            <td>{v?.nama_unit}</td>
                            <td>{!v?.total_realisasi ? "-" : Formatting.currency(v?.total_realisasi)}</td>
                            <td><button className="btn-primary btn" onClick={() => { setDetailData(v); setModalView(!modalView) }}>Detail</button></td>
                            <td>{v?.is_status === 0 ? "Need Approval Kaunit" : v?.is_status === 1 ? "Done" : v?.is_status === 9 ?  "Need Approval Kacab"  : v?.is_status === 10 ? "Need Approval Kawil" : v?.is_status === 11 ? "Checking BFO" : v?.is_status === 12 ? "Request FullFill" : v?.is_status === 13 && v?.is_transfer === 1  ? "Dropping Finance" : v?.is_status === 14  ? "Dropping BFO" :  v?.is_status === 3 & v?.is_reversal === 1 ? "Reversal" :  v?.is_status === 3 & v?.is_reversal === 2 ? "Reversal By Sistem"  : "Reject"}</td>
                            <td>{v?.approval_final}</td>
                            <td>{!v?.time_kaunit ? "-" : dayjs(v?.time_kaunit).format("YYYY-MM-DD HH:mm")}</td>
                            <td>{!v?.time_kacab ? "-" : dayjs(v?.time_kacab).format("YYYY-MM-DD HH:mm")}</td>
                            <td>{!v?.time_rceo ? "-" : dayjs(v?.time_rceo).format("YYYY-MM-DD HH:mm")}</td>
                            <td>{!v?.time_bfo ? "-" : dayjs(v?.time_bfo).format("YYYY-MM-DD HH:mm")}</td>
                            
                        </tr>
                    ))}

                </table>
            </div>
            <DataTable
                data={paramsFetch?.data}
                customStyles={customStylesPageOnly}
                pagination
                dense
                persistTableHead
                paginationServer
                paginationTotalRows={paramsFetch?.total}
                onChangeRowsPerPage={onChangePageSizeHis}
                onChangePage={onChangePaginationHis}
            />
        </>
    )
}

export default PettyCashHistoryTable