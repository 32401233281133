import React, { useEffect } from 'react'
import { useState } from 'react';
import { connect } from 'react-redux';
import AuthApi from 'services/auth/auth.api';
import { setLoadingAsync } from 'services/globals/loading.reducer';
import Swal from 'sweetalert2';

const LockOpenCashier = ({ children, dispatch, isProfile }) => {
  const [statusKasir, setStatusKasir] = useState(0);


  useEffect(() => {
      setStatusKasir(isProfile?.status_cabang?.status_outlet === "Close" ? 0 : 1)
      if (isProfile?.status_cabang?.status_outlet === "Close") {
        Swal.fire({
          title: 'Halaman tidak dapat diakses',
          text: "Silakan lakukan Open Kasir terlebih dahulu",
          icon: 'warning',
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'OK'
        })
      }
  }, [])

  if (statusKasir === 0) {
    return "";
  } else {
    return (
       children 
    )
  }

}



const mapStateToProps = function (state) {

  return {
    isLoading: state.global.isLoading,
    isProfile: state.profile
  };
};

export default connect(mapStateToProps)(LockOpenCashier)

// export default LockOpenCashier