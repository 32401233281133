import React from "react";
import { ProSidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { setMenuAsync } from "../../services/globals/menu.reducer";
import Formatting from "../../utils/formattingJson";

const MenuFA = ({isMenu, dispatch, isLoading}) => {
  const navigate = useNavigate();
  let pathname = window.location.pathname.split("/");
  const onClickMenu = () => {
    Formatting.delayExecution(100).then(() => {
      console.log("elementPro", checkElement())
      if(checkElement() > 0){
        dispatch(setMenuAsync(true));
      }else{
        dispatch(setMenuAsync(false));
      }
    })
  }

  const checkElement = () => {
    const allWithClass = Array.from(
      document.getElementsByClassName('pro-sub-menu open')
    );
    return allWithClass.length;
  }
  
    return (
          <ProSidebar className={!isLoading && isMenu ? "height100" : "heightAuto"} onClick={onClickMenu}>
            <Menu>
                <SubMenu title="Pembayaran">
                  <SubMenu title="Cair / Perpanjang">
                    <MenuItem>Pencairan Gadai</MenuItem>
                    <MenuItem>Pencairan Cicilan</MenuItem>
                    <MenuItem>Input Dana Talangan</MenuItem>
                  </SubMenu>
                  <SubMenu title="Pelunasan">
                    <MenuItem>Pelunasan Gadai</MenuItem>
                    <MenuItem>Pelunasan Cicilan</MenuItem>
                    <MenuItem>Pembayaran Cicilan</MenuItem>
                    <MenuItem>Input Titipan</MenuItem>
                    <MenuItem>Mutasi Titipan</MenuItem>
                  </SubMenu>
                  <SubMenu title="Query SBG Cair">
                    <MenuItem>Query Gadai</MenuItem>
                    <MenuItem>Query Cicilan</MenuItem>
                  </SubMenu>
                </SubMenu>

                <SubMenu title="Finance">
                  <MenuItem onClick={() => navigate("/fa/finance/open-cashier")} active={pathname[pathname.length-1] === "open-cashier"}>Open Cashier</MenuItem>
                  <MenuItem>Petty Cash</MenuItem>
                  <MenuItem>Mutasi Bank HO - Cabang</MenuItem>
                  <MenuItem>Mutasi Bank Cabang - HO</MenuItem>
                  <MenuItem>Mutasi Bank Internal Cabang</MenuItem>
                  <MenuItem>Mutasi Bank Cabang - Unit/Pos</MenuItem>
                  <MenuItem>Advance Internal</MenuItem>
                  <MenuItem>Advance Eksternal</MenuItem>
                  <MenuItem>Payment Request</MenuItem>
                  <MenuItem>Close Cashier</MenuItem>
                  <MenuItem>Query Saldo Kas & Bank</MenuItem>
                  <MenuItem>Rekon Bank</MenuItem>
                  <MenuItem>Advance Transfer</MenuItem>
                  <MenuItem>Payment Request HO</MenuItem>
                  <MenuItem>Correction</MenuItem>
                  <MenuItem>Suspend</MenuItem>
                  <MenuItem>BAP</MenuItem>
                  <MenuItem>Mutasi Bank HO Holding - Propinsi</MenuItem>
                  <MenuItem>Mutasi Bank Propinsi - HO Holding</MenuItem>
                </SubMenu>

                <SubMenu title="Treasury">
                  <MenuItem>Funding</MenuItem>
                  <MenuItem>Piutang Fintech</MenuItem>
                  <MenuItem>Hutang Fintech</MenuItem>
                  <MenuItem>Pinjaman Bank</MenuItem>
                  <MenuItem>Mutasi Flag Status Barang</MenuItem>
                  <MenuItem>Rekon Fintech</MenuItem>
                  <MenuItem>Saldo VA</MenuItem>
                  <MenuItem>Generate CEK</MenuItem>
                  <MenuItem>Joint Financing</MenuItem>
                </SubMenu>

                <SubMenu title="Accounting">
                  <MenuItem>Adjustment Memorial</MenuItem>
                  <MenuItem>GL Auto</MenuItem>
                  <MenuItem>Cadangan Piutang</MenuItem>
                  <MenuItem>Perhitungan BAK</MenuItem>
                  <MenuItem>Recurring</MenuItem>
                  <MenuItem>Monitoring Oracle</MenuItem>
                  <MenuItem>Jurnal Insentif</MenuItem>
                </SubMenu>

                <SubMenu title="Collection">
                  <MenuItem>Penanganan OVD</MenuItem>
                  <MenuItem>Query Overdue Customer</MenuItem>
                  <MenuItem>Surat Pemberitahuan 1</MenuItem>
                  <MenuItem>Surat Pemberitahuan</MenuItem>
                  <MenuItem>Konfirmasi by Email/Sms</MenuItem>
                  <MenuItem>Penanganan Nasabah</MenuItem>
                </SubMenu>
                <SubMenu title="Blanko">
                  <MenuItem>Generate Blanko</MenuItem>
                  <MenuItem>Mutasi Blanko</MenuItem>
                </SubMenu>
                <SubMenu title="Penjualan">
                  <MenuItem>Penjualan Barang Jaminan</MenuItem>
                </SubMenu>
                <SubMenu title="Insentif">
                  <MenuItem>Pembagian Insentif Admin</MenuItem>
                  <MenuItem>Upload Insentif</MenuItem>
                </SubMenu>
                <SubMenu title="ORM">
                  <MenuItem>Detail Event</MenuItem>
                  <MenuItem>Open</MenuItem>
                  <MenuItem>Close</MenuItem>
                </SubMenu>

            </Menu>
          </ProSidebar>
    );
};

const mapStateToProps = function (state) {
  
  return {
    isLoading: state.global.isLoading,
    isMenu: state.menu.isMenu,
  };
};

export default connect(mapStateToProps)(MenuFA);