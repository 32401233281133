import React from "react";
import Dropdown from "./ReactSelect";
import Text from "./Text";
import Checkbox from "./Checkbox";
import Null from "./Null";
import TextArea from "./TextArea";
import Formatting from "../../utils/formattingJson";
import FileInput from "./FileInput";
import Button from "./Button";
import MultiFileInput from "./MultiFileInput";

const InputGroup = ({ formGroup }) => {
  return (
    <div className="row container">
      {formGroup?.map((form) =>
        form?.formType == "" ? (
          <Null />
        ) : form?.formType == "textArea" ? (
          <TextArea
            isRequired={form.isRequired}
            fullWidth={form?.fullWidth}
            clickable={form?.clickable}
            isBold={form?.isBold}
            inputClick={form?.onClick}
            readonly={form?.readonly}
            inputType={
              form?.formType === "number" || form?.formType === "rupiah" || form?.formType === "rupiah2"
                ? "text"
                : form?.formType
            }
            inputLabel={form?.label}
            disabled={form?.disabled}
            placeholder={form?.placeholder}
            inputValue={
              form?.formType === "number"
                ? Formatting.onlyNumber(form?.value)
                : form?.formType === "float"
                  ? Formatting.floatNumber(form?.value)
                  : form?.formType === "rupiah"
                    ? Formatting.currency(form?.value, true)
                    : form?.formType === "rupiah2"
                      ? Formatting.currency(form?.value, true, true)
                      : form?.value
            }
            minLength={form?.minLength}
            inputChange={form?.onChange}
            inputName={form?.name}
            maxLength={form?.maxLength}
            min={form?.min}
            max={form?.max}
            defaultValue={form?.defaultValue}
          />
        ) : form?.formType == "dropdown" ? (
          <Dropdown
            isBold={form?.isBold}
            fullWidth={form?.fullWidth}
            isRequired={form.isRequired}
            classNames={form?.className}
            disabled={form?.disabled}
            inputLabel={form?.label}
            inputValue={form?.value}
            inputChange={form?.onChange}
            inputName={form?.name}
            inputOptions={form?.option}
          />
        ) : form?.formType == "text" ||
          form?.formType == "textNumberString" ||
          form?.formType == "date" ||
          form?.formType == "float" ||
          form?.formType == "number" ||
          form?.formType == "password" ||
          form?.formType === "rupiah" ||
          form?.formType === "rupiah2" ||
          form?.formType === "rupiahProduk" ? (
          <Text
            ref={form?.ref}
            isBold={form?.isBold}
            isRequired={form.isRequired}
            fullWidth={form?.fullWidth}
            clickable={form?.clickable}
            inputClick={form?.onClick}
            readonly={form?.readonly}
            onKeyUp={form?.onKeyUp}
            inputType={
              form?.formType === "textNumberString" || form?.formType === "number" || form?.formType === "rupiah" || form?.formType === "rupiah2"
                ? "text"
                : form?.formType
            }
            inputLabel={form?.label}
            disabled={form?.disabled}
            placeholder={form?.placeholder}
            inputValue={
              form?.formType === "number"
                ? Formatting.onlyNumber(form?.value) === ""
                  ? form?.value
                  : Formatting.onlyNumber(form?.value)
                : form?.formType === "float"
                  ? Formatting.floatNumber(form?.value)
                  : form?.formType === "rupiah"
                    ? Formatting.currency(form?.value, true)
                    : form?.formType === "rupiah2"
                      ? Formatting.currency(form?.value, true, true)
                      : form?.formType === "rupiahProduk"
                        ? Formatting.currencyProduk(form?.value, true)
                          : form?.formType === "textNumberString"
                          ? Formatting.onlyNumberString(form?.value, true, true)
                            : form?.value
            }
            minLength={form?.minLength}
            inputChange={form?.onChange}
            inputName={form?.name}
            maxLength={form?.maxLength}
            min={form?.min}
            max={form?.max}
            defaultValue={form?.defaultValue}
          />
        ) : form?.formType == "checkbox" ? (
          <Checkbox
            isRequired={form.isRequired}
            inputType={form?.formType}
            inputLabel={form?.label}
            disabled={form?.disabled}
            inputValue={form?.value}
            inputClick={form?.onClick}
            checked={form?.checked}
            inputName={form?.name}
          />
        ) : form?.formType == "file" ? (<FileInput
          isBold={form?.isBold}
          isRequired={form.isRequired}
          fullWidth={form?.fullWidth}
          clickable={form?.clickable}
          inputClick={form?.onClick}
          readonly={form?.readonly}
          onKeyUp={form?.onKeyUp}
          inputLabel={form?.label}
          disabled={form?.disabled}
          inputName={form?.name}
          requiredFile={form?.requiredFile}
          handleOnchange={form?.onChange}
          labelFileName={form?.labelFileName}
          />) :  form?.formType == "multifile" ? (<MultiFileInput
            isBold={form?.isBold}
            isRequired={form.isRequired}
            fullWidth={form?.fullWidth}
            clickable={form?.clickable}
            inputClick={form?.onClick}
            readonly={form?.readonly}
            onKeyUp={form?.onKeyUp}
            inputLabel={form?.label}
            disabled={form?.disabled}
            inputName={form?.name}
            requiredFile={form?.requiredFile}
            handleOnchange={form?.onChange}
            labelFileName={form?.labelFileName}
            />) :   <Button
          ref={form?.ref}
          isBold={form?.isBold}
          isRequired={form.isRequired}
          fullWidth={form?.fullWidth}
          clickable={form?.clickable}
          inputClick={form?.onClick}
          disabled={form?.disabled}
          label={form?.label}
        />
      )}
    </div>
  );
};

export default InputGroup;

// formGroup?.map((form) => (

//     form?.formType == 'dropdown' ?
//         ''
//         :
//         form?.formType !== 'text' && form?.formType !== 'number' && <Text inputType={form?.formType} inputLabel={form?.label} inputValue={form?.value} inputChange={form?.onChange} inputName={form?.name} />

// ))
