import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import InputGroup from "../GroupInput/InputGroup";
import MastersApi from "../../services/masters/masters.api";
import DataTable from 'react-data-table-component';
import { setLoadingAsync } from '../../services/globals/loading.reducer';
import { customStyles } from "../CustomStyles/CustomStyles";
import MastersApiSyariah from "../../services/masters/masters_syariah.api";
import { decodeAuth } from "utils/cookie";

const OptionsProduk = ({ dispatch, title, show, produk, parsingData, setShow, withSearch, hideModal, isJf = 0 }) => {

  const [selectedOptions, setSelectedOptions] = useState(null);

  const columns = [

    {
      name: 'Pilih',
      cell: (row, index) => <input type="radio" name="pilih_kelurahan" onClick={() => setSelectedOptions(row)} />,
      center: true,
      // sortable: true,
    },
    {
      name: 'Kode Produk',
      selector: row => row.kode_produk,
      center: true,
      width: '15%'
      // sortable: true,
    },
    {
      name: 'Nama Produk',
      selector: row => row.nama_produk,
      center: true,
      width: '50%'
      // sortable: true,
    },
    {
      name: 'Lama Simpan Hari',
      cell: row => row.lama_simpan_hari,
      center: true,
      // sortable: true,
    },
    {
      name: 'Lama Simpan Bulan',
      cell: row => row.lama_simpan_bulan,
      center: true,
      // sortable: true,
    },
  ];

  const [searchOpen, setSearchOpen] = useState(false);
  const [paramsFetch, setParamsFetch] = useState({
    page: 1,
    page_size: 10,
    data: [],
    total: 1,
    total_page: 10,
  });

  const [search, setSearch] = useState({
    kodeProduk: '',
    namaProduk: ""
  })

  useEffect(() => {
    dispatch(setLoadingAsync(true))
    if (produk) {
      console.log(produk)
      setParamsFetch(produk)
      dispatch(setLoadingAsync(false))
      return
    }
    fetchData(1).then((res) => {
      setParamsFetch(res)
      console.log("resss", res)
      dispatch(setLoadingAsync(false))

    })
  }, [])

  const fetchData = (page = 1, page_size = 10,) => {
    return decodeAuth().isSyariah === 1 ? MastersApiSyariah.GetProduk(`?page=${page}&page_size=${page_size}&searchKodeProduk=${search.kodeProduk}&searchNamaProduk=${search.namaProduk}&isActive=1&isJf=${isJf}`) : MastersApi.GetProduk(`?page=${page}&page_size=${page_size}&searchKodeProduk=${search.kodeProduk}&searchNamaProduk=${search.namaProduk}&isActive=1&isJf=${isJf}`)
  }

  const onChange = (v) => {
    console.log(v)
    setSearch({ ...search, [v.target.name]: v.target.value })
  }

  const onChangePageSize = (v) => {
    dispatch(setLoadingAsync(true))
    fetchData(1, v).then((res) => {
      setParamsFetch(res)
      dispatch(setLoadingAsync(false))
    })
  }

  const onChangePagination = (v) => {
    dispatch(setLoadingAsync(true))
    fetchData(v, paramsFetch?.page_size).then((res) => {
      setParamsFetch(res)
      dispatch(setLoadingAsync(false))
    })
  }

  const onClickSearch = () => {
    dispatch(setLoadingAsync(true))
    fetchData(1, paramsFetch.page_size).then((res) => {
      setParamsFetch(res)
      dispatch(setLoadingAsync(false))
    })
  }
  return (
    <Modal
      isOpen={show}
      centered
      size="xl"
      scrollable
      toggle={() => hideModal()}
    >
      <ModalHeader toggle={() => hideModal()}>{title}</ModalHeader>
      <ModalBody>
       {
      withSearch ? (
          <>
           <div className="row table-search mb-0">
          <span className="txt-body hand-click" onClick={() => setSearchOpen(!searchOpen)}>{searchOpen ? `-` : `+`} Search </span>
          <hr className="mt-2" />
        </div>
        <div className="row table-search-body" style={{ display: searchOpen ? "" : "none" }}>
          <div className="col-6 col-md-3">
            <span className="txt-body">Kode Produk</span>
          </div>
          <div className="col-6 col-md-3">
            <input className="input-table" name="kodeProduk" onChange={onChange}></input>
          </div>
          <div className="col-6 col-md-3">
            <span className="txt-body">Nama Produk</span>
          </div>
          <div className="col-6 col-md-3">
            <input className="input-table" name="namaProduk" onChange={onChange}></input>
          </div>
          <div className="col-12">
            <button className="btn-table mt-2" onClick={onClickSearch}>View</button>
          </div>
        </div></>
        ) : ""
       }
        <div className="row">
          <div className="col-12">
            <span className="txt-body">Total Data : {paramsFetch?.total}</span>
          </div>
          <div className="col-12">
            {
              produk ? <DataTable
                columns={columns}
                data={paramsFetch?.data}
                customStyles={customStyles}
                highlightOnHover
                // pagination
                dense
                responsive
                persistTableHead
                fixedHeader
                paginationServer
              // paginationTotalRows={paramsFetch?.total}
              // onChangeRowsPerPage={onChangePageSize}
              // onChangePage={onChangePagination}

              // sortServer
              // onSort={handleSort}
              // progressPending={loading}
              /> : <DataTable
                columns={columns}
                data={paramsFetch?.data}
                customStyles={customStyles}
                highlightOnHover
                pagination
                dense
                responsive
                persistTableHead
                fixedHeader
                paginationServer
                paginationTotalRows={paramsFetch?.total}
                onChangeRowsPerPage={onChangePageSize}
                onChangePage={onChangePagination}

              // sortServer
              // onSort={handleSort}
              // progressPending={loading}
              />
            }
          </div>

        </div>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={() => hideModal()}>Batal</Button>

        <Button color="primary" disabled={selectedOptions === null} onClick={() => parsingData(selectedOptions)}>Simpan</Button>{' '}
      </ModalFooter>
    </Modal>
  );
}

const mapStateToProps = function (state) {

  return {
    isLoading: state.global.isLoading,
    isProfile: state.profile
  };
};

export default connect(mapStateToProps)(OptionsProduk);