import React, { useEffect } from "react";
import { ProSidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { setMenuAsync } from "../../services/globals/menu.reducer";
import Formatting from "../../utils/formattingJson";

const MenuUtility = ({isMenu, dispatch, isLoading}) => {
  const navigate = useNavigate();
  let pathname = window.location.pathname.split("/");

  const onClickMenu = () => {
    Formatting.delayExecution(100).then(() => {
      console.log("elementPro", checkElement())
      if(checkElement() > 0){
        dispatch(setMenuAsync(true));
      }else{
        dispatch(setMenuAsync(false));
      }
    })
  }

  const checkElement = () => {
    const allWithClass = Array.from(
      document.getElementsByClassName('pro-sub-menu open')
    );
    return allWithClass.length;
  }
  
    return (
          <ProSidebar className={!isLoading && isMenu ? "height100" : "heightAuto"} onClick={onClickMenu}>
            <Menu>
              <SubMenu title="Master">
                  <MenuItem onClick={() => navigate("/utility/master/customer")} active={pathname[pathname.length-1] === "customer"}>Customer</MenuItem>
                  <SubMenu title="Informasi Geografis">
                    <MenuItem onClick={() => navigate("/utility/master/informasi-geografis/kelurahan")} active={pathname[pathname.length-1] === "kelurahan"}>Kelurahan</MenuItem>
                    <MenuItem onClick={() => navigate("/utility/master/informasi-geografis/area")} active={pathname[pathname.length-1] === "area"}>Area</MenuItem>
                    <MenuItem onClick={() => navigate("/utility/master/informasi-geografis/wilayah")} active={pathname[pathname.length-1] === "wilayah"}>Wilayah</MenuItem>
                  </SubMenu>
        
                  <SubMenu title="Informasi Cabang">
                  <MenuItem onClick={() =>navigate('/utility/master/informasi-cabang/cabang')} active={pathname[pathname.length-1] === "cabang"}>Cabang</MenuItem>
                    <MenuItem onClick={() =>navigate('/utility/master/informasi-cabang/jenis-cabang')} active={pathname[pathname.length-1] === "jenis-cabang"}>Jenis Cabang</MenuItem>
                    <MenuItem onClick={() =>navigate('/utility/master/informasi-cabang/grade-cabang')} active={pathname[pathname.length-1] === "grade-cabang"}>Grade Cabang</MenuItem>
                    {/* <MenuItem onClick={() =>navigate('/utility/master/informasi-cabang/limit-approval')} active={pathname[pathname.length-1] === "limit-approval"}>Limit Approval</MenuItem> */}
                  </SubMenu>
                  <SubMenu title="Informasi Karyawan">
                    <MenuItem onClick={() =>navigate('/utility/master/informasi-karyawan/divisi')} active={pathname[pathname.length-1] === "divisi"}>Divisi</MenuItem>
                    <MenuItem onClick={() =>navigate('/utility/master/informasi-karyawan/jabatan')} active={pathname[pathname.length-1] === "jabatan"}>Jabatan</MenuItem>
                    <MenuItem onClick={() =>navigate('/utility/master/informasi-karyawan/grade-karyawan')} active={pathname[pathname.length-1] === "grade-karyawan"}>Grade Karyawan</MenuItem>
                    <MenuItem onClick={() =>navigate('/utility/master/informasi-karyawan/karyawan-internal')} active={pathname[pathname.length-1] === "karyawan-internal"}>Karyawan Internal</MenuItem>
                    <MenuItem onClick={() =>navigate('/utility/master/informasi-karyawan/karyawan-eksternal')} active={pathname[pathname.length-1] === "karyawan-eksternal"}>Karyawan Eksternal</MenuItem>
                  </SubMenu>
                  <SubMenu title="Informasi Customer">
                    <MenuItem onClick={() => navigate("/utility/master/informasi-customer/pekerjaan")} active={pathname[pathname.length-1] === "pekerjaan"}>Pekerjaan</MenuItem>
                    <MenuItem onClick={() => navigate("/utility/master/informasi-customer/referensi-customer")} active={pathname[pathname.length-1] === "referensi-customer"}>Referensi Customer</MenuItem>
                    <MenuItem onClick={() => navigate("/utility/master/informasi-customer/referensi-transaksi")} active={pathname[pathname.length-1] === "referensi-transaksi"}>Referensi Transaksi</MenuItem>
                    <MenuItem onClick={() => navigate("/utility/master/informasi-customer/tujuan-transaksi")} active={pathname[pathname.length-1] === "tujuan-transaksi"}>Tujuan Transaksi</MenuItem>
                    <MenuItem onClick={() => navigate("/utility/master/informasi-customer/alasan-bad-customer")} active={pathname[pathname.length-1] === "alasan-bad-customer"}>Alasan Bad Customer</MenuItem>
                    <MenuItem onClick={() => navigate("/utility/master/informasi-customer/program")} active={pathname[pathname.length-1] === "program"}>Program</MenuItem>
                    <MenuItem onClick={() => navigate("/utility/master/informasi-customer/sektor-ekonomi")} active={pathname[pathname.length-1] === "sektor-ekonomi"}>Sektor Ekonomi</MenuItem>
                    <MenuItem onClick={() => navigate("/utility/master/informasi-customer/alasan-batal")} active={pathname[pathname.length-1] === "alasan-batal"}>Alasan Batal</MenuItem>
                    <MenuItem onClick={() => navigate("/utility/master/informasi-customer/pendidikan")} active={pathname[pathname.length-1] === "pendidikan"}>Pendidikan</MenuItem>
                    <MenuItem onClick={() => navigate("/utility/master/informasi-customer/status-rumah")} active={pathname[pathname.length-1] === "status-rumah"}>Status Rumah</MenuItem>
                    <MenuItem onClick={() => navigate("/utility/master/informasi-customer/sumber-dana")} active={pathname[pathname.length-1] === "sumber-dana"}>Sumber Dana</MenuItem>
                    <MenuItem onClick={() => navigate("/utility/master/informasi-customer/bidang-usaha")} active={pathname[pathname.length-1] === "bidang-usaha"}>Bidang Usaha</MenuItem>
                    <MenuItem onClick={() => navigate("/utility/master/informasi-customer/jabatan-customer")} active={pathname[pathname.length-1] === "jabatan-customer"}>Jabatan Customer</MenuItem>
                    <MenuItem onClick={() => navigate("/utility/master/informasi-customer/hasil-follow-up")} active={pathname[pathname.length-1] === "hasil-follow-up"}>Hasil Follow UP</MenuItem>
                    <MenuItem onClick={() => navigate("/utility/master/informasi-customer/cara-follow-up")} active={pathname[pathname.length-1] === "cara-follow-up"}>Cara Follow UP</MenuItem>
                    <MenuItem onClick={() => navigate("/utility/master/informasi-customer/agama")} active={pathname[pathname.length-1] === "agama"}>Agama</MenuItem>
                  </SubMenu>
                  <SubMenu title="Barang">
                    <MenuItem onClick={() => navigate("/utility/master/barang/jenis-barang")} active={pathname[pathname.length-1] === "jenis-barang"}>Jenis Barang</MenuItem>
                    <MenuItem onClick={() => navigate("/utility/master/barang/jenis-emas")} active={pathname[pathname.length-1] === "jenis-emas"}>Jenis Emas</MenuItem>
                    <MenuItem onClick={() => navigate("/utility/master/barang/barang-emas")} active={pathname[pathname.length-1] === "barang-emas"}>Barang (Emas)</MenuItem>

                    <MenuItem>Merek</MenuItem>
                    <MenuItem>TIPE</MenuItem>
                    <MenuItem>Model</MenuItem>
                    <MenuItem>Kondisi Barang</MenuItem>
                    <MenuItem>Barang (Non Emas)</MenuItem>
                    <MenuItem>Grade</MenuItem>
                  </SubMenu>
                  <SubMenu title="Inventory">
                    <MenuItem>Brankas</MenuItem>
                    <MenuItem>Laci</MenuItem>
                    <MenuItem>Lajur</MenuItem>
                  </SubMenu>
                  <MenuItem>Bad Customer</MenuItem>
                  <MenuItem>Hari Libur</MenuItem>
                  <SubMenu title="Informasi Partner">
                    <MenuItem>Tipe Partner</MenuItem>
                    <MenuItem>Partner</MenuItem>
                  </SubMenu>
              </SubMenu>

              <SubMenu title="Master Setting">
                  <MenuItem onClick={() =>navigate('/utility/master-setting/harga-stle')} active={pathname[pathname.length-1] === "harga-stle"}>Harga STLE</MenuItem>
                  <SubMenu title="Produk">
                    <MenuItem onClick={() =>navigate('/utility/master-setting/produk/produk-gadai')} active={pathname[pathname.length-1] === "produk-gadai"}>Produk Gadai</MenuItem>
                    <MenuItem>Produk Cicilan</MenuItem>
                    <MenuItem onClick={() =>navigate('/utility/master-setting/produk/rate')} active={pathname[pathname.length-1] === "rate"}>Rate</MenuItem>
                  </SubMenu>
                  {/* <MenuItem>Limit Approval Taksiran</MenuItem> */}
                  {/* <MenuItem>Grade</MenuItem> */}
                  <MenuItem>Setting Nasional</MenuItem>
                  <MenuItem>Target</MenuItem>
                  <MenuItem>Setting Insentif</MenuItem>
                  <MenuItem>Plafon</MenuItem>
                  <MenuItem>Setting JF</MenuItem>
              </SubMenu>

              <SubMenu title="Master F&A">
                  <MenuItem onClick={() =>navigate('/utility/master-fa/Chart-Of-Account')} active={pathname[pathname.length-1] === "Chart-Of-Account"}>Chart of Account</MenuItem>
                  <MenuItem onClick={() =>navigate('/utility/master-fa/bank')} active={pathname[pathname.length-1] === "bank"}>Bank</MenuItem>
                  <MenuItem onClick={() =>navigate('/utility/master-fa/setting-accounting')} active={pathname[pathname.length-1] === "setting-accounting"}>Setting Accounting</MenuItem>
                  <MenuItem>Group COA</MenuItem>
                  <MenuItem>Budget</MenuItem>
                  <MenuItem onClick={() =>navigate('/utility/master-fa/cash-flow')} active={pathname[pathname.length-1] === "cash-flow"}>Cashflow</MenuItem>
                  <MenuItem onClick={() =>navigate('/utility/master-fa/used-for')} active={pathname[pathname.length-1] === "used-for"}>Used For</MenuItem>

              </SubMenu>

              <SubMenu title="User Management">
                  <MenuItem onClick={() =>navigate('/utility/user-management/user')} active={pathname[pathname.length-1] === "user"}>User</MenuItem>
                  <MenuItem onClick={() =>navigate('/utility/user-management/level')} active={pathname[pathname.length-1] === "level"}>Level</MenuItem>
              </SubMenu>
            </Menu>
          </ProSidebar>
    );
};

const mapStateToProps = function (state) {
  
  return {
    isLoading: state.global.isLoading,
    isMenu: state.menu.isMenu,
  };
};

export default connect(mapStateToProps)(MenuUtility);