import { ADMIN, KASIR, KA_CAB, KA_UNIT, KA_UNIT_MIGRASI, PENAKSIR, FINANCE, ALL_MODUL_KA_UNIT, KA_UNIT_PENAKSIR, PENAKSIR_KASIR, PENAKSIR_KASIR_MIGRASI, RISK, STAFF_BISNIS, HR_HEAD, HR_STAFF, FINANCE_STAFF, AUDIT_HEAD, AUDIT_STAFF, OPR_MANAGER, OPR_SPV } from "utils/ConstJabatan";
import { OJT_PENAKSIR } from "../../../utils/ConstJabatan";

export const Utility = [
  // Master
  {
    title: "Master",
    akses: [ADMIN, PENAKSIR, OJT_PENAKSIR, KA_UNIT, KA_UNIT_MIGRASI, KA_CAB, ALL_MODUL_KA_UNIT, KA_UNIT_PENAKSIR, PENAKSIR_KASIR, PENAKSIR_KASIR_MIGRASI, STAFF_BISNIS,HR_HEAD,HR_STAFF, AUDIT_HEAD, AUDIT_STAFF, RISK],
    submenu: [
      {
        title: "Customer",
        link: "/utility/master/customer",
        akses: [ADMIN, OJT_PENAKSIR, PENAKSIR, KA_UNIT, KA_UNIT_MIGRASI, KA_CAB, ALL_MODUL_KA_UNIT, KA_UNIT_PENAKSIR, PENAKSIR_KASIR, PENAKSIR_KASIR_MIGRASI, AUDIT_HEAD, AUDIT_STAFF, RISK]
      },
      {
        title: "Informasi Geografis",
        akses: [ADMIN],
        submenu: [
          {
            title: "Kelurahan",
            link: "/utility/master/informasi-geografis/kelurahan",
            akses: [ADMIN],
          },
          {
            title: "Area",
            link: "/utility/master/informasi-geografis/area",
            akses: [ADMIN],
          },
          {
            title: "Wilayah",
            link: "/utility/master/informasi-geografis/wilayah",
            akses: [ADMIN],
          },
        ],
      },
      {
        title: "Informasi Cabang",
        akses: [ADMIN],
        submenu: [
          {
            title: "Cabang",
            akses: [ADMIN],
            link: '/utility/master/informasi-cabang/cabang'
          },
          {
            title: "Jenis Cabang",
            akses: [ADMIN],
            link: '/utility/master/informasi-cabang/jenis-cabang'
          },
          {
            title: "Grade Cabang",
            akses: [ADMIN],
            link: '/utility/master/informasi-cabang/grade-cabang'
          },
        ],
      },
      {
        title: "Informasi Karyawan",
        akses: [ADMIN, STAFF_BISNIS,HR_HEAD,HR_STAFF, AUDIT_HEAD],
        submenu: [
          {
            title: "Divisi",
            akses: [ADMIN,HR_HEAD,HR_STAFF],
            link: '/utility/master/informasi-karyawan/divisi'
          },
          {
            title: "Jabatan",
            akses: [ADMIN,HR_HEAD,HR_STAFF],
            link: '/utility/master/informasi-karyawan/jabatan'
          },
          {
            title: "Grade Karyawan",
            akses: [ADMIN,HR_HEAD,HR_STAFF],
            link: '/utility/master/informasi-karyawan/grade-karyawan'
          },
          {
            title: "Karyawan Internal",
            akses: [ADMIN,HR_HEAD,HR_STAFF, STAFF_BISNIS, AUDIT_HEAD],
            link: '/utility/master/informasi-karyawan/karyawan-internal'
          },
          {
            title: "Karyawan Eksternal",
            akses: [ADMIN,STAFF_BISNIS,HR_HEAD,HR_STAFF, AUDIT_HEAD],
            link: '/utility/master/informasi-karyawan/karyawan-eksternal'
          },
        ],
      },
      {
        title: "Informasi Customer",
        akses: [ADMIN],
        submenu: [
          {
            title: "Pekerjaan",
            akses: [ADMIN],
            link: '/utility/master/informasi-customer/pekerjaan'
          },
          {
            title: "Agama",
            akses: [ADMIN],
            link: '/utility/master/informasi-customer/agama'
          },
          {
            title: "Jenis Referensi",
            akses: [ADMIN],
            link: '/utility/master/informasi-customer/jenis-referensi'
          },
          {
            title: "Referensi Customer",
            akses: [ADMIN],
            link: '/utility/master/informasi-customer/referensi-customer'
          },
          {
            title: "Referensi Transaksi",
            akses: [ADMIN],
            link: '/utility/master/informasi-customer/referensi-transaksi'
          },
          {
            title: "Tujuan Transaksi",
            akses: [ADMIN],
            link: '/utility/master/informasi-customer/tujuan-transaksi'
          },
          {
            title: "Alasan Bad Customer",
            akses: [ADMIN],
            link: '/utility/master/informasi-customer/alasan-bad-customer'
          },
          {
            title: "Program",
            akses: [ADMIN],
            link: '/utility/master/informasi-customer/program'
          },
          {
            title: "Sektor Ekonomi",
            akses: [ADMIN],
            link: '/utility/master/informasi-customer/sektor-ekonomi'
          },
          {
            title: "Alasan Batal",
            akses: [ADMIN],
            link: '/utility/master/informasi-customer/alasan-batal'
          },
          {
            title: "Pendidikan",
            akses: [ADMIN],
            link: '/utility/master/informasi-customer/pendidikan'
          },
          {
            title: "Status Rumah",
            akses: [ADMIN],
            link: '/utility/master/informasi-customer/status-rumah'
          },
          {
            title: "Sumber Dana",
            akses: [ADMIN],
            link: '/utility/master/informasi-customer/sumber-dana'
          },
          {
            title: "Bidang Usaha",
            akses: [ADMIN],
            link: '/utility/master/informasi-customer/bidang-usaha'
          },
          {
            title: "Jabatan Customer",
            akses: [ADMIN],
            link: '/utility/master/informasi-customer/jabatan-customer'
          },
          {
            title: "Hasil Follow Up",
            akses: [ADMIN],
            link: '/utility/master/informasi-customer/hasil-follow-up'
          },
          {
            title: "Cara Follow Up",
            akses: [ADMIN],
            link: '/utility/master/informasi-customer/cara-follow-up'
          },
        ],
      },
      {
        title: "Barang",
        akses: [ADMIN],
        submenu: [
          {
            title: "Jenis Barang",
            akses: [ADMIN],
            link: "/utility/master/barang/jenis-barang"
          },
          {
            title: "Jenis Emas",
            akses: [ADMIN],
            link: "/utility/master/barang/jenis-emas"
          },
          {
            title: "Barang (Emas)",
            akses: [ADMIN],
            link: "/utility/master/barang/barang-emas"
          },
          {
            title: "Merek *",
            akses: [ADMIN],
            // link: 'wilayah'
          },
          {
            title: "Tipe *",
            akses: [ADMIN],
            // link: 'wilayah'
          },
          {
            title: "Model *",
            akses: [ADMIN],
            // link: 'wilayah'
          },
          {
            title: "Kondisi Barang *",
            akses: [ADMIN],
            // link: 'wilayah'
          },
          {
            title: "Barang (Non Emas) *",
            akses: [ADMIN],
            // link: 'wilayah'
          },
          {
            title: "Grade *",
            akses: [ADMIN],
            // link: 'wilayah'
          },
        ],
      },
      {
        title: "Inventory",
        akses: [ADMIN],
        submenu: [
          {
            title: "Brankas",
            akses: [ADMIN],
            link: '/utility/master/inventory/brankas'
          },
          {
            title: "Laci",
            akses: [ADMIN],
            link: '/utility/master/inventory/laci'
          },
          {
            title: "Lajur",
            akses: [ADMIN],
            link: '/utility/master/inventory/lajur'
          },
        ],
      },
      {
        title: "Bad Customer *",
        akses: [ADMIN],
        link: ''
      },
      {
        title: "Hari Libur *",
        akses: [ADMIN],
        link: ''
      },
      {
        title: "Informasi Partner",
        akses: [ADMIN],
        submenu: [
          {
            title: "Tipe Partner",
            akses: [ADMIN],
            link: '/utility/master/informasi-partner/tipe-partner'
          },
          {
            title: "Partner",
            akses: [ADMIN],
            link: '/utility/master/informasi-partner/partner'
          },
        ],
      },
      {
        title: "Alasan Pengeluaran",
        akses: [ADMIN],
        link: '/utility/master/alasan-pengeluaran'
      },
      {
        title: "Blanko",
        akses: [ADMIN],
        submenu: [
          {
            title: "Jenis Blanko",
            akses: [ADMIN],
            link: '/utility/master/blanko/jenis-blanko'
          },
        ],
      },
      {
        title: "Collection",
        akses: [ADMIN],
        submenu: [
          {
            title: "Jenis Penanganan",
            akses: [ADMIN],
            link: '/utility/master/collection/jenis-penanganan'
          },
          {
            title: "Hasil Penanganan",
            akses: [ADMIN],
            link: '/utility/master/collection/hasil-penanganan'
          },
        ],
      },
      {
        title: "Mitra Gadai",
        akses: [ADMIN],
        link: '/utility/master/mitra-gadai'
      },
    ],
  
  },
  //Master Setting
  {
    title: "Master Setting",
    akses: [ADMIN, RISK],
    submenu: [
      {
        title: "Harga STLE",
        akses: [ADMIN, RISK],
        link: "/utility/master-setting/harga-stle"
      },
      {
        title: "Harga Jual",
        akses: [ADMIN, RISK],
        link: "/utility/master-setting/harga-jual"
      },
      {
        title: "Harga Diskon Penjualan",
        akses: [ADMIN],
        link: "/utility/master-setting/harga-diskon-penjualan"
      },
      {
        title: "Produk",
        akses: [ADMIN,RISK],
        submenu: [
          {
            title: "Produk Gadai",
            akses: [ADMIN,RISK],
            link: "/utility/master-setting/produk/produk-gadai"
          },
          {
            title: "Produk Cicilan",
            akses: [ADMIN],
            link: "/utility/master-setting/produk/produk-cicilan"
          },
          {
            title: "Rate",
            akses: [ADMIN],
            link: "/utility/master-setting/produk/rate"
          },
        ],
      },
      {
        title: "Setting Nasional *",
        akses: [ADMIN],
        // link: "/utility/master-setting/harga-stle"
      },
      {
        title: "Target *",
        akses: [ADMIN],
        // link: "/utility/master-setting/harga-stle"
      },
      {
        title: "Setting Insentif *",
        akses: [ADMIN],
        // link: "/utility/master-setting/harga-stle"
      },
      {
        title: "Plafon *",
        akses: [ADMIN],
        // link: "/utility/master-setting/harga-stle"
      },
      {
        title: "Setting JF",
        akses: [ADMIN],
        link: "/utility/master-setting/setting-jf"
      },
    ],
  },
  //Master F&A
  {
    title: "Master F&A",
    akses: [ADMIN, KA_UNIT, KASIR, FINANCE, ALL_MODUL_KA_UNIT, KA_UNIT_PENAKSIR, PENAKSIR_KASIR, FINANCE_STAFF],
    submenu: [
      {
        title: "Batch",
        link: "/utility/master-fa/batch",
        akses: [ADMIN, KA_UNIT, KA_UNIT_MIGRASI, ALL_MODUL_KA_UNIT, KA_UNIT_PENAKSIR, PENAKSIR_KASIR, PENAKSIR_KASIR_MIGRASI],
      },
      {
        title: "Chart of Account",
        akses: [ADMIN],
        link: "/utility/master-fa/Chart-Of-Account"
      },
      {
        title: "Jenis Transaksi",
        akses: [ADMIN],
        link: "/utility/master-fa/jenis-transaksi"
      },
      {
        title: "Bank",
        akses: [ADMIN],
        link: "/utility/master-fa/bank"
      },
      {
        title: "Setting Accounting",
        akses: [ADMIN],
        link: "/utility/master-fa/setting-accounting"
      },
      {
        title: "Group COA *",
        akses: [ADMIN],
        // link: "/utility/master-setting/harga-stle"
      },
      {
        title: "Budget *",
        akses: [ADMIN],
        // link: "/utility/master-setting/harga-stle"
      },
      {
        title: "Cashflow",
        akses: [ADMIN],
        link: "/utility/master-fa/cash-flow"
      },
      {
        title: "Used For",
        akses: [ADMIN],
        link: "/utility/master-fa/used-for"
      },
      {
        title: "Generate Cek",
        link: "/utility/master-fa/generate-cek",
        akses: [ADMIN, KA_UNIT, KA_UNIT_MIGRASI, FINANCE, FINANCE_STAFF],
      },
    ],
  },
  //Master User Management
  {
    title: "User Management",
    akses: [ADMIN],
    submenu: [
      {
        title: "User",
        akses: [ADMIN],
        link: "/utility/user-management/user"
      },
      // {
      //   title: "Level",
      //   akses: [ADMIN],
      //   link: "/utility/user-management/level"
      // }
    ],
  }
  
];