import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import utility from "../../styles/images/menu-utility.svg";
import gadai from "../../styles/images/menu-gadai.svg";
import fna from "../../styles/images/menu-fna.svg";
import closing from "../../styles/images/menu-closing.svg";
import report from "../../styles/images/menu-report.svg";
import gadaimas from "../../styles/images/menu-gadaimas.svg";
import chevronRight from "../../styles/images/chevron-right.svg";
import auditIcon from "../../styles/images/audit_icon.svg";

import MenuUtility from "../Menu/MenuUtility";
import MenuAplikasi from "../Menu/MenuAplikasi";
import MenuFA from "../Menu/MenuFA";
import MenuClosing from "../Menu/MenuClosing";
import MenuReport from "../Menu/MenuReport";
import Loading from "../Loading/Loading";
import ModalProfile from "../ModalProfile/ModalProfile";
import { useNavigate } from "react-router-dom";
import AuthApi from "../../services/auth/auth.api";
import AuthApiSyariah from "../../services/auth/auth_syariah.api";

import dayjs from "dayjs";
import Navbar from "../TopMenuNav/Navbar"
import { setMenuAsync } from "../../services/globals/menu.reducer";
import { Utility } from "../TopMenuNav/Menu/Utility";
import { Aplikasi } from "../TopMenuNav/Menu/Aplikasi";
import { FA } from "../TopMenuNav/Menu/FA";
import { Closing } from "../TopMenuNav/Menu/Closing";
import { Report } from "../TopMenuNav/Menu/Report";
import { audit } from "components/TopMenuNav/Menu/Audit";
import { ReportAudit } from "components/TopMenuNav/Menu/ReportAudit";

import { UtilitySyari } from "../TopMenuNavSyariah/Menu/Utility";
import { AplikasiSyari } from "../TopMenuNavSyariah/Menu/Aplikasi";
import { FASyari } from "../TopMenuNavSyariah/Menu/FA";
import { ClosingSyari } from "../TopMenuNavSyariah/Menu/Closing";
import { ReportSyari } from "../TopMenuNavSyariah/Menu/Report";
import { auditSyari } from "components/TopMenuNavSyariah/Menu/Audit";
import { ReportAuditSyari } from "components/TopMenuNavSyariah/Menu/ReportAudit";
import { ADMIN, KASIR, PENAKSIR, FINANCE, KA_UNIT, KA_UNIT_MIGRASI, KA_CAB, KA_AREA, DIREKTUR, DIRUT, RISK, CSDO, FINANCE_STAFF, KA_WIL, ALL_MODUL_KA_UNIT, KA_UNIT_PENAKSIR, PENAKSIR_KASIR, PENAKSIR_KASIR_MIGRASI, WAKACAB, STAFF_BISNIS, AUDIT_STAFF, AUDIT_HEAD, HR_HEAD, HR_STAFF, STAFF_ADMIN_PROPINSI, OPR_MANAGER, OPR_SPV, ACC_HEAD, ACC_STAFF, COLLECTION, BISNIS_SUPPORT_WILAYAH, KADIV_BISNIS, OFS, KADEPT_CPA, KADIV_CPA } from "utils/ConstJabatan";
import { setProfileAsync } from "services/globals/Profile.reducer";
import defaultProfile from 'styles/images/default-profile.png'
import { setLoadingAsync } from "services/globals/loading.reducer";
import Swal from "sweetalert2";
import hamburgerMenu from 'styles/images/hamburger_menu.svg'
import Offcanvas from 'react-bootstrap/Offcanvas';
import NavbarMobile from "components/TopMenuNav/NavbarMobile";

import MastersApi from "services/masters/masters.api";
import { decodeAuth } from "utils/cookie";
import { useLayoutEffect } from "react";
import { OJT_PENAKSIR } from "../../utils/ConstJabatan";

var map = new Map();
map.set('Utility', utility);
map.set('Aplikasi', gadai);
map.set('F&A', fna);
map.set('Closing', closing);
map.set('Report', report);
map.set('Audit', audit);
map.set('Report Audit', ReportAudit);
const AllMenu = [
  ...Utility, ...Aplikasi, ...FA, ...Closing, ...Report, ...audit, ...ReportAudit
]
const AllMenuSyariah = [
  ...UtilitySyari, ...AplikasiSyari, ...FASyari, ...ClosingSyari, ...auditSyari, ...ReportAuditSyari, ...ReportSyari
]


const Layout = ({ dispatch, children, isMenu, isProfile, isLoading }) => {
  const navigate = useNavigate();
  let pathname = window.location.pathname.split("/");

  const [loadProfile, setLoadProfile] = useState(true)
  const [modalProfile, setModalProfile] = useState(false);
  const [dataProfile, setDataProfile] = useState();
  const [dataMenu, setDataMenu] = useState([]);
  const [aksesMenu, setAksesMenu] = useState({
    utility: [ADMIN, OJT_PENAKSIR, PENAKSIR, KA_UNIT, KA_UNIT_MIGRASI, KA_CAB, ALL_MODUL_KA_UNIT, KA_UNIT_PENAKSIR, PENAKSIR_KASIR, PENAKSIR_KASIR_MIGRASI, RISK, STAFF_BISNIS, HR_HEAD, HR_STAFF, FINANCE, FINANCE_STAFF, AUDIT_HEAD, AUDIT_STAFF],
    aplikasi: [ADMIN, PENAKSIR, KA_WIL, KA_UNIT, KA_UNIT_MIGRASI, KA_CAB, DIREKTUR, DIRUT, RISK, KASIR, ALL_MODUL_KA_UNIT, KA_UNIT_PENAKSIR, PENAKSIR_KASIR, PENAKSIR_KASIR_MIGRASI, AUDIT_STAFF, AUDIT_HEAD, OPR_MANAGER, OPR_SPV, COLLECTION, BISNIS_SUPPORT_WILAYAH, OFS, KADEPT_CPA, KADIV_CPA],
    fa: [ADMIN, KASIR,DIREKTUR,DIRUT, FINANCE, KA_UNIT, KA_UNIT_MIGRASI, KA_CAB, CSDO, FINANCE_STAFF, KA_WIL, ALL_MODUL_KA_UNIT, PENAKSIR_KASIR, PENAKSIR_KASIR_MIGRASI, WAKACAB, AUDIT_HEAD, AUDIT_STAFF, STAFF_ADMIN_PROPINSI, RISK, OPR_MANAGER, COLLECTION, OFS, KADEPT_CPA, KADIV_CPA],
    closing: [ADMIN],
    report: [ADMIN, KA_UNIT, KA_UNIT_MIGRASI, KA_CAB, KA_AREA, KA_WIL, FINANCE, DIREKTUR, DIRUT, KASIR, PENAKSIR, ALL_MODUL_KA_UNIT, KA_UNIT_PENAKSIR, PENAKSIR_KASIR, PENAKSIR_KASIR_MIGRASI, AUDIT_STAFF, AUDIT_HEAD, OPR_MANAGER, OPR_SPV, RISK, ACC_HEAD, ACC_STAFF, HR_HEAD, HR_STAFF, BISNIS_SUPPORT_WILAYAH, KADIV_BISNIS, STAFF_BISNIS, STAFF_ADMIN_PROPINSI, OFS],
    audit: [ADMIN, AUDIT_HEAD, AUDIT_STAFF],
    reportAudit: [ADMIN, AUDIT_HEAD, AUDIT_STAFF]
  })
  const [show, setShow] = useState(false);
  const [menu, setMenu] = useState([]);

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  const generateBreadCrumb = (pathname) => {
    pathname = pathname.replaceAll("%20", " ")
    var paths = pathname.split("/");
    paths = paths[paths.length - 1] === "" ? paths.slice(0, paths.length - 1) : paths;
    paths = paths[1] === "" ? paths.slice(1) : paths;
    var breadcrumb = paths.map((path, index) => {
      if (index === 0) {
        return (<li></li>);
      }
      return (<li style={{ fontWeight: paths.length == index + 1 ? "bold" : "" }}>{capitalizeFirstLetter(path.replace(/-/g, " "))}&nbsp;<img src={chevronRight} alt="" style={{ display: index !== paths.length - 1 ? "" : "none" }} />&nbsp;</li>);
    });
    return (<ul className="inline-list-bc">{breadcrumb}</ul>);
  }


  const handleSidebar = () => setShow(!show);

  useEffect(() => {
    // dispatch(setLoadingAsync(true))
    if (decodeAuth().isSyariah === 1) {
      AuthApiSyariah.getProfile().then((res) => {

        if (window.location.pathname.split("/").length > 2) {
          if (!flattenMenu(decodeAuth().isSyariah === 1 ? AllMenuSyariah : AllMenu).find((x) => x.link === window.location.pathname)?.akses?.includes(res?.profile?.id_jabatan_karyawan)) {
            Swal.fire("Upss", "Anda tidak memiliki akses", "warning")
            navigate('/')
          }
        }

        dispatch(setProfileAsync(res))
        setDataProfile(res);
        // AuthApi.getMenu().then((res) => {
        setLoadProfile(false)
        //   setMenu(res)
        // })

      }).catch((err) => {
        setLoadProfile(false)
        Swal.fire(
          'Maaf',
          err.response.data.Response.errorMessage,
          'error'
        );
      })
    } else {
      AuthApi.getProfile().then((res) => {

        if (window.location.pathname.split("/").length > 2) {
          if (!flattenMenu(decodeAuth().isSyariah === 1 ? AllMenuSyariah : AllMenu).find((x) => x.link === window.location.pathname)?.akses?.includes(res?.profile?.id_jabatan_karyawan)) {
            Swal.fire("Upss", "Anda tidak memiliki akses", "warning")
            navigate('/')
          }
        }

        dispatch(setProfileAsync(res))
        setDataProfile(res);
        // AuthApi.getMenu().then((res) => {
        setLoadProfile(false)
        //   setMenu(res)
        // })
      }).catch((err) => {
        setLoadProfile(false)
        Swal.fire(
          'Maaf',
          err.response.data.Response.errorMessage,
          'error'
        );
      })
    }
  }, [])



  function flattenMenu(menu, result = []) {
    for (let i = 0; i < menu.length; i++) {
      const { title, akses, link, submenu } = menu[i];
      result.push({ title, akses, link });

      if (submenu && submenu.length > 0) {
        flattenMenu(submenu, result);
      }
    }
    return result;
  }

  useLayoutEffect(() => {
    
    localStorage.openpages = Date.now();
    window.addEventListener('storage', function (e) {
      if (e.key == "openpages") {
        // Listen if anybody else is opening the same page!
        localStorage.page_available = Date.now();
      }
      if (e.key == "page_available") {
        Swal.fire({
          title: 'Dilarang Membuka 2 TAB',
          icon: 'warning',
          text: "Info untuk penggunaan aplikasi PROMAS hanya boleh 1 TAB dalam 1 BROWSER, silakan tutup TAB lainnya",
          allowOutsideClick: false,
          // reverseButtons: true,
          // cancelButtonText: "Batal",
          // confirmButtonText: 'Tutup Tab',
          showConfirmButton: false
        }).then((result) => {
          if (result.isConfirmed) {

            // this.window.close()
          }
          if (result.dismiss) {

            // window.open("", "_blank", "");
            // window.close();          
          }
        })
      }
    }, false);

  
  }, [])

  window.addEventListener('visibilitychange', function (e) {
    if (document.visibilityState === "visible") {
      localStorage.openpages = Date.now();
      window.addEventListener('storage', function (e) {
        if (e.key == "openpages") {
          // Listen if anybody else is opening the same page!
          localStorage.page_available = Date.now();
        }

        if (e.key == "page_available") {
          Swal.fire({
            title: 'Dilarang Membuka 2 TAB',
            icon: 'warning',
            text: "Info untuk penggunaan aplikasi PROMAS hanya boleh 1 TAB dalam 1 BROWSER, silakan tutup TAB lainnya",
            allowOutsideClick: false,
            // reverseButtons: true,
            // cancelButtonText: "Batal",
            // confirmButtonText: 'Tutup Tab',
            showConfirmButton: false
          })

        }
      }, false);
    }

  })
  useEffect(() => {
    if (modalProfile) {
      document.body.style.overflowY = "hidden"
      window.scrollTo(0, 0)
    } else {
      document.body.style.overflowY = "auto"
    }
  }, [modalProfile])


  if (loadProfile) {
    return (
      <Loading />
    );
  } else {
    return (
      <>
        {isLoading && <Loading />}
        {modalProfile && <ModalProfile data={dataProfile} hidemodal={() => setModalProfile(false)} />}
        <div className={`container-fluid ${decodeAuth().isSyariah === 1 ? `bg-header-syariah` : `bg-header`} navbar-fixed-top`}>
          <div className='row full-width'>
            <div className='col-7 d-none d-md-block'>
              <ul className='menu-top'>
                {/* {dataMenu.length > 0 && dataMenu.map((v) => (
                <li className={`menu-horizontal center-vertical ${pathname[1].toLowerCase() === v.title.toLowerCase() ? "menu-active" : ""}`} onClick={() => navigate(`/${v.title.toLowerCase()}`)}><img src={map.get(v.title)} alt="" />&nbsp;{v.title}</li>
              ))} */}

                { /* YG dipake */}
                {
                  decodeAuth().isSyariah === 1 ? (
                    <>
                      <li style={{ display: aksesMenu.utility.includes(dataProfile?.profile?.id_jabatan_karyawan) ? "" : "none" }} className={`menu-horizontal center-vertical ${pathname[1] === "utility" ? "menu-active" : ""}`} onClick={() => navigate("/utility")}><img src={utility} alt="" />&nbsp;Utility</li>
                      <li style={{ display: aksesMenu.aplikasi.includes(dataProfile?.profile?.id_jabatan_karyawan) ? "" : "none" }} className={`menu-horizontal center-vertical ${pathname[1] === "aplikasi" ? "menu-active" : ""}`} onClick={() => navigate("/aplikasi")}><img src={gadai} alt="" />&nbsp;Aplikasi</li>
                      <li style={{ display: aksesMenu.fa.includes(dataProfile?.profile?.id_jabatan_karyawan) ? "" : "none" }} className={`menu-horizontal center-vertical ${pathname[1] === "fa" ? "menu-active" : ""}`} onClick={() => navigate("/fa")}><img src={fna} alt="" />&nbsp;F&A</li>
                      <li style={{ display: aksesMenu.closing.includes(dataProfile?.profile?.id_jabatan_karyawan) ? "" : "none" }} className={`menu-horizontal center-vertical ${pathname[1] === "closing" ? "menu-active" : ""}`} onClick={() => navigate("/closing")}><img src={closing} alt="" />&nbsp;Closing</li>
                      <li style={{ display: aksesMenu.report.includes(dataProfile?.profile?.id_jabatan_karyawan) ? "" : "none" }} className={`menu-horizontal center-vertical ${pathname[1] === "report" ? "menu-active" : ""}`} onClick={() => navigate("/report")}><img src={report} alt="" />&nbsp;Report</li>
                      <li style={{ display: aksesMenu.audit.includes(dataProfile?.profile?.id_jabatan_karyawan) ? "" : "none" }} className={`menu-horizontal center-vertical ${pathname[1] === "audit" ? "menu-active" : ""}`} onClick={() => navigate("/audit")}><img src={auditIcon} alt="" />&nbsp;Audit</li>
                      <li style={{ display: aksesMenu.reportAudit.includes(dataProfile?.profile?.id_jabatan_karyawan) ? "" : "none" }} className={`menu-horizontal center-vertical ${pathname[1] === "report-audit" ? "menu-active" : ""}`} onClick={() => navigate("/report-audit")}><img src={report} alt="" />&nbsp;Report Audit</li>
                    </>
                  ) :
                    (
                      <>
                        <li style={{ display: aksesMenu.utility.includes(dataProfile?.profile?.id_jabatan_karyawan) ? "" : "none" }} className={`menu-horizontal center-vertical ${pathname[1] === "utility" ? "menu-active" : ""}`} onClick={() => navigate("/utility")}><img src={utility} alt="" />&nbsp;Utility</li>
                        <li style={{ display: aksesMenu.aplikasi.includes(dataProfile?.profile?.id_jabatan_karyawan) ? "" : "none" }} className={`menu-horizontal center-vertical ${pathname[1] === "aplikasi" ? "menu-active" : ""}`} onClick={() => navigate("/aplikasi")}><img src={gadai} alt="" />&nbsp;Aplikasi</li>
                        <li style={{ display: aksesMenu.fa.includes(dataProfile?.profile?.id_jabatan_karyawan) ? "" : "none" }} className={`menu-horizontal center-vertical ${pathname[1] === "fa" ? "menu-active" : ""}`} onClick={() => navigate("/fa")}><img src={fna} alt="" />&nbsp;F&A</li>
                        <li style={{ display: aksesMenu.closing.includes(dataProfile?.profile?.id_jabatan_karyawan) ? "" : "none" }} className={`menu-horizontal center-vertical ${pathname[1] === "closing" ? "menu-active" : ""}`} onClick={() => navigate("/closing")}><img src={closing} alt="" />&nbsp;Closing</li>
                        <li style={{ display: aksesMenu.report.includes(dataProfile?.profile?.id_jabatan_karyawan) ? "" : "none" }} className={`menu-horizontal center-vertical ${pathname[1] === "report" ? "menu-active" : ""}`} onClick={() => navigate("/report")}><img src={report} alt="" />&nbsp;Report</li>
                        <li style={{ display: aksesMenu.audit.includes(dataProfile?.profile?.id_jabatan_karyawan) ? "" : "none" }} className={`menu-horizontal center-vertical ${pathname[1] === "audit" ? "menu-active" : ""}`} onClick={() => navigate("/audit")}><img src={auditIcon} alt="" />&nbsp;Audit</li>
                        <li style={{ display: aksesMenu.reportAudit.includes(dataProfile?.profile?.id_jabatan_karyawan) ? "" : "none" }} className={`menu-horizontal center-vertical ${pathname[1] === "report-audit" ? "menu-active" : ""}`} onClick={() => navigate("/report-audit")}><img src={report} alt="" />&nbsp;Report Audit</li>
                      </>)
                }


                {/* <li className={`menu-horizontal center-vertical ${pathname[1] === "utility" ? "menu-active" : ""}`} onClick={() => navigate("/utility")}><img src={utility} alt="" />&nbsp;Utility</li>
              <li className={`menu-horizontal center-vertical ${pathname[1] === "aplikasi" ? "menu-active" : ""}`} onClick={() => navigate("/aplikasi")}><img src={gadai} alt=""/>&nbsp;Aplikasi</li>
              <li className={`menu-horizontal center-vertical ${pathname[1] === "fa" ? "menu-active" : ""}`} onClick={() => navigate("/fa")}><img src={fna} alt=""/>&nbsp;F&A</li>
              <li className={`menu-horizontal center-vertical ${pathname[1] === "closing" ? "menu-active" : ""}`} onClick={() => navigate("/closing")}><img src={closing} alt=""/>&nbsp;Closing</li>
              <li className={`menu-horizontal center-vertical ${pathname[1] === "report" ? "menu-active" : ""}`} onClick={() => navigate("/report")}><img src={report} alt=""/>&nbsp;Report</li> */}

                {/* {
              menu?.Header?.map((item) => (
                <li className={`menu-horizontal center-vertical ${pathname[1] === item?.link?.slice(1,item?.link?.length)    ? "menu-active" : ""}`} onClick={() => navigate(item?.link)}>
                  {
                    item?.icon[0].toUpperCase() + item?.icon.slice(1).toLowerCase() === "Utility" ?
                      <img src={utility} alt="" /> :
                      item?.icon[0].toUpperCase() + item?.icon.slice(1).toLowerCase() === "Gadai" ?
                        <img src={gadai} alt="" /> :
                        item?.icon[0].toUpperCase() + item?.icon.slice(1).toLowerCase() === "Fa" ?
                          <img src={fna} alt="" /> :
                          item?.icon[0].toUpperCase() + item?.icon.slice(1).toLowerCase() === "Report" ?
                            <img src={report} alt="" /> :
                            <img src={closing} alt="" />
                  }

                  &nbsp;{item?.title}</li>
              ))
            } */}

              </ul>
            </div>

            <div className="col-2 d-flex d-md-none">
              <img src={hamburgerMenu} alt="burgermenu" className="col-8" onClick={handleSidebar} />
            </div>

            <div className="col-md-2 col-6 center-vertical">
              <div className="row color-white menu-horizontal">
                <div className="col-12">
                  <span>System Date : {dayjs(dataProfile?.system_date).format("DD/MM/YYYY")}</span>
                </div>
              </div>
            </div>
            <div className='col-md-3 col-4' onClick={() => setModalProfile(!modalProfile)}>
              <div className='row color-white center-vertical menu-horizontal float-right'>
                <div className='col-md-2 col-12'>
                  <img className="img-rounded mx-auto" src={dataProfile?.profile?.image || defaultProfile} alt="" width="30" height="30" />
                </div>
                <div className='col-10'>
                  <div className='row text-left m-l-0 elipsis-name'>
                    <span>{dataProfile?.profile?.nama}</span>
                  </div>
                  <div className='row text-left m-l-0 elipsis-name'>
                    <span className={`${decodeAuth().isSyariah === 1 ? `color-syariah` : `color-gray`} font-12`}>{dataProfile?.profile?.initial_cabang} - {dataProfile?.profile?.nama_jabatan_karyawan}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='containter-fluid'>
          <div className='row full-width'>
            <div className='col-12 d-none d-md-block'>
              {/* <Navbar data={menu?.Header?.filter((x) => x?.title?.toLowerCase() === pathname[1])} /> */}
              <Navbar hidemodal={() => setModalProfile(false)} userId={dataProfile?.profile?.id_jabatan_karyawan} data={pathname[1] === "utility" ? decodeAuth().isSyariah === 1 ? UtilitySyari : Utility : pathname[1] === "aplikasi" ? decodeAuth().isSyariah === 1 ? AplikasiSyari : Aplikasi : pathname[1] === "fa" ? decodeAuth().isSyariah === 1 ? FASyari : FA : pathname[1] === "closing" ? decodeAuth().isSyariah === 1 ? ClosingSyari : Closing : pathname[1] === "report" ? decodeAuth().isSyariah === 1 ? ReportSyari : Report : pathname[1] === "audit" ? decodeAuth().isSyariah === 1 ? auditSyari : audit : pathname[1] === "report-audit" ? decodeAuth().isSyariah === 1 ? ReportAuditSyari : ReportAudit : []} />
            </div>
          </div>
          <div className="row">
            <div className='col-12 content-pad'>
              <div className='row'>
                <div className='col-12 pb-3'>
                  <ul className="inline-list">{""}</ul>
                </div>
              </div>
            </div>
          </div>
          <div className='row content-container'>
            <div className='col-12 p-4' >
              {window.location.pathname !== "/ubah-password" ? generateBreadCrumb(window.location.pathname) : null}
              {children}
            </div>
          </div>
          <div className='row m-t-10 mb-4'>
            <div className='col-12 text-center'>
              <span>{dayjs().format('YYYY')} &copy; ProMAS. All rights reserved.</span>
            </div>
          </div>
        </div>


        {/* Sidebar */}
        <Offcanvas show={show} onHide={handleSidebar}>
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>List Menu</Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body >
            <div className="d-block"  >
              <div className="d-flex flex-wrap justify-content-center">

                <li style={{ display: aksesMenu.utility.includes(dataProfile?.profile?.id_jabatan_karyawan) ? "" : "none" }} className={`menu-horizontal center-vertical menu-mobile ${pathname[1] === "utility" ? "menu-active" : ""}`} onClick={() => navigate("/utility")}><img src={utility} alt="" />&nbsp;Utility</li>


                <li style={{ display: aksesMenu.aplikasi.includes(dataProfile?.profile?.id_jabatan_karyawan) ? "" : "none" }} className={`menu-horizontal center-vertical menu-mobile ${pathname[1] === "aplikasi" ? "menu-active" : ""}`} onClick={() => navigate("/aplikasi")}><img src={gadai} alt="" />&nbsp;Aplikasi</li>


                <li style={{ display: aksesMenu.fa.includes(dataProfile?.profile?.id_jabatan_karyawan) ? "" : "none" }} className={`menu-horizontal center-vertical menu-mobile ${pathname[1] === "fa" ? "menu-active" : ""}`} onClick={() => navigate("/fa")}><img src={fna} alt="" />&nbsp;F&A</li>


                <li style={{ display: aksesMenu.closing.includes(dataProfile?.profile?.id_jabatan_karyawan) ? "" : "none" }} className={`menu-horizontal center-vertical menu-mobile ${pathname[1] === "closing" ? "menu-active" : ""}`} onClick={() => navigate("/closing")}><img src={closing} alt="" />&nbsp;Closing</li>


                <li style={{ display: aksesMenu.report.includes(dataProfile?.profile?.id_jabatan_karyawan) ? "" : "none" }} className={`menu-horizontal center-vertical menu-mobile ${pathname[1] === "report" ? "menu-active" : ""}`} onClick={() => navigate("/report")}><img src={report} alt="" />&nbsp;Report</li>

                <li style={{ display: aksesMenu.report.includes(dataProfile?.profile?.id_jabatan_karyawan) ? "" : "none" }} className={`menu-horizontal center-vertical menu-mobile ${pathname[1] === "audit" ? "menu-active" : ""}`} onClick={() => navigate("/audit")}><img src={report} alt="" />&nbsp;Audit</li>

              </div>

              <NavbarMobile hidemodal={() => setModalProfile(false)} hideModalMobile={() => setShow(false)} userId={dataProfile?.profile?.id_jabatan_karyawan} data={pathname[1] === "utility" ? Utility : pathname[1] === "aplikasi" ? Aplikasi : pathname[1] === "fa" ? FA : pathname[1] === "closing" ? Closing : pathname[1] === "report" ? Report : pathname[1] === "audit" ? audit : pathname[1] === "audit-report" ? ReportAudit : []} />

            </div>
          </Offcanvas.Body>
        </Offcanvas>
      </>
    );
  }

}

const mapStateToProps = function (state) {

  return {
    isLoading: state.global.isLoading,
    isMenu: state.menu.isMenu,
    isProfile: state.profile
  };
};

export default connect(mapStateToProps)(Layout);