import React from 'react'
import { useEffect } from 'react'
import { useRef } from 'react'
import { useState } from 'react'
import { FileUploader } from 'react-drag-drop-files'
import { useDropzone } from 'react-dropzone'
import { toast } from 'react-toastify'
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import './dropInput.css'
import fileIcon from 'styles/images/files.png'
import { decodeAuth } from "utils/cookie";

const FileInput = ({ inputLabel, fullWidth, isRequired, isBold, handleOnchange , inputName, requiredFile,labelFileName }) => {
    const [file, setFile] = useState()
    const [temp, setTemp] = useState()
    const [show, setShow] = useState(false)
    const [dragActive, setDragActive] = useState(false)
    const ref = useRef(null)

    const handleChange = () => {
        setShow(false)
        setFile(temp)
        console.log(inputName)
        handleOnchange(temp, inputName)
    }

    const handleDrag = (v) => {
        // console.log(v)
        v.preventDefault();
        v.stopPropagation();
        if (v.type === "dragenter" || v.type === "dragover") {
            setDragActive(true);
        } else if (v.type === "dragleave") {
            setDragActive(false);
        }
    }

    const handleDrop = function (e) {
        e.preventDefault();
        e.stopPropagation();
        setDragActive(false);
        if (e.dataTransfer.files && e.dataTransfer.files[0]) {
            if (requiredFile) {
                if (requiredFile.includes(e.dataTransfer.files[0]?.type?.split('/')[1])) {
                    setTemp(e.dataTransfer.files[0])
                } else {
                    toast.error("format file tidak sesuai")
                }
            } else {
                setTemp(e.dataTransfer.files[0])
            }
            // console.log(e.dataTransfer.files)
            // at least one file has been dropped so do something
            // handleFiles(e.dataTransfer.files);
        }
    };

    const handleManual = (v) => {
        if (requiredFile) {
            if (requiredFile.includes(v.target.files[0]?.type?.split('/')[1])) {
                setTemp(v.target.files[0])
            } else {
                toast.error("format file tidak sesuai")
            }
        } else {
            setTemp(v.target.files[0])
        }
    }

    const manualInput = () => {
        ref.current.click()
    }


    useEffect(() => {
        if (!show) {
            setTemp(null)
        }
    }, [show])





    return (
        <>
            <Modal
                isOpen={show}
                centered
                size="lg"
                scrollable
                toggle={() => console.log}
            >
                <ModalHeader toggle={() => setShow(false)}>Input File</ModalHeader>
                <ModalBody>
                    <div className="row">
                        <div className="col-12">
                            <input accept={requiredFile ? requiredFile?.map((x) => (`.${x}`)) : "*"} type="file" className="file-manual"  onChange={(v) => handleManual(v)} ref={ref} />
                        </div>
                        <div onClick={manualInput} style={{cursor: "pointer"}} className={dragActive ? 'drop-zone active' : 'drop-zone'} onDragEnter={handleDrag} onDragLeave={handleDrag} onDragOver={handleDrag} onDrop={handleDrop} >
                          {!temp ? <>
                            <p>Tarik File Disini</p>
                            <p>atau</p>
                            <button className='btn-manual btn' >Click Disini</button>
                          </> : <>
                            <div className="border col-10 mx-auto py-4">
                            <img src={fileIcon} alt="img" className='image'  />
                          <h5 className='text-center'>{temp?.name}</h5>
                            </div>
                          <h4 style={{color: "black", marginTop: "0.8em"}} >Ganti File ?</h4>
                            <div className="d-flex justify-content-center">
                            <p>Tarik File disini atau </p>
                            <p style={{marginLeft: "0.5em", fontWeight: "bold", color: "black"}}>Click Disini</p>
                            </div>
                          </> }
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={() => setShow(false)}>
                        Batal
                    </Button>
                    <Button color={decodeAuth().isSyariah === 1  ? "success" : "primary"} disabled={!temp ? true : false}  onClick={() => handleChange()}>
                        Simpan
                    </Button>
                </ModalFooter>
            </Modal>
            <div className={`mt-3 ${fullWidth? "col-lg-12" : "col-lg-6"} d-flex`} style={{fontWeight: isBold ? 'bold' : 'normal'}}>
                <div className={`${fullWidth ? "col-lg-3" : "col-lg-5"}`}>
                    <label class="txt-body-modal">{inputLabel} <span style={{ color: `${isRequired ? 'red' : ''}` }}>
                        {isRequired ? '*' : ''}</span>
                    </label>
                </div>
                <div className={`${fullWidth? "col-lg-9 ml-full" : "col-lg-7"}`}>
                    <button className={`btn-cek2 ${decodeAuth().isSyariah === 1 ? 'btn-primary-syariah' : 'btn-primary'}`} onClick={() => setShow(true)}>
                        {!file ? "Pilih File" : labelFileName || file?.name}
                    </button>
                </div>
            </div>
        </>


    )
}

export default FileInput