import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import DataTable from "react-data-table-component";
import { customStyles } from "components/CustomStyles/CustomStyles";
import Swal from "sweetalert2";
import MastersApi from "services/masters/masters.api";
import { setLoadingAsync } from "services/globals/loading.reducer";
import "./MutasiHoCabang.css";
import Formatting from "utils/formattingJson";
import InputGroup from "components/GroupInput/InputGroup";
import cloneDeep from "lodash.clonedeep";
import { Button } from "reactstrap";
import dayjs from "dayjs";
import FotoSelfieDropping from "./FotoSelfieDropping";
import ModalView from 'pages/Fa/Finance/PettyCashTransaction/ModalView';

const Dropping = ({ history, dispatch, isLoading, isProfile }) => {
  const [statusKasir, setStatusKasir] = useState(0);
  const [selectedWilayah, setSelectedWilayah] = useState(null);
  const [modalFotoSelfie, setModalFotoSelfie] = useState(false);
  const [imageSelfie, setImageSelfie] = useState();
  const [keterangan, setKeterangan] = useState();
  const [wilayahOptions, setWilayahOptions] = useState();
  const [selectedRows, setSelectedRows] = useState([]);
  const [paramsFetch, setParamsFetch] = useState({
    page: 1,
    page_size: 10,
    data: [],
    total: 0,
    total_page: 10,
  });
  const [detailData, setDetailData] = useState(null)
  const [modalView, setModalView] = useState(false)
  const [toggledClearRows, setToggleClearRows] = React.useState(false);

  useEffect(() => {
    dispatch(setLoadingAsync(true));
    fetchData(1, 10)
      .then((res) => {

    
        setParamsFetch(res);
        dispatch(setLoadingAsync(false));
      })
      .catch((err) => {
        dispatch(setLoadingAsync(false));
        console.log(err.response.data.Response.errorMessage);
        Swal.fire("Maaf", err.response.data.Response.errorMessage, "error");
      });
  }, []);

  console.log(paramsFetch)

  const handleClearRows = () => {
    setToggleClearRows(!toggledClearRows);
  };

  const fetchData = () => {
    return MastersApi.getFullFillPettyCash(`?is_status=13&is_transfer=1&status_tab=dropping_bfo`);
  };

  const onChangeHandle = (v) => {
    setKeterangan(v.target.value);
  };



  const keteranganForm = [
    // {
    //   formType: ''
    // },
    {
      formType: "textArea",
      label: "Keterangan",
      value: keterangan,
      onChange: onChangeHandle,
    },
  ];

  const columns = [
    {
      name: "Nominal Transfer",
      selector: (row) => Formatting.currency(row.total_pengajuan),
      center: true,
      // sortable: true,
    },
    {
      name: "Saldo Petty cash",
      selector: (row) => Formatting.currency(row.SaldoPettyCash),
      center: true,
      // sortable: true,
    },
    {
      name: "Plafon",
      selector: (row) => Formatting.currency(row.plafon),
      center: true,
      // sortable: true,
    },
    {
      name: "Jenis Transaksi",
      selector: (row) => "Petty Cash",
      center: true,
      // sortable: true,
    },
    {
      name: "Nama Unit",
      selector: (row) => row.nama_unit,
      center: true,
      // sortable: true,
    },
    {
      name: "Kode Unit",
      selector: (row) => row?.kode_unit,
      center: true,
      // sortable: true,
    },
    {
      name: "No",
      cell: (row, i) => i + 1,
      center: true,
      width: "5%",
    },
  ];

  const handleChangeCheck = ({ allSelected, selectedRows, selectedCount }) => {
    setSelectedRows(selectedRows);
  };

  // const rowSelectCritera = row => selectedRows.filter((x) => x.index === row.index).length > 0;

  const submit = () => {
    Swal.fire({
      title: "Dropping Petty cash",
      text: "Dropping " + selectedRows?.length + " Data ?",
      icon: "question",
      showCancelButton: true,
      reverseButtons: true,
      cancelButtonText: "Batal",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Submit",
    }).then((result) => {
      if (result.isConfirmed) {
        setModalFotoSelfie(true);
      }
    });
  };

  const formPengajuanSelfie = (x) => {


    let dataDropping = [];
    selectedRows?.map((v) => {
      dataDropping.push({
        id_unit: v?.id_unit,
        id_buffer_petty_cash: v?.id_buffer_petty_cash,
      });
    });


    dispatch(setLoadingAsync(true));
    MastersApi.PostDroppingPettyCashBFO({ is_status: 14,  selfie_ofs_dropping: x,  keterangan_ofs_dropping: keterangan || "",  data: dataDropping })
      .then((res) => {
        fetchData(paramsFetch?.page, paramsFetch?.page_size)
          .then((res) => {
            setParamsFetch(res);
            dispatch(setLoadingAsync(false));
          })
          .catch((err) => {
            dispatch(setLoadingAsync(false));
            console.log(err.response.data.Response.errorMessage);
            Swal.fire("Maaf", err.response.data.Response.errorMessage, "error");
          });
        Swal.fire("Berhasil", "Proses Dropping Berhasil", "success");
        setSelectedWilayah(null);
        setKeterangan("");
        handleClearRows();
      })
      .catch((err) => {
        dispatch(setLoadingAsync(false));
        console.log(err.response.data.Response.errorMessage);
        Swal.fire("Maaf", err.response.data.Response.errorMessage, "error");
      });
  };

  return (
    <>
      {modalView && <ModalView obj={detailData} title="History Petty Cash" hideModal={() => setModalView(!modalView)} show={modalView} />}
      {modalFotoSelfie && <FotoSelfieDropping setModalFotoSelfie={setModalFotoSelfie} modalFotoSelfie={modalFotoSelfie} dispatch={dispatch} form={imageSelfie} setForm={formPengajuanSelfie} />}
      <div className="row mt-4">
        <div className="col-12">
          <DataTable
            columns={columns}
            data={paramsFetch?.master_cabang_petty_cash}
            customStyles={customStyles}
            highlightOnHover
            // pagination
            dense
            responsive
            persistTableHead
            fixedHeader
            paginationServer
            selectableRows
            direction={"RTL"}
            selectableRowsHighlight={true}
            onSelectedRowsChange={handleChangeCheck}
            // selectableRowSelected={rowSelectCritera}
            clearSelectedRows={toggledClearRows}
          // onChangeRowsPerPage={onChangePageSizeApp}
          // onChangePage={onChangePaginationApp}
          />
        </div>
        <div className="col-12 mt-2">
          <InputGroup formGroup={keteranganForm} />
        </div>
        <div className="col-12 mb-4 mt-4">
          <Button color="primary" disabled={selectedRows?.length === 0} onClick={() => submit()}>
            Dropping
          </Button>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = function (state) {
  return {
    isLoading: state.global.isLoading,
    isProfile: state.profile,
  };
};

export default connect(mapStateToProps)(Dropping);
