import { ADMIN, AUDIT_HEAD, AUDIT_STAFF } from "utils/ConstJabatan";

export const ReportAudit = [
    {
        title: "Audit Virtual",
        akses: [ADMIN, AUDIT_HEAD, AUDIT_STAFF],
        submenu: [
            // {
            //     title: "Wilayah",
            //     akses: [ADMIN, AUDIT_HEAD, AUDIT_STAFF],
            //     link: "/report-audit/audit-virtual/wilayah"
            // },
            {
                title: "Outlet",
                akses: [ADMIN, AUDIT_HEAD, AUDIT_STAFF],
                link: "/report-audit/audit-virtual/outlet"
            },
            {
                title: "Laporan penggunaan Sistem",
                akses: [ADMIN, AUDIT_HEAD, AUDIT_STAFF],
                link: "/report-audit/laporan-penggunaan-sistem"
            },
            {
                title: "Laporan Grade Audit Virtual Outlet",
                akses: [ADMIN, AUDIT_HEAD, AUDIT_STAFF],
                link: "/report-audit/audit-virtual/grade-audit-virtual-outlet"
            },
        ]
    },
    // {
    //     title: "Audit General",
    //     akses: [ADMIN, AUDIT_HEAD, AUDIT_STAFF],
    //     submenu: [
    //         {
    //             title: "Wilayah",
    //             akses: [ADMIN, AUDIT_HEAD, AUDIT_STAFF],
    //             link: "/report-audit/audit-general/wilayah"
    //         },
    //         {
    //             title: "Outlet",
    //             akses: [ADMIN, AUDIT_HEAD, AUDIT_STAFF],
    //             link: "/report-audit/audit-general/outlet"
    //         },
    //     ]

    // },
    // {
    //     title: "Audit Rutin",
    //     akses: [ADMIN, AUDIT_HEAD, AUDIT_STAFF],
    //     submenu: [
    //         {
    //             title: "Wilayah",
    //             akses: [ADMIN, AUDIT_HEAD, AUDIT_STAFF],
    //             link: "/report-audit/audit-rutin/wilayah"
    //         },
    //         {
    //             title: "Outlet",
    //             akses: [ADMIN, AUDIT_HEAD, AUDIT_STAFF],
    //             link: "/report-audit/audit-rutin/outlet"
    //         },
    //     ]

    // }
  
]