import { connectRouter } from "connected-react-router";
import { combineReducers } from "redux";
import loadingReducer from "../services/globals/loading.reducer";
import menuReducer from "../services/globals/menu.reducer";
import profileReducer from "../services/globals/Profile.reducer"

const makeRootReducer = (history) => {
  return combineReducers({
    router: connectRouter(history),
    global: loadingReducer,
    menu: menuReducer,
    profile: profileReducer,
  });
};

export default makeRootReducer;
