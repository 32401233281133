import { applyMiddleware, createStore } from "redux";
import thunkMiddleware from "redux-thunk";
import { createBrowserHistory } from 'history';

import rootReducer from "./reducers";

export default function configureStore(preloadedState) {
  const history = createBrowserHistory();
//   history.listen((location) => {
//     ReactGA.set({ page: location.pathname });
//     ReactGA.pageview(location.pathname);
//   });

  const store = createStore(rootReducer(history), preloadedState, applyMiddleware(thunkMiddleware));
    
  if (process.env.NODE_ENV !== "production" && module.hot) {
    module.hot.accept("./reducers", () => store.replaceReducer(rootReducer));
  }

  return store;
}
