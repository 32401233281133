import { ADMIN, AUDIT_HEAD, AUDIT_STAFF } from "utils/ConstJabatan";

export const audit = [
    {
            title: "Audit Virtual",
            akses: [ADMIN,AUDIT_HEAD,AUDIT_STAFF],
            submenu: [
                {
                  title: "Finance",
                  akses: [ADMIN,AUDIT_HEAD,AUDIT_STAFF],
                  submenu: [
                    {
                        title: "Open Cashier",
                        akses: [ADMIN,AUDIT_HEAD,AUDIT_STAFF],
                        link: "/audit/audit-virtual/finance/open-cashier"
                    },
                    {
                        title: "Close Cashier",
                        akses: [ADMIN,AUDIT_HEAD,AUDIT_STAFF],
                        link: "/audit/audit-virtual/finance/close-cashier"
                    },
                    {
                        title: "Petty Cash",
                        akses: [ADMIN,AUDIT_HEAD,AUDIT_STAFF],
                        link: "/audit/audit-virtual/finance/petty-cash"
                    },
                    {
                        title: "BAP",
                        akses: [ADMIN,AUDIT_HEAD,AUDIT_STAFF],
                        link: "/audit/audit-virtual/finance/bap"
                    }
                  ]
                },
                {
                    title: "Customer",
                    akses: [ADMIN,AUDIT_HEAD,AUDIT_STAFF],
                    submenu: [
                      {
                          title: "Pencairan",
                          akses: [ADMIN,AUDIT_HEAD,AUDIT_STAFF],
                          link: "/audit/audit-virtual/customer/pencairan"
                      },
                      {
                          title: "Pelunasan",
                          akses: [ADMIN,AUDIT_HEAD,AUDIT_STAFF],
                          link: "/audit/audit-virtual/customer/pelunasan"
                      },
                    ]
                  },
            ]
    },
    // {
    //     title: "General Audit",
    //     akses: [ADMIN,AUDIT_HEAD,AUDIT_STAFF],
    //     link: "/audit/general-audit"
    // },
    // {
    //     title: "Audit Rutin",
    //     akses: [ADMIN,AUDIT_HEAD,AUDIT_STAFF],
    //     link: "/audit/audit-rutin"
    // }
]