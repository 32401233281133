import { ADMIN } from "utils/ConstJabatan";

export const ClosingSyari = [
 //Closing
 {
    title: "Closing",
    akses: [ADMIN],
    submenu: [
      {
        title: "Accounting / Bulanan *",
        akses: [ADMIN],
        link: ""
      },
      {
        title: "Operasional / Harian",
        akses: [ADMIN],
        link: "/closing/closing/operasional-harian"
      },
    ]
  },
];