import React, { lazy, Suspense } from "react";
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from "react-router-dom";
import Loading from "./components/Loading/Loading";
import PrivateRoute from "./components/Layout/PrivateRoutes";
import { getToken, decodeAuth } from "utils/cookie";
import AppKonven from "AppKonven";
import AppSyariah from "AppSyariah";

const Login = lazy(() => import("./pages/Login/Login"));

// console.log("AKUN",decodeAuth())

export const App = (props) => {
  return (
    <Router>
      <Suspense fallback={<Loading />}>
      {/* <Routes>
          <Route path="/" element={<Navigate replace to="/login" />} />
          <Route exact path="/login" element={<Login />} />
          <Route exact path="/home" element={<PrivateRoute><div></div> </PrivateRoute>} />
      </Routes> */}

          {decodeAuth().isSyariah === 1 ? <AppSyariah /> : <AppKonven />}
      </Suspense>
    </Router>
  );
};