import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import DataTable from 'react-data-table-component';
import ModalNew from "./ModalNew";
import ModalEdit from "./ModalEdit";
import ModalPengeluaran from "./ModalPengeluaran";
import ModalView from "./ModalView";
import { customStyles } from "components/CustomStyles/CustomStyles";
import Swal from 'sweetalert2';
import MastersApi from "services/masters/masters.api";
import { setLoadingAsync } from "services/globals/loading.reducer"
import AuthApi from "services/auth/auth.api";
import { ALL_MODUL_KA_UNIT, KASIR, KA_UNIT, KA_UNIT_PENAKSIR, PENAKSIR_KASIR } from "utils/ConstJabatan";
import Formatting from "utils/formattingJson";
import LockOpenCashier from "components/Layout/LockOpenCashier";
import InputGroup from "components/GroupInput/InputGroup";


const FleggingVoucher = ({ history, dispatch, isLoading, isProfile }) => {

  const [kodeVoucher, setKodeVoucher] = useState(null)


  const changeProses = () => {



    if (!kodeVoucher) {
      Swal.fire(
        'Warning',
        'Isi Kode Voucher Terlebih Dahulu',
        'warning'
      )
      return
    }

    Swal.fire({
      title: 'Konfirmasi',
      icon: 'question',
      text: `Anda Yakin Ingin Flegging Kode Voucher ${kodeVoucher}`,
      showDenyButton: true,
      denyButtonText: 'Batal',
      confirmButtonText: 'Submit',
    }).then((calls) => {
      if (calls.isConfirmed) {
        dispatch(setLoadingAsync(true))
        MastersApi.postFleggingVoucher({
          "kode_voucher": kodeVoucher,
        }).then((res) => {
          Swal.fire(
            'Success',
            `Berhasil Flegging Kode Voucher ${kodeVoucher}`,
            'success'
          )
            setKodeVoucher(null)
          dispatch(setLoadingAsync(false))

        }).catch((err) => {
          dispatch(setLoadingAsync(false));
          console.log(err.response.data.Response.errorMessage)
          Swal.fire(
            'Maaf',
            err.response.data.Response.errorMessage,
            'error'
          );
        })
      }
    })
  }



  let formSbg = [
  
    {
      formType: "text",
      label: "Kode Voucher",
      value: kodeVoucher,
      onChange: (v) => setKodeVoucher(v.target.value)
    },
    {
      formType: "button",
      label: "Flegging Voucher",
      onClick: changeProses,
    }


  ]





  return (
    <>
      <div className="row">
        <div className="col-10">
          <InputGroup formGroup={formSbg} />
        </div>
      </div>



    </>
  );
}

const mapStateToProps = function (state) {

  return {
    isLoading: state.global.isLoading,
    isProfile: state.profile
  };
};

export default connect(mapStateToProps)(FleggingVoucher);